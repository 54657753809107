var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"sub\">\n  <div class=\"subA\">\n    <h1>\n      <div>"
    + alias4(((helper = (helper = helpers.errorID || (depth0 != null ? depth0.errorID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"errorID","hash":{},"data":data}) : helper)))
    + "</div>\n      <div>System Error</div>\n    </h1>\n    <p>"
    + alias4(((helper = (helper = helpers.reason || (depth0 != null ? depth0.reason : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reason","hash":{},"data":data}) : helper)))
    + "</p>\n    <div class=\"img\"></div>\n  </div>\n  <hr>\n  <p><button type='button' id='logoff'>LoginPage</button></p>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}