Application = require 'application'
View        = require 'views/view'
template    = require 'views/templates/car_detail_base'
CarDetailModel = require 'models/car_detail_model'
WatchListBtnView = require 'views/watchlist_btn_view'
NegoApplyBtnView = require 'views/nego_apply_btn_view'
IndicatorView   = require 'views/indicator_view'

ExpandImageView = require 'views/expand_image_view'
module.exports = class CarDetailBase extends View
  id       : 'car_detail_base'
  template : template
  className : 'cont second'

  events:
    'click .btnOk'     : 'btnClickOk'
    'click .carImage'  : 'expandCarImage'
    'touchstart .prevCond_car_detail' : 'hoverStartMobile'
    'touchend   .prevCond_car_detail' : 'hoverEndMobile'
    'mouseover  .prevCond_car_detail' : 'hoverStartMobile'
    'mouseout   .prevCond_car_detail' : 'hoverEndMobile'
    'click      #self_image'     : 'viewSelfImage'
    'click      #tenkaizu_image' : 'viewTenkaizuImage'

  invYear : undefined
  invNo   : undefined
  #ボタン非表示に使用する。
  bottunNoFlag : undefined

  initialize: ->
    _.bindAll @
    @carDetailModel = new CarDetailModel
    @_$window = $(window)

  ###
   遷移用
  ###
  setCarData : (@invYear,@invNo,@subViewFlag) ->
    #Viewが非表示になってもモデルは消えないようなのでここで宣言する
    @stopListening()
    @_$window.off() 
    @_$window.on 'resize' , @imageSizeChange
    @listenTo @carDetailModel , 'change' , @render
    @carDetailModel.set {'invYear' : @invYear}, { silent : true}
    @carDetailModel.set {'invNo'   : @invNo  }, { silent : true}
    @carDetailModel.emitCarDetail()
    # フッタ消す
    $('#footer_container').addClass("mobile_footer_hide")

  ###
   遷移用
  ###
  setCarDataPaging : (invYear,invNo,@carDataCollection,@utilPagingView,@eventName) ->
    @pagingFlag = true
    retModel = _.find(@carDataCollection.models, (model) =>
        if model.get('inventory_year') is invYear
          if model.get('inventory_no') is invNo
            return true
        return false
      )
    @currentIndex = _.indexOf(@carDataCollection.models,retModel)
    ###
     イベントセット
    ###
    newIvent = {
      'click .cardetail_next_btn'  : 'carDetailNext'
      'click .cardetail_prev_btn'  : 'carDetailBack'
    }
    _.extend(@events,newIvent)
    @setCarData invYear,invNo,true

  carDetailNext : ->
    index = @currentIndex + 1
    if index >= @carDataCollection.models.length
      pageCnt = parseInt(@utilPagingView.dataCount / @utilPagingView.limit)
      if parseInt(@utilPagingView.page) is pageCnt
        return
      #次のページへ遷移しindexを0から始める
      @indicator = new IndicatorView
        title   : Backbone.AUCNET.message.get "ms_indicator_carname_title"
      $('body').append(@indicator.render().el)
      @utilPagingView.goNext()
      @listenToOnce @carDataCollection, @eventName, =>
        @pagingCarData(0)
    else
      @pagingCarData(index)

  carDetailBack : ->
    index = @currentIndex - 1
    if index < 0
      if parseInt(@utilPagingView.page) is 0
        return
      
      @indicator = new IndicatorView
        title   : Backbone.AUCNET.message.get "ms_indicator_carname_title"
      $('body').append(@indicator.render().el)
      @utilPagingView.goPrev()
      @listenToOnce @carDataCollection, @eventName, =>
        @pagingCarData(@carDataCollection.models.length - 1)
    else
      @pagingCarData(index)

  pagingCarData : (index) ->
    @undelegateEvents()
    @currentIndex = index
    @invYear = @carDataCollection.models[@currentIndex].get('inventory_year')
    @invNo   = @carDataCollection.models[@currentIndex].get('inventory_no')
    @setCarData @invYear,@invNo,true


  beforeRender : ->
    @delegateEvents @events

  #モデルを返す。
  getRenderData: ->
    pagingFlag : @pagingFlag
    carDetail   : @carDetailModel.toJSON()

  afterRender: ->
    @indicator?.suicide()

    #次へ前へボタン
    unless _.isEmpty(@carDataCollection)
      $(".cardetail_prev_btn").removeClass("carDetailOpacity");
      $(".cardetail_next_btn").removeClass("carDetailOpacity");
      pageCnt = parseInt(@utilPagingView.dataCount / @utilPagingView.limit)
      if @currentIndex <= 0 and parseInt(@utilPagingView.page) is 0
        $(".cardetail_prev_btn").addClass("carDetailOpacity");
      if @currentIndex >= ( @carDataCollection.models.length - 1 ) and parseInt(@utilPagingView.page) is pageCnt
        $(".cardetail_next_btn").addClass("carDetailOpacity");

    if @subViewFlag
      #商談候補ボタン追加
      @watchListBtnView = new WatchListBtnView
        invNo   : @carDetailModel.get('inventory_no')
        invYear : @carDetailModel.get('inventory_year')
        loginId : @options.loginId
        btnClass: 'detailsaddToBtn'
      @$el.find(".WatchListBtn").append(@watchListBtnView.el)
      @watchListBtnView.checkWatchList()

      #商談申し込みボタン追加
      @negoApplyBtnView = new NegoApplyBtnView
        invNo      : @carDetailModel.get('inventory_no')
        invYear    : @carDetailModel.get('inventory_year')
        btnClass: 'detailsaddToBtn'
      @$el.find(".ApplyNegoBtn").append(@negoApplyBtnView.el)
      @negoApplyBtnView.checkNego()
    # 全画面化
    # wHeight = $(window).height()
    # @$el.find(".cont").css "height", wHeight + "px"
    @imageSizeChange()

  imageSizeChange : =>
    # 画像表示枠の高さ判定(PCのみ縦幅を指定しない)
    userAgent = window.navigator.userAgent.toLowerCase();
    #if (userAgent.indexOf('ipad') is -1) or (userAgent.indexOf('iphone') is -1) or (userAgent.indexOf('iphone') is -1) or (userAgent.indexOf('android') is -1) or (userAgent.indexOf('mobile') is -1)
    $modalDetails = @$el.find('.modalDetails')
    if $modalDetails.height() isnt 0
      @test_param = $modalDetails.height()
      @test_param = @test_param + 10

    # 展開図が読み込まれていない時, 展開図の高さを付加
    # jQuery#height()はnullを返す場合があるので一旦文字列にしてから数字にする
    if +($modalDetails.find(".car_detail_img_area.tenkaizu_image img").height() + "") < 40
      # オリジナル画像幅 : 画像高さ = 229px : 339px
      # 表示幅 : 画像幅（cssで70％指定されてる） : 画像縦 = 100 : 70 : 約103
      tenkaizuHeight = Math.floor( $modalDetails.width() * 1.03)
      @test_param = @test_param + tenkaizuHeight

    # 自己申告画像の時
    if +($modalDetails.find(".car_detail_img_area.self_image img").height() + "") < 40
      # オリジナル画像幅 : 画像高さ = 640px : 480px
      # 表示幅 : 画像幅（cssで70％指定されてる） : 画像縦 = 100 : 70 :
      selfImageHeight = Math.floor( $modalDetails.width() * 0.52)
      @test_param = @test_param + selfImageHeight

    @$el.find(".imageBox").css "height", @test_param + "px"

  btnClickOk: ->
    $(document).unbind('keydown', @disableFocusOut)
    $(document).unbind('keydown', @disableEnterPress)

    # $('#main_container').show()
    #
    #$('.slider').css "left", "0%"
    Backbone.AUCNET.RenderController.returnView()

  disableFocusOut: (e) ->
    if e.keyCode is 9
      e.stopPropagation()
      e.preventDefault()
      $(e.currentTarget).focus()
      return

  disableEnterPress: (e) ->
    #if e.keyCode is 13
    #  console.log 'PRESS ENTER'
    return

  viewSelfImage : =>
    localStorage.ImageUrl = Backbone.AUCNET.DEFINE.get('PICTURE_URL') + "00" 
    localStorage.ImageIndex = JSON.stringify(0)
    localStorage.ImageArray   =  JSON.stringify([@carDetailModel.get('self_image')])
    localStorage.ImageType = "0"
    window.open("./image_view.html");

  viewTenkaizuImage  : =>
    localStorage.ImageUrl = "data:image/png;base64,"
    localStorage.ImageIndex = JSON.stringify(0)
    localStorage.ImageArray   =  JSON.stringify([@carDetailModel.get('imageFile')])
    localStorage.ImageType = "1"
    window.open("./image_view.html");

  expandCarImage : (param) =>
    imageUrl = param.currentTarget.id
    localStorage.ImageUrl =   Backbone.AUCNET.DEFINE.get('PICTURE_URL') + "00" 
    localStorage.ImageIndex = JSON.stringify(_.indexOf(@carDetailModel.get('image'),imageUrl))
    localStorage.ImageArray   =  JSON.stringify(@carDetailModel.get('image'))
    localStorage.ImageType = "0"
    window.open("./image_view.html");

  #自分自身を削除する。
  suicide: ->
    #modelが削除済みの場合があるためチェック
    @carDetailModel?.destory() unless _.isEmpty(@carDetailModel?.destory)
    @watchListBtnView?.suicide()
    @negoApplyBtnView?.suicide()
    @.remove()
    # フッタ見せる
    $('#footer_container').removeClass("mobile_footer_hide")
