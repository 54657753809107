Application = require 'application'
View        = require 'views/view'
template    = require 'views/templates/nego_apply_btn'
NegoApplyBtnModel = require 'models/nego_apply_btn_model'
NegoApplyView = require 'views/nego_apply_view'

module.exports = class NegoApplyBtnView extends View
  id       : 'nego_apply_btn'
  template : template

  BTN_APLLY = 'lb_nego_apply'
  BTN_SEE   = 'lb_nego_see'
  BTN_IMPROPER = 'lb_watchlist_improper'

  events:
    'click .negoApplyBtn' : 'btnClickNegoApply'

  initialize: =>
    #console.debug 'NegoApplyBtnView#initialize'

    #モデルをクラス変数にセット
    @model = new NegoApplyBtnModel
      invNo   : @options.invNo
      invYear : @options.invYear
      loginId : @options.loginId
    @listenTo @model, 'change', @render
    return

  afterRender: ->
    if @options.btnClass is 'detailsaddToBtn'
      @$el.find("button").removeClass('addToBtn').addClass('detailsaddToBtn')

  checkNego : =>
    @model.checkNego()

  afterRender: =>
    retJson = @model.toJSON()
    #if retJson.ret is false
      #console.debug @$el.find(".negoApplyBtn")

  getRenderData: =>
    #console.debug 'NegoApplyBtnView#getRenderData '
    BtnLabel : @model.get 'message'

  btnClickNegoApply : (param) =>
    label = param.currentTarget.value
    if label is BTN_SEE
      negoId = @model.get 'negoId'
      #URLなし
      Backbone.AUCNET.RenderController.renderNegoPage negoId, @options.invYear, @options.invNo,true
      #URLあり
      #Application.router.navigate "nego/#{negoId}/#{@options.invYear}/#{@options.invNo}", true

    else if label is BTN_APLLY
      json =
        inventoryYear : @options.invYear
        inventoryNo   : @options.invNo
      Backbone.AUCNET.RenderController.renderNegoApply [json], false

    else
      userAgent = window.navigator.userAgent.toLowerCase();
      if (userAgent.indexOf('iphone') isnt -1) or (userAgent.indexOf('android') isnt -1) or (userAgent.indexOf('mobile') isnt -1)
        @showMsgBox('ms_how_to_buy1', "ms_how_to_buy_title")
        $(".appBoxMsgArea").append('<a href="tel:0364402222">(03-6440-2222)</a> ')
        $(".appBoxMsgArea").append(Backbone.AUCNET.message.get 'ms_how_to_buy2')

      else
        @showMsgBox('ms_how_to_buy3', "ms_how_to_buy_title")
