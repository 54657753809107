Application = require 'application'
View        = require 'views/view'
Template    = require 'views/templates/search_conditions'
Label       = require 'models/label_model'
IndicatorView   = require 'views/indicator_view'
###
 [車両検索] 条件選択ビュー
###
module.exports = class SearchConditionsView extends View
  id          : 'search_conditions'
  template    : Template

  # 走行距離要素
  MILEAGE_ITEM :
    [
      {"cd" : "",   "disp" : "lb_search_cond_none"       }
      {"cd" : "0",  "disp" : "lb_search_cond_mileage_1" }
      {"cd" : "1",  "disp" : "lb_search_cond_mileage_2" }
      {"cd" : "2",  "disp" : "lb_search_cond_mileage_3" }
      {"cd" : "3",  "disp" : "lb_search_cond_mileage_4" }
      {"cd" : "4",  "disp" : "lb_search_cond_mileage_5" }
      {"cd" : "5",  "disp" : "lb_search_cond_mileage_6" }
      {"cd" : "6",  "disp" : "lb_search_cond_mileage_7" }
      {"cd" : "7",  "disp" : "lb_search_cond_mileage_8" }
      {"cd" : "8",  "disp" : "lb_search_cond_mileage_9" }
      {"cd" : "9",  "disp" : "lb_search_cond_mileage_10" }
      {"cd" : "10", "disp" : "lb_search_cond_mileage_11" }
      {"cd" : "11", "disp" : "lb_search_cond_mileage_12" }
      {"cd" : "12", "disp" : "lb_search_cond_mileage_13" }
      {"cd" : "13", "disp" : "lb_search_cond_mileage_14" }
      {"cd" : "14", "disp" : "lb_search_cond_mileage_15" }
      {"cd" : "15", "disp" : "lb_search_cond_mileage_16" }
    ]

  # 評価点要素
  ESTIMATION_ITEM :
    [
      {"cd" : "0", "disp" : "lb_search_cond_none"          }
      {"cd" : "12", "disp" : "lb_search_cond_estimation_1"  }
      {"cd" : "11", "disp" : "lb_search_cond_estimation_2"  }
      {"cd" : "10", "disp" : "lb_search_cond_estimation_3"  }
      {"cd" : "9",  "disp" : "lb_search_cond_estimation_4"  }
      {"cd" : "8",  "disp" : "lb_search_cond_estimation_5"  }
      {"cd" : "7",  "disp" : "lb_search_cond_estimation_6"  }
      {"cd" : "6",  "disp" : "lb_search_cond_estimation_7"  }
      {"cd" : "5",  "disp" : "lb_search_cond_estimation_8"  }
      {"cd" : "4",  "disp" : "lb_search_cond_estimation_9"  }
      {"cd" : "3",  "disp" : "lb_search_cond_estimation_10" }
      {"cd" : "2",  "disp" : "lb_search_cond_estimation_11" }
      {"cd" : "1",  "disp" : "lb_search_cond_estimation_12" }
    ]

  # 車検要素
  INSPECT_ITEM :
    [
      {"cd" : "",  "disp" : "lb_search_cond_none"      }
      {"cd" : "1", "disp" : "lb_search_cond_inspect_1" }
      {"cd" : "0", "disp" : "lb_search_cond_inspect_2" }
    ]

  # シフト要素
  SHIFT_ITEM :
    [
      {"cd" : "",  "disp" : "lb_search_cond_none"    }
      {"cd" : "1", "disp" : "lb_search_cond_shift_1" }
      {"cd" : "2", "disp" : "lb_search_cond_shift_2" }
    ]

  # 評価リスト
  ESTIMATION :
    ["R", "1", "2", "25", "3", "35", "4", "45", "5", "55", "6", "S"]

  events:
    'click #btnPrev'                      : 'goPrev'
    # 'click #btnSearchExec'                : 'goResult'
    'change .Style'                       : 'changeStyle'
    'change .Grade'                       : 'changeGrade'
    'change .Color'                       : 'changeColor'
    'change #search_cond_modelyear_from'  : 'changeModelYear'
    'change #search_cond_modelyear_to'    : 'changeModelYear'
    'change #search_cond_mileage_from'    : 'changeMileage'
    'change #search_cond_mileage_to'      : 'changeMileage'
    'change #search_cond_estimation_from' : 'changeEstimation'
    'change #search_cond_estimation_to'   : 'changeEstimation'
    'change .search_cond_inspect'         : 'changeInspect'
    'change .search_cond_shift'           : 'changeShift'
    #指定なし
    'click .Style_Non'                    : 'changeStyleNon'
    'click .Grade_Non'                    : 'changeGradeNon'
    'click .Color_Non'                    : 'changeColorNon'
    'blur #search_cond_modelyear_from'  : 'blurModelYear'
    'blur #search_cond_modelyear_to'    : 'blurModelYear'
    'blur #search_cond_mileage_from'    : 'blurMileage'
    'blur #search_cond_mileage_to'      : 'blurMileage'
    'blur #search_cond_estimation_from' : 'blurEstimation'
    'blur #search_cond_estimation_to'   : 'blurEstimation'


  # 初期化
  initialize: ->
    _.bindAll @

  # 起動
  start: ->
    #start guruguru
    @indicator = new IndicatorView
      title   : Backbone.AUCNET.message.get "ms_indicator_condition_title"
    $('body').append(@indicator.render().el)
    # モデルにイベントとコールバックを設定
    @listenToOnce @options.searchModel, 'GetStyle', @options.searchModel.getGrade
    @listenToOnce @options.searchModel, 'GetGrade', @options.searchModel.getColor
    @listenToOnce @options.searchModel, 'GetColor', @render
    # 車名取得
    @options.searchModel.getStyle()
    # カウンタ取得
    @updateCount()

  # hbsに書き出すデータを指定
  getRenderData: ->
    styleList  : @options.searchModel.get('Style')
    gradeList  : @options.searchModel.get('Grade')
    colorList  : @options.searchModel.get('Color')
    modelyear  : @makeModeYearList()
    mileage    : @MILEAGE_ITEM
    estimation : @ESTIMATION_ITEM
    inspect    : @INSPECT_ITEM
    shift      : @SHIFT_ITEM

  # レンダリング後処理
  afterRender: ->
    # デフォルト選択
    @$el.find('input[name="search_cond_inspect_value"]').val([''])
    @$el.find('input[name="search_cond_shift_value"]').val([''])

    # カウンタ取得
    @listenToOnce @options.searchModel, 'GetSearchCount', @setSearchCount
    @options.searchModel.getSearchCount @options.searchModel.getSearchConditions()

    #remove guruguru
    @indicator.suicide()

  # 年式リスト生成
  makeModeYearList: ->
    modeyearList = []
    currentYear = new Date().getFullYear()
    for year in [currentYear...(currentYear-20)]
      wareki = Backbone.AUCNET.DEFINE.get('TO_WAREKI')
      modeyearList.push {cd : year, disp : wareki[year] + Backbone.AUCNET.DEFINE.get('YEAR')}
    return modeyearList

  # 車名選択画面へ遷移
  goPrev: ->
    # 条件をクリア
    @options.searchModel.setSelectedStyleList undefined
    @options.searchModel.setSelectedGradeList undefined
    @options.searchModel.setSelectedColorList undefined
    @options.searchModel.setModelYear undefined
    @options.searchModel.setMission undefined
    @options.searchModel.setMileage undefined
    @options.searchModel.setInspect undefined
    @options.searchModel.setEstimation undefined
    # 車名選択画面を表示
    @options.parent.changeChildView 'search_car_name', false
    @updateCount()

  # 検索結果画面へ遷移
  goResult: ->
    count = @$el.find("#searchCount")
    count2 = $("#searchCount").text()
    if count? and count2 is '0'
      @showMsgBox("ms_search_condition_error", "ms_search_condition_error_title")
    else
      # 検索結果画面を表示
      @options.parent.changeChildView 'search_result', true

  # 型式変更
  changeStyle: ->
    # 選択済み型式リストを更新
    checkedStyle = @getCheckedItem(@$el.find(".Style"))
    if _.isEmpty(checkedStyle)
      $('.Style_Non').prop( 'checked' : true)
      @options.searchModel.setSelectedStyleList undefined
    else
      $('.Style_Non').prop( 'checked' : false)
      @options.searchModel.setSelectedStyleList checkedStyle
    # グレード更新
    @options.searchModel.setSelectedGradeList undefined
    @listenToOnce @options.searchModel, 'GetGrade', @updateGrade
    @options.searchModel.getGrade()
    # 色更新
    @options.searchModel.setSelectedColorList undefined
    @listenToOnce @options.searchModel, 'GetColor', @updateColor
    @options.searchModel.getColor()
    # カウンタ取得
    @updateCount()

  # グレード変更
  changeGrade: ->
    # 選択済み型式リストを更新
    checkedGrade = @getCheckedItem(@$el.find(".Grade"))
    if _.isEmpty(checkedGrade)
      $('.Grade_Non').prop( 'checked' : true)
      @options.searchModel.setSelectedGradeList undefined
    else
      $('.Grade_Non').prop( 'checked' : false)
      @options.searchModel.setSelectedGradeList checkedGrade

    # 色更新
    @options.searchModel.setSelectedColorList undefined
    @listenToOnce @options.searchModel, 'GetColor', @updateColor
    @options.searchModel.getColor()
    # カウンタ取得
    @updateCount()

  # 色変更updateCount
  changeColor: ->
    # 選択済み型式リストを更新
    checkedColor = @getCheckedItem(@$el.find(".Color"))
    if _.isEmpty(checkedColor)
      $('.Color_Non').prop( 'checked' : true)
      @options.searchModel.setSelectedColorList undefined
    else
      $('.Color_Non').prop( 'checked' : false)
      @options.searchModel.setSelectedColorList checkedColor
    # カウンタ取得
    @updateCount()

  # 年式変更
  changeModelYear: ->
    # 年式設定
    modeYearFrom = @$el.find('#search_cond_modelyear_from option:selected').val()
    modeYearTo   = @$el.find('#search_cond_modelyear_to option:selected'  ).val()

    wrkFrom = modeYearFrom
    wrrkTo  = modeYearTo
    if modeYearFrom isnt '' and modeYearTo isnt ''
      if modeYearFrom > modeYearTo
        modeYearFrom = wrrkTo
        modeYearTo = wrkFrom

    @options.searchModel.setModelYear
      from : if modeYearFrom isnt '' then modeYearFrom else null
      to   : if modeYearTo   isnt '' then modeYearTo   else null

    #FromTo入れ替え

    # カウンタ取得
    @updateCount()

  # 走行距離変更
  changeMileage: ->
    # 走行距離設定
    mileageFrom = @$el.find('#search_cond_mileage_from option:selected').val()
    mileageTo   = @$el.find('#search_cond_mileage_to option:selected'  ).val()

    wrkFrom = mileageFrom
    wrrkTo  = mileageTo
    if mileageFrom isnt '' and mileageTo isnt ''
      if mileageFrom > mileageTo
        mileageFrom = wrrkTo
        mileageTo = wrkFrom

    @options.searchModel.setMileage
      from : if mileageFrom isnt '' then mileageFrom + "0000" else null
      to   : if mileageTo   isnt '' then mileageTo + "0000"   else null
    # カウンタ取得
    @updateCount()

  # 評価点変更
  changeEstimation: ->
    # 評価設定
    estimationFrom = @$el.find('#search_cond_estimation_from option:selected').val()
    estimationTo   = @$el.find('#search_cond_estimation_to option:selected'  ).val()

    if estimationFrom isnt "0" and estimationTo isnt "0"
      if Number(estimationFrom) > Number(estimationTo)
        @options.searchModel.setEstimation @editEstimation(estimationTo, estimationFrom)
      else
        @options.searchModel.setEstimation @editEstimation(estimationFrom, estimationTo)
    else if estimationFrom is "0" and estimationTo is "0"
      @options.searchModel.setEstimation null
    else
      @options.searchModel.setEstimation @editEstimation(estimationFrom, estimationTo)
    # カウンタ取得
    @updateCount()


  blurModelYear : ->
    modeYearFrom = @$el.find('#search_cond_modelyear_from option:selected').val()
    modeYearTo   = @$el.find('#search_cond_modelyear_to option:selected'  ).val()
    if modeYearFrom > modeYearTo and modeYearTo isnt '' and modeYearFrom isnt ''
      $("#search_cond_modelyear_from").val(modeYearTo)
      $("#search_cond_modelyear_to").val(modeYearFrom)

  blurMileage : ->
    mileageFrom = @$el.find('#search_cond_mileage_from option:selected').val()
    mileageTo   = @$el.find('#search_cond_mileage_to option:selected'  ).val()
    if Number(mileageFrom) > Number(mileageTo) and mileageTo isnt '' and mileageFrom isnt ''
      $("#search_cond_mileage_from").val(mileageTo)
      $("#search_cond_mileage_to").val(mileageFrom)

  blurEstimation :->
    estimationFrom = @$el.find('#search_cond_estimation_from option:selected').val()
    estimationTo   = @$el.find('#search_cond_estimation_to option:selected'  ).val()
    if Number(estimationFrom) > Number(estimationTo) and estimationTo isnt "0" and estimationFrom isnt "0"
      $("#search_cond_estimation_from").val(estimationTo)
      $("#search_cond_estimation_to").val(estimationFrom)


  # シフト変更
  changeShift : (param) ->
    # シフト設定
    mission = param.currentTarget.value
    #console.log mission
    @options.searchModel.setMission mission
    # カウンタ取得
    @updateCount()

  # 車検変更
  changeInspect : (param) ->
    # 車検設定
    inspect = param.currentTarget.value
    #console.log inspect
    @options.searchModel.setInspect inspect
    # カウンタ取得
    @updateCount()

  # カウンタ更新
  updateCount: ->
    @listenToOnce @options.searchModel, 'GetSearchCount', @setSearchCount
    @options.searchModel.getSearchCount @options.searchModel.getSearchConditions()

  # グレード更新
  updateGrade: ->
    @$el.find("#GradeArea").empty()
    #指定なしをappend
    gradeNonHtml = "<label><input class='Grade_Non overflow' type='checkbox' value='cond_none' checked></input>"
    gradeNonHtml += "<li class='gradeLine'>" + Backbone.AUCNET.label.get('lb_search_cond_none') + "</li></label>"
    @$el.find("#GradeArea").append(gradeNonHtml)

    gradeList = @options.searchModel.get('Grade')
    for item in gradeList
      @$el.find("#GradeArea").append('<label><input class="Grade" type="checkbox" value="' + item.grade_name + '"></input><li class="gradeLine">' + item.grade_name + '</li></label>')

  # 色更新
  updateColor: ->
    @$el.find("#ColorArea").empty()
    #指定なしをappend
    colorNonHtml =  "<label>"
    colorNonHtml += "<li>"
    colorNonHtml += "<input class='Color_Non' type='checkbox' value='cond_none' checked></input>"
    colorNonHtml += "<span class='color22'></span>"
    colorNonHtml += "<label>" +  Backbone.AUCNET.label.get('lb_search_cond_none') + "</label>"
    colorNonHtml += "</li>"
    colorNonHtml += "</label>"

    @$el.find("#ColorArea").append(colorNonHtml)

    colorList = @options.searchModel.get('Color')
    for item in colorList
      #console.log "item.color_id " + item.color_id
      colorHtml = " <label><li>"
      colorHtml += "<input class='Color' type='checkbox' value='" + item.color_id + "'></input>"
      colorHtml += "<span class='color" + item.color_id + "''></span>"
      colorHtml += "<label>" + @getLabel(item.color_name) + "</label>"
      colorHtml += "</li></label>"
      @$el.find("#ColorArea").append(colorHtml)

  # カウント設定
  setSearchCount: (param) ->
    # @$el.find("#searchCount").remove()
    # @$el.find("#btnSearchExec").append('<span id="searchCount">' + param + '</span>')
    $("#searchCount").remove()
    $("#btnSearchExec").append('<span id="searchCount" class="searchCountClass">' + param + '</span>')
    if param > 0
      $("#btnSearchExec").addClass("flash animated")
    else
      $("#btnSearchExec").removeClass("flash animated")

  # チェック済みアイテム取得
  getCheckedItem: (array) ->
    retArray = []
    for item in array
      if item.checked then retArray.push item.value
    return retArray

  # 評価編集
  editEstimation: (from, to) ->
    #if _.isEmpty(from)  _.isEmpty(to)
    #console.log 'from :' + from + ' to : ' + to
    start = from
    end   = if to is "0" then (@ESTIMATION.length - 1) else to
    retArray = []
    if from is to
      retArray.push @ESTIMATION[from - 1]
    else if from is "0" and to isnt "0"
      retArray = @ESTIMATION.slice(0, end)
      retArray.push ""
    else if from isnt "0" and to is "0"
      retArray = @ESTIMATION.slice(from - 1 ,@ESTIMATION.length)
    else
      #console.log 'start :' + start + ' end : ' + end
      #console.log @ESTIMATION.slice(start - 1 , end)
      retArray = @ESTIMATION.slice(start - 1, end)
    #console.log 'retArray : ' + retArray
    return retArray


  changeStyleNon : ->
    #console.log 'changeStyleNon'
    #未選択で指定なしを解除できない制御
    checked = @getCheckedItem(@$el.find(".Style"))
    if _.isEmpty(checked)
      $('.Style_Non').prop( 'checked' : true)
      return
    @$el.find(".Style").prop( 'checked' : false)
    $('.Style_Non').prop( 'checked' : true)
    @changeStyle()

  changeGradeNon :->
    #console.log 'changeGradeNon'
    #未選択で指定なしを解除できない制御
    checked = @getCheckedItem(@$el.find(".Grade"))
    if _.isEmpty(checked)
      $('.Grade_Non').prop( 'checked' : true)
      return
    @$el.find(".Grade").prop( 'checked' : false)
    $('.Grade_Non').prop( 'checked' : true)
    @changeGrade()

  changeColorNon : ->
    #console.log 'changeColorNon'
    #未選択で指定なしを解除できない制御
    checked = @getCheckedItem(@$el.find(".Color"))
    if _.isEmpty(checked)
      $('.Color_Non').prop( 'checked' : true)
      return
    @$el.find(".Color").prop( 'checked' : false)
    @$el.find("#ColorSpan").prop( 'checked' : false)
    $('.Color_Non').prop( 'checked' : true)
    @changeColor()
