Application        = require 'application'
View               = require 'views/view'
Template           = require 'views/templates/search_result'
CarDataCollection  = require 'models/car_data_collection'
DataLineListView   = require 'views/data_line_list_view'
UtilPagingView     = require 'views/util_paging_view'
UtilChangeDispView = require 'views/util_change_disp_view'
SearchToolView     = require 'views/search_tool_view'
CarDetailBaseView  = require 'views/car_detail_base_view'
IndicatorView   = require 'views/indicator_view'
###
 [車両検索] 検索結果ビュー
###
module.exports = class SearchResultView extends View
  id          : 'search_result'
  template    : Template

  # 表示モード (l:リスト, b:ブロック)
  mode : "b"

  # イベント設定
  events:
    # 画像クリック　＞　車両詳細表示
    'click .car_thumbnail' : 'showCarDetail'
    'click .precedingPage'  : 'precedingPage'
    #'touchstart .checkBoxHover' : 'hoverStart'
    'touchend   .checkBoxHover' : 'hoverEnd'
    'mouseover  .checkBoxHover' : 'hoverStart'
    'mouseout   .checkBoxHover' : 'hoverEnd'
    #'touchstart .precedingPage' : 'hoverStart'
    'touchend   .precedingPage' : 'hoverEnd'
    'mouseover  .precedingPage' : 'hoverStart'
    'mouseout   .precedingPage' : 'hoverEnd'
    'click .breadCrumbLink'     : 'onClickBreadCrumb'

  # 初期化
  initialize: ->
    _.bindAll @
    @breadCrumbFlag = false

  # 起動(親から呼び出される)
  start: ->
    # レンダリング
    @render()

  # リストレンダ
  listRender: ->
    # データリストビューのレンダリング
    @dataLineListView.setCollection
      carDataCollection : @carDataCollection
    @dataLineListView.render()
    # ページングビューのレンダリング
    @utilPagingView.setPagingData
      dataCount  : @carDataCollection.count.search_car_data_count
    @indicator.suicide()
    @utilPagingView.render()


    # データリストビュー生成
    lsStringMyList = localStorage.getItem('search_result.list_disp_mode')
    if lsStringMyList?
      @dataLineListView.changeDisplayModeOnly(lsStringMyList)

  # レンダリング後の処理
  afterRender: ->
    # 検索モデルから検索条件を取得
    conditions = @options.searchModel.getSearchConditions()
    @searchCond = conditions.searchcond
    # 車両データコレクション生成
    @carDataCollection = new CarDataCollection
    @listenTo @carDataCollection, 'GetSearchCarDataList', @listRender
    # 表示切替ビュー生成
    @utilChangeDispView = new UtilChangeDispView ls_key_id : @id
    @listenTo @utilChangeDispView, "ChangeDisplayMode", @changeDisplayMode, @
    # 検索ツールビュー生成
    @searchToolView = new SearchToolView
    @listenTo @searchToolView, 'ReflectSearchToolConditions', @reflectSearchToolConditions
    # ページングビュー生成
    @utilPagingView = new UtilPagingView id : @id + '_pagination'
    @listenTo @utilPagingView, 'paging', @getSearchCarDataList
    # データリストビュー生成
    lsStringMyList = localStorage.getItem('search_result.list_disp_mode')

    if lsStringMyList?
      @mode = lsStringMyList
    @dataLineListView = new DataLineListView
      collection : @carDataCollection
      mode : @mode
    # コンテンツ組立
    @$el.find(".child_search_tool_area")
      .append(@searchToolView.el)

    @$el.find(".bgDes")
      .append(@utilChangeDispView.el)
      .append(@utilPagingView.el)

    @$el
      .append(@dataLineListView.el)
    # 各ビューのレンダリング
    @utilChangeDispView.render()
    @utilPagingView.render()
    # 検索実行
    @getSearchCarDataList()
    # 絞り込み条件取得
    @searchToolView.dispSearchSearchToolCond(searchcond : @searchCond)

  # 表示切替
  changeDisplayMode: (mode) ->
    @mode = mode
    # データリストビュー呼び出し
    @dataLineListView.changeDisplayMode(@mode)

  # 検索ツール条件反映
  reflectSearchToolConditions: ->
    # 検索条件取得
    conditions      = @searchToolView.getSearchToolConditions()
    @searchToolCond = conditions.searchcond
    @sortCond       = conditions.sortcond
    # カレントページ初期化  TODO 微妙...
    @utilPagingView.page = 0
    # 車両検索実行
    @getSearchCarDataList()

  # 車両検索実行
  getSearchCarDataList: ->
    @indicator = new IndicatorView
      title   : Backbone.AUCNET.message.get "ms_indicator_carname_title"
    $('body').append(@indicator.render().el)
    @carDataCollection.getSearchCarDataList
      limit      : @utilPagingView.limit
      offset     : @utilPagingView.getOffset()
      searchcond : _.extend({}, @searchCond, @searchToolCond , )
      sortcond   : @sortCond

  # 車両詳細表示
  showCarDetail: (param) ->
    invKey = param.currentTarget.id.split('|')
    Backbone.AUCNET.RenderController.renderCarDetailPaging(invKey[0],invKey[1],@carDataCollection ,
      @utilPagingView,'GetSearchCarDataList' )

  #自分自身を削除する。
  suicide: ->
    @utilChangeDispView?.suicide()
    @utilPagingView?.suicide()
    @dataLineListView?.suicide()
    @carDataCollection?.remove() unless _.isEmpty(@carDataCollection?.remove)
    @.remove()

  precedingPage : ->
    @options.parent.changeChildView(@options.parent.afterViewName,true)
    @searchToolView.filterClear()


  getRenderData : ->
    breadCrumbFlag : @breadCrumbFlag
    childHistory   : @childHistory

  setBreadCrumb : (@childHistory) ->
    @breadCrumbFlag = true

  onClickBreadCrumb : (param) ->
    #console.log param
    @options.parent.changeChildView(param.currentTarget.id)
    @searchToolView.filterClear()
    @searchToolView.filterResult()








