View                 = require 'views/view'
Template             = require 'views/templates/search'
SearchMethodView     = require 'views/search_method_view'
SearchMakerView      = require 'views/search_maker_view'
SearchCarNameView    = require 'views/search_car_name_view'
SearchConditionsView = require 'views/search_conditions_view'
SearchInvNoView      = require 'views/search_inv_no_view'
SearchResultView     = require 'views/search_result_view'
SearchModel          = require 'models/search_model'

###
 [車両検索] 検索ページビュー
###
module.exports = class SearchPageView extends View
  id                   : 'search_page'
  template             : Template
  className            : ''

  # events :
  #   "click   #btnSearchExec"                : 'goResult'

  # 初期化
  initialize: ->
    _.bindAll @

    #$(window).resize ->
    #  wHeight = $(window).height()
    #  $("#main_container .cont").css "height", wHeight + "px"

  suicide : ->
    @searchMethodView?.suicide()
    @searchMakerView?.suicide()
    @searchCarNameView?.suicide()
    @searchConditionsView?.suicide()
    @searchInvNoView?.suicide()
    @searchResultView?.suicide()
    @searchModel?.destroy()
    @.remove()

  # レンダリング後の処理
  afterRender: ->
    @delegateEvents {
      "click   #btnSearchExec"    : 'goResult'
      'touchstart .searchBtnCont' : 'hoverStartMobile'
      'touchend   .searchBtnCont' : 'hoverEndMobile'
      'mouseover  .searchBtnCont' : 'hoverStartMobile'
      'mouseout   .searchBtnCont' : 'hoverEndMobile'
      #'touchstart .checkBoxHover' : 'hoverStart'
      'touchend   .checkBoxHover' : 'hoverEnd'
      'mouseover  .checkBoxHover' : 'hoverStart'
      'mouseout   .checkBoxHover' : 'hoverEnd'
      #'touchstart .prevCond' : 'hoverStartMobile'
      #'touchend   .prevCond' : 'hoverEndMobile'
      #'mouseover  .prevCond' : 'hoverStartMobile'
      #'mouseout   .prevCond' : 'hoverEndMobile'
      #'touchstart .nextCond' : 'hoverStartMobile'
      #'touchend   .nextCond' : 'hoverEndMobile'
      #'mouseover  .nextCond' : 'hoverStartMobile'
      #'mouseout   .nextCond' : 'hoverEndMobile'
    }
    @childHistory = []
    # 検索モデル取得
    @searchModel = new SearchModel
    @searchModel.setSocket(Backbone.AUCNET.socket)
    # 子ビュー生成
    @search_child_area = @$el.find("#search_child_area")
    @searchMethodView = new SearchMethodView
      parent      : @
      searchModel : @searchModel
    @search_child_area.append(@searchMethodView.el)
    @searchMakerView = new SearchMakerView
      parent      : @
      searchModel : @searchModel
    @search_child_area.append(@searchMakerView.el)
    @searchCarNameView = new SearchCarNameView
      parent      : @
      searchModel : @searchModel
    @search_child_area.append(@searchCarNameView.el)
    @searchConditionsView = new SearchConditionsView
      parent      : @
      searchModel : @searchModel
    @search_child_area.append(@searchConditionsView.el)
    @searchInvNoView = new SearchInvNoView
      parent      : @
      searchModel : @searchModel
    @search_child_area.append(@searchInvNoView.el)
    @searchResultView = new SearchResultView
      parent      : @
      searchModel : @searchModel
    @search_child_area.append(@searchResultView.el)
    # 検索方法表示
    @changeChildView('search_method', true)

    # 検索結果画面のみ表示領域固定で確保されている為、非表示を明示的に実行
    @$el.find('#search_result').hide()


    # 全画面化
    # wHeight = $(window).height()
    # @$el.find(".cont").css "height", wHeight + "px"

  # 子ビュー切替
  changeChildView: (viewName, isRender) ->

    @afterViewName = @thisViewName
    @thisViewName = viewName
    # 画面のスクロール位置をリセット
    $("html,body").scrollTop(0);
    # 子ビューを全て隠す
    @hideAllChild()
    # 遷移先の子ビューを起動
    if isRender
      if viewName == 'search_method'
        @searchMethodView.start()
        @hideFooter()
      if viewName == 'search_maker'
        @childHistory.push(viewName)
        @searchMakerView.start()
        @hideFooter()
      if viewName == 'search_car_name'
        @childHistory.push(viewName)
        if @afterViewName isnt 'search_result'
          @searchCarNameView.start()
        @showFooter()
      if viewName == 'search_conditions'
        @childHistory.push(viewName)
        if @afterViewName isnt 'search_result'
          @searchConditionsView.start()
        @showFooter()
      if viewName == 'search_inv_no'
        @childHistory.push(viewName)
        if @afterViewName isnt 'search_result'
          @searchInvNoView.start()
        @showFooter()
      if viewName == 'search_result'
        @hideFooter()
        @searchResultView.setBreadCrumb @childHistory
        @searchResultView.start()
    else
      if viewName == 'search_method'
        @childHistory = []
      if viewName == 'search_maker'
        @childHistory = ['search_maker']
      if viewName == 'search_car_name'
        @childHistory = ['search_maker','search_car_name']
      if viewName == 'search_conditions'
        @childHistory = ['search_maker','search_car_name','search_conditions']
      if viewName == 'search_inv_no'
        @childHistory = ['search_inv_no']
      

    if viewName == 'search_method'
      @hideFooter()
    if viewName == 'search_maker'
      @hideFooter()
    if viewName == 'search_car_name'
      @showFooter()
    if viewName == 'search_conditions'
      @showFooter()
    if viewName == 'search_inv_no'
      @showFooter()

    # 対象の子ビューを表示
    $('#' + viewName).show()

    # if viewName == 'search_result'
    #   @hideFooter()
    @condNavigationCtl(viewName)

  # 子ビューを隠す
  hideAllChild: ->
    $('#search_method'    ).hide()
    $('#search_maker'     ).hide()
    $('#search_car_name'  ).hide()
    $('#search_conditions').hide()
    $('#search_inv_no'    ).hide()
    $('#search_result'    ).hide()

  # 検索フッターを隠す
  hideFooter: ->
    #@$el.find('.condNavigation').hide()
    @$el.find('.searchBtnCont').hide()

  showFooter: ->
    #@$el.find('.condNavigation').show()
    @$el.find('.searchBtnCont').show()

  # 検索条件の状態表示
  condNavigationCtl: (viewName) ->
    $('.condNavigation li').removeClass('active').hide()
    if viewName == 'search_method'
      $('.cond1').addClass('active').show()
    if viewName == 'search_maker'
      $('.cond2').addClass('active').show()
      $('.cond3').show()
      $('.cond4').show()
    if viewName == 'search_car_name'
      $('.cond2').show()
      $('.cond3').addClass('active').show()
      $('.cond4').show()
    if viewName == 'search_conditions'
      $('.cond2').show()
      $('.cond3').show()
      $('.cond4').addClass('active').show()
    if viewName == 'search_inv_no'
      $('.cond5').addClass('active').show()
    #if viewName == 'search_result'
    #  $('.cond1').addClass('active').show()

  # 検索結果画面へ遷移
  goResult: ->
    if $('#search_car_name').css("display") == 'block'
      @searchCarNameView.goResult()
    if $('#search_conditions').css("display") == 'block'
      @searchConditionsView.goResult()
    if $('#search_inv_no').css("display") == 'block'
      @searchInvNoView.goResult()
