var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"sliderOuter\">\r\n  <div class=\"slider\">\r\n    <div class=\"cont\" >\r\n      <h1 class=\"ptitle\">\r\n        <span data-label=\"lb_candidate\" class=\"ptitle_title\"></span>\r\n      </h1>\r\n      <div class=\"searchTool_area\"></div>\r\n      <div class=\"bgDes\"></div>\r\n      <div class=\"candidate_list_area\"></div>\r\n      <div class=\"candidate_footer_area\"></div>\r\n    </div>\r\n    <div id=\"detail_container\"></div>\r\n    <div id=\"apply_container\"></div>\r\n  </div>\r\n</div>\r\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}