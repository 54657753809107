Model        = require 'models/model'
Application  = require 'application'

#管理するVIEW
CarDetailView   = require 'views/car_detail_base_view'
NegoApplyView   = require 'views/nego_apply_view'
NegoPageView    = require 'views/nego_page_view'

###
  ページング制御モデル
###
module.exports = class RenderController extends Model

  #持つべきデータをここに書く。
  # @get 'xxxxxx' で取得可能
  defaults :
    current_view  : undefined #現在のVIEW(メニューから遷移されたVIEW)
    sub_view      : undefined #現在のサブビュー(詳細、申込、詳細)

  history : []  #以前のVIEW

  NegoApplyView : undefined
  CarDetailView : undefined
  NegoPageView  : undefined

  ClassName : 'RenderController'


  initialize: ->
    _.bindAll @

  setCurrentView : (view) ->
    @_killSubView()
    #現在のカレントを保持
    @set 'current_view'  : view , {silent : true}
    @render()

  ###
   前Viewへ戻る（基本的にサブビューでしか使用されない）
   各画面の戻る場合はこれを使う
  ###
  returnView : ->
    if _.isEmpty(@history)
      @_killSubView()
    else
      @set 'sub_view' : @history.pop() , {silent : true}
      $('#sub_container').empty()
      $('#sub_container').html @get('sub_view').render().el

  ###
   前の画面を保持しておく用
  ###
  _setSubHistory : (view)->
    @history.push view
    if @history.lenght >= 3
      @history.shift()

  ###
    サブビュー準備処理
  ###
  _setSubView : (view)  ->
    #サブビュー表示状態での遷移ならば履歴を保持
    @_setSubHistory @get('sub_view') unless _.isEmpty(@get('sub_view'))
    #表示サブビューをセット
    @set 'sub_view' , view　, { silent : true }
    #メインはハイド
    $('#main_container').hide()
    $('#sub_container').empty()

  ###
    サブビュー始末処理
  ###
  _killSubView : ->
    #削除処理
    @negoPageView?.suicide()
    @CarDetailView?.suicide()
    @NegoApplyView?.suicide()
    for view in @history
      view?.suicide()
    @history = []
    @set 'sub_view' : undefined , {silent : true}
    $('#sub_container').empty()
    $('#main_container').show()

  ###
  商談申込へ遷移
  以下の形式で引数を入力
  @param [{inventoryYear : xxxx , inventoryNo : xxxx}, {}]
  ###
  renderNegoApply : (array = [], exitDelFlag) ->
    #インスタンスが存在しない場合に生成
    @NegoApplyView = new NegoApplyView #if @NegoApplyView is undefined
    #表示サブビューをセット
    @_setSubView @NegoApplyView
    $('#sub_container').append @get('sub_view').el
    @NegoApplyView.setApplyData array , exitDelFlag

  ###
  車両詳細へ遷移
  以下の形式で引数を入力
  ###
  renderCarDetail : (invYear,invNo,subViewFlag)->
    #インスタンスが存在しない場合に生成
    @CarDetailView?.suicide()
    @CarDetailView = new CarDetailView #if @CarDetailView is undefined
    #表示サブビューをセット
    @_setSubView @CarDetailView
    $('#sub_container').append @get('sub_view').el
    @CarDetailView.setCarData(invYear,invNo,subViewFlag)

  ###
  車両詳細へ遷移
  以下の形式で引数を入力
  ###
  renderCarDetailPaging : (invYear,invNo,carDataCollection,utilPagingView,eventName)->
    #インスタンスが存在しない場合に生成
    @CarDetailView?.suicide()
    @CarDetailView = new CarDetailView #if @CarDetailView is undefined
    #表示サブビューをセット
    @_setSubView @CarDetailView
    $('#sub_container').append @get('sub_view').el
    @CarDetailView.setCarDataPaging(invYear,invNo,carDataCollection,utilPagingView,eventName)


  renderNegoPage : (negoId,invYear,invNo,exitFlag)->
    #console.debug "#{@ClassName} : " + "renderCarDetail"
    #インスタンスが存在しない場合に生成
    @negoPageView = new NegoPageView()
    #表示サブビューをセット
    @_setSubView @negoPageView
    # 商談部屋IDをセット
    @negoPageView.setNegoID negoId, invYear, invNo , exitFlag
    $('#sub_container').html @negoPageView.render().el

  render : ->
    #console.debug "#{@ClassName} : " + "render"
    $('#main_container').empty()
    $('#main_container').html @get('current_view').render().el



