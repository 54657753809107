View             = require 'views/view'
TemplateLine         = require 'views/templates/data_line'
TemplateAlbum         = require 'views/templates/data_album'
WatchListBtnView = require 'views/watchlist_btn_view'

###
 [検索]
###
module.exports = class DataLineView extends View
  className : 'data_line_view'
  template  : TemplateAlbum

  events :
    'click .data_line_check' : 'clickDataLineCheck'

  initialize: ->
    _.bindAll @
    @listenTo @model, 'AddCheckbox', @addCheckbox

    # アルバム表示、リスト表示切替
    switch @options.list_type
      when "b" then @template = TemplateAlbum
      when "l" then @template = TemplateLine
      else          @template = TemplateAlbum

  afterRender: ->
    # イメージタグ組み立て
    ###
      @TODO NoImage画像
      @TODO URIの指定方法
    ###
    imgTag = '<img ' +
      'src="https://www.aucneostation.com/member/search/img/nophoto_s_1.jpg"' +
      ' width="80" height="65" >'
    if @model.get('image')? and @model.get('image') isnt ''
      imgTag =
        '<img src="' +
        Backbone.AUCNET.DEFINE.get('PICTURE_URL') +
        #'6/' +
        '00/' +
        @model.get('inventory_year') +
        '/' +
        @model.get('image') +
        '" alt="" />'
    # 画像設定
    #@$('.car_thumbnail').append(imgTag)
    # 商談候補ボタン追加
    @watchListBtnView = new WatchListBtnView
      invNo   : @model.get('inventory_no')
      invYear : @model.get('inventory_year')
      loginId : Backbone.AUCNET.loginUser.get("_userid")

    @$el.find(".WatchListBtn_area").append(@watchListBtnView.el)
    #@$el.find(".WatchListBtn").append(@watchListBtnView.el)
    @watchListBtnView.checkWatchList()

  getRenderData: ->
    # データ設定
    checkbox_flag  : if @model.collection.checkFlag? then true else undefined
    inventory_year : @model.get('inventory_year')
    inventory_no   : @model.get('inventory_no')
    carName        : @model.get('car_name')
    gradeName      : @model.get('grade_name')
    image          : @model.get('image')
    fine           : @model.get('fine')
    regionName     : @model.get('region_name')
    modelYear      : @model.get('model_year')
    model          : @model.get('model')
    style          : @model.get('style')
    exhaust        : @model.get('exhaust')
    fuelCd         : @model.get('fuel_cd')
    shift          : @model.get('shift')
    inspect        : @model.get('inspect')
    mileage        : @model.get('mileage')
    no_mileage     : @model.get('no_mileage')
    isMeterChangeFlg   : @model.get('isMeterChangeFlg')
    shift          : @model.get('shift')
    outcolor       : @model.get('outcolor')
    outcolor_no    : @model.get('outcolor_no')
    one_owner      : @model.get('one_owner')
    gurantee       : @model.get('gurantee')
    manual         : @model.get('manual')
    estimation     : @model.get('estimation')
    outward_value  : @model.get('outward_value')
    upholstery_value     : @model.get('upholstery_value')
    price     : @model.get('price')
    drive_cd     : @model.get('drive_cd')
    navi  : @model.get('navi')
    sunroof  : @model.get('sunroof')
    leather_seat  : @model.get('leather_seat')
    isFine         : @model.get('isFine')
    isSFine        : @model.get('isSFine')
    isImportedCar        : @model.get('isImportedCar')

    status         : @model.get ('status')
    estimation_flag : @model.get ('estimation_flag')


  # 書類編集
  editDocument: (json) ->
    ret = ''
    if json.one_owner? then ret = ret + '<span data-label=' + json.one_owner + ' />'
    if json.gurantee?  then ret = ret + '<span data-label=' + json.gurantee + ' />'
    if json.manual?    then ret = ret + '<span data-label=' + json.manual + ' />'
    return ret

  # 装備編集
  editEquipment: (json) ->
    ret = ''
    if json.navi?         then ret = ret + '<span data-label=' + json.navi + ' />'
    if json.navi_kind?    then ret = ret + '<span data-label=' + json.navi_kind + ' />'
    if json.sunroof?      then ret = ret + '<span data-label=' + json.sunroof + ' />'
    if json.leather_seat? then ret = ret + '<span data-label=' + json.leather_seat + ' />'

  # チェックボックスクリック時の処理
  clickDataLineCheck: ->
    if @$el.find('.data_line_check:checked').length is 1
      @model.set 'checked', true
    else
      @model.set 'checked', false
