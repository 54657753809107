Application   = require 'application'
View          = require 'views/view'
Template      = require 'views/templates/util_user_menu'

###
 ユーザーメニュービュー
###
module.exports = class UserMenuView extends View
  id       : 'user_menu'
  template : Template

  events:
    'click #btnLogout' : 'logout'
    'click a.configBtn': 'configBtnClick'
    'touchstart .sub_header_button' : 'hoverStart'
    'touchend   .sub_header_button' : 'hoverEnd'
    'mouseover  .sub_header_button' : 'hoverStart'
    'mouseout   .sub_header_button' : 'hoverEnd'

  #otherLinkhoverStart : (param) ->
  #  $(param.currentTarget).addClass "other_link_hover"

  #otherLinkhoverEnd : (param) ->
  #  $(param.currentTarget).removeClass "other_link_hover"

  # 初期化
  initialize: ->
    _.bindAll @

  # hbsに書き出すデータを指定
  getRenderData: ->
    # ユーザID設定
    if Backbone.AUCNET.roleID is '0'
      uid = Backbone.AUCNET.loginUser.get('_userid')
    else
      uid = Backbone.AUCNET.loginUser.get('_adminid')
    UserId : uid

  # レンダリング後の処理
  afterRender: ->
    if Backbone.AUCNET.roleID is '0'
      @$el.find('.settings_button_class').show()
    else if Backbone.AUCNET.roleID is '1'
      @$el.find('.settings_button_class').hide()
    else if Backbone.AUCNET.roleID is '2'
      @$el.find('.settings_button_class').hide()
    else
      @$el.find('.settings_button_class').show()

  # ログアウト
  logout: (event) ->
    event.preventDefault()
    $("body").find('#footer_container').empty()
    # ログアウト処理呼び出し
    Backbone.AUCNET.loginUser.logoff()

  configBtnClick : (param) ->
    Backbone.history.fragment = ''
    Application.router.navigate $(param.currentTarget).data('menucd'), true
    # if $(".each_content").hasClass("indent")
    #   $(".each_content").toggleClass("indent"); 
    if !$(".lsidebar").hasClass("indent_menu")
      $(".lsidebar").toggleClass("indent_menu"); 
