var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\""
    + alias2(alias1((depth0 != null ? depth0.preview_class : depth0), depth0))
    + "\">\n  "
    + alias2(alias1((depth0 != null ? depth0.message : depth0), depth0))
    + "\n  <spam data-label=\""
    + alias2(alias1((depth0 != null ? depth0.lb_send_role_kbn : depth0), depth0))
    + "\"></spam>\n  <div class=\"detailsButton\">\n    <button type='button' class=\"btnSend detailsaddToBtn\" data-label=\"lb_btn_send\">送信</button>\n    <button type='button' class=\"btnCancel detailsaddToBtn\" data-label=\"lb_cancel\">キャンセル</button>\n  </div>\n</div>";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}