View        = require 'views/view'
template    = require 'views/templates/expand_image'

module.exports = class ExpandImageView extends View
  id       : 'expand_image'
  template : template

  events:
    'click .btnExit'        : 'exit'
    'click .btnImageBack' : 'imageBack'
    'click .btnImageNext' : 'imageNext'

  initialize: ->
    #console.debug 'UtilConfirmView#initialize'
    # 既にconfirmが存在していたら中止する
    if $("#" + @id).length isnt 0
      # 基底クラスで定義されている@renderを
      # 自殺するだけの関数に書き換える
      @render = ->
        _.defer =>
          @.suicide()
      return
    _.bindAll @

    ## 背景画面のTab移動とEnter押下とスクロールを無効にする
    $("body").addClass("scrollBlock");
    $(window).on "touchmove.noScroll", (e) ->
      e.preventDefault()
    $(document).bind('keydown', @disableFocusOut)
    $(document).bind('keydown', @disableEnterPress)
    @imageURL = @options.curentImageUrl

  getRenderData : ->
    #console.debug @options.imageArray
    image      : @imageURL
    imagePath  : Backbone.AUCNET.DEFINE.get('PICTURE_URL') + "00" + @imageURL
    curentNo   : _.indexOf(@options.imageArray,@imageURL) + 1
    imageCount : @options.imageArray.length

  exit: ->
    #console.debug 'UtilConfirmView#ok'
    @options.callback(true) if @options.callback?
    @.suicide()

  suicide: ->
    #console.debug 'UtilConfirmView#suicide'
    $("body").removeClass("scrollBlock");
    $(window).off('.noScroll');
    $(document).unbind('keydown', @disableFocusOut)
    $(document).unbind('keydown', @disableEnterPress)
    @.remove()

  imageBack : (param)=>
    index = _.indexOf(@options.imageArray,param.currentTarget.id)
    index = index - 1
    if index < 0
      index = @options.imageArray.length - 1
    @imageURL = @options.imageArray[index]
    @render()

  imageNext : (param)=>
    index = _.indexOf(@options.imageArray,param.currentTarget.id)
    index = index + 1
    if index >= @options.imageArray.length
      index = 0
    @imageURL = @options.imageArray[index]
    @render()

  disableFocusOut: (e) ->
    if e.keyCode is 9
      e.stopPropagation()
      e.preventDefault()
      $(e.currentTarget).focus()
      return

  disableEnterPress: (e) ->
    #if e.keyCode is 13
    #  console.log 'PRESS ENTER'
    return
