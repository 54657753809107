
Setting =
  initialize: (callback) ->
    URL = window.location.hostname
    URL = URL.replace( "www.", "" );

    # ステージ
    if URL is "stage.w-bidmart.com"
      Backbone.AUCNET.CONST = WS_URI : "https://wsstest.w-bidmart.com"
    # 本番
    else if URL is "w-bidmart.com"
      Backbone.AUCNET.CONST = WS_URI : "https://wss.w-bidmart.com"
    # ローカル
    else
      Backbone.AUCNET.CONST = WS_URI : "http://localhost:8201"

    Backbone.AUCNET.SOCKETIO_OPTION =
      'reconnection': true
      'reconnectionDelay': 1000
      'reconnectionDelayMax': 8640
      'timeout': 3000
      'transports' : ['websocket']

    Backbone.AUCNET.LANGUAGE_ID = 'ja'

    # jQueryDatepicker設定
    $.datepicker.setDefaults
      dateFormat      : 'yy/mm/dd'
      showOn          : "both"
      buttonImage     : getDatetime() + "images/cal.png"
      buttonImageOnly : true
      buttonText      : ""

    # Freeze the object
    Object.freeze? this

    # 設定値
    Backbone.AUCNET.setting =
      st_na_timeout  : 10000
      st_na_interval : 1000
      st_na_rank1    : 10
      st_na_rank2    : 20
      st_na_rank3    : 30
      st_na_rank4    : 40
      st_na_rank5    : 50000000
      # 商談一覧 残り時間「わずか」表示時間 (単位 : 秒)
      remain_time    : 1800
      # 複数商談申込み最大数
      max_nego_cnt   : 5
      # ログイン後初期画面
      init_disp      : "#negolist"

    callback()

module.exports = Setting
