var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<div data-label=\""
    + container.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data}) : helper)))
    + "\" /></div>\n\n<div class=\"emailInpCont\">\n  <input type=\"text\" placeholder=\"\" id=\"userIdtxt\">\n  <button type='button' class=\"userSearch save\" data-label=\"lb_user_search\"></button>\n  <div class=\"clear\"></div>\n</div>\n\n<div class=\"settingMail\"></div>\n<div class=\"settingCredit\"></div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}