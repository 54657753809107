Model        = require 'models/model'
messageDatas = require 'data/messages'

###
 メッセージ
###
module.exports = class Message extends Model

  initialize: ->
    _.bindAll @

  getMessage: (languageid = "") ->
    data = (item for item in messageDatas when item.id is languageid)
    #console.debug "MessageModel#getMessage languageid : %s", languageid
    @set data[0]
