var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <button type='button' class=\"btnCancel util_confirm_btn\" data-label=\"lb_confirm_cancel\"></button>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "<div class=\"appOverlay\">\r\n  <div id=\"util_confirm_mobile_set\" class=\"appBox\">\r\n    <div class=\"appBTitle\">"
    + container.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</div>\r\n    <div class=\"wordbreak appBoxMsgArea\">"
    + ((stack1 = ((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\r\n    <div class=\"util_confirm_btn_area\">\r\n      <button type='button' class=\"btnOk util_confirm_btn\"  data-label=\"lb_confirm_ok\"></button>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.cancelFlg : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\r\n  </div>\r\n</div>";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}