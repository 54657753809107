View            = require 'views/view'
template        = require 'views/templates/nego_send'
IndicatorView   = require 'views/indicator_view'
template2       = require 'views/templates/nego_send_preview'
blankTemplate   = require 'views/templates/view_base'
UtilConfirmView = require 'views/util_confirm_view'
util            = require 'lib/util'

###
 [商談] 送信メッセージ作成
###
module.exports = class NegoSendView extends View
  id        : 'nego_send'
  template  : template
  template2 : template2
  blankTemplate : blankTemplate

  preview_data : null

  collection :
    # メッセージログ
    log      : null
    # メッセージマスタ
    messages : null

  # --------------
  # 定数宣言
  # --------------
  RC_OK    : '0'  #正常終了
  RC_NG    : '1'  #入力チェックNG

  # 入力金額の最小幅(単位は円)
  INTERVAL_PRICE : 5000

  # 最終ロール区分
  LAST_ROLE_KBN :
    BUYER  : '1' #落札希望店
    OWNER  : '2' #出品店

  # フラグ
  FLAG_OFF : '0'
  FLAG_ON  : '1'

  # 商談ステータス
  NEGO_STATUS :
    REQUEST  : '1' # 申込中
    ONGOING  : '2' # 商談中
    CONTRACT : '3' # 成約
    FLOW     : '4' # 流れ
    CANCEL   : '5' # キャンセル

  # ユーザー権限区分
  USER_ROLE_KBN :
    OTHER    : '0' # 他社
    BUYER    : '1' # 購入希望者
    SELLER   : '2' # 出品店
    ADMIN    : '3' # 管理者

  # 選択可能なメッセージコード一覧
  MESSAGE_CD_LIST :
    # 落札希望店
    BUYER_MSG_TYPE1 : ["EMPTY"] # 商談申込中
    BUYER_MSG_TYPE2 : ["K02", "K03", "K04", "K05"] # T04　が出品店から返却された時
    BUYER_MSG_TYPE3 : ["K02", "K04", "K05"] # T05　が出品店から返却された時
    BUYER_MSG_TYPE4 : ["K02", "K05"] # T05　が出品店から返却された時

    # 出品店
    OWNER_MSG_TYPE1  : ["T04", "T05", "T03", "T01", "T02"] # 商談中＆落札希望店
    OWNER_MSG_TYPE2  : ["T06", "T07", "T08", "T09"] # 商談中＆落札希望店
    OWNER_MSG_TYPE3  : ["T06", "T08", "T09"] # 商談中＆落札希望店
    OWNER_MSG_TYPE4  : ["T06","T09"] # 商談中＆落札希望店

    ONGOING_ADMIN  : ["EMPTY"]                                  # 商談中＆管理者

    REQUEST_SELLER_OFFER : ["K01"] # 商談申込中＆出品店
    REQUEST_SELLER : ["T01", "T02", "T03", "T04", "T05"] # 商談中＆出品店
    REQUEST_ADMIN  : ["EMPTY"]                           # 商談申込中＆管理者
    ONGOING_BUYER  : ["K02", "K03", "K04", "K05"]        # 商談中＆落札希望店
    ONGOING_SELLER : ["T06", "T07", "T08", "T09"]        # 商談中＆出品店
    #ONGOING_ADMIN  : []                                  # 商談中＆管理者

    # 値寄せ不可時に許可するコード
    NEYOSE_FUKA    : ["K02", "K05", "T01", "T02", "T03", "T06", "T09"]

  # メッセージコード(一部)
  MESSAGE_CD :
    REQUEST :
      OWNER_CONTRACTS_SPEEDY : "T03"
      OWNER_PRICE_DOWN       : "T04"
      OWNER_PRICE_LAST       : "T05"
      ADMIN_CANCEL           : "A01"
    ONGOING :
      BUYER_CONTRACTS_SPEEDY : "K02"
      BUYER_PRICE_UP_AGAIN   : "K03"
      BUYER_PRICE_LAST       : "K04"
      BUYER_FLOW             : "K05"
      OWNER_CONTRACTS_SPEEDY : "T06"
      OWNER_PRICE_DOWN_AGAIN : "T07"
      OWNER_PRICE_LAST       : "T08"
      OWNER_FLOW             : "T09"
      ADMIN_CANCEL           : "A02"

  # ポップアップメッセージコード
  POPUP_MESSAGE_CD :
    CLICK_UNSELECTABLE_BUTTON : "01"
    MONEY_FORMAT_ERROR        : "02"
    OUT_OF_BOUND_MONEY        : "03"
    ILLEGAL_INTERVAL_MONEY    : "04"
    SEND_MESSAGE_OK           : "05"
    QUESTION_SEND_MESSAGE     : "06"
    QUESTION_SEND_CONTAIN_FLOW_MESSAGE : "07"
    OUT_OF_BOUND_BUYER_MONEY_HOPE_AMOUNT  : "12"
    OUT_OF_BOUND_OWNER_MONEY_HOPE_AMOUNT  : "14"

  # 要回答者区分
  NEED_ANSWERER_KBN :
    BUYER  : "1"  # 落札希望店

  # ------------
  # イベント定義
  # ------------
  events :
    "click   button.nego_btn_preview"       : "previewNegoMessage"  # プレビューボタン
    "click   button.btnSend"                : "sendNegoMessage"     # 送信実行ボタン
    "click   button.btnCancel"              : "cancelSendMessage"   # 送信キャンセルボタン
    "change  #nego_select_send_messages"    : "setInputEnable"      # メッセージの選択変更
    "click   button#btn_nego_admin_cancel"  : "cancelNego"          # 商談キャンセルボタン

  # -------------------------
  # インスタンス化時
  # -------------------------
  initialize: ->
    # console.info "NegoSendView#initialize"
    _.bindAll @
    # メッセージ受信時、選択可能なメッセージを制御
    #@listenTo @collection.log, "add"                 , @setSelectableMessages
    @listenTo @model          , 'SuccessJoinNegoRoom' , @changeSelectableMessages
    @listenTo @collection.log , 'reset'               , @changeSelectableMessages
    @listenTo @collection.log , 'sort'                , @changeSelectableMessages

    # ToDo: 商談配信コントロールから商談変更情報が連携されたときの処理を追加すること

  # -------------------------
  # レンダリングデータ設定
  # -------------------------
  getRenderData: ->
    # console.info "NegoSendView#getRenderData"

    # ボタン名、メッセージラベルに文言を当てる
    _.each @collection.messages.models, (value, key, list) ->
      msg = @getMessage(value.get('messagelabel'))
      value.set 'message', msg, {silent : true}

      lbbutton = value.get 'message_btn_label'
      value.set 'lbbutton', lbbutton, {silent : true}
    , @

    return {
      messages : @collection.messages.toJSON()
    }

  # -------------------------
  # レンダリング後
  # -------------------------
  afterRender: ->
    # console.info "NegoSendView#afterRender"
    # メッセージ制御
    #@setInputEnable()

  # -------------------------
  # 選択したメッセージをプレビュー表示
  # -------------------------
  previewNegoMessage: ->
    # console.info "NegoSendView#previewNegoMessage"
    messageCd = @$el.find("#nego_select_send_messages option:selected").val()

    if !messageCd or messageCd is '' or messageCd is 'EMPTY'
      return

    inputTenThousand = @$el.find("#nego_input_ten_price").val()
    inputThousand = @$el.find("#nego_input_price").val()
    # 千の単位未入力時、0にする
    inputThousand = "0" if inputThousand?.length is 0
    inputPrice = inputTenThousand + inputThousand

    @preview_data =
      message_cd      : messageCd
      #message_price   : @$el.find("#nego_input_price").val()  # 金額入力欄の値をそのまま設定する
      message_price   : inputPrice
      can_input_price : !@$el.find("#nego_input_price").hasClass("readonlyBackgroundColor")

    # 入力チェック
    rc = @checkSendNegoMessage(@preview_data)
    if rc isnt @RC_OK
      # プレビュー部 非表示
      $("div.nego_list_preview_block").empty()
      return

    # プレビュー部 描画
    messagePrice = ""
    switch messageCd
      when @MESSAGE_CD.REQUEST.OWNER_CONTRACTS_SPEEDY, @MESSAGE_CD.ONGOING.OWNER_CONTRACTS_SPEEDY
        messagePrice = @model.get("buyer_hope_price")
      when @MESSAGE_CD.ONGOING.BUYER_CONTRACTS_SPEEDY
        messagePrice = @model.get("owner_hope_price")
      else
        #inputPrice = @$el.find("#nego_input_price").val()
        if inputPrice is ""
          messagePrice = null
        else
          messagePrice = @_editMoney(util.toOneByteAlphaNumeric(util.trim(inputPrice)))
    options =
      price : messagePrice
    msg = util.editNegoMessage(@collection.messages, @preview_data.message_cd, options)

    switch @model.get "role"
      when @USER_ROLE_KBN.BUYER
        preview_class = "previewDisplay2"
      when @USER_ROLE_KBN.SELLER
        preview_class = "previewDisplay"
      when @USER_ROLE_KBN.ADMIN
        preview_class = "previewDisplay"

    renderData =
      send_role_kbn : @model.get("role")
      message       : msg
      lb_send_role_kbn : "lb_send_role_kbn_" + @model.get("role")
      preview_class : preview_class
    $("div.nego_list_preview_block").html @template2 renderData
    @drawLabel()
    $('#nego_receive .scrollable').scrollTop($('#nego_receive .scrollable')[0].scrollHeight);
    $('body, html').scrollTop($('div.previewDisplay').offset().top - 50);

  # -------------------------
  # 商談キャンセルをプレビュー表示
  # -------------------------
  cancelNego: ->
    # console.info "NegoSendView#cancelNego"
    negoStatus = @model.get("nego_status")
    switch negoStatus
      when @NEGO_STATUS.REQUEST
        messageCd = @MESSAGE_CD.REQUEST.ADMIN_CANCEL
      when @NEGO_STATUS.ONGOING
        messageCd = @MESSAGE_CD.ONGOING.ADMIN_CANCEL
      else
        return  # ありえない

    @preview_data =
      message_cd      : messageCd
      message_price   : null
      can_input_price : false

    # 入力チェック
    rc = @checkSendNegoMessage(@preview_data)

    if rc is @RC_OK
      # プレビュー部 描画
      options = []
      msg = util.editNegoMessage(@collection.messages, @preview_data.message_cd, options)

      renderData =
        send_role_kbn : @model.get("role")
        message       : msg
        lb_send_role_kbn : "lb_send_role_kbn_" + @model.get("role")
        preview_class : "previewDisplay"
      $("div.nego_list_preview_block").html @template2 renderData
      @drawLabel()

  # -------------------------
  # プレビュー表示中のメッセージを送信
  # -------------------------
  sendNegoMessage: ->
    # kurukuru start
    indicator = new IndicatorView
      title   : Backbone.AUCNET.message.get ""
    @$el.append(indicator.render().el)
    # 入力チェック
    rc = @checkSendNegoMessage(@preview_data)

    if rc is @RC_OK
      userRole = @model.get("role")
      negoRemainCnt = parseInt(@model.get("nego_remain_cnt"))
      if userRole is @USER_ROLE_KBN.BUYER and negoRemainCnt > 1
        id = @POPUP_MESSAGE_CD.QUESTION_SEND_CONTAIN_FLOW_MESSAGE
      else
        id = @POPUP_MESSAGE_CD.QUESTION_SEND_MESSAGE

      title = @getMessage("ms_nego_msg" + id + "_title")
      message = @getMessage("ms_nego_msg" + id + "_message")

      # メッセージ送信実行
      data =
        nego_id        : @model.get("nego_id")
        inventory_year : @model.get("inventory_year")
        inventory_no   : @model.get("inventory_no")
        message_cd     : @preview_data.message_cd
        message_price  : @preview_data.message_price
      @collection.log.setNegoMessage data, indicator
      # プレビュー部 消去
      $("div.nego_list_preview_block").empty()

      # 金額欄の初期化
      @$el.find("#nego_input_ten_price").val ''
      @$el.find("#nego_input_price").val 0

  # -------------------------
  # プレビュー表示中のメッセージを送信キャンセル
  # -------------------------
  cancelSendMessage: ->
    # console.info "NegoSendView#cancelSendMessage"
    # プレビュー部 消去
    $("div.nego_list_preview_block").empty()

  # -------------------------
  # メッセージ選択変更時処理
  # -------------------------
  setInputEnable: ->
    # console.info "NegoSendView#setInputEnable"

    $chat_input_span = @$el.find(".chat_input_span")
    $input_ten   = @$el.find("#nego_input_ten_price")
    $input   = @$el.find("#nego_input_price")
    $selected = @$el.find("#nego_select_send_messages option:selected")
    hasPrice = $selected.data("hasprice")
    hasUnpushable = $selected.hasClass("unpushableColor")

    # 金額入力欄の活性/非活性 設定変更
    $input_ten.removeClass("readonlyBackgroundColor")
    $input.removeClass("readonlyBackgroundColor")
    $chat_input_span.removeClass("zeros3")
    if hasPrice and !hasUnpushable
      $input_ten.removeAttr("readonly")
      $input.removeAttr("readonly")
      $input.val("0")
    else
      $chat_input_span.addClass("zeros3")
      $input_ten.attr({"readonly" : "readonly"})
      $input_ten.addClass("readonlyBackgroundColor")
      $input_ten.val("")
      $input.attr({"readonly" : "readonly"})
      $input.addClass("readonlyBackgroundColor")
      $input.val("")

    # プレビューボタンの活性/非活性 設定変更
    if hasUnpushable
      @$el.find(".nego_btn_preview").attr("disabled", true)
    else
      @$el.find(".nego_btn_preview").removeAttr("disabled")

    # 選択できないボタンが押されたときのエラーメッセージ出力
    # if hasUnpushable
    #   @_alert(@POPUP_MESSAGE_CD.CLICK_UNSELECTABLE_BUTTON)

  # -------------------------
  # 受け取ったメッセージによって選択できるメッセージを制御する
  # -------------------------
  changeSelectableMessages : ->
    # console.info "NegoSendView#changeSelectableMessages"

    negoStatus = @model.get "nego_status"
    userRole = @model.get "role"
    messageCdList2 = []
    messageCdList2 = @MESSAGE_CD_LIST.REQUEST_ADMIN.slice(0)

    # 選択可能メッセージコード一覧設定
    messageCdList = []
    switch negoStatus
      # 申込中
      when @NEGO_STATUS.REQUEST
        switch userRole
          when @USER_ROLE_KBN.BUYER
            messageCdList = @MESSAGE_CD_LIST.BUYER_MSG_TYPE1.slice(0)
          when @USER_ROLE_KBN.SELLER
            messageCdList = @MESSAGE_CD_LIST.OWNER_MSG_TYPE1.slice(0)
          when @USER_ROLE_KBN.ADMIN
            messageCdList = @MESSAGE_CD_LIST.REQUEST_ADMIN.slice(0)
      # 商談中
      when @NEGO_STATUS.ONGOING
        switch userRole
          when @USER_ROLE_KBN.BUYER
            if @model.get("last_message_cd_owner") is "T04" or @model.get("last_message_cd_owner") is "T07" or @model.get("last_message_cd_owner") is "T08"
              messageCdList = @MESSAGE_CD_LIST.BUYER_MSG_TYPE2.slice(0)
            if @model.get("last_message_cd_owner") is "T05" or @model.get("last_message_cd_owner") is "T08"
              messageCdList = @MESSAGE_CD_LIST.BUYER_MSG_TYPE3.slice(0)
            if  @model.get("last_message_cd_buyer") is "K04"
              messageCdList = @MESSAGE_CD_LIST.BUYER_MSG_TYPE4.slice(0)
          when @USER_ROLE_KBN.SELLER
            if @model.get("last_message_cd_buyer") is "K03" or @model.get("last_message_cd_buyer") is "K01"
              messageCdList = @MESSAGE_CD_LIST.OWNER_MSG_TYPE2.slice(0)
            if @model.get("last_message_cd_buyer") is "K04"
              messageCdList = @MESSAGE_CD_LIST.OWNER_MSG_TYPE3.slice(0)
            if  @model.get("last_message_cd_owner") is "T08" or @model.get("last_message_cd_owner") is "T05"
              messageCdList = @MESSAGE_CD_LIST.OWNER_MSG_TYPE4.slice(0)
          when @USER_ROLE_KBN.ADMIN
            messageCdList = @MESSAGE_CD_LIST.ONGOING_ADMIN.slice(0)

    # 自分がボールを持っていない場合、「流札」のメッセージを削除
    switch util.editBuySellKbn(@model.toJSON())
      when @USER_ROLE_KBN.BUYER
        if  @model.get("need_answerer_kbn") is @USER_ROLE_KBN.SELLER
          k05_index = messageCdList.indexOf("K05")
          if k05_index > 0
            messageCdList.splice(k05_index, 1);
      when @USER_ROLE_KBN.SELLER
        if  @model.get("need_answerer_kbn") is @USER_ROLE_KBN.BUYER
          T09_index = messageCdList.indexOf("T09")
          if T09_index > 0
            messageCdList.splice(T09_index, 1);

    # 値寄せ不可の場合、成約と流札のみにする
    # 値寄せ不可 = 二つの希望価格の絶対値がピッチより狭い
    ownerHopePrice = +(@model.get("owner_hope_price"))
    buyerHopePrice = +(@model.get("buyer_hope_price"))
    diff = Math.abs(ownerHopePrice - buyerHopePrice)
    # ピッチ取得
    ownerPrice = +(@model.get("owner_price"))
    pitch = util.checkPitch(null, ownerPrice).pitch
    # メッセージリストを許可したもののみに削る
    if diff <= pitch
      messageCdList = _.filter(messageCdList, (cd) ->
        cd in @MESSAGE_CD_LIST.NEYOSE_FUKA
      , @)

    # 選択可能メッセージのみ表示する
    messageOptions = @negotiationMessageSelect @collection.messages
    @$el.find("#nego_select_send_messages").empty()

    selectedValue = null
    pushableFlag = null
    for i in [0...messageOptions.length]
      # messageOption = messageOptions.eq(i)
      messageOption = messageOptions[i]
      messageCd = messageOption.attr("value")

      if $.inArray(messageCd, messageCdList) < 0
        # messageOption.css("display", "none")
        messageOption.removeClass("message_option_display")
      else
        # messageOption.css("display", "")
        messageOption.addClass("message_option_display")
        selectedValue = messageCd       if !selectedValue?

      # 現在ユーザーが押せるボタンかを判定&設定する
      #   実際にはボタンは押せるが必ずエラーになる
      pushableFlag = false
      ownerPrice = parseInt(@model.get("owner_price"))
      ownerHopePrice = parseInt(@model.get("owner_hope_price"))
      ownerFinalPriceFlg = @model.get("owner_final_price_flg")
      buyerHopePrice = parseInt(@model.get("buyer_hope_price"))
      buyerFinalPriceFlg = @model.get("buyer_final_price_flg")
      lastRoleKbn = @model.get("last_role_kbn")

      # メッセージの表示
      if messageOption.hasClass("message_option_display") and !messageOption.hasClass("unpushableColor")
        @$el.find("#nego_select_send_messages").append messageOption

    @drawLabel()

    # 送信機能部の表示/非表示
    previewBlockDisplay = false

    switch negoStatus
      when @NEGO_STATUS.REQUEST, @NEGO_STATUS.ONGOING
        $('.chatBox').css "width", "60%"
        $('.chatBox').removeClass 'height_700'
        $(".chooseMsgeBox").css "display", ""
        @$el.find("#nego_select_send_messages").val(selectedValue)
        previewBlockDisplay = true
      when @NEGO_STATUS.CONTRACT, @NEGO_STATUS.FLOW, @NEGO_STATUS.CANCEL
        $('.chatBox').css "width", "100%"
        $('.chatBox').addClass 'height_700'
        $(".chooseMsgeBox").css "display", "none"

    if @USER_ROLE_KBN.OTHER is userRole
      $('.chatBox').css "width", "100%"
      $('.chatBox').addClass 'height_700'
      $(".chooseMsgeBox").css "display", "none"

    # 商談キャンセルボタンの表示/非表示
    if negoStatus in [@NEGO_STATUS.REQUEST, @NEGO_STATUS.ONGOING] and userRole is @USER_ROLE_KBN.ADMIN
      @$el.find(".nego_admin_cancel").css("display", "")
      previewBlockDisplay = true
    else
      @$el.find(".nego_admin_cancel").css("display", "none")

    # プレビュー部の表示/非表示
    if previewBlockDisplay
      $(".nego_list_preview_block").css("display", "")
    else
      $(".nego_list_preview_block").css("display", "none")

    # メッセージ選択変更時処理
    @setInputEnable()

  # -------------------------
  # 商談メッセージ修正
  # -------------------------
  negotiationMessageSelect : (param)->
    messageOptions = new Array()
    _messageMaster = param.filter((item) ->
      item.get("short_message_kbn") isnt "short"
    )

    for i in [0..._messageMaster.length]
      messageOption = document.createElement("option")
      _messageMaster[i] = _messageMaster[i].toJSON()
      $(messageOption).val _messageMaster[i].message_cd
      $(messageOption).attr "data-hasprice", _messageMaster[i].hasprice
      $(messageOption).attr "data-message",  _messageMaster[i].message
      $(messageOption).attr "data-label",    _messageMaster[i].lbbutton
      messageOptions[i] = $(messageOption)
      #messageOptions[i] = "<option value="+param[i].message_cd+" data-hasprice="+param[i].hasprice+" data-message="+param[i].message+" data-label="+param[i].lbbutton+"></option>"
    messageOptions


  # --------------------------
  #  送信メッセージ入力チェック
  #    プレビューボタン、送信ボタン押下時に、指定できるメッセージ内容であるか、クライアント情報だけでチェックできる項目をチェックする
  #  _json
  #    message_cd    : String 選択されたボタンに紐付くメッセージコード
  #    message_price : String 金額欄の入力値(加工前)
  #    can_input_price : boolean  金額欄が入力可能か
  # --------------------------
  checkSendNegoMessage : (_json) ->
    # console.info "NegoSendView#checkSendNegoMessage"
    # ログインユーザーが送信できるメッセージ（金額も含め）なのかチェックする
    # DBの内容を見ずに判定できることだけ行う
    #
    # 1.選択されたボタン名が金額必須の場合
    #   1-1.金額が入力されていること
    #   1-2.1～999999の範囲に収まっていること
    #   1-3.入力可能な範囲に収まっていること。
    #   1-4.5000円で割り切れること。　5000円はマスター化する
    #
    # 2.入力エラーが発生した場合
    #   エラーポップアップを表示する。エラーの理由が分かるように。
    #
    # HTML改ざんによる不整合チェックはサーバー側で実施する
    switch @model.get "role"
      when @USER_ROLE_KBN.BUYER
        preview_class = "previewDisplay2"
      when @USER_ROLE_KBN.SELLER
        preview_class = "previewDisplay"
      when @USER_ROLE_KBN.ADMIN
        preview_class = "previewDisplay"

    if !_json.can_input_price
      return @RC_OK

    inputPrice = util.toOneByteAlphaNumeric(util.trim(_json.message_price))
    if !util.isInteger(inputPrice) or inputPrice is ""
      # console.debug "数値形式でない"
      @_alert(@POPUP_MESSAGE_CD.MONEY_FORMAT_ERROR)
      return @RC_NG
    if inputPrice.length > 6
      # console.debug "7桁以上はNG"
      @_alert(@POPUP_MESSAGE_CD.OUT_OF_BOUND_MONEY)
      return @RC_NG

    currentPrice = @_editMoney(inputPrice)
    buyerHopePrice = @model.get("buyer_hope_price")
    ownerHopePrice = @model.get("owner_hope_price")
    ownerPrice = @model.get("owner_price")

    messageCd = @$el.find("#nego_select_send_messages option:selected").val()
    switch messageCd
      #入力　売り手価格ダウン
      when @MESSAGE_CD.REQUEST.OWNER_PRICE_DOWN, @MESSAGE_CD.REQUEST.OWNER_PRICE_LAST
        if !(buyerHopePrice < currentPrice <= ownerHopePrice)
          if (currentPrice > ownerHopePrice)
            @_alert('18')
          else if ( currentPrice <= buyerHopePrice)
            @_alert('16')
          return@RC_NG
      when @MESSAGE_CD.ONGOING.OWNER_PRICE_DOWN_AGAIN
        if !(buyerHopePrice < currentPrice < ownerHopePrice)
          if (currentPrice >= ownerHopePrice)
            @_alert('15')
          else if ( currentPrice <= buyerHopePrice)
            @_alert('16')
          return@RC_NG
      when @MESSAGE_CD.ONGOING.BUYER_PRICE_UP_AGAIN
        if !(buyerHopePrice < currentPrice < ownerHopePrice)
          # console.debug "購入希望額(" + buyerHopePrice + ") ＜入力(" + currentPrice + ") ＜ 販売金額(" + ownerHopePrice + ")　でない"
          # console.debug "価格提示"
          if currentPrice <= buyerHopePrice
            @_alert('14')
          if currentPrice >= ownerHopePrice
            @_alert('17')
          return @RC_NG
      when @MESSAGE_CD.ONGOING.BUYER_PRICE_LAST
        if !(buyerHopePrice <= currentPrice < ownerHopePrice)
          # console.debug "購入希望額(" + buyerHopePrice + ") ≦入力(" + currentPrice + ") ＜ 販売金額(" + ownerHopePrice + ")　でない"
          if currentPrice < buyerHopePrice
            @_alert('14')
          if currentPrice >= ownerHopePrice
            @_alert('17')
          return @RC_NG
      when @MESSAGE_CD.ONGOING.OWNER_PRICE_LAST
        if !(buyerHopePrice < currentPrice <= ownerHopePrice)
          # console.debug "購入希望額(" + buyerHopePrice + ") ＜入力(" + currentPrice + ") ≦ 販売金額(" + ownerHopePrice + ")　でない"
          if (currentPrice > ownerHopePrice)
            @_alert('15')
          else if ( currentPrice <= buyerHopePrice)
            @_alert('16')
          return@RC_NG
    # ピッチ(販売金額ごとの提示金額単位)チェック
    pitchCheck = util.checkPitch(currentPrice, ownerPrice)
    if pitchCheck.ret is no
      @_alert(@POPUP_MESSAGE_CD.ILLEGAL_INTERVAL_MONEY, pitchCheck.pitch)
      return @RC_NG

    return @RC_OK

  # -------------------------------------
  # 商談画面 OK専用のメッセージポップアップ表示
  # _id : "01"～"99"
  # -------------------------------------
  _alert: (_id, _param = null) ->
    title = @getMessage("ms_nego_msg" + _id + "_title")
    message = @getMessage("ms_nego_msg" + _id + "_message")
    if _param?
      message = message.replace(/%s/, _param)

    confirmView = new UtilConfirmView
      title   : title
      message : message
      cancelDelFlg : true
      callback : (ok)=>
        return ok
    @$el.append(confirmView.render().el)

  # -------------------------------------
  # 金額入力欄を実データ金額へ編集する
  #   _money    : 整数値が入った文字列
  # -------------------------------------
  _editMoney: (_money) ->
    switch Backbone.AUCNET.LANGUAGE_ID
      when "ja"
        return parseInt(_money) * 1000
    return 0
