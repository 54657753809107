# メニューバー
View               = require 'views/view'
template           = require 'views/templates/util_side_navigation'
Application = require 'application'

module.exports = class SideNavigationView extends View
  id       : 'util_side_navigation'
  template : template

  initialize: ->
    #console.debug "UtilSideNavigation#initialize"
    @collection = Backbone.AUCNET.sideMenu
    _.bindAll @
    # コレクションのイベントを見張る
    @listenTo @collection, 'change', @render

  # hbsに書き出すデータを指定
  getRenderData: ->

    if Backbone.AUCNET.roleID is '0'
      @user_menus = @collection.where(menu_auth : 0)
    else if Backbone.AUCNET.roleID is '1'
      @user_menus = @collection.where(menu_auth : 1)
    else if Backbone.AUCNET.roleID is '2'
      @user_menus = @collection.where(menu_auth : 2)
    else
      @user_menus = @collection.where(menu_auth : 0)

    for i in [0...(@user_menus.length)]
      @user_menus[i] = @user_menus[i].toJSON()

    menus: @user_menus

  afterRender: ->
    # メニューボタンの活性/非活性の制御
    @delegateEvents
      'click    a.menus_link'    : 'menusPress'
      'click    .renew_item_count'    : 'renewItemCount'
      'click    #privacy_policy_link'    : 'privacy_policy_link'
      'click    #terms_and_conditions_link'    : 'terms_and_conditions_link'
      'click    #operations_guide_link'    : 'operations_guide_link'
      #'touchstart .navigation_link_button' : 'hoverStart'
      #'touchend   .navigation_link_button' : 'hoverEnd'
      'mouseover  .navigation_link_button' : 'hoverStart'
      'mouseout   .navigation_link_button' : 'hoverEnd'
      #'touchstart .link_color' : 'otherLinkhoverStart'
      #'touchend   .link_color' : 'otherLinkhoverEnd'
      'mouseover  .link_color' : 'otherLinkhoverStart'
      'mouseout   .link_color' : 'otherLinkhoverEnd'
      #'touchstart .searchBtnCont' : 'hoverStartMobile'
      #'touchend   .searchBtnCont' : 'hoverEndMobile'
      'mouseover  .searchBtnCont' : 'hoverStartMobile'
      'mouseout   .searchBtnCont' : 'hoverEndMobile'

  menusPress: (param)->
    @$el.find('.menus_link').removeClass('current_menu')
    $(param.currentTarget).addClass('current_menu')
    @collection.where(menucd : $(param.currentTarget).data('menucd'))[0].set(newitemcount : undefined)
    #現在表示しているURLを削除する事で同じ画面へ遷移できるようになる
    Backbone.history.fragment = ''
    Application.router.navigate $(param.currentTarget).data('menucd'), true
    localStorage.removeItem 'iy'
    localStorage.removeItem 'in'
    localStorage.removeItem 'nab'
    # if $(".each_content").hasClass("indent")
    #   $(".each_content").toggleClass("indent");
    if !$(".lsidebar").hasClass("indent_menu")
      $(".lsidebar").toggleClass("indent_menu");


  otherLinkhoverStart : (param) ->
    $(param.currentTarget).addClass "other_link_hover"

  otherLinkhoverEnd : (param) ->
    $(param.currentTarget).removeClass "other_link_hover"


  renewItemCount:->
    Application.router.navigate 'test', true


  privacy_policy_link: ->
    window.open Backbone.AUCNET.DEFINE.get('PRIVACY_POLICY_URL')

  terms_and_conditions_link: ->
    window.open getDatetime() + Backbone.AUCNET.DEFINE.get('TERMS_AND_COND_URL')

  operations_guide_link: ->
    window.open getDatetime() + Backbone.AUCNET.DEFINE.get('OPERATIONS_GUIDE_URL')
