Application     = require 'application'
View            = require 'views/view'
template        = require 'views/templates/nego_apply'
listener        = require 'lib/listener'
NegoApplyModel  = require 'models/nego_apply_model'
UtilConfirmView = require 'views/util_confirm_view'
util            = require 'lib/util'
IndicatorView   = require 'views/indicator_view'

module.exports = class NegoApplyView extends View

  id        : 'nego_aplly'
  template  : template
  className : 'cont second'

  events:
    'click    #negoApply'          : 'negoApply'
    'click    #btnCancel'          : 'cancel'
    #'click    .apply_car_image'    : 'showCarDetail'
    'click    #stock_terms_link'   : 'openStockTermsLink'
    'blur     #price_ten_thousand' : 'checkEn'
    'blur     #price_thousand'     : 'checkEn'

  #申込を終了する必要があるエラー
  #有効なメールが存在しない。
  exit : ['ms_apply_msg08_message','ms_apply_msg10_message','ms_apply_msg15_message']

  # メール画面に飛ばすエラー
  toMailSetting : ['ms_apply_msg01_message']

  initialize: =>
    _.bindAll @
    #商談申込モデル
    @applyModel = new NegoApplyModel
    #モデル監視開始

  setApplyData : (applyDataArray,@chancelDelFlg) =>
    #エラーの場合ポップアップを表示し、自分自身を削除する [error]というtriggerは予約されているようです。
    @listenTo @applyModel    , '_error'      , @applyError , @applyModel.get 'messageParam'
    @listenTo @applyModel    , 'change'      , @applyRender
    @applyModel.getApplyData(applyDataArray)

  beforeRender : ->
    @delegateEvents @events

  #------------
  # 申し込みエラー(Eメール未登録等に使用する)
  #------------
  applyError : (paramArray = [])->
    messageId = []
    api_message = @applyModel.get 'api_message'
    message = ''
    if !_.isEmpty(api_message)
      options =
        api_err_cd       : paramArray.api_err_obj.api_err_cd
        api_err_kbn      : paramArray.api_err_obj.api_err_kbn
        admin_api_err_cd : paramArray.api_err_obj.admin_api_err_cd

      message = util.editNegoMessage([], paramArray.message_cd, options)

    else
      if @applyModel.get('message') in ['ms_apply_msg14_message', 'ms_apply_msg17_message']
        message = util.getMessage @applyModel.get('message') , paramArray
      else
        messageId.push @applyModel.get 'message'
        message = util.getMessages messageId , paramArray

    confirmView = new UtilConfirmView
      title    : Backbone.AUCNET.message.get 'ms_apply_msg01_title'
      message  : message
      cancelDelFlg : true
      # =>(ファットアロー)で渡すとcontextがここになる
      callback : (ok)=>
        #申込を閉じる必要のあるエラーの場合
        # messageIdは配列
        for val in messageId
          if val in @exit
            Backbone.AUCNET.RenderController.returnView()
            break
          # メールなしのエラーコードの場合
          # メール画面に遷移
          if val in @toMailSetting
            Backbone.AUCNET.RenderController.returnView()
            carList = @applyModel.get 'carDataArray'
            Application.router.navigate "config?nab=1&iy=#{carList[0].inventoryYear}&in=#{carList[0].inventoryNo}", true
            break

    @$el.append(confirmView.render().el)

  render:  ->
    @delegateEvents @events
    this

  #独自のレンダー
  applyRender : ->
    @beforeRender()
    @$el.html @template @getRenderData()
    @afterRender()
    @drawLabel()
    this

  getRenderData: =>
    cancel = true
    if @chancelDelFlg
      cancel = false
    hours         :  @applyModel.get 'expirationDateList'
    carDataArray  :  @applyModel.get 'carDataArray'
    emailArray    :  @applyModel.get 'emailArray'
    cancel        :  cancel
    #@sethours time

  # チェック済みアイテム取得
  getCheckedItem: (array) ->
    retArray = []
    for item in array
      if item.checked
        retArray.push item.value
    return retArray

  afterRender: ->
    window.scroll( 0, 0 );
    @appendMinimumPrice()
    return

  #-----------------------
  # 商談申込を行う
  #-----------------------
  negoApply: ->
    inputTenThousand = @$el.find('input#price_ten_thousand').val()
    inputThousand = @$el.find('input#price_thousand').val()
    # 千の単位未入力時、0にする
    inputThousand = "0" if inputThousand?.length is 0
    inputPrice = inputTenThousand + inputThousand
    #入力金額チェック
    # 千の位の長さが1でない or 何も入力されていない
    if (inputThousand?.length isnt 1) or (inputPrice is "0")
      @applyModel.set {'message': 'ms_apply_msg05_message'}, { silent : true }
      @applyModel.trigger '_error'
      return

    #入力値チェック
    unless util.isInteger inputPrice
      @applyModel.set {'message': 'ms_nego_msg02_message'}, { silent : true }
      @applyModel.trigger '_error'
      return

    #千単位入力のため
    price = (inputPrice * 1000)
    minPrice = @getMinPrice()
    if price < minPrice
      @applyModel.set {'message': 'ms_apply_msg06_message'}, { silent : true }
      @applyModel.trigger '_error'
      return

    #選択データ取得
    carList = @applyModel.get 'carDataArray'
    #申し込み車両の最大販売金額を取得
    jsonCarData = _.max carList, (carList) ->
      return carList.price

    #ピッチチェック
    pitchRet = util.checkPitch price,jsonCarData.price
    unless pitchRet.ret
      @applyModel.set {'message': pitchRet.message}, { silent : true }
      @applyError [pitchRet.pitch]
      return

    #回答期限チェック
    ancerDate = @$el.find('select[id="hours"]').val()
    if _.isEmpty(ancerDate)
      @applyModel.set {'message': 'ms_apply_msg07_message'}, { silent : true }
      @applyModel.trigger '_error'
      return

    #メールアドレスチェック確認
    mailList = @getCheckedItem(@$el.find('.send_mail_address'))
    if _.isEmpty(mailList)
      @applyModel.set {'message': 'ms_apply_msg02_message'}, { silent : true }
      @applyModel.trigger '_error'
      return

    #同意チェック確認
    checkAgree = @getCheckedItem(@$el.find('.checkAgree'))
    if _.isEmpty(checkAgree)
      @applyModel.set {'message': 'ms_apply_msg03_message'}, { silent : true }
      @applyModel.trigger '_error'

    #入力上限チェック
    message = ''
    if price > jsonCarData.price
      message = util.getMessage 'ms_apply_msg04_message' , [util.numberWithComma(jsonCarData.price)]
      price = jsonCarData.price
    else
      message = util.getMessage 'ms_apply_msg12_message' , [util.numberWithComma(price), util.formatDateString(ancerDate), mailList?.join(",<br>") ]

    #申し込み確認ポップアップ表示
    confirmView = new UtilConfirmView
      title    : Backbone.AUCNET.message.get 'ms_apply_msg02_title'
      message  : message
      # =>(ファットアロー)で渡すとcontextがここになる
      callback : (ok)=>
        #申込を閉じる必要のあるエラーの場合
        if ok
          # インディケーター(くるくる)表示
          indicator = new IndicatorView
            title   : Backbone.AUCNET.message.get "ms_indicator_apply_title"
          @$el.append(indicator.render().el)

          #申込みを行う
          @.$('#negoApply').attr('disabled', true);
          @applyModel.negoApply carList, mailList, price, ancerDate , (err ,negoId) =>
            # クルクル削除
            indicator.suicide()
            @.$('#negoApply').attr('disabled', false);
            @.$('#negoApply').removeAttr('disabled');
            if err
              @applyError @applyModel.get 'messageParam'
            else
              #複数商談時は商談候補へ
              if carList.lenth > 1
                Application.router.navigate "candidate", true
              else
                Backbone.AUCNET.RenderController.renderNegoPage negoId, carList[0].inventoryYear, carList[0].inventoryNo,false
                #Application.router.navigate "nego/#{negoId}/#{carList[0].inventoryYear}/#{carList[0].inventoryNo}", true
    @$el.append(confirmView.render().el)

  #------------------
  # 最低希望金額表示
  #------------------
  appendMinimumPrice : ->
    @$el.find('.minPrice').empty()
    keyList = @applyModel.get 'carDataArray'
    if _.isEmpty(keyList)
      return
    minimaxPrice = @getMinPrice keyList
    @$el.find('.minPrice').append util.editMoneyFormat minimaxPrice

    # 価格交渉不可の場合は希望金額に初期値を設定
    jsonCarData = _.max keyList, (selectCar) ->
      return selectCar.price
    unless jsonCarData.isGyohankakakuKosho
      priceTenThousand = Math.floor(minimaxPrice / 10000)
      if priceTenThousand > 0
        @.$('#price_ten_thousand').val priceTenThousand
      strPrice = String(minimaxPrice)
      @.$('#price_thousand').val strPrice.substr(strPrice.length - 4, 1)

  #------------------
  # 全角を半角に編集
  #------------------
  checkEn: (param) ->
    value = String(param.currentTarget.value)

    if value? and value.length > 0
      regex = /[Ａ-Ｚａ-ｚ０-９]/g
      param.currentTarget.value = value.replace regex, (m, pos, src) ->
        String.fromCharCode(m.charCodeAt(0)- 65248)
    else
      param.currentTarget.value = param.currentTarget.value

  #------------------
  # 最低希望金額取得
  #------------------
  getMinPrice : ->
    selectCar = @applyModel.get 'carDataArray'
    jsonCarData = _.max selectCar, (selectCar) ->
      return selectCar.price
    maxPrice = jsonCarData.price

    if jsonCarData.isGyohankakakuKosho
      #最低希望金額は販売額の98%(千桁以下切り捨て)
      minimaxPrice = maxPrice * Backbone.AUCNET.DEFINE.get('APPLY_MINIMAX_PRICE') # 設定値に変更
      minimaxPrice = minimaxPrice / 10000
      minimaxPrice = Math.floor(minimaxPrice)
      minimaxPrice = minimaxPrice * 10000
      return minimaxPrice
    else
      #価格交渉不可の場合は業販価格のまま
      return maxPrice

  #------------------
  # 商談申込みキャンセル
  #------------------
  cancel: ->
    Backbone.AUCNET.RenderController.returnView()

  #------------------
  # 車両詳細を開く
  #------------------
  showCarDetail: (param) ->
    invKey = param.currentTarget.id.split('|')
    Backbone.AUCNET.RenderController.renderCarDetail(invKey[0],invKey[1],false)

  #------------------
  # 共有在庫規約を開く
  #------------------
  openStockTermsLink: ->
    window.open getDatetime() + Backbone.AUCNET.DEFINE.get('STOCK_TERMS_URL')
