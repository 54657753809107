var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "\n  <div id=\"negolist_header_input\" class=\"displayMode\">\n    <label>\n      <input type=\"radio\" name=\"negolist_display_mode\" value=\"b\" checked=\"checked\">\n      <span data-label=\"lb_changeblockview\"></span>\n    </label>\n    <label>\n      <input type=\"radio\" name=\"negolist_display_mode\" value=\"l\">\n      <span data-label=\"lb_changelistview\" ></span>\n    </label>\n  </dv>";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}