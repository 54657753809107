# メニュー情報
Collection = require 'models/collection'

menuData   = require 'data/menu'

module.exports = class SideMenuCollection extends Collection

  initialize: ->
    #console.debug 'SideMenuCollection#initialize'
    _.bindAll @
    # メニューデータのセット
    @.reset menuData

  setSocket: ->
    @socket = Backbone.AUCNET.socket

    # 新着件数更新時
    @socket.on 'renewItemCount', (d) =>
      data = JSON.parse d
      #console.debug 'SideMenuCollection#initialize:renewItem[%s]', Date.now(), data
      # menucdによってitemcountを書き換える
      item = @.get(data.menucd)
      #console.debug 'item'
      #console.debug item
      #console.debug item
      if data.cnt
        item?.set 'newitemcount', data.cnt

  renewItemCount: (model) ->

    #console.debug 'renewItemCount@emit'
    @socket.emit 'renewItemCount',
      socketid   : Backbone.AUCNET.loginUser.get('_socketid')
      userid     : Backbone.AUCNET.loginUser.get('_userid')
