var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"sliderOuter\">\r\n  <div class=\"slider\">\r\n    <div class=\"cont\" >\r\n      <div class=\"child_search_tool_area\"></div>\r\n      <button type='button' class='precedingPageRequest' data-label=\"lb_request_back\"></button>\r\n      <h1 class=\"ptitleResult\">\r\n        <span class=\"ResultTitle_Request\" data-label=\"lb_search_result\"/>\r\n      </h1>\r\n      <div class=\"bgDes\"></div>\r\n      <div class=\"child_search_list_area\"></div>\r\n    </div>\r\n    <div id=\"detail_container\"></div>\r\n    <div id=\"apply_container\"></div>\r\n  </div>\r\n</div>\r\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}