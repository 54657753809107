application = require 'application'
View     = require 'views/view'
template = require 'views/templates/util_footer'

module.exports = class UtilFooterView extends View
  class : "util_footer"
  template: template
  initialize: ->
    _.bindAll @


