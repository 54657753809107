View       = require 'views/view'
Template   = require 'views/templates/request_data_line_base'

CarDataCollection  = require 'models/car_data_collection'
DataLineListView   = require 'views/data_line_list_view'
UtilPagingView     = require 'views/util_paging_view'
UtilChangeDispView = require 'views/util_change_disp_view'
SearchToolView     = require 'views/search_tool_view'
CarDetailBaseView  = require 'views/car_detail_base_view'
IndicatorView   = require 'views/indicator_view'
###
 [リクエスト] リクエストデータリストビュー
###
module.exports = class RequestDataListBaseView extends View
  id       : 'request_data_list_base'
  template : Template

  # 検索条件
  searchcond  : null
  # ソート条件
  sortcond    : null
  # 表示モード (l:リスト, b:ブロック)
  mode : "b"

  _requestId : 0
  _pageNo : 0

  # 初期化
  initialize: ->
    _.bindAll @
    # 車両データコレクション生成
    @carDataCollection = new CarDataCollection
    @listenTo @carDataCollection, 'GetRequestCarDataList', @listRender
    # 表示切替ビュー生成
    @utilChangeDispView = new UtilChangeDispView ls_key_id : @id
    @listenTo @utilChangeDispView, "ChangeDisplayMode", @changeDisplayMode, @
    # 検索ツールビュー生成
    @searchToolView = new SearchToolView
    @listenTo @searchToolView, 'ReflectSearchToolConditions', @reflectSearchToolConditions
    # ページングビュー生成
    @utilPagingView = new UtilPagingView id : @id
    @listenTo @utilPagingView, 'paging', @getRequestData
    # データリストビュー生成
    lsStringMyList = localStorage.getItem('request_data_list_base.list_disp_mode')
    if lsStringMyList?
      @mode = lsStringMyList
    @dataLineListView = new DataLineListView
      collection : @carDataCollection
      mode : @mode

  suicide : ->
    @carDataCollection?.remove()
    @carDataCollection?.stopListening()
    @utilChangeDispView?.remove()
    @searchToolView?.suicide()
    @utilPagingView?.remove()
    @.remove()

  # リストレンダ
  listRender: ->
    # データリストビューのレンダリング
    @dataLineListView.setCollection
      carDataCollection : @carDataCollection
    @dataLineListView.render()
    # ページングビューのレンダリング
    @utilPagingView.setPagingData
      dataCount  : @carDataCollection.count.search_car_data_count
    @utilPagingView.render()
    @indicator.suicide()
    # データリストビュー生成
    lsStringMyList = localStorage.getItem('request_data_list_base.list_disp_mode')
    if lsStringMyList?
      @dataLineListView.changeDisplayModeOnly(lsStringMyList)

  # レンダリング後の処理
  afterRender: ->
    # コンテンツ組立
    @$el.find(".child_search_tool_area")
      .append(@searchToolView.el)

    @$el.find('.bgDes')
   　　　.append(@utilChangeDispView.el)
      .append(@utilPagingView.el)

    @$el.find(".child_search_list_area")
      .append(@dataLineListView.el)

    # 各ビューのレンダリング
    @utilChangeDispView.render()
    @utilPagingView.render()
    # リクエスト取得
    @getRequestData()
    # 絞り込み条件取得
    @searchToolView.dispRequestSearchToolCond
      requestid : @_requestId

    # イベント設定
    @delegateEvents
      'click .car_thumbnail' : 'showCarDetail'
      'click .precedingPageRequest'  : 'precedingPage'
      #'touchstart .precedingPageRequest' : 'hoverStart'
      'touchend   .precedingPageRequest' : 'hoverEnd'
      'mouseover  .precedingPageRequest' : 'hoverStart'
      'mouseout   .precedingPageRequest' : 'hoverEnd'

  # 表示切替
  changeDisplayMode: (mode) ->
    @mode = mode
    # データリストビュー呼び出し
    @dataLineListView.changeDisplayMode(@mode)

  # 検索ツール条件反映
  reflectSearchToolConditions: ->
    # 検索条件取得
    conditions  = @searchToolView.getSearchToolConditions()
    @searchcond = conditions.searchcond
    @sortcond   = conditions.sortcond
    # カレントページ初期化
    @utilPagingView.page = 0
    # リクエスト
    @getRequestData()

  # リクエスト
  getRequestData: ->
    #console.debug "KURUKURUKURUKURUOK REQUEST!!!!!!!!!"
    @indicator = new IndicatorView
      title   : Backbone.AUCNET.message.get "ms_indicator_carname_title"
    $('body').append(@indicator.render().el)
    @carDataCollection.getRequestCarDataList
      searchcond : @searchcond
      sortcond   : @sortcond
      requestid  : @_requestId
      limit      : @utilPagingView.limit
      offset     : @utilPagingView.getOffset()

  # 車両詳細表示
  showCarDetail: (param) ->
    invKey = param.currentTarget.id.split('|')
    Backbone.AUCNET.RenderController.renderCarDetailPaging(invKey[0],invKey[1],@carDataCollection, @utilPagingView ,'GetRequestCarDataList')

  setRequestId: (requestId,pageNo) ->
    @_requestId = requestId
    @_pageNo = pageNo

  precedingPage: ->
    @trigger "precedingPage" , @_pageNo
