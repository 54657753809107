var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "      <li class=\"navigation_link_button\">\r\n        <label>\r\n          <a class=\""
    + alias2(alias1((depth0 != null ? depth0["class"] : depth0), depth0))
    + " menus_link\" data-menucd=\""
    + alias2(alias1((depth0 != null ? depth0.menucd : depth0), depth0))
    + "\">\r\n            <span class=\""
    + alias2(alias1((depth0 != null ? depth0.iconClass : depth0), depth0))
    + "\"></span>\r\n            <font data-label=\""
    + alias2(alias1((depth0 != null ? depth0.label : depth0), depth0))
    + "\"></font>\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.newitemcount : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </a>\r\n        </label>\r\n      </li>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "              <div class=\"new_item_count\">"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.newitemcount : depth0), depth0))
    + "</div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<nav class=\"navigation\">\r\n  <ul>\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.menus : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </ul>\r\n</nav>\r\n<div class=\"other_link\">\r\n  <label class='link_color' data-label=\"lb_operations_guide\" id=\"operations_guide_link\">利用簡易マニュアル</label>\r\n  <label class='link_color' data-label=\"lb_privacy_policy\" id=\"privacy_policy_link\">プライバシーポリシー</label>\r\n  <label class='link_color' data-label=\"lb_terms_and_conditions\" id=\"terms_and_conditions_link\">利用規約</label>\r\n</div>";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}