View       = require 'views/view'
Template   = require 'views/templates/util_change_disp'

###
 [共通] 表示切替ビュー
###
module.exports = class UtilChangeDispView extends View
  className : 'displayMode'
  template  : Template
  ls_key_id : undefined

  # ローカルストレージ キーワード
  LS_KEY_LDM : ".list_disp_mode"

  # イベント設定
  #events :
  #  "click .util_change_disp_input " : "fireChangeDisplayMode"

  # 初期化
  initialize: ->
    _.bindAll @
    @ls_key_id = @options.ls_key_id

  # レンダリング後処理
  afterRender: ->
    if Backbone.AUCNET.UserAgent is "MT"
      @$el.hide()

    @delegateEvents
      "click .util_change_disp_input " : "fireChangeDisplayMode"
    lsString = localStorage.getItem(@ls_key_id + @LS_KEY_LDM)
    if lsString?
      @$el.find('.util_change_disp_input').removeClass('active')
      switch lsString
        when "b" then @$el.find('.grid.util_change_disp_input').addClass('active')
        when "l" then @$el.find('.list.util_change_disp_input').addClass('active')
        else          @$el.find('.grid.util_change_disp_input').addClass('active')

  # 表示切替
  fireChangeDisplayMode:(event) ->
    @$el.find('.util_change_disp_input').removeClass('active')
    @$(event.currentTarget).addClass('active')
    mode = @$(event.currentTarget).data('value')
    @trigger "ChangeDisplayMode", mode
    # ローカルストレージに表示件数保存(ページ毎)
    localStorage.setItem(@ls_key_id + @LS_KEY_LDM, mode)
