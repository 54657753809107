Model        = require 'models/model'

###
　商談不可設定モデル
###
module.exports = class NegoDisableModel extends Model

  socket: undefined

  initialize: ->
     _.bindAll @
     @socket = Backbone.AUCNET.socket
     #ソケット破棄
     @socket.removeAllListeners 'stopBid'
     @socket.removeAllListeners 'getNegoDisable'

     @socket.on 'getNegoDisable', (d) =>
       #console.log "getNegoDisable = " + JSON.parse(d)
       result = JSON.parse(d)
       @set 'disable_flag' : result.disable_flag
       @trigger 'checkDisable'


     # ネット商談不可設定
     @socket.on 'stopBid', (d) =>
       #console.log "success stopBid !!!"
       #console.log "result = " + JSON.parse(d)
       result = JSON.parse(d)
       @set 'result' : result.result
       @trigger 'resultStopBid'

  #-------------
  # 商談不可設定
  #-------------
  stopBid: ->
    #console.log 'stopBid in model'
    @socket.emit 'stopBid',
      owner_member_cd : @get 'owner_member_cd'
      disabled_flag : @get 'disabled_flag'

  getNegoDisable: =>
    @socket.emit 'getNegoDisable',
      owner_member_cd : @get 'owner_member_cd'

