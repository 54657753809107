###
クライアントの設定情報
###

client_define =
  {
    "FORGET_PASSWORD_URL" :'pdf/password_20140416.pdf'
    "ENVIRONMENT_URL" :'autoweb_rem.html'
    "LOGIN_BANNER_AUC_URL" :'http://www.aucnet.co.jp/'
    "LOGIN_BANNER_AUCNEO_URL" :'https://www.aucneostation.com/'
  }

module.exports = client_define