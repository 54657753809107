Application = require 'application'
View        = require 'views/view'
Template    = require 'views/templates/search_inv_no'

###
 [車両検索] 問合番号入力ビュー
###
module.exports = class SearchInvNoView extends View
  id          : 'search_inv_no'
  template    : Template

  events:
    'click  #btnPrev'              : 'goPrev'
    'blur   .search_inv_no'        : 'checkInvNo'
    'change #search_inv_no_to'     : 'editInvNoFromTo'
    'keyup  .search_inv_no'        : 'removeInvalidChar'

  # 初期化
  initialize: ->
    _.bindAll @

  # 起動
  start: ->
    # レンダリング
    @options.searchModel.unbind 'GetSearchCount'
    @options.searchModel.bind 'GetSearchCount', @setSearchCount
    @render()
    $("#searchCount").remove()

  # 問合番号チェック
  checkInvNo: (param) ->
    value = param.currentTarget.value
    if value? and value.length > 0 and value.match(/^[0-9]+$/g) is null
      # 数値チェックでエラーの場合、アラートメッセージを表示
      #@showMsgBox 'ms_aleart_msg_input_inv_no' , 'ms_nego_msg18_title'
      #param.currentTarget.focus()
    else
      # 正しい値の場合、アラートメッセージを非表示
      if value.length > 0 and value.length != 8
        # 8桁に満たない場合、0パディング
        param.currentTarget.value = ('0000000' + value).slice(-8)
      @getCount()
  # 検索方法選択画面へ遷移
  goPrev: ->
    # 条件をクリア
    @options.searchModel.setInvNo undefined
    # 検索方法選択画面を表示
    @options.parent.changeChildView 'search_method', false

  # 検索結果画面へ遷移
  goResult: ->
    invNoCond = @editInvNoCond()
    if _.isEmpty(invNoCond.range.from) and _.isEmpty(invNoCond.range.to) and _.isEmpty(invNoCond.spec)
      @showMsgBox("ms_search_inv_number", "ms_search_inv_number_title")

    else
      #count = @$el.find("#searchCount")
      #count2 = $("#searchCount").text()
      #入力チェック
      inputError = false
      unless _.isEmpty(invNoCond.range.from)
        if invNoCond.range.from.match(/^[0-9]+$/g) is null
          inputError = true
      unless _.isEmpty(invNoCond.range.to)
        if invNoCond.range.to.match(/^[0-9]+$/g) is null
          inputError = true
      unless _.isEmpty(invNoCond.spec)
        for val in invNoCond.spec
          if val.match(/^[0-9]+$/g) is null
            inputError = true

      if inputError
        @showMsgBox 'ms_aleart_msg_input_inv_no' , 'ms_nego_msg18_title'
        return

      #else if count? and count2 is '0'
      #  @showMsgBox("ms_search_condition_error", "ms_search_condition_error_title")

      else
        # 入力チェックをパスした場合は、改めて対象件数を取得
        @listenToOnce @options.searchModel, 'GetSearchCount', @goResultReal
        @options.searchModel.setInvNo invNoCond
        @options.searchModel.getSearchCount @options.searchModel.getSearchConditions()

  # 検索結果画面へ遷移
  goResultReal: (param) ->
    # 件数が0の場合はメッセージ表示
    if param is '0'
      @showMsgBox("ms_search_condition_error", "ms_search_condition_error_title")
    # 検索上限を超えた場合はメッセージを表示
    else if param > Backbone.AUCNET.DEFINE.get "SEARCH_LIMIT"
      # 検索上限を超えた場合はメッセージを表示
      @showMsgBox("ms_search_car_name_limit_over", "ms_search_condition_error_title")
    else
      # 検索結果画面を表示
      @options.parent.changeChildView 'search_result', true

  # 問合番号編集
  editInvNoCond: ->
    fromto = @editInvNoFromTo()
    spec   = @editInvNoSpec()

    invNoCond = {}
    if fromto? then _.extend(invNoCond, fromto)
    if spec?   then _.extend(invNoCond, spec)
    return invNoCond

  # 問合番号From-To編集
  editInvNoFromTo: ->
    # HTMLから問合番号From-To取得
    from = @$el.find('#search_inv_no_from')[0].value
    to   = @$el.find('#search_inv_no_to')[0].value
    if from > to and from isnt '' and to isnt ''
      $("#search_inv_no_from").val(to)
      $("#search_inv_no_to").val(from)
      from = @$el.find('#search_inv_no_from')[0].value
      to   = @$el.find('#search_inv_no_to')[0].value

    range :
      from : if from? and from isnt '' then from else null
      to   : if to?   and to   isnt '' then to   else null


  # 問合番号指定
  editInvNoSpec: ->
    # HTMLから問合番号指定取得
    retArray = []
    array = @$el.find('.search_inv_no_spec')
    for item in array
      if item? and item.value isnt '' then retArray.push item.value
    spec : retArray

  # 入力不可の文字を削除する
  removeInvalidChar: (param) =>
    # 数字以外を除外
    param.target.value = param.target.value.replace(/[^0-9]/g,'');
    if param.keyCode is 13
      # ENTERキーの場合はフォーカス切り替え
      @focusNext param

  # 次項目にフォーカスを設定する
  # 最期の項目の場合は検索実行
  focusNext: (param) =>
    switch param.target.id
      when "search_inv_no_from"   then @$el.find("#search_inv_no_to").focus()
      when "search_inv_no_to"     then @$el.find("#search_inv_no_spec1").focus()
      when "search_inv_no_spec1"  then @$el.find("#search_inv_no_spec2").focus()
      when "search_inv_no_spec2"  then @$el.find("#search_inv_no_spec3").focus()
      when "search_inv_no_spec3"  then @$el.find("#search_inv_no_spec4").focus()
      when "search_inv_no_spec4"  then @$el.find("#search_inv_no_spec5").focus()
      when "search_inv_no_spec5"  then @$el.find("#search_inv_no_spec6").focus()
      when "search_inv_no_spec6"  then @$el.find("#search_inv_no_spec7").focus()
      when "search_inv_no_spec7"  then @$el.find("#search_inv_no_spec8").focus()
      when "search_inv_no_spec8"  then @$el.find("#search_inv_no_spec9").focus()
      when "search_inv_no_spec9"  then @$el.find("#search_inv_no_spec10").focus()
      when "search_inv_no_spec10" then @$el.find("#search_inv_no_spec11").focus()
      when "search_inv_no_spec11" then @$el.find("#search_inv_no_spec12").focus()
      when "search_inv_no_spec12" then @goResult()

  getCount: ->
    invNoCond = @editInvNoCond()
    if _.isEmpty(invNoCond.range.from) and _.isEmpty(invNoCond.range.to) and _.isEmpty(invNoCond.spec)
      @setSearchCount "- - - - - -"

    else
      #入力チェック
      inputError = false
      unless _.isEmpty(invNoCond.range.from)
        if invNoCond.range.from.match(/^[0-9]+$/g) is null
          inputError = true
      unless _.isEmpty(invNoCond.range.to)
        if invNoCond.range.to.match(/^[0-9]+$/g) is null
          inputError = true
      unless _.isEmpty(invNoCond.spec)
        for val in invNoCond.spec
          if val.match(/^[0-9]+$/g) is null
            inputError = true

      if inputError
        @setSearchCount "- - - - - -"
        return
      else
        @options.searchModel.setInvNo invNoCond
        @options.searchModel.getSearchCount @options.searchModel.getSearchConditions()

  # カウント設定
  setSearchCount: (param) ->
    #@$el.find("#searchCount").remove()
    #@$el.find("#btnSearchExec").append('<span id="searchCount">' + param + '</span>')
    $("#searchCount").remove()
    $("#btnSearchExec").append('<span id="searchCount" class="searchCountClass">' + param + '</span>')
    @searchCount = param
