util = require './util'
NegoMessageCollection = require 'models/nego_message_collection'

# 3桁毎にカンマを挿入
Handlebars.registerHelper 'numberWithComma', (num) ->
  util.numberWithComma num

# 数値を円マーク+3桁毎にカンマを挿入
Handlebars.registerHelper 'numberWithYenComma', (num) ->
  if num is ''
    return ''
  else
    "￥" + util.numberWithComma(num)

# ユーザ入力項目(マルチライン)を出力
Handlebars.registerHelper 'userInputMultiLine', (str) ->
  return str unless str?
  u_str = util.unescapeUnicode str
  return u_str.replace('|', '　')

# ユニコードのエスケープ解除
Handlebars.registerHelper 'unescapeUnicode', (str) ->
  util.unescapeUnicode str

# テンプレート用関数
# 第一引数と第二引数の値を比較します
#  同じ場合：true
#  違う場合：false
Handlebars.registerHelper "equal", (lvalue, rvalue, options) ->
  unless lvalue is rvalue
    return options.inverse this
  else
    return options.fn this

#展開図、自己申告画像の出し分け処理
Handlebars.registerHelper "printpic", (selfhyoka, imagekesai, estimation, options) ->

  if estimation is '1' or estimation is '2'
    return options.inverse this  ##tenkaizu

  else if  estimation is '0' or estimation is '3'
    if selfhyoka is '1'
      #console.debug "fn1 tenkaizu!!!"
      return options.fn this  ##selfimage
    else
      #console.debug "inverse selfhyoka"
      return options.fn this  ##selfimage
  else
      return

#自己申告評価点の出し分け処理
Handlebars.registerHelper "editSelfHyokaTen", (selfhyoka, estimation, options) ->
  if selfhyoka is '1'
    if estimation is '1' or estimation is '2'
      return options.inverse this
    else
      return options.fn this
  else
    return options.inverse this



# 評価点の出し分け処理
Handlebars.registerHelper "editEstimationDisplay", (estimation, estimation_flag) ->
  if estimation_flag is '0'or estimation_flag is '3'
    return ''

  if estimation is '0'or estimation is ''
    return ''

  return estimation

# 修復歴申告の出し分け処理
Handlebars.registerHelper "repairHistory", (estimation_flag, options) ->
  if estimation_flag is '1' or estimation_flag is '2'
    return options.inverse this
  else
    return options.fn this


# エスケープを行わずに出力
Handlebars.registerHelper 'safeString', (str) ->
  return new Handlebars.SafeString(str)

# 日付フォーマットに変換
Handlebars.registerHelper 'formatDateString', (str) ->
  util.formatDateString str

# 日付フォーマットに変換（Date型）
Handlebars.registerHelper 'formatDate', (date) ->
  return "" unless date?
  label = Backbone.AUCNET.label
  format = label.get "lb_date_format"
  date = new Date date.split(".")[0]
  return util.formatDate date, format

# 日付フォーマットに変換（Date型）(時間なし、/区切り)
Handlebars.registerHelper 'formatDateNoTaime', (date) ->
  return "" unless date?
  label = Backbone.AUCNET.label
  format = label.get "lb_date_format_no_taime"
  date = new Date date
  return util.formatDate date, format


# 金額フォーマットに変換
Handlebars.registerHelper 'formatMoney', (num) ->
  util.formatMoney num

# 商談ステータスのラベルを返す
Handlebars.registerHelper 'getNegoStatus', (statusCD) ->
  DEFINE = Backbone.AUCNET.DEFINE
  retStatus = switch statusCD
    when DEFINE.get "NEGO_STATUS_REQUEST"  then "lb_nego_status_request"
    when DEFINE.get "NEGO_STATUS_ONGOING"  then "lb_nego_status_ongoing"
    when DEFINE.get "NEGO_STATUS_CONTRACT" then "lb_nego_status_contract"
    when DEFINE.get "NEGO_STATUS_FLOW"     then "lb_nego_status_flow"
    when DEFINE.get "NEGO_STATUS_CANCEL"   then "lb_nego_status_cancel"
    else ""
  return retStatus

# 商談ステータスのラベルを返す
Handlebars.registerHelper 'getAgreementWrap', (statusCD) ->
  DEFINE = Backbone.AUCNET.DEFINE
  retStatus = switch statusCD
    when DEFINE.get "NEGO_STATUS_REQUEST"  then "lb_nego_status_request"
    when DEFINE.get "NEGO_STATUS_ONGOING"  then "lb_nego_status_ongoing"
    when DEFINE.get "NEGO_STATUS_CONTRACT" then "lb_nego_status_contract"
    when DEFINE.get "NEGO_STATUS_FLOW"     then "lb_nego_status_flow"
    when DEFINE.get "NEGO_STATUS_CANCEL"   then "lb_nego_status_cancel"
    else ""

  html =  "<div class='agreementWrap" + statusCD + " past_only' style='display:none;'>"
  html += "<span class='agreementInner" + statusCD + "' data-label='" + retStatus + "'></span></div>"
  return html

# 配列が空の時はその旨表示する
Handlebars.registerHelper 'eachArray', (array, options) ->
  if array.length is 0
    options.inverse array
  else
    html = ""
    for item in array
      html += options.fn item
    return html

# スラッシュ区切りの色ラベルをspanで返す
Handlebars.registerHelper 'getColorLabel', (colorlabel) ->
  if colorlabel? and colorlabel isnt ''
    colors = colorlabel.split "/"
    html = []
    for item in colors
      html.push Backbone.AUCNET.label.get item
    return html.join "/"

# スラッシュ区切りの色ラベルをdivで返す
Handlebars.registerHelper 'getColorLabelDiv', (colorlabel) ->
  if colorlabel? or colorlabel isnt ''
    label = Backbone.AUCNET.label
    format = label.get "lb_date_format_no_taime"

    colors = colorlabel.split "/"
    html = []
    for item in colors
      html.push label.get item
    return html.join "/"

#テーブルの行を作成する。
Handlebars.registerHelper 'createTableLine', (label, value) ->
  return "<tr><th data-label = '" + label + "'></th><td>" + value + "</td></tr>"

#テーブルの行を作成する。
Handlebars.registerHelper 'createTableLineValueIsLabel', (label, value) ->
  return "<tr><th data-label = '" + label + "'></th><td data-label = '" + value + "'></td></tr>"

#フラグによってテーブルの行を作成する。
Handlebars.registerHelper 'decisionCreateTableLine', (label, value, flag) ->
  return if flag isnt '1'
  return "<tr><th data-label = '" + label + "'></th><td>" + value + "</td></tr>"

#フラグによってテーブルの行を作成する。
Handlebars.registerHelper 'decisionCreateTableLineValueIsLabel', (label, value, flag) ->
  return if flag isnt '1'
  return "<tr><th data-label = '" + label + "'></th><td data-label = '" + value + "'></td></tr>"

# safariでデザインが崩れる為デフォルト100%を外す
#Handlebars.registerHelper 'outputImage', (imageUrl, width = "100%", height = "100%") ->
Handlebars.registerHelper 'outputImage', (imageUrl, width = "", height = "") ->
  NURL = Backbone.AUCNET.DEFINE.get('NOPICTURE')
  URL = Backbone.AUCNET.DEFINE.get('PICTURE_URL') + "00" + imageUrl
  img = new Image();
  img.src = URL
  img.onload = ->
    return "<img src='" + URL + "' > "

  img.onerror = ->
    #no good

    jQuery("img[src='#{URL}']").attr("src", NURL)
  return "<img src='" + URL + "' > "

#Handlebars.registerHelper 'outputImage', (imageUrl, width = "100%", height = "100%") ->
Handlebars.registerHelper 'outputImageForAlbum', (imageUrl, width = "", height = "") ->
  NURL = Backbone.AUCNET.DEFINE.get('NOPICTURE')
  URL = Backbone.AUCNET.DEFINE.get('PICTURE_URL') + "2" + imageUrl
  img = new Image();
  img.src = URL
  img.onload = ->
    return "<img src='" + URL + "' > "

  img.onerror = ->
    #no good

    jQuery("img[src='#{URL}']").attr("src", NURL)
  return "<img src='" + URL + "' > "

  #return "<img src='https://www.aucneostation.com/member/search/img/nophoto_s_1.jpg' width='" + width + "' height='" + height + "' > "

#状態アイコンを作成する
Handlebars.registerHelper 'outputCarStatusIcon', (status) ->
  statusClass = "statusNegociating" if status is 'car_status_0'
  statusClass = "statusAccepting" if status is 'car_status_1'
  statusClass = "statusNegociateImpossible" if status is 'car_status_2'
  return "<div class='nAccepting #{statusClass}'><span class='carStatus' data-label='#{status}'></span></div>"

# リスト用の画像取得
Handlebars.registerHelper 'outputImageForList', (imageUrl, width = "", height = "") ->
  NURL = "../images/noPhoto_s.jpg"
  URL = Backbone.AUCNET.DEFINE.get('PICTURE_URL') + "2" + imageUrl
  img = new Image();
  img.src = URL;
  img.onload = ->
    #console.log arguments...
    return "<img src='" + URL + "' > "

  img.onerror = ->
    #console.log arguments...
    #no good
    jQuery("img[src='#{URL}']").attr("src", NURL)
  return "<img src='" + URL + "' > "

  #申し込み用画像取得
Handlebars.registerHelper 'outputImageApply', (imageUrl, width = "", height = "") ->
  NURL = "../images/noPhoto_s.jpg"
  URL = Backbone.AUCNET.DEFINE.get('PICTURE_URL') + "3" + imageUrl
  img = new Image();
  img.src = URL;
  img.onload = ->
    #console.log arguments...
    return "<img src='" + URL + "' style='width: 100%;height: 100%;'> "

  img.onerror = ->
    #console.log arguments...
    #no good
    jQuery("img[src='#{URL}']").attr("src", NURL).css('width','100%').css('height','100%')
  return "<img src='" + URL + "' style='width: 100%;height: 100%;'> "

  #展開図取得
Handlebars.registerHelper 'outputtenkaizu', (imageUrl, width = "", height = "") ->
  NURL = "../images/noPhoto_s.jpg"
  URL = "data:image/png;base64,"+imageUrl
  img = new Image();
  img.src = URL;
  img.onload = ->
    #console.log arguments...
    return "<img src='" + URL + "' > "

  img.onerror = ->
    #console.log arguments...
    #no good
    jQuery("img[src='#{URL}']").attr("src", NURL)
  return "<img src='" + URL + "' > "

  #return "<img src='https://www.aucneostation.com/member/search/img/nophoto_s_1.jpg' width='" + width + "' height='" + height + "' > "




# 売り買い区分表示
Handlebars.registerHelper 'getBuySellLabel', (buy_sell_kbn) ->
  DEFINE = Backbone.AUCNET.DEFINE
  label = switch buy_sell_kbn
    when DEFINE.get "USER_ROLE_KBN_OTHER"  then "lb_nego_user_kbn_other"
    when DEFINE.get "USER_ROLE_KBN_BUYER"  then "lb_nego_user_kbn_buyer"
    when DEFINE.get "USER_ROLE_KBN_SELLER" then "lb_nego_user_kbn_seller"
    else ""
  return label

# console表示（便利）
Handlebars.registerHelper 'console', (obj) ->
  #console.log "viewhelper : %o", obj

# 配列の最初の1アイテムのみ表示し、配列が2以上の場合はボタンを表示する
Handlebars.registerHelper 'firstItem', (array, options) ->
  html = options.fn array[0]
  #html += "<button data-label='lb_negolist_more_item' class='negolist_multi_show'></button><div class='negolist_multi_container'></div>" if array.length > 1
  return html

# 配列の最初の1アイテムのみ表示し、配列が2以上の場合はボタンを表示する
Handlebars.registerHelper 'firstItemBtn', (array, options) ->
  return "<button type='button' data-label='lb_negolist_more_item' class='negolist_multi_show'></button><div class='negolist_multi_container'></div>" if array.length > 1

# 配列の最初のみ表示せず、他は表示する
Handlebars.registerHelper 'eachWithoutFirst', (array, options) ->
  arrayWithoutFirst =  array.slice(1)
  html = ""
  html += options.fn item for item in arrayWithoutFirst
  return html

# 商談メッセージを返す
Handlebars.registerHelper "getNegoMessageLabel", (messageCD, price = null, limit_hour = null) ->
  message = util.editNegoMessageShortMessage new NegoMessageCollection, messageCD, {price:price, limithour: limit_hour}
  return message

Handlebars.registerHelper "apendMailAdrress", (adrJson,delNonFlag) ->
  if adrJson.address? and adrJson.address isnt ""
    if adrJson.flg is '1'
      #console.log 'delNonFlag :' , delNonFlag
      if delNonFlag
        return "<li><span class='word_break'>" + adrJson.address + "</span></li>"
      else
        return "<li><span class='word_break'>" + adrJson.address + "</span><button type='button' class='delEmail delete' data-label='lb_mail_del' value='" + adrJson.address + "'></button></li>"
    else
      return "<li><span class='word_break'>" + adrJson.address + "</span><span data-label='lb_mail_no_activate'></span>
              <span style='float:right;'><button type='button' class='mailButton delEmail delete' data-label='lb_mail_del' value='" + adrJson.address + "'></button>
              <button class='mailButton reSendEmail delete' data-label='lb_mail_re_send' value='" + adrJson.address + "'></span></button></li>"
  else
    return "<li><span data-label='lb_mail_no_data'></span></li>"

# 商談候補用申し込みボタン切り替え
Handlebars.registerHelper "getApplyBtnForCandidate", (status, inventory_year, inventory_no, carName, gradeName, imagePath) ->
  if status is 'car_status_1'
    return "<label class='oCChkBx_overwrite'><input class='data_line_check' type='checkbox' value='" + inventory_year + "|" + inventory_no + "|" + carName + "|" + gradeName + "|" + imagePath + "' style='display: none;' id='" + inventory_year + "|" + inventory_no + "|" + carName + "|" + gradeName + "|" + imagePath + "'><label class='oCChkBx' for='" + inventory_year + "|" + inventory_no + "|" + carName + "|" + gradeName + "|" + imagePath + "'><span data-label='lb_candidate_nego_apply_chk' /></label></input></label>"
  else
    return "<div class='oCChkBx_checkbox'><span class='KAETEKUDASAI' data-label = '" + status + "'> </span></div>"

# DEFINEを返す
Handlebars.registerHelper "getDefine", (defineName) ->
  return Backbone.AUCNET.DEFINE.get defineName


# 残り時間がわずかの場合文言を表示
Handlebars.registerHelper "editRemaintime", (remainTime) ->
  ret = remainTime
  limit = Backbone.AUCNET.setting.remain_time
  if remainTime < limit
    ret = Backbone.AUCNET.label.get "lb_negolist_remain_time_little"
  return ret

# 【商談一覧系】ステータスと売り買い区分でリボンの色クラスを返す
Handlebars.registerHelper "getRibbonClass", (negoStatus, hashOwnerMemberCD, hashBuyerMemberCD) ->
  NEGO_STATUS_REQUEST  = Backbone.AUCNET.DEFINE.get "NEGO_STATUS_REQUEST"
  NEGO_STATUS_ONGOING  = Backbone.AUCNET.DEFINE.get "NEGO_STATUS_ONGOING"
  NEGO_STATUS_CONTRACT = Backbone.AUCNET.DEFINE.get "NEGO_STATUS_CONTRACT"
  NEGO_STATUS_FLOW     = Backbone.AUCNET.DEFINE.get "NEGO_STATUS_FLOW"
  NEGO_STATUS_CANCEL   = Backbone.AUCNET.DEFINE.get "NEGO_STATUS_CANCEL"
  myMemberCD = Backbone.AUCNET.hashUserID
  ribbonName = ""
  switch myMemberCD
    when hashOwnerMemberCD
      switch negoStatus
        when NEGO_STATUS_REQUEST, NEGO_STATUS_ONGOING
          ribbonName = "buistalk"       # 売り&(商談申込中,商談中)
        when NEGO_STATUS_CONTRACT, NEGO_STATUS_FLOW, NEGO_STATUS_CANCEL
          ribbonName = "negResult"      # 売り&(成約,流札,キャンセル)
    when hashBuyerMemberCD
      switch negoStatus
        when NEGO_STATUS_REQUEST, NEGO_STATUS_ONGOING
          ribbonName = "buistalkGreen"  # 買い&(商談申込中,商談中)
        when NEGO_STATUS_CONTRACT, NEGO_STATUS_FLOW, NEGO_STATUS_CANCEL
          ribbonName = "negResultGreen" # 買い&(成約,流札,キャンセル)
    else
      ribbonName = "none"
  return ribbonName


# 第一引数が第二引数を数字に変換し、第一引数が大きい場合if節を、第二引数が大きい場合else節を実行する
Handlebars.registerHelper "isBigger", (firstValue, secondValue, options)->
  # 数字として比較
  if (+firstValue > +secondValue)
    options.fn this
  else
    options.inverse this

# 【商談一覧系】成約中の場合とそれ以外の場合
Handlebars.registerHelper "isContract", (negoStatus, options) ->
  if (negoStatus is Backbone.AUCNET.DEFINE.get "NEGO_STATUS_CONTRACT")
    options.fn this
  else
    options.inverse this

# 【商談一覧】書類を表示
Handlebars.registerHelper "getDocumentLabel", (obj) ->
  ret = ''
  if obj.one_owner? then ret = ret + '<span data-label=' + obj.one_owner + ' />'
  if obj.gurantee?  then ret = ret + '<span data-label=' + obj.gurantee + ' />'
  if obj.manual?    then ret = ret + '<span data-label=' + obj.manual + ' />'
  return ret

# 【商談一覧】装備を編集
Handlebars.registerHelper "getEquipmentLabel", (obj) ->
  ret = ''
  if obj.navi?         then ret = ret + '<span data-label=' + obj.navi + ' />'
  if obj.navi_kind?    then ret = ret + '<span data-label=' + obj.navi_kind + ' />'
  if obj.sunroof?      then ret = ret + '<span data-label=' + obj.sunroof + ' />'
  if obj.leather_seat? then ret = ret + '<span data-label=' + obj.leather_seat + ' />'

# 管理者の場合true
Handlebars.registerHelper "isAdmin", (notUseVal, options) ->
  if Backbone.AUCNET?.roleID is Backbone.AUCNET.DEFINE.get "ROLE_ID_ADMIN"
    options.fn this
  else
    options.inverse this


# 第一引数が第二引数がイコールならばif節を、違う場合else節を実行する
Handlebars.registerHelper "isEquals", (firstValue, secondValue, options) ->
  if firstValue is secondValue
    options.fn this
  else
    options.inverse this

# ラベルを返す
Handlebars.registerHelper "getLabelStr", (label) ->
  return Backbone.AUCNET.label.get label
