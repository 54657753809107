Model        = require 'models/model'

###
　商談申し込みボタン用モデル
###
module.exports = class NegoApplyBtnModel extends Model

  socket: undefined

  #持つべきデータをここに書く。
  defaults: undefined

  NEGO_APLLY_CHECK = 'NegoApplyCheck'

  initialize: =>
    _.bindAll @
    @socket = Backbone.AUCNET.socket

    #check
    @socket.removeAllListeners NEGO_APLLY_CHECK
    @socket.on NEGO_APLLY_CHECK, (d) =>
      retJson = JSON.parse d
      @set 'negoId'  : retJson.negoId , { silent : true }
      @set 'message' : retJson.message

  ###
    商談状況確認
  ###
  checkNego : =>
    @socket.emit NEGO_APLLY_CHECK ,
      invNo   : @get('invNo')
      invYear : @get('invYear')
    return