LoginViewBase = require 'views/admin_login_view'

###
  [管理者ログイン] login.html用のビュー
###
module.exports = class LoginView extends LoginViewBase

  id: 'login_view'

  initialize: ->
    super
    @drawLabel()
    @$el.show()

  login:(event) ->
    event?.preventDefault?()
    super

  loginSuccess0 :(data = {}) ->
    _jump 'index.html#negolistadmin'

  loginSuccess1 :(data = {}) ->
    # haken画面に遷移
    _jump 'index.html#negolistadmin'

  loginSuccess2 :(data = {}) ->
    if (data.myNegoCnt?) and (data.myNegoCnt > 0)
      _jump "index.html#negolist"
    else
      _jump "index.html#negolist"

  # プライベートな関数
  _jump = (segment) ->
    $("#loginForm").attr("action" , segment)
    # .submit()だとIEのオートコンプリート機能が効かない
    # そのためsubmitボタンを擬似的にクリックしてsubmitさせる
    $("#dummySubmitBtn").click()
