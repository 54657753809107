var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"mobileHeader\">\r\n      <button type=\"button\" class=\"navBtn\" style=\"overflow: hidden; display: block;\">\r\n            <div class=\"navLabel\">MENU</div>\r\n      </button>\r\n</div>\r\n\r\n<div class=\"lsidebar indent_menu\">\r\n      <div class=\"headerLogo\"><span class=\"logo_menu\"></span></div>\r\n      <div class=\"menu_list_area\"></div>\r\n</div>\r\n\r\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}