Collection = require 'models/collection'

dataList   = require 'data/data_list'

###
 [車両情報共通] 車両情報コレクション
###
module.exports = class CarDataCollection extends Collection

  socket: undefined

  initialize: ->
    _.bindAll @
    @.reset dataList
    # ソケットをセットする　
    @socket = Backbone.AUCNET.socket

  # マイリスト取得
  getMylistCarDataList: (model) ->
    # コールバック登録
    @socket.on 'GetMylistCarDataList', (d) =>
      # コールバック解除
      @socket.removeAllListeners 'GetMylistCarDataList'
      # 結果格納
      data = JSON.parse(d)
      @reset data.carDataList
      @count = data.carDataCnt
      # 通知
      @trigger 'GetMylistCarDataList'

    # G/Wに送信
    @socket.emit 'GetMylistCarDataList',
      socketid   : Backbone.AUCNET.loginUser.get('_socketid')
      searchcond : model.searchcond
      sortcond   : model.sortcond
      mylistid   : model.mylistid
      limit      : model.limit
      offset     : model.offset

  # リクエスト商品検索
  getRequestCarDataList: (model) ->
    # コールバック登録
    @socket.on 'GetRequestCarDataList', (d) =>
      # コールバック解除
      @socket.removeAllListeners 'GetRequestCarDataList'
      # 結果格納
      data = JSON.parse(d)
      @reset data.carDataList
      @count = data.carDataCnt
      # 通知
      @trigger 'GetRequestCarDataList'

    # G/Wに送信
    @socket.emit 'GetRequestCarDataList',
      socketid   : Backbone.AUCNET.loginUser.get('_socketid')
      searchcond : model.searchcond
      sortcond   : model.sortcond
      requestid  : model.requestid
      limit      : model.limit
      offset     : model.offset

  # 車両検索
  getSearchCarDataList: (model) ->
    # コールバック登録
    @socket.on 'GetSearchCarDataList', (d) =>
      # コールバック解除
      @socket.removeAllListeners 'GetSearchCarDataList'
      # 結果格納
      data = JSON.parse(d)
      @reset data.carDataList
      @count = data.carDataCnt
      # 通知
      @trigger 'GetSearchCarDataList'

    # G/Wに送信
    @socket.emit 'GetSearchCarDataList',
      socketid   : Backbone.AUCNET.loginUser.get('_socketid')
      searchcond : model.searchcond
      sortcond   : model.sortcond
      limit      : model.limit
      offset     : model.offset


  # 商談候補検索
  getCandidateCarDataList: (model) ->
    # コールバック登録
    @socket.on 'GetCandidateCarDataList', (d) =>
      # コールバック解除
      @socket.removeAllListeners 'GetCandidateCarDataList'
      # 結果格納
      data = JSON.parse(d)
      @reset data.carDataList
      @count = data.carDataCnt
      # 通知
      @trigger 'GetCandidateCarDataList'

    # G/Wに送信
    @socket.emit 'GetCandidateCarDataList',
      socketid   : Backbone.AUCNET.loginUser.get('_socketid')
      searchcond : model.searchcond
      sortcond   : model.sortcond
      limit      : model.limit
      offset     : model.offset

  # チェックボックス追加フラグ設定 (for商談候補一覧)
  addCheckFlag: ->
    @checkFlag = true
