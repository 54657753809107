Model        = require 'models/model'

###
　車両詳細モデル
###
module.exports = class CarDetailModel extends Model

  socket: undefined

  #持つべきデータをここに書く。
  defaults: undefined

  initialize: ->
    _.bindAll @
    @socket = Backbone.AUCNET.socket

  emitCarDetail: ->
    #ソケット到着時の処理
    @socket.on 'CarDetail', (d) =>
      #ソケット破棄
      @socket.removeAllListeners 'CarDetail'
      @set JSON.parse(d)
      #データ要求

    @socket.emit 'CarDetail',
      invNo   : @get('invNo')
      invYear : @get('invYear')