Model = require 'models/model'

###
 [車両検索] 検索モデル
###
module.exports = class SearchModel extends Model
  socket : undefined

  # 初期化
  initialize: ->
    _.bindAll @

  # ソケット設定
  setSocket: (socket) ->
    @socket = socket

  # 条件取得(共通)
  getConditions: (eventName, itemName, json) =>
    # コールバック設定
    @socket.on eventName, (d) =>
      # コールバック解除
      @socket.removeAllListeners eventName
      # データ設定
      @set itemName, JSON.parse(d)
      # View側へ通知
      @trigger eventName
    # ソケット書込
    @socket.emit eventName, json

  # メーカー取得
  getMaker: ->
    @getConditions 'GetMaker', 'Maker'

  # 車名取得
  getCarName: ->
    @getConditions 'GetCarName', 'CarName',
      conditions :
        makerlist : @selectedMakerList

  # 型式取得
  getStyle: ->
    @getConditions 'GetStyle', 'Style',
      conditions :
        carnamelist : @selectedCarNameList

  # グレード取得
  getGrade: (param) ->
    @getConditions 'GetGrade', 'Grade',
      conditions :
        carnamelist : @selectedCarNameList
        stylelist   : if _.isEmpty(@selectedStyleList) then null else @selectedStyleList

  # カラー取得
  getColor: (param) ->
    @getConditions 'GetColor', 'Color',
      conditions :
        carnamelist : @selectedCarNameList
        stylelist   : if _.isEmpty(@selectedStyleList) then null else @selectedStyleList
        gradelist   : if _.isEmpty(@selectedGradeList) then null else @selectedGradeList

  # 件数取得
  getSearchCount: (model) ->
    # コールバック登録
    @socket.on 'GetSearchCount', (d) =>
      # コールバック解除
      @socket.removeAllListeners 'GetSearchCount'
      # 通知
      @trigger 'GetSearchCount', JSON.parse(d).count

    # G/Wに送信
    @socket.emit 'GetSearchCount',
      socketid   : Backbone.AUCNET.loginUser.get('_socketid')
      searchcond : model.searchcond
  # 選択メーカーリスト設定
  setSelectedMakerList: (json) ->
    @selectedMakerList = json

  # 選択車名リスト設定
  setSelectedCarNameList: (json) ->
    @selectedCarNameList = json

  # 選択型式リスト設定
  setSelectedStyleList: (json) ->
    @selectedStyleList = json

  # 選択グレードリスト設定
  setSelectedGradeList: (json) ->
    @selectedGradeList = json

  # 選択カラーリスト設定
  setSelectedColorList: (json) ->
    @selectedColorList = json

  # 年式設定
  setModelYear: (json) ->
    @selectedModelYear = json

  # シフト設定
  setMission: (json) ->
    @selectedMission = json

  # 走行距離設定
  setMileage: (json) ->
    @selectedMileage = json

  # 車検設定
  setInspect: (json) ->
    @selectedInspect = json

  # 評価設定
  setEstimation: (json) ->
    @selectedEstimation = json

  # 問合番号設定
  setInvNo: (json) ->
    @selectedInvNo = json

  # 検索条件取得
  getSearchConditions: ->
    # 返却値設定
    conditions =
      searchcond :
        modelYear  : if @selectedModelYear?   then @selectedModelYear   else null
        carName    : @convertCarNameList()
        grade      : if @selectedGradeList?   then @selectedGradeList   else null
        certify    : if @selectedStyleList?   then @selectedStyleList   else null
        mission    : if @selectedMission?     then @selectedMission     else null
        mileage    : if @selectedMileage?     then @selectedMileage     else null
        inspect    : if @selectedInspect?     then @selectedInspect     else null
        color1     : if @selectedColorList?   then @selectedColorList   else null
        estimation : if @selectedEstimation?  then @selectedEstimation  else null
        invno      : if @selectedInvNo?       then @selectedInvNo       else null
    return conditions

  # 車名リスト編集
  convertCarNameList: ->
    ret = null
    if @selectedCarNameList?
      retArray = []
      # 選択車名リストから車名グループ取得
      for item in @selectedCarNameList
        groupArray = item.split('|')
        # 車名グループを車名に分割
        for carname in groupArray
          retArray.push carname
      ret = retArray
    return ret
