###
  商談一覧表示
  商談コレクションを表示する
###
View     = require 'views/view'
Template = require 'views/templates/negolist_list'
CarDetailBaseView  = require 'views/car_detail_base_view' #車両詳細画面


module.exports = class NegolistListView extends View
  className  : 'negolist_list negoSell'
  template   : Template
  collection : {}

  events:
    'click .box label.showdetail' : 'onClickImage'

  # ToDo:タイムリミット(HH:MM)表示と、タイムバーの描画

  # -------------------------
  # イニシャライズ
  # -------------------------
  initialize: ->
    _.bindAll @
    @listenTo @collection, 'reset'  , @render
    @collection.getNegolist()

  beforeRender: ->


  # -------------------------
  # レンダリング後処理
  # -------------------------
  afterRender: ->
    @changeDisplayMode(@mode)
    @trigger 'checkNew'

    # タイマーレンダリング
    #clearInterval @timerArray.shift()  if @timerArray.length > 0
    #@collection.each (model) =>
      #return if model.get('remain_time') < 0
      # @timer 第一引数：タイマーエレメント　第二引数：カウントダウン秒数
      #console.log "本当にこのモデルは一点の？"
      #work_param = model.get('list')
      #modelparam = work_param[0]
      #console.log $(".count_down_timer_"+modelparam.id)
      #console.log modelparam.carname_full
      #console.log model[0].remain_time
      #console.log model[0].timer_lastupdate_date
      #console.log model[0].first_timelimit_date_to_ymd
      #@timer $(".count_down_timer_"+modelparam.id), modelparam.remain_time, modelparam.timer_lastupdate_date,  modelparam.first_timelimit_date_to_ymd
      #dataLineView = new DataLineView(model: model)
      #@$el.find(".data_line-1").append(dataLineView.render().el)

  # -------------------------
  # レンダリングデータ設定
  # -------------------------
  getRenderData: ->
    # list: @collection.toJSON()

  # -------------------------
  # 商談一覧取得をサーバにキック
  # -------------------------
  getSearchNegolist:(conditions) ->
    @collection.getNegolist(conditions)

  # -------------------------
  # ブロックリスト表示切替
  # -------------------------
  changeDisplayMode:(mode) =>
    @mode = mode
    switch mode
      when "b" then @$el.find("ul.displayList").addClass("listType1").removeClass("dmList"   )
      when "l" then @$el.find("ul.displayList").addClass("dmList"   ).removeClass("listType1")
      else          @$el.find("ul.displayList").addClass("listType1").removeClass("dmList"   )

  # ----------------------------------------------------------------------------------------------
  # タイマー描画　elem：タイマーエレメント
  # 引数：
  # endTime：残り時間の秒数　startTime：タイマーが動き出した日時 フォーマット’1999, 11, 31, 23, 59, 59’
  # ----------------------------------------------------------------------------------------------
  timer : (elem, endTime, startTimeDate, endTimeDate) ->
    return  if $(elem).length is 0
    if endTime < 0 or endTime is null then return false

                 # 終了時間を求めます　　　　　　　　　　　　　　　　　　　開始時間を求めます
    basic_time = (new Date(endTimeDate).getTime()) - (new Date(startTimeDate).getTime())
    test_param = new Date(startTimeDate).getTime()

    deg = 130
    sd = new Date(startTimeDate).getTime()
    ed = new Date().getTime() + ((endTime * 1000) + 1000)
    nd_tmp = new Date().getTime()
    degrot = (260 / ((basic_time / 1000) * 100))
    degtot = 0

    course_param = ((nd_tmp - sd) + 1000)

    startTime = setInterval(->
      nd = new Date().getTime()
      course_param2 = (nd - course_param)
      diff = Math.floor((ed - nd) / 1000)
      difft = (ed - nd)
      difft2 = (ed - ((sd + ((new Date().getTime()) - nd_tmp)) + course_param))
      degtot++
      hh = 0
      mm = 0
      ss = 0
      hh = Math.floor(diff / 3600)  if Math.floor((diff / 3600)) > 0
      mm = Math.floor((diff - (hh * 3600)) / 60)  if Math.floor((diff - (hh * 3600)) / 60) > 0
      ss = Math.floor(diff - (mm * 60) - (hh * 3600))  if Math.floor(diff - (mm * 60) - (hh * 3600)) > 0
      hh = (if (hh > 9) then hh else "0" + hh)
      mm = (if (mm > 9) then mm else "0" + mm)
      ss = (if (ss > 9) then ss else "0" + ss)
      deg = 130 - (260 - (degrot * Math.floor((difft2) / 10)))

      # 残り時間が30分以内だったら「わずか」を表示
      if diff < Backbone.AUCNET.setting.remain_time
        dispTime = Backbone.AUCNET.label.get "lb_negolist_remain_time_little"
        elem.find(".remain_time_little").html "#{dispTime}"

      elem.find(".limitTime"). html "#{hh}:#{mm}:#{ss}"
      if deg < 0 and deg >= -130
        elem.find(".timerInner2").show 0
        elem.find(".timer").addClass "whole"
      else clearInterval startTime  if deg <= -130 or ed < nd
      elem.find(".timerInner1").css("transform", "rotate(" + deg + "deg)").css "-webkit-transform", "rotate(" + deg + "deg)"

      #sec
      degs = 130 - (4.4 * (60 - (Math.floor(difft / 1000) % 60)))

      if degs < 0 and degs >= -130
        elem.find(".timerSec2").show 0
        elem.find(".timerSecInner").addClass "whole"
      else if degs <= -130
        if difft > 1000
          degs = 130
          elem.find(".timerSecInner").removeClass "whole"
          elem.find(".timerSec2").hide 0

      elem.find(".timerSec1").css("transform", "rotate(" + degs + "deg)").css "-webkit-transform", "rotate(" + degs + "deg)"
    # 10000ミリ秒間隔で呼び出す
    , 1000)
    Backbone.AUCNET.timerArray_list[Backbone.AUCNET.timerArray.length] = startTime

  # -------------------------
  # 車両画像クリック処理
  # -------------------------
  onClickImage: (event) ->
    $currentTarget = @$(event.currentTarget)
    inventoryYear  = $.trim("" + $currentTarget.data("inventory_year"))
    inventoryNo    = $.trim("" + $currentTarget.data("inventory_no")  )
    Backbone.AUCNET.RenderController.renderCarDetail(inventoryYear,inventoryNo,true)

