var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<div class=\"UserMenu\">\r\n  <div class=\"UserId\">"
    + container.escapeExpression(((helper = (helper = helpers.UserId || (depth0 != null ? depth0.UserId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"UserId","hash":{},"data":data}) : helper)))
    + "</div>\r\n    <label><a id=\"btnLogout\" data-label=\"lb_logout\" class=\"logout_button_class\"></a></label>\r\n    <label><a data-label=\"lb_config\" class=\"configBtn settings_button_class\" data-menucd=\"config\"></a></label>\r\n\r\n</div>\r\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}