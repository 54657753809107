var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <img src=\"../images/noPhoto_s.jpg\">\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        "
    + ((stack1 = (helpers.outputImageForList || (depth0 && depth0.outputImageForList) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.image : depth0),{"name":"outputImageForList","hash":{},"data":data})) != null ? stack1 : "")
    + "\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " <span class=\"fine\"  style=\"float:right;\"></span>";
},"7":function(container,depth0,helpers,partials,data) {
    return "<span class=\"sfine\" style=\"float:right;\"></span>";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return ((stack1 = ((helper = (helper = helpers.model || (depth0 != null ? depth0.model : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"model","hash":{},"data":data}) : helper))) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return " $";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<li class=\"item line\">\r\n  <div class=\"box\">\r\n    "
    + ((stack1 = (helpers.outputCarStatusIcon || (depth0 && depth0.outputCarStatusIcon) || alias2).call(alias1,(depth0 != null ? depth0.status : depth0),{"name":"outputCarStatusIcon","hash":{},"data":data})) != null ? stack1 : "")
    + "\r\n    <div class=\"paddedBox\">\r\n      <div class=\"car_thumbnail\" id=\""
    + alias4(((helper = (helper = helpers.inventory_year || (depth0 != null ? depth0.inventory_year : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inventory_year","hash":{},"data":data}) : helper)))
    + "|"
    + alias4(((helper = (helper = helpers.inventory_no || (depth0 != null ? depth0.inventory_no : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inventory_no","hash":{},"data":data}) : helper)))
    + "\">\r\n"
    + ((stack1 = (helpers.equal || (depth0 && depth0.equal) || alias2).call(alias1,(depth0 != null ? depth0.image : depth0),"",{"name":"equal","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "      </div>\r\n      <div class=\"details\">\r\n        <div class=\"carDetails\">\r\n          "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isFine : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <!-- ファインのみ表示 -->\r\n          "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isSFine : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <!-- ファインのみ表示 -->\r\n        </div>\r\n        <div class='inv_no_area'>\r\n          <span class=\"grey\" data-label='lb_data_line_list_header_3'>問合番号</span>\r\n          <span class=\"black\">"
    + ((stack1 = ((helper = (helper = helpers.inventory_no || (depth0 != null ? depth0.inventory_no : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inventory_no","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\r\n        </div>\r\n        <div class=\"WatchListBtn_area\"></div>\r\n        <div class=\"salesAmmount\">\r\n          <span data-label='lb_nego_owner_hope_price'></span>\r\n          "
    + ((stack1 = (helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || alias2).call(alias1,(depth0 != null ? depth0.price : depth0),{"name":"numberWithYenComma","hash":{},"data":data})) != null ? stack1 : "")
    + "\r\n          <br>\r\n          <span class=\"grey\" data-label='lb_data_line_list_header_9'>型式</span>\r\n          <span class=\"black\">"
    + ((stack1 = ((helper = (helper = helpers.style || (depth0 != null ? depth0.style : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"style","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\r\n          <span class=\"grey\" data-label='lb_data_line_list_header_4'> 地区 </span>\r\n          <span class=\"black\">"
    + ((stack1 = ((helper = (helper = helpers.regionName || (depth0 != null ? depth0.regionName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"regionName","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\r\n        </div>\r\n        <div class=\"carName_area\">\r\n          <span class=\"carName\">"
    + ((stack1 = ((helper = (helper = helpers.carName || (depth0 != null ? depth0.carName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"carName","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\r\n          <span class=\"gradeName\">"
    + ((stack1 = ((helper = (helper = helpers.gradeName || (depth0 != null ? depth0.gradeName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"gradeName","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\r\n        </div>\r\n        <div class=\"carSpec nego\">\r\n          <ul>\r\n            <li>\r\n              <div data-label='lb_data_line_list_header_5'>登録年</div>\r\n              <div data-label='lb_data_line_list_header_6'>モデル</div>\r\n              <div data-label='lb_data_line_list_header_13'>走行距離</div>\r\n              <div data-label='lb_data_line_list_header_14'>車検</div>\r\n              <div data-label='lb_data_line_list_header_15'>色</div>\r\n              <div data-label='lb_data_line_list_header_16'>色No</div>\r\n              <div data-label='lb_data_line_list_header_12'>シフト</div>\r\n              <div data-label='lb_data_line_list_header_24'>駆動方式</div>\r\n              <div data-label='lb_data_line_list_header_10'>排気量</div>\r\n              <div data-label='lb_data_line_list_header_11'>ガソリン</div>\r\n              <div data-label='lb_data_line_list_header_17'>書類</div>\r\n              <div data-label='lb_data_line_list_header_18'>装備</div>\r\n              <div data-label='lb_data_line_list_header_19'>評価点</div>\r\n              <div data-label='lb_data_line_list_header_20'>外装</div>\r\n              <div data-label='lb_data_line_list_header_21'>内装</div>\r\n            </li>\r\n            <li>\r\n              <div>"
    + ((stack1 = ((helper = (helper = helpers.modelYear || (depth0 != null ? depth0.modelYear : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modelYear","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\r\n\r\n              <div>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isImportedCar : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\r\n              <div>"
    + ((stack1 = ((helper = (helper = helpers.mileage || (depth0 != null ? depth0.mileage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mileage","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + ((stack1 = ((helper = (helper = helpers.no_mileage || (depth0 != null ? depth0.no_mileage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"no_mileage","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMeterChangeFlg : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\r\n              <div>"
    + ((stack1 = ((helper = (helper = helpers.inspect || (depth0 != null ? depth0.inspect : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inspect","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\r\n              <div>"
    + ((stack1 = (helpers.getColorLabel || (depth0 && depth0.getColorLabel) || alias2).call(alias1,(depth0 != null ? depth0.outcolor : depth0),{"name":"getColorLabel","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\r\n              <div>"
    + ((stack1 = ((helper = (helper = helpers.outcolor_no || (depth0 != null ? depth0.outcolor_no : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"outcolor_no","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\r\n              <div>"
    + ((stack1 = ((helper = (helper = helpers.shift || (depth0 != null ? depth0.shift : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shift","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\r\n              <div>"
    + ((stack1 = ((helper = (helper = helpers.drive_cd || (depth0 != null ? depth0.drive_cd : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"drive_cd","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\r\n              <div>"
    + ((stack1 = ((helper = (helper = helpers.exhaust || (depth0 != null ? depth0.exhaust : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"exhaust","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\r\n              <div>"
    + ((stack1 = ((helper = (helper = helpers.fuelCd || (depth0 != null ? depth0.fuelCd : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fuelCd","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\r\n              <div>\r\n                <span style=\"float:left;\" class=\"mini_document_flag_"
    + alias4(((helper = (helper = helpers.one_owner || (depth0 != null ? depth0.one_owner : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"one_owner","hash":{},"data":data}) : helper)))
    + "\"></span>\r\n                <span style=\"float:left;\" class=\"mini_document_flag_"
    + alias4(((helper = (helper = helpers.gurantee || (depth0 != null ? depth0.gurantee : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"gurantee","hash":{},"data":data}) : helper)))
    + "\"></span>\r\n                <span style=\"float:left;\" class=\"mini_document_flag_"
    + alias4(((helper = (helper = helpers.manual || (depth0 != null ? depth0.manual : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"manual","hash":{},"data":data}) : helper)))
    + "\"></span>\r\n              </div>\r\n              <div>\r\n                <span style=\"float:left;\" class=\"notBlock mini_equipment_flag_navi"
    + alias4(((helper = (helper = helpers.navi || (depth0 != null ? depth0.navi : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"navi","hash":{},"data":data}) : helper)))
    + "\"></span>\r\n                <span style=\"float:left;\" class=\"notBlock mini_equipment_flag_sunroof"
    + alias4(((helper = (helper = helpers.sunroof || (depth0 != null ? depth0.sunroof : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sunroof","hash":{},"data":data}) : helper)))
    + "\"></span>\r\n                <span style=\"float:left;\" class=\"notBlock mini_equipment_flag_leather_seat"
    + alias4(((helper = (helper = helpers.leather_seat || (depth0 != null ? depth0.leather_seat : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"leather_seat","hash":{},"data":data}) : helper)))
    + "\"></span>\r\n              </div>\r\n              <div>"
    + ((stack1 = (helpers.editEstimationDisplay || (depth0 && depth0.editEstimationDisplay) || alias2).call(alias1,(depth0 != null ? depth0.estimation : depth0),(depth0 != null ? depth0.estimation_flag : depth0),{"name":"editEstimationDisplay","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\r\n              <div>"
    + ((stack1 = (helpers.editEstimationDisplay || (depth0 && depth0.editEstimationDisplay) || alias2).call(alias1,(depth0 != null ? depth0.outward_value : depth0),(depth0 != null ? depth0.estimation_flag : depth0),{"name":"editEstimationDisplay","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\r\n              <div>"
    + ((stack1 = (helpers.editEstimationDisplay || (depth0 && depth0.editEstimationDisplay) || alias2).call(alias1,(depth0 != null ? depth0.upholstery_value : depth0),(depth0 != null ? depth0.estimation_flag : depth0),{"name":"editEstimationDisplay","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\r\n            </li>\r\n          </ul>\r\n        </div>\r\n        <div class=\"clear\"></div>\r\n      </div>\r\n    </div>\r\n    <div class=\"clear\"></div>\r\n  </div>\r\n</li>\r\n\r\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}