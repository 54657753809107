View        = require 'views/view'
Template    = require 'views/templates/candidate_footer'

###
 [商談候補] 商談候補フッタビュー
###
module.exports = class CandidateFooterView extends View
  id          : 'candidate_footer'
  template    : Template

  # 複数商談可否
  isMultiNego : false

  # 初期化
  initialize: ->
    _.bindAll @
    # 複数商談設定
    if Backbone.AUCNET.DEFINE.get('PARA_NEGO_COUNT') > 1
      @isMultiNego = true

  # レンダリングデータ取得
  getRenderData: ->
    isMultiNego : @isMultiNego

  # レンダリング後処理
  afterRender: ->
    # イベント設定
    @delegateEvents
      'click .btnRemoveAll'    : 'removeAllWatchItem'
      'click .btnApplyNego'    : 'applyNego'
      'click .btnChkAllSelect' : 'selectAllCheck'
      'click .btnChkAllClear'  : 'clearAllCheck'

  # 商談候補クリア
  removeAllWatchItem: ->
    @trigger 'RemoveAllWatchItem'

  # 商談申込
  applyNego: ->
    @trigger 'ApplyNego'

  # 全選択
  selectAllCheck: ->
    @trigger 'SelectAllCheck'

  # 全選択解除
  clearAllCheck: ->
    @trigger 'ClearAllCheck'
