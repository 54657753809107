###
  【商談】商談情報表示
###
# ページングなしの商談一覧表示
NegolistList       = require 'views/negolist_list_view'
#車両詳細画面
CarDetailBaseView  = require 'views/car_detail_base_view'
# 商談一覧コレクション
NegoCollection     = require 'models/nego_collection'
# 複数商談用テンプレート
Template           = require 'views/templates/nego_info'

util               = require 'lib/util'

UtilConfirmView = require 'views/util_confirm_view'

module.exports = class NegoInfoView extends NegolistList
  className  : 'nego_info'
  collection : null
  template   : Template
  #events :
    # 車両詳細表示
    # "click div.showdetail" : "showCarDetail"
  #timerArray : new Array()

  # -------------------------
  # イニシャライズ
  # -------------------------
  initialize: ->
    _.bindAll @
    # 商談一覧のコレクションを使用
    @collection = new NegoCollection [],
      eventName : "GetNegolistPlain"

    # アイテムプッシュの準備
    @listenTo @collection    , 'reset'      , @render
    @listenTo @collection    , 'removeNego' , @removeNego
    @listenTo @collection    , 'updateNego' , @updateNego

    # 商談データ取得
    @collection.getNegolist {
      inventoryyear : @options.inventoryYear
      inventoryno   : @options.inventoryNo
      negoid        : @options.negoID
    }

  # -------------------------
  # レンダリングデータの設定
  # -------------------------
  getRenderData: ->
    # 複数商談の時、車両情報が複数あるので表示したいものだけに絞る
    @collection.each (model, index) =>
      list = model.get "list"
      # inventory_yearとinventory_noが今開いている商談と同じものだけ返す
      editList = (item for item in list when item.inventory_year is @options.inventoryYear and item.inventory_no is @options.inventoryNo)
      # 売り買い区分設定
      # editList[0].buy_sell_kbn = @editBuySellKbn(editList[0])
      editList[0].buy_sell_kbn = util.editBuySellKbn(editList[0])

      model.set "list", editList
    return {
      list : @collection.toJSON()
    }

  # -------------------------
  # レンダリング後の処理
  # -------------------------
  afterRender: ->
    # 「商談」ボタン非表示
    @$el.find("a[data-label=lb_nego]").hide()

    # タイマーレンダリング
    # 初期化
    if Backbone.AUCNET.timerArray.length > 0
      for timer_item in Backbone.AUCNET.timerArray
        clearInterval timer_item
      Backbone.AUCNET.timerArray = new Array()
    # 複数商談の時、車両情報が複数あるので表示したいものだけに絞る
    DEFINE = Backbone.AUCNET.DEFINE
    @collection.each (model, index) =>
      item_list = model.get "list"
      if item_list?[index]?
        endTimeDate = item_list[index].timelimit_date_to_ymd

        #if !(item_list[index].first_timelimit_date_to_ymd is item_list[index].timelimit_date_to_ymd)
        #  endTimeDate = item_list[index].timelimit_date_to_ymd
        @timer $(".count_down_timer_info_" + item_list[index].id), item_list[index].remain_time, item_list[index].timer_lastupdate_date, endTimeDate
        if item_list[0].nego_status is DEFINE.get("NEGO_STATUS_REQUEST") or item_list[0].nego_status is DEFINE.get("NEGO_STATUS_ONGOING")
          @$el.find("div.ongoing_only").show()
          @$el.find("div.past_only"   ).hide()
        else
          @$el.find("div.ongoing_only").hide()
          @$el.find("div.past_only"   ).show()



    # super()

  # ----------------------------------
  # 売り買い区分を取得
  # ----------------------------------
  # editBuySellKbn : (item) =>
  #   DEFINE = Backbone.AUCNET.DEFINE
  #   ret = DEFINE.get "USER_ROLE_KBN_OTHER"                                                    # 他社商談
  #   if item.hash_owner_member_cd is Backbone.AUCNET.hashUserID then ret = DEFINE.get "USER_ROLE_KBN_SELLER" # 自社の売り商談
  #   if item.hash_buyer_member_cd is Backbone.AUCNET.hashUserID then ret = DEFINE.get "USER_ROLE_KBN_BUYER"  # 自社の買い商談
  #   return ret

  # -------------------------
  # 車両詳細表示
  # -------------------------
  showCarDetail:(event) =>
    $currentTarget = @$(event.currentTarget)
    inventoryYear  = $.trim("" + $currentTarget.data("inventory_year"))
    inventoryNo    = $.trim("" + $currentTarget.data("inventory_no")  )

    ###
      NEGO_STATUS_CONTRACT : '3' # 成約
      NEGO_STATUS_FLOW     : '4' # 流れ
      NEGO_STATUS_CANCEL   : '5' # キャンセル
    ###
    if @options.model.get('nego_status') in ['3', '4', '5']
      Backbone.AUCNET.RenderController.renderCarDetail(inventoryYear,inventoryNo,false)
    else if Backbone.AUCNET.hashUserID isnt @options.model.get('hash_owner_member_cd') and Backbone.AUCNET.hashUserID isnt @options.model.get 'hash_buyer_member_cd'
      Backbone.AUCNET.RenderController.renderCarDetail(inventoryYear,inventoryNo,false)
    else
      confirmView = new UtilConfirmView
          title    : Backbone.AUCNET.message.get 'ms_nego_msg13_title'
          message  : Backbone.AUCNET.message.get 'ms_nego_msg13_message'
          # =>(ファットアロー)で渡すとcontextがここになる
          callback : (ok)=>
            if ok
              Backbone.AUCNET.RenderController.renderCarDetail(inventoryYear,inventoryNo,false)
      @$el.append(confirmView.render().el)


  # -------------------------
  # 新着アイテムを取得し、新着順にソート
  # -------------------------
  getNewItems: ->
    @itemcount = 0
    @trigger "GetNewItems"

  # -------------------------
  # サーバからのプッシュ通知・画面上のアイテム削除
  # -------------------------
  # 実際に画面上から削除するのではなく、白く網かけする
  removeNego:(id) ->
    @$el.find("li.negolist_item_#{id}").addClass("removed")

  # -------------------------
  # サーバからのプッシュ通知・アイテム変更
  # -------------------------
  updateNego:(id) ->
    @trigger 'UpdateNego'
    @render()


  # ----------------------------------------------------------------------------------------------
  # タイマー描画　elem：タイマーエレメント
  # 引数：
  # endTime：残り時間の秒数　startTime：タイマーが動き出した日時 フォーマット’1999, 11, 31, 23, 59, 59’
  # ----------------------------------------------------------------------------------------------
  timer : (elem, endTime, startTimeDate, endTimeDate) ->
    return  if $(elem).length is 0
    if endTime < 0 then return false

                 # 終了時間を求めます　　　　　　　　　　　　　　　　　　　開始時間を求めます
    basic_time = (new Date(endTimeDate).getTime()) - (new Date(startTimeDate).getTime())
    test_param = new Date(startTimeDate).getTime()

    deg = 130
    sd = new Date(startTimeDate).getTime()
    ed = new Date().getTime() + ((endTime * 1000) + 1000)
    nd_tmp = new Date().getTime()
    degrot = (260 / ((basic_time / 1000) * 100))
    degtot = 0

    course_param = ((nd_tmp - sd) + 1000)

    startTime = setInterval(->
      nd = new Date().getTime()
      course_param2 = (nd - course_param)
      diff = Math.floor((ed - nd) / 1000)
      difft = (ed - nd)
      difft2 = (ed - ((sd + ((new Date().getTime()) - nd_tmp)) + course_param))
      degtot++
      hh = 0
      mm = 0
      ss = 0
      hh = Math.floor(diff / 3600)  if Math.floor((diff / 3600)) > 0
      mm = Math.floor((diff - (hh * 3600)) / 60)  if Math.floor((diff - (hh * 3600)) / 60) > 0
      ss = Math.floor(diff - (mm * 60) - (hh * 3600))  if Math.floor(diff - (mm * 60) - (hh * 3600)) > 0
      hh = (if (hh > 9) then hh else "0" + hh)
      mm = (if (mm > 9) then mm else "0" + mm)
      ss = (if (ss > 9) then ss else "0" + ss)
      deg = 130 - (260 - (degrot * Math.floor((difft2) / 10)))

      # 残り時間が30分以内だったら「わずか」を表示
      if diff < Backbone.AUCNET.setting.remain_time
        dispTime = Backbone.AUCNET.label.get "lb_negolist_remain_time_little"
        elem.find(".remain_time_little").html "#{dispTime}"

      elem.find(".limitTime"). html "#{hh}:#{mm}:#{ss}"
      if deg < 0 and deg >= -130
        elem.find(".timerInner2").show 0
        elem.find(".timer").addClass "whole"
      else clearInterval startTime  if deg <= -130 or ed < nd
      elem.find(".timerInner1").css("transform", "rotate(" + deg + "deg)").css "-webkit-transform", "rotate(" + deg + "deg)"

      #sec
      degs = 130 - (4.4 * (60 - (Math.floor(difft / 1000) % 60)))

      if degs < 0 and degs >= -130
        elem.find(".timerSec2").show 0
        elem.find(".timerSecInner").addClass "whole"
      else if degs <= -130
        if difft > 1000
          degs = 130
          elem.find(".timerSecInner").removeClass "whole"
          elem.find(".timerSec2").hide 0

      elem.find(".timerSec1").css("transform", "rotate(" + degs + "deg)").css "-webkit-transform", "rotate(" + degs + "deg)"
    # 10000ミリ秒間隔で呼び出す
    , 1000)
    Backbone.AUCNET.timerArray[Backbone.AUCNET.timerArray.length] = startTime
