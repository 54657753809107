Model = require 'models/model'

module.exports = class Connect extends Model

  socket: undefined

  initialize: ->
    _.bindAll @
    @socket = Backbone.AUCNET.socket

    # 接続状態取得
    @socket.on 'ping', (data) =>
      data.receivetime = Date.now()
      @trigger 'ping', data

  ping: (timeoutid) ->
    @socket.emit 'ping',
      timeoutid: timeoutid
      sendtime: Date.now()
