application = require 'application'
View        = require 'views/view'
#template    = require 'views/templates/util_page_base'
template    = require 'views/templates/nego_page'

NegoReceiveView = require 'views/nego_receive_view'
NegoSendView    = require 'views/nego_send_view'
NegoInfoView    = require 'views/nego_info_view'

NegoJoinModel     = require 'models/nego_join_model'
NegoLogCollection     = require 'models/nego_log_collection'
NegoMessageCollection = require 'models/nego_message_collection'
UtilConfirmView = require 'views/util_confirm_view'
util            = require 'lib/util'

###
 商談画面
###
module.exports = class NegoPageView extends View
  id        : 'nego_page'
  template  : template

  events :
    "click   button.btnSend"                : "sendNegoMessage"     # 送信実行ボタン
    "click   button.btnCancel"              : "cancelSendMessage"   # 送信キャンセルボタン
    "click   #nego_page_exit"               : "negoPageExit"   # 送信キャンセルボタン
    "blur    #nego_input_ten_price"         : "checkEn"
    "blur    #nego_input_price"             : "checkEn"
    #'touchstart .prevCond' : 'hoverStartMobile'
    #'touchend   .prevCond' : 'hoverEndMobile'
    'mouseover  .prevCond' : 'hoverStartMobile'
    'mouseout   .prevCond' : 'hoverEndMobile'
  # 商談部屋モデルに渡す商談ID
  negoID : null
  inventoryYear : null
  inventoryNo   : null

  initialize: ->
    _.bindAll @

    #$(window).resize ->
    #  wHeight = $(window).height()
    #  $("#main_container .cont").css "height", wHeight + "px"


  # 商談のIDを指定（router.coffeeでurl変更時にセットされる）
  setNegoID: (id, inventoryYear, inventoryNo,@exitFlag) =>
    # 商談IDのチェック
    application.router.navigate 'negolist' if _.isNaN Number id
    application.router.navigate 'negolist' if _.isNaN Number inventoryYear
    application.router.navigate 'negolist' if _.isNaN Number inventoryNo
    # セット
    @negoID        = id
    @inventoryYear = inventoryYear
    @inventoryNo   = inventoryNo

  getRenderData: ->
    return exitFlag   : @exitFlag
  # このビューがrender()された後に実行される
  # View#renderを参照のこと
  afterRender: ->
    window.scroll( 0, 0 );
    #前回使用分のコレクション、ビューをクリア
    @suicide()

    @delegateEvents()
    return unless @negoID?

    # コンテンツの組み立て
    # 商談メッセージログデータ
    @negoLog           = new NegoLogCollection
    # 閲覧中商談データ
    @negoJoin          = new NegoJoinModel

    @negoJoin.set 'currentNegoID'        : @negoID        , {silent:true}
    @negoJoin.set 'currentInventoryYear' : @inventoryYear , {silent:true}
    @negoJoin.set 'currentInventoryNo'   : @inventoryNo   , {silent:true}

    # メッセージマスタ
    @negoMessageMaster = new NegoMessageCollection

    # 商談情報ビュー
    @negoInfoView = new NegoInfoView
        negoID        : @negoID
        inventoryYear : @inventoryYear
        inventoryNo   : @inventoryNo
        model         : @negoJoin #車両詳細出力時の確認メッセージﾛｼﾞｯｸでのみ使用
    # 商談送信部ビュー
    @negoSendView = new NegoSendView
      model      : @negoJoin
      collection :
        messages : @negoMessageMaster
        log      : @negoLog
    # 商談受信部ビュー
    @negoReceiveView = new NegoReceiveView
      model      : @negoJoin
      collection :
        messages : @negoMessageMaster
        log      : @negoLog
    @listenTo @negoReceiveView , 'alert_api' , @_alert_api

    # 入室願いemit
    #@negoJoinReset()
    @negoJoin.joinNegoRoom @negoID, @inventoryYear, @inventoryNo
    # メッセージログコレクションに商談IDを設定
    @negoLog.negoID        = @negoID
    @negoLog.inventoryYear = @inventoryYear
    @negoLog.inventoryNo   = @inventoryNo

    #@$el
    #    .append(negoInfoView.el)
        #.append(negoSendView.render().el)
        #.append(negoReceiveView.render().el)
    @$el.find(".negolist_item").append(@negoInfoView.el)
    @$el.find(".chatBox").append(@negoReceiveView.render().el)
    @$el.find(".chooseMsgeBox").append(@negoSendView.render().el)

    # 全画面化
    # wHeight = $(window).height()
    # @$el.find(".cont").css "height", wHeight + "px"

    @listenTo @negoInfoView , 'UpdateNego' , @negoJoinReset


  sendNegoMessage: ->
    @negoSendView.sendNegoMessage()

  cancelSendMessage: ->
    @negoSendView.cancelSendMessage()

  negoJoinReset: ->
    #console.log "negoJoinReset"
    @negoJoin.joinNegoRoomReset @negoID, @inventoryYear, @inventoryNo


  negoPageExit : ->
    Backbone.AUCNET.RenderController.returnView()

  _alert_api: (_param) ->
    title = @getMessage("ms_nego_msg10_title")

    options =
      api_err_cd       : _param.api_err_obj.api_err_cd
      api_err_kbn      : _param.api_err_obj.api_err_kbn
      admin_api_err_cd : _param.api_err_obj.admin_api_err_cd

    message = util.editNegoMessage([], _param.message_cd, options)

    confirmView = new UtilConfirmView
      title   : title
      message : message
      cancelDelFlg : true
      callback : (ok)=>
        return ok
    @$el.append(confirmView.render().el)

  suicide: ->
    @negoLog?.remove?()
    @negoJoin?.remove?()
    @negoMessageMaster?.remove?()
    @negoInfoView?.stopListening?()
    @negoInfoView?.remove?()
    @negoSendView?.stopListening?()
    @negoSendView?.remove?()
    @negoReceiveView?.stopListening?()
    @negoReceiveView?.remove?()

    @negoLog           = null
    @negoJoin          = null
    @negoMessageMaster = null
    @negoInfoView      = null
    @negoSendView      = null
    @negoReceiveView   = null

  #------------------
  # 全角を半角に編集
  #------------------

  checkEn: (param) ->
    #console.debug "NNNNNNNNNNNNNNN"
    value = String(param.currentTarget.value)
    #console.debug "value1 = " + value

    if value? and value.length > 0
      regex = /[Ａ-Ｚａ-ｚ０-９]/g
      param.currentTarget.value = value.replace regex, (m, pos, src) ->
        String.fromCharCode(m.charCodeAt(0)- 65248)
    else
      param.currentTarget.value = param.currentTarget.value
