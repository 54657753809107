Collection   = require 'models/collection'

###
 商談コレクション
###
module.exports = class NegoCollection extends Collection

  socket: null

  eventName : ""
  datacount : 0
  datacountResult : 0

  # 他社進行中
  ALL_ONGOING  : "GetNegolistAllOngoing"
  # 管理者進行中
  ADMIN_ONGING : "GetNegolistadminOngoing"
  # 管理者過去
  ADMIN_PAST   : "GetNegolistadminPast"
  # 自社進行中
  MY_ONGING    : "GetNegolistMyOngoing"
  # 自社過去
  MY_PAST      : "GetNegolistMyPast"

  # -------------------------
  # 初期化処理
  # -------------------------
  initialize:(models, options) =>
    _.bindAll @
    @eventName = options.eventName
    @socket = Backbone.AUCNET.socket
    # 前回使用ソケットイベントリスナの切断
    @socket.removeAllListeners @eventName
    # ソケットイベントリスナバインド
    # 最初の受け取り
    @socket.on @eventName, (d)=>
      data = JSON.parse d
      @datacount = data.datacount
      @datacountResult = data?.datacount_result
      @reset data.list
    @onControllerEvent()


  # -------------------------
  # イベント名でサーバにemit
  # -------------------------
  getNegolist: (conditions) =>
    @socket.emit @eventName,
      conditions : conditions

  # -------------------------
  # 全件数取得
  # -------------------------
  getDataCount: =>
    return @datacount
  # -------------------------
  # 全件数取得
  # -------------------------
  getDataCountResult: =>
    return @datacountResult

  # -------------------------
  # その商談は自社か
  # -------------------------
  _isMyNego: (ownerMemCD, buyerMemCd) ->
    # TODO ユーザコードの取得
    myUserID = Backbone.AUCNET.hashUserID
    if myUserID in [ownerMemCD, buyerMemCd]
      true
    else
      false

  # -------------------------
  # その商談は進行中か
  # -------------------------
  _isOngoing: (negoStatus) ->
    ongoing = [
      Backbone.AUCNET.DEFINE.get "NEGO_STATUS_REQUEST" # 申込中
      Backbone.AUCNET.DEFINE.get "NEGO_STATUS_ONGOING" # 進行中
    ]
    if negoStatus in ongoing
      true
    else
      false

  # -------------------------
  # コントローラプッシュイベントオフ
  # -------------------------
  offControllerEvent: () ->
    @socket.removeAllListeners "RemoveNego"
    @socket.removeAllListeners "AddNego"
    @socket.removeAllListeners "ChangeNego"

  # -------------------------
  # コントローラプッシュイベントオン
  # -------------------------
  onControllerEvent: () ->
    # 削除時
    @listenTo Backbone.AUCNET.receiveModel, "RemoveNego", (data)=>
      # 複数商談のため、入れ子になっている
      # list : {nego_id: hoge, list: [{id : n,...}, {id : 2n, ...}]}
      for negos in data.list
        for nego in negos.list
          id = nego.id
          @trigger "removeNego", id

    # 追加
    @listenTo Backbone.AUCNET.receiveModel, "AddNego", (data)=>
      for negos in data.list
        for nego in negos.list
          id = nego.id
          # 自社商談か
          myNego = @_isMyNego nego.hash_owner_member_cd, nego.hash_buyer_member_cd
          # 進行中商談か
          onGoing = @_isOngoing nego.nego_status
          # このコレクションが何のコレクションかで振り分け
          switch @eventName
            # 他社進行中
            when @ALL_ONGOING
              if (not myNego) and onGoing
                @trigger "addNego", id
            # 管理者進行中
            when @ADMIN_ONGING
              if onGoing
                @trigger "addNego", id
            # 管理者過去
            when @ADMIN_PAST
              if not onGoing
                @trigger "addNego", id
            # マイ進行中
            when @MY_ONGING
              if myNego and onGoing
                @trigger "addNego", id
            # マイ過去
            when @MY_PAST
              if myNego  and not onGoing
                @trigger "addNego", id

    # 変更時
    @listenTo Backbone.AUCNET.receiveModel, "UpdateNego", (data)=>
      for negos in data.list
        # 現在持っているモデル内に送信されてきたアイテムが有るかチェック
        models = @where({nego_id : negos.nego_id})
        # ない場合何もしない
        if models.length isnt 1
          return
        # ある場合,本コレクションに含まれる更新の有ったモデルを書き換える
        else
          models[0].set negos, {merge: true, silent:true}
          @add models[0], {merge: true, silent:true}
          # 商談毎にトリガーを引く（複数商談対応）
          for nego in negos.list
            id = nego.id
            @trigger "updateNego", id
