###
インフォ情報
###

infos =
[
  {
    "id"                 : "ja"
    "in_"           : "エラーが発生しました"
    "in_E0001"           : "エラーが発生しました"
    "in_E0002"           : "ウェブソケットが切断されました"
    "in_E0003"           : "データベースエラーが発生しました"
    "info2"              : "インフォメーション２"
    "info3"              : "インフォメーション３"
    "accsess_error"      : "不正なアクセスです"

  }
]
module.exports = infos