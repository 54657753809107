View       = require 'views/view'
template   = require 'views/templates/negolist_page'
application = require 'application'

# 過去/進行中切り替え
NegolistTab        = require 'views/negolist_tab_view'
# 検索ツール
NegolistSearch     = require 'views/negolist_search_view'
# ページング付き商談一覧表示
NegolistList       = require 'views/negolist_list_inc_paging_view'
# 商談一覧コレクション
NegoCollection     = require 'models/nego_collection'
# 検索条件モデル
NegolistSearchModel = require 'models/negolist_search_model'

###
 商談一覧画面
###
module.exports = class NegolistPageView extends View
  id        : 'negolist_page'
  template  : template
  className : ''

  # ブロック（b）、リスト(l)表示
  mode : "b"
  mode_my_past : "b"
  mode_my_ongoing : "b"
  # 画面初期化時にどちらを表示するのかを選択する（o：商談中画面　p：商談履歴）
  initial_display_screen : "o"

  # 現在表示中のリスト
  showingView : null

  initialize: ->
    _.bindAll @
    #console.log "商談一覧画面の初期化"
    if @options.display_screen
      @initial_display_screen = @options.display_screen

  beforeRender: ->
    # GETパラメータの除去
    window.history.pushState null, null, location.pathname + location.hash

  afterRender: ->
    # 過去商談
    @negoCollectionMyPast      = new NegoCollection [],
      eventName : "GetNegolistMyPast"
    # 進行中商談
    @negoCollectionMyOngoing   = new NegoCollection [],
      eventName : "GetNegolistMyOngoing"

    # 過去商談/進行中商談切り替え
    @negolistTab        = new NegolistTab
      collection : @negoCollectionMyOngoing

    # タイマーの初期化
    if Backbone.AUCNET.timerArray_list.length > 0
      for timer_item in Backbone.AUCNET.timerArray_list
        clearInterval timer_item
      Backbone.AUCNET.timerArray_list = new Array()

    @negolistListMyOngoing  = new NegolistList
      collection : @negoCollectionMyOngoing
      id         : "negolist_list_my_ongoing"
      ongoing    : true
      past       : false
      admin      : false
    if @initial_display_screen is 'p'
      @negolistListMyPast     = new NegolistList
        collection : @negoCollectionMyPast
        id         : "negolist_list_my_past"
        ongoing    : false
        past       : true
        admin      : false

    # 検索条件過去
    @searchPastModel = new NegolistSearchModel
    # 検索条件進行
    @searchOngoingModel = new NegolistSearchModel
    # 検索ツール
    @negolistSearchView = new NegolistSearch
      model : @searchOngoingModel
      currentScreenIsNowNego : if @initial_display_screen is 'o' then true else false

    # イベントリスナ
    # ヘッダでの切り替えイベント
    #@listenTo @negolistTab           , "ShowMyOngoing"     , @showMyOngoing
    #@listenTo @negolistTab           , "ShowMyPast"        , @showMyPast
    @listenTo @negolistTab           , "ShowMyOngoing"     , @showMyOngoingReload
    @listenTo @negolistTab           , "ShowMyPast"        , @showMyPastReload
    @listenTo @negolistTab           , "ShowAllOngoing"    , @showAllOngoing
    # 検索条件取得
    @listenTo @negolistSearchView    , "FireSearch"        , @kickSearch
    if @initial_display_screen is 'o'
      # ページング
      @listenTo @negolistListMyOngoing , "ChangePage"        , @changePage
      # 新着
      @listenTo @negolistListMyOngoing , "GetNewItems"       , @getNewItems
    else
      # ページング
      @listenTo @negolistListMyPast    , "ChangePage"        , @changePage
      # 新着
      @listenTo @negolistListMyPast    , "GetNewItems"       , @getNewItems

    ###
     @TODO 一時的に管理者のみ表示
    ###
    #if Backbone.AUCNET.roleID is '1' or Backbone.AUCNET.roleID is '2'
    @$el.find("#negolist_child_search_tool_area")
        .append(@negolistSearchView.render().el)

    if @initial_display_screen is 'o'
      @$el.find("#negolist_child_area")
        .append(@negolistTab.render().el)
        .append(@negolistListMyOngoing.el)
    else
      @$el.find("#negolist_child_area")
        .append(@negolistTab.render().el)
        .append(@negolistListMyPast.el)

    # 商談中表示か商談履歴のどちらを表示するのか決める
    if @initial_display_screen is 'o'
      @showMyOngoing()
    else
      @showMyPast()
    #@showMyOngoing()
    @setSearchDisp()

  # -------------------------
  # 商談履歴の画面から、商談中への画面遷移の場合は再描画を起こす
  # 商談が終了したデータを表示させない為
  # -------------------------
  showMyOngoingReload: ()->
    #console.log "商談中表示か商談履歴のどちらを表示するのか決める  showMyOngoingReload"
    #@initial_display_screen = 'o'
    application.router.initial_display_screen_negolist = 'o'
    # メニューのボタンを押したのと同じ動きをします
    $(".negolistBtn.menus_link").click()

  showMyPastReload: ()->
    #console.log "商談中表示か商談履歴のどちらを表示するのか決める  showAdminPastReload"
    #@initial_display_screen = 'p'
    application.router.initial_display_screen_negolist = 'p'
    # メニューのボタンを押したのと同じ動きをします
    $(".negolistBtn.menus_link").click()

  # -------------------------
  # 検索部分の表示制御
  # -------------------------
  setSearchDisp: ()->
    # 売り店舗コード
    @$el.find("#negolist_input_owner_member_div").hide()
    # 買い店舗コード
    @$el.find("#negolist_input_buyer_member_div").hide()
    # 売り買い区分
    @$el.find("#negolist_search_nego_buy_sell_kbn_div").show()
    # 商談ステータス
    @$el.find("#negolist_search_nego_status_div").hide()

  # -------------------------
  # 検索実行
  # -------------------------
  kickSearch:(conditions) ->
    @showingView.getSearchNegolist conditions

  # -------------------------
  # ページング
  # -------------------------
  changePage:(ret) ->
    @negolistSearchView.setLimitAndOffset ret
    conditions = @negolistSearchView.getSearchConditions()
    @showingView.getSearchNegolist conditions

  # -------------------------
  # 新着順でアイテム取得
  # -------------------------
  getNewItems: ->
    # サーチビューの検索条件をリセット
    @negolistSearchView.setDispNewItemConditions()
    conditions =
      sortkbn : Backbone.AUCNET.DEFINE.get 'NEGOLIST_SORT_KBN_NEW'
    @showingView.getSearchNegolist conditions

  # -------------------------
  # ブロック、リスト表示切替
  # -------------------------
  #changeDisplayMode:(mode) ->
  #  console.log "ここ入ってないんじゃないの？"
  #  console.log "チェンジディスプレイでどのリストを見ているかを知りたいです。"
  #  console.log @showingView
  #  @mode = mode
  #  @showingView.changeDisplayMode(@mode)

  # -------------------------
  # 現在進行中商談表示
  # -------------------------
  showMyOngoing: ->
    # 表示中のビューを変更
    @showingView = @negolistListMyOngoing
    #@showingView.changeDisplayMode(@mode)
    lsStringMyOngoing = localStorage.getItem('negolist_list_my_ongoing.list_disp_mode')
    if lsStringMyOngoing?
      @mode_my_ongoing = lsStringMyOngoing

    @showingView.changeDisplayMode(@mode_my_ongoing)
    # 検索ツール設定変更
    @negolistSearchView.setConditions(Backbone.AUCNET.DEFINE.get "NEGOLIST_KBN_MY_ONGOING")
    # 検索ツール表示変更
    @$el.find("#negolist_search_nego_status_div").hide()
    @$el.find("label.ongoing_only").hide()
    # ラジオボタンリセット（ソートは過去と進行中でソートが異なるため）
    @$el.find("#negolist_search_radio_sort input[type=radio]").attr("checked", false)
    @$el.find("#negolist_search_radio_sort label.ongoing_only").show().children("input:first").attr("checked", true)
    # 条件クリア
    #@negolistSearchView.clearDisp()
    # モデル入れ替え
    @searchPastModel.offSockets()
    @searchOngoingModel.onSockets()
    @negolistSearchView.model = @searchOngoingModel

    # 実際の表示変更
    @$el.find("#negolist_btn_my_ongoing"  ).css({color:"red"}).addClass('active')
    @$el.find("#negolist_btn_my_past"     ).css({color:"black"}).removeClass('active')
    @$el.find("#negolist_btn_all_ongoing" ).css({color:"black"})
    @$el.find("#negolist_list_my_ongoing" ).show()
    @$el.find("#negolist_list_my_past"    ).hide()
    # 車輌の項目
    @$el.find("div.ongoing_only").show()
    @$el.find("div.past_only"   ).hide()
    # 商談終了のデータの表示をもとに戻す為に以下の処理を実行

  # -------------------------
  # 過去商談表示
  # -------------------------
  showMyPast: ->
    @showingView = @negolistListMyPast
    #@showingView.changeDisplayMode(@mode)
    lsStringMyPast = localStorage.getItem('negolist_list_my_past.list_disp_mode')
    if lsStringMyPast?
      @mode_my_past = lsStringMyPast

    @showingView.changeDisplayMode(@mode_my_past)
    @negolistSearchView.setConditions(Backbone.AUCNET.DEFINE.get "NEGOLIST_KBN_MY_PAST")
    # 検索ツール表示変更

    @$el.find("#negolist_search_nego_status_div").show()
    @$el.find("label.ongoing_only").hide()
    # ラジオボタンリセット（ソートは過去と進行中でソートが異なるため）
    @$el.find("#negolist_search_radio_sort input[type=radio]").attr("checked", false)
    @$el.find("#negolist_search_radio_sort label.past_only").show().children("input:first").attr("checked", true)
    #@negolistSearchView.clearDisp()
    # 条件クリア
    # モデル入れ替え
    @searchOngoingModel.offSockets()
    @searchPastModel.onSockets()
    @negolistSearchView.model = @searchPastModel

    # 実際の表示変更
    @$el.find("#negolist_btn_my_ongoing"  ).css({color:"black"}).removeClass('active')
    @$el.find("#negolist_btn_my_past"     ).css({color:"red"}).addClass('active')
    @$el.find("#negolist_btn_all_ongoing" ).css({color:"black"})
    @$el.find("#negolist_list_my_ongoing" ).hide()
    @$el.find("#negolist_list_my_past"    ).show()
    # 車輌の項目
    @$("div.ongoing_only").hide()
    @$("div.past_only"   ).show()

  # -------------------------
  # 後始末
  # -------------------------
  suicide: ->
    @negolistTab?.remove()
    @negoCollectionMyPast?.stopListening()
    @negoCollectionMyPast?.remove()
    @negoCollectionMyOngoing?.stopListening()
    @negoCollectionMyOngoing?.remove()
    @negolistListMyPast?.remove()
    @negolistListMyOngoing?.remove()
    @negolistSearchView?.remove()
    @remove()
