View        = require 'views/view'
template    = require 'views/templates/util_confirm'

module.exports = class UtilConfirmView extends View
  id       : 'util_confirm'
  template : template

  events:
    'click .btnCancel' : 'cancel'
    'click .btnOk'     : 'ok'

  initialize: ->
    #console.debug 'UtilConfirmView#initialize'
    # 既にconfirmが存在していたら中止する
    if $("#" + @id).length isnt 0
      # 基底クラスで定義されている@renderを
      # 自殺するだけの関数に書き換える
      @render = ->
        _.defer =>
          @.suicide()
      return
    _.bindAll @

    if @options.cancelDelFlg is undefined or @options.cancelDelFlg is false
      @cancelFlg = true
    else
      @cancelFlg = false

    ## 背景画面のTab移動とEnter押下とスクロールを無効にする
    $("body").addClass("scrollBlock");
    $(window).on "touchmove.noScroll", (e) ->
      e.preventDefault()
    $(document).bind('keydown', @disableFocusOut)
    $(document).bind('keydown', @disableEnterPress)

  getRenderData: ->
    title     : @options.title
    message   : @options.message
    cancelFlg : @cancelFlg

  afterRender: ->

  ok: ->
    #console.debug 'UtilConfirmView#ok'
    @options.callback(true) if @options.callback?
    @.suicide()

  cancel: ->
    #console.debug 'UtilConfirmView#cancel'
    @options.callback(false) if @options.callback?
    @.suicide()

  suicide: ->
    #console.debug 'UtilConfirmView#suicide'
    $("body").removeClass("scrollBlock");
    $(window).off('.noScroll');
    $(document).unbind('keydown', @disableFocusOut)
    $(document).unbind('keydown', @disableEnterPress)
    @.remove()


  disableFocusOut: (e) ->
    if e.keyCode is 9
      e.stopPropagation()
      e.preventDefault()
      $(e.currentTarget).focus()
      return

  disableEnterPress: (e) ->
    #if e.keyCode is 13
    #  console.log 'PRESS ENTER'
    return
