Model = require 'models/model'
util = require 'lib/util'

module.exports = class SettingCreditModel extends Model

  socket: undefined

  defaults:
    message: undefined

  initialize: ->
    _.bindAll @
    @socket = Backbone.AUCNET.socket
    @socket.removeAllListeners 'GetYosinResult'

    @socket.on 'GetYosinResult', (d) =>
      res = JSON.parse(d)
      if res.yosin is 0
        @set 'creditMessage': '与信が設定されていません。'
      else
        @set 'creditMessage':
          "現在の与信残高は #{util.numberWithComma(res.yosinzan)} です。"

  updateCredit: (credit) =>
    @socket.emit 'updateCredit',
      memberCd: @get 'memberCd'
      credit: credit

  suicide: ->
    @socket.removeAllListeners 'GetYosinResult'
    @.remove
