application = require 'application'
listener = require 'lib/listener'

View     = require 'views/view'
#template = require 'views/templates/util_connect'

module.exports = class UtilConnectView extends View
  id: 'util-connect-view'
  #template: template

  _setting: undefined
  _stoping_flg: undefined

  initialize: ->
    _.bindAll @
    @model.bind 'ping', @recvPing
    @_setting = Backbone.AUCNET.setting

  afterRender: ->
    @sendPing()

  setStopingFlg: (param) ->
    @_stoping_flg = param

  sendPing: ->
    # タイムアウト処理
    timeoutid = 0
    if @_stoping_flg and @_setting.st_na_timeout isnt 0
      timeoutid = window.setTimeout ->
        Backbone.AUCNET.waitOn()
        @_stoping_flg = undefined
      , @_setting.st_na_timeout
    @model.ping timeoutid
        
    if @_stoping_flg
      # インターバル送信
      window.setTimeout =>
        @sendPing()
      , @_setting.st_na_interval

  recvPing: (data) ->
    window.clearTimeout data.timeoutid
    diff = data.receivetime - data.sendtime
    if diff > @_setting.st_na_rank5 
      application.router.navigate 'error/E0002', true
      @_stoping_flg = undefined
    else
      Backbone.AUCNET.waitOff()
