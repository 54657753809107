View       = require 'views/view'
Template   = require 'views/templates/mylist_page_base'

CarDataCollection  = require 'models/car_data_collection'
DataLineListView   = require 'views/data_line_list_view'
UtilPagingView     = require 'views/util_paging_view'
UtilChangeDispView = require 'views/util_change_disp_view'
SearchToolView     = require 'views/search_tool_view'
CarDetailBaseView  = require 'views/car_detail_base_view'
MylistTabView      = require 'views/mylist_tab_view'
IndicatorView   = require 'views/indicator_view'
###
 [マイリスト] マイリストビュー
###
module.exports = class MylistPageView extends View
  id        : 'mylist_page'
  template  : Template
  className : ''

  # 検索条件
  searchcond  : null
  # ソート条件
  sortcond    : null
  # 表示モード (l:リスト, b:ブロック)
  mode : "b"

  # 初期化
  initialize: ->
    _.bindAll @
    ## 車両データコレクション生成
    #@carDataCollection = new CarDataCollection
    #@listenTo @carDataCollection, 'GetMylistCarDataList', @listRender
    ## マイリストタブビュー生成
    #@mylistTabView = new MylistTabView
    #  collection : @carDataCollection
    #@listenTo @mylistTabView, 'ChangeMylistTab', @changeMylistID
    ## 表示切替ビュー生成
    #@utilChangeDispView = new UtilChangeDispView ls_key_id : @id
    #@listenTo @utilChangeDispView, "ChangeDisplayMode", @changeDisplayMode, @
    ## 検索ツールビュー生成
    #@searchToolView = new SearchToolView
    #@listenTo @searchToolView, 'ReflectSearchToolConditions', @reflectSearchToolConditions
    ## ページングビュー生成
    #@utilPagingView = new UtilPagingView id : @id
    #@listenTo @utilPagingView, 'paging', @getMylist
    ## データリストビュー生成
    #lsStringMyList = localStorage.getItem('mylist_page.list_disp_mode')
    #if lsStringMyList?
    #  @mode = lsStringMyList
    #@dataLineListView = new DataLineListView
    #  collection : @carDataCollection
    #  mode : @mode


  # レンダリング前処理
  beforeRender: ->
    # 車両データコレクション生成
    @carDataCollection = new CarDataCollection
    @listenTo @carDataCollection, 'GetMylistCarDataList', @listRender
    # マイリストタブビュー生成
    @mylistTabView = new MylistTabView
      collection : @carDataCollection
    @listenTo @mylistTabView, 'ChangeMylistTab', @changeMylistID
    # 表示切替ビュー生成
    @utilChangeDispView = new UtilChangeDispView ls_key_id : @id
    @listenTo @utilChangeDispView, "ChangeDisplayMode", @changeDisplayMode, @
    # 検索ツールビュー生成
    @searchToolView = new SearchToolView
    @listenTo @searchToolView, 'ReflectSearchToolConditions', @reflectSearchToolConditions
    # ページングビュー生成
    @utilPagingView = new UtilPagingView id : @id
    @listenTo @utilPagingView, 'paging', @getMylist
    # データリストビュー生成
    lsStringMyList = localStorage.getItem('mylist_page.list_disp_mode')
    if lsStringMyList?
      @mode = lsStringMyList
    @dataLineListView = new DataLineListView
      collection : @carDataCollection
      mode : @mode


  # リストレンダ
  listRender: ->
    @indicator?.suicide()
    # データリストビューのレンダリング
    @dataLineListView.setCollection
      carDataCollection : @carDataCollection
    @dataLineListView.render()

    # ページングビューのレンダリング
    @utilPagingView.setPagingData
      dataCount  : @carDataCollection.count.search_car_data_count
    @utilPagingView.render()

    # データリストビュー生成
    lsStringMyList = localStorage.getItem('mylist_page.list_disp_mode')
    if lsStringMyList?
      @dataLineListView.changeDisplayModeOnly(lsStringMyList)


  # レンダリング後処理
  afterRender: ->
    # コンテンツ組立
    @$el.find('.searchTool_area')
      .append(@searchToolView.el)

    @$el.find('.mylist_tab_area')
      .append(@mylistTabView.el)

    @$el.find('.bgDes')
      .append(@utilChangeDispView.el)
      .append(@utilPagingView.el)

    @$el.find('.mylist_list_area')
      .append(@dataLineListView.el)
    # 各ビューのレンダリング
    @searchToolView.render()
    @utilChangeDispView.render()
    @utilPagingView.render()

    @searchcond = null
    @sortcond   = null
    # マイリストタブビュー起動
    @mylistTabView.start()

    # イベント設定
    @delegateEvents
      'click .car_thumbnail' : 'showCarDetail'

    @searchToolView.dispMylistSearchToolCond
      mylistid   : @mylistTabView.getMylistId()

    @indicator?.suicide()


  changeMylistID: (changeMylistTabFLG = false) ->
    @searchToolView.mylistFilterClear()
    @getMylist changeMylistTabFLG
    # 絞り込み条件取得
    @searchToolView.dispMylistSearchToolCond
      mylistid   : @mylistTabView.getMylistId()


  # 表示切替
  changeDisplayMode: (mode) ->
    @mode = mode
    # データリストビュー呼び出し
    if !_.isEmpty(@mylistTabView.getMylistId())
      @dataLineListView.changeDisplayMode(@mode)

  # 検索ツール条件反映
  reflectSearchToolConditions: ->
    # 検索条件取得
    conditions  = @searchToolView.getSearchToolConditions()
    @searchcond = conditions.searchcond
    @sortcond   = conditions.sortcond
    # カレントページ初期化
    @utilPagingView.page = 0
    # マイリスト取得
    @getMylist()


  # マイリスト取得
  getMylist: (changeMylistTabFLG = false) ->
    # マイリストが選択されている場合のみ、実行
    if !_.isEmpty(@mylistTabView.getMylistId())
      if changeMylistTabFLG
        ofset_param = 0
      else
        ofset_param = @utilPagingView.getOffset()

      #マイリスト取得
      @carDataCollection.getMylistCarDataList
        limit      : @utilPagingView.limit
        offset     : ofset_param
        mylistid   : @mylistTabView.getMylistId()
        searchcond : @searchcond
        sortcond   : @sortcond

      @indicator = new IndicatorView
        title   : Backbone.AUCNET.message.get "ms_indicator_carname_title"
      $('body').append(@indicator.render().el)

      if changeMylistTabFLG
        @utilPagingView.page = 0
    else
      @initList()

  # 車両詳細表示
  showCarDetail: (param) ->
    invKey = param.currentTarget.id.split('|')
    Backbone.AUCNET.RenderController.renderCarDetailPaging(invKey[0],invKey[1],@carDataCollection,@utilPagingView ,'GetMylistCarDataList')

  # リスト初期化
  initList:  ->
    @utilPagingView.setPagingData
      dataCount  : 0
    @utilPagingView.render()
    @$el.find('ul.data_line-1').empty()

  suicide: ->
    @carDataCollection?.remove()
    @mylistTabView?.suicide()
    @utilChangeDispView?.remove()
    @searchToolView?.suicide()
    @utilPagingView?.remove()
    @dataLineListView?.remove()
    @indicator?.suicide()
    @.remove()

