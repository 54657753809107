View               = require 'views/view'
Template           = require 'views/templates/util_paging'
IndicatorView   = require 'views/indicator_view'
###
 [共通] ページングビュー(改造版)
###
module.exports = class UtilPagingView extends View
  id        : undefined
  template  : Template

  # データ総件数
  dataCount : undefined
  # 現在の頁番号
  page      : 0
  # 表示件数
  limit     : 50

  # ローカルストレージ キーワード
  LS_KEY_LIMIT : ".limit"

  events:
    'click  .paging_prev'     : 'gotoPrev'
    'click  .paging_next'     : 'gotoNext'
    'click  .paging_number'   : 'jumpDirect'
    'click  .paging_disp_num' : 'changeDispNum'
    # hover対応
    #'touchstart .paging_prev'             : 'hoverStart'
    #'touchend   .paging_prev'             : 'hoverEnd'
    'mouseover  .paging_prev'             : 'hoverStart'
    'mouseout   .paging_prev'             : 'hoverEnd'
    #'touchstart .paging_next'             : 'hoverStart'
    #'touchend   .paging_next'             : 'hoverEnd'
    'mouseover  .paging_next'             : 'hoverStart'
    'mouseout   .paging_next'             : 'hoverEnd'
    #'touchstart .paging_number'           : 'hoverStart'
    #'touchend   .paging_number'           : 'hoverEnd'
    'mouseover  .paging_number'           : 'hoverStart'
    'mouseout   .paging_number'           : 'hoverEnd'
    #'touchstart .paging_disp_num'         : 'hoverStart'
    #'touchend   .paging_disp_num'         : 'hoverEnd'
    'mouseover  .paging_disp_num'         : 'hoverStart'
    'mouseout   .paging_disp_num'         : 'hoverEnd'

  # 初期処理
  initialize: ->
    _.bindAll @
    # ローカルストレージから表示件数取得
    lsString = localStorage.getItem(@id + @LS_KEY_LIMIT)
    if lsString?
      lsLimit = parseInt(lsString)
      if lsLimit isnt NaN then @limit = lsLimit

  # レンダリング後処理
  afterRender: ->
    # 古い情報の削除
    @delegateEvents()
    @$el.find('.paging_number').remove()

    # ページ番号生成
    pageCnt = parseInt(@dataCount / @limit)
    if @dataCount > 0 and @dataCount % @limit == 0 then pageCnt--
    for i in [0..pageCnt]
      tag = @getPageTag i, pageCnt
      if tag? then @$el.find('.paging_number_links').append(tag)

    # 表示件数の再描画
    #@$el.find('.paging_disp_num').val(@limit)
    @$el.find('.paging_disp_num').removeClass('active')
    @$el.find(".paging_disp_num[data-value="+@limit+"]").addClass('active')

    # prev nextの活性非活性
    if @page == 0
      @$el.find('.paging_prev').addClass('disabled')
    else
      @$el.find('.paging_prev').removeClass('disabled')

    if @page == pageCnt
      @$el.find('.paging_next').addClass('disabled')
    else
      @$el.find('.paging_next').removeClass('disabled')

  # ページ番号タグ生成
  getPageTag: (num, last) ->
    # 現在頁の場合、Diabledで生成
    if @page == num
      return '<a class="paging_number paging_number_current item disabled" href="#">' + (num + 1) + '</a>'
    # 現在頁の前後2頁の場合、普通に生成
    if num >= (parseInt(@page) - 1) and num <= (parseInt(@page) + 1)
      return '<a class="paging_number item" href="#">' + (num + 1) + '</a>'
    if num == 0
      # 先頭頁、かつ、現在頁の前2頁と連続している場合、普通に生成
      if num == (parseInt(@page) - 2)
        return '<a class="paging_number item" href="#">' + (num + 1) + '</a>'
      else
        # 連続していない場合、間に「...」を設定
        return '<a class="paging_number item" href="#">' + (num + 1) + '</a><span class="empty">...</span>'
    if num == last
      # 最終頁、かつ、現在頁の後2頁と連続している場合、普通に生成
      if num == (parseInt(@page) + 2)
        return '<a class="paging_number item" href="#">' + (num + 1) + '</a>'
      else
        # 連続していない場合、間に「...」を設定
        return '<span class="empty">...</span><a class="paging_number item" href="#">' + (num + 1) + '</a>'
    # 生成対象外の頁の場合、null返却
    return null

  # レンダリングデータ返却
  getRenderData: ->
    page_id           : @id
    paging_data_count : @dataCount

  # 総件数設定
  setPagingData: (param) ->
    @dataCount = param.dataCount

  # 前のページへ移動
  gotoPrev: (param) ->
    # アクション中断
    param.preventDefault()
    # イベント発生要素取得
    $ele = $(param.currentTarget)
    # 非活性の場合、処理終了
    return if $ele.hasClass('disabled')
    # 先頭ページの場合は、処理終了
    return if parseInt(@page) is 0

    @page  = parseInt(@page) - 1
    @trigger 'paging'

  # 次のページへ移動
  gotoNext: (param) ->
    param.preventDefault()
    $ele = $(param.currentTarget)
    return if $ele.hasClass('disabled')
    pageCnt = parseInt(@dataCount / @limit)
    # 最後尾ページの場合は、処理終了
    return if parseInt(@page) is pageCnt

    @page  = parseInt(@page) + 1
    @trigger 'paging'

  # ページ番号指定
  jumpDirect: (param) ->
    param.preventDefault()
    $ele = $(param.currentTarget)
    return if $ele.hasClass('disabled')
    @page  = $ele.text() - 1
    @trigger 'paging'

  # 表示件数変更
  changeDispNum: (param) ->
    param.preventDefault()
    @$el.find('.paging_disp_num').removeClass('active')
    $(param.currentTarget).addClass('active')
    @page = 0
    @limit = $(param.currentTarget).data('value')
    @trigger 'paging'
    # ローカルストレージに表示件数保存(ページ毎)
    localStorage.setItem(@id + @LS_KEY_LIMIT, @limit)

  # オフセット取得
  getOffset: ->
    return @limit * @page


  #----------------------
  # 車両詳細用NextPrev
  #----------------------
  # 前のページへ移動
  goPrev: ->
    # 先頭ページの場合は、処理終了
    return if parseInt(@page) is 0

    @page  = parseInt(@page) - 1
    @trigger 'paging'

  # 次のページへ移動
  goNext: ->
    pageCnt = parseInt(@dataCount / @limit)
    # 最後尾ページの場合は、処理終了
    return if parseInt(@page) is pageCnt

    @page  = parseInt(@page) + 1
    @trigger 'paging'

