###
メッセージ情報
###

messages = [
  {
    "id": "ja"
    "ms_loginFailed_title": "ログインエラー"
    "ms_loginFailed_L01": "IDまたはパスワードが違います。"
    "ms_loginFailed_L02": "入力されたユーザーIDは無効です。"
    "ms_loginFailed_L03": "入力されたIDは権限がありません。"
    "ms_loginFailed_L04": "入力されたIDは無効です。"
    "ms_loginFailed_L05": "IDを入力してください。"
    "ms_loginFailed_L06": "パスワードを入力してください。"
    # API共通系エラー
    "ms_api_error_EL001": "IDまたはパスワードが違います。"
    "ms_api_error_EA001": "要求がタイムアウトしました。"
    "ms_api_error_EA002": "要求がタイムアウトしました。"
    "ms_api_error_EA003": "システムメンテナンス中です。"

    "ms_search_car_name_error": "車両を選択してください。"
    "ms_search_car_name_limit_over": "該当件数が多いため、条件を絞って頂けますようお願い致します。<br>（該当件数目安：10000件）"
    "ms_search_car_maker_error": "メーカーを選択してください。"
    "ms_search_car_name_error_title": "車両選択エラー"
    "ms_search_car_maker_error_title": "メーカー選択エラー"
    "ms_search_condition_error": "検索できる車両がありません。"
    "ms_search_condition_error_title": "条件検索エラー"
    "ms_search_inv_number": "問合番号を入力してください。"
    "ms_search_inv_number_title": "問合番号入力エラー"



    # 商談候補画面
    "ms_candidate_del_confirm_title": "削除確認"
    "ms_candidate_del_confirm_msg": "全ての車両をお気に入りから外します。宜しいですか？"
    "ms_candidate_max_nego_cnt": "複数商談申込みは最大{$}件までです。選択車輌を減らして下さい。"
    # 商談画面
    "ms_nego_no_data": "メッセージ無し"
    "ms_nego_input_price": "[入力価格]"
    "ms_nego_buyer_start": "商談お願いします。希望価格【 %s円 】。【 %h時 】までにご回答お願いします。"
    "ms_nego_buyer_contracts_speedy": "【 %s円 】で購入します。"
    "ms_nego_buyer_price_up_again": "購入希望価格【 %s円 】に変更しますので、ご検討お願いします。"
    "ms_nego_buyer_price_last": "最終購入価格【 %s円 】です。ご検討お願いします。"
    "ms_nego_buyer_flow": "申し訳ございませんが、今回流します。また機会がございましたら、よろしくお願いします。"
    "ms_nego_owner_cancel_no_stock": "申し訳ございません。在庫がありませんので、商談できません。"
    "ms_nego_owner_cancel_retail": "申し訳ございません。小売商談中なので、商談できません。"
    "ms_nego_owner_contracts_speedy": "【 %s円 】で売却します。"
    "ms_nego_owner_price_down": "売却希望価格【 %s円 】です。ご検討お願いします。"
    "ms_nego_owner_price_last": "最終売却希望価格【 %s円 】です。ご検討お願いします。"
    "ms_nego_owner_price_down_again": "売却希望価格【 %s円 】に変更しますので、ご検討お願いします。"
    "ms_nego_owner_flow": "申し訳ございませんが、今回流します。また機会がございましたら、よろしくお願いします。"
    "ms_nego_admin_request_cancel": "申し訳ございません。諸事情により商談を流札します。"
    "ms_nego_admin_ongoing_cancel": "申し訳ございません。諸事情により商談を流札します。"
    "ms_nego_admin_request_time_out": "商談回答期限が過ぎましたので流札いたします。"
    "ms_nego_admin_ongoing_time_out": "商談回答期限が過ぎましたので流札いたします。"


    "ms_short_nego_buyer_start": "商談依頼 希望価格%s円。回答%h時まで"
    "ms_short_nego_owner_cancel_no_stock": "商談キャンセル在庫なし"
    "ms_short_nego_owner_cancel_retail": "商談キャンセル小売商談中"
    "ms_short_nego_owner_contracts_speedy": "%s円にて売却"
    "ms_short_nego_owner_price_down": "売却希望価格%s円です。ご検討お願いします。"
    "ms_short_nego_owner_price_last": "最終売却価格%s円です。ご検討お願いします。"
    "ms_short_nego_buyer_contracts_speedy": "%s円にて購入"
    "ms_short_nego_buyer_price_up_again": "購入希望価格変更%s円"
    "ms_short_nego_buyer_price_last": "最終購入価格%s円 ご検討お願いします。"
    "ms_short_nego_buyer_flow": "流札いたします。"
    "ms_short_nego_owner_contracts_speedy": "%s円で売却します。"
    "ms_short_nego_owner_price_down_again": "売却希望価格変更%s円"
    "ms_short_nego_owner_flow": "流札いたします。"
    "ms_short_nego_admin_request_cancel": "諸事情により商談を流札します。"
    "ms_short_nego_admin_ongoing_cancel": "諸事情により商談を流札します。"
    "ms_short_nego_admin_request_time_out": "商談回答期限が過ぎましたので流札いたします。"
    "ms_short_nego_admin_ongoing_time_out": "商談回答期限が過ぎましたので流札いたします。"


    "ms_nego_msg01_title": "選択できません"
    "ms_nego_msg01_message": "現在選択されたボタンは押せません"
    "ms_nego_msg02_title": "入力エラー"
    "ms_nego_msg02_message": "入力価格に誤りがあります"
    "ms_nego_msg03_title": "入力エラー"
    "ms_nego_msg03_message": "提示できる価格を入力してください。"
    "ms_nego_msg04_title": "入力エラー"
    "ms_nego_msg04_message": "%s円単位に入力してください。"
    "ms_nego_msg05_title": "メッセージ送信完了"
    "ms_nego_msg05_message": "メッセージを送信しました。"
    "ms_nego_msg06_title": "メッセージ送信"
    "ms_nego_msg06_message": "メッセージを送信します。宜しいですか？"
    "ms_nego_msg07_title": "メッセージ送信"
    "ms_nego_msg07_message": "メッセージを送信します。同時に申し込まれた他の商談は流れます。宜しいですか？"
    "ms_nego_msg08_title": "送信結果"
    "ms_nego_msg08_message": "送信されました。"
    "ms_nego_msg09_title": "送信結果"
    "ms_nego_msg09_message": "送信に失敗しました。"
    "ms_nego_msg10_title": "商談エラー"
    "ms_nego_msg10_message": "商談が表示できませんでした。"
    "ms_nego_msg11_title": "中断"
    "ms_nego_msg11_message": "他社がメッセージを登録しております。メッセージをご確認ください。"
    "ms_nego_msg12_title": "入力エラー"
    "ms_nego_msg12_message": "希望金額は商談相手の希望金額以上を入力してください。"
    "ms_nego_msg13_title": "警告"
    "ms_nego_msg13_message": "商談中ですが、車両詳細に遷移しますか？"
    "ms_nego_msg14_title": "入力エラー"
    "ms_nego_msg14_message": "希望金額は前回の自社希望金額以上を入力してください。"

    "ms_nego_msg15_title": "入力エラー"
    "ms_nego_msg15_message": "希望金額は前回の自社希望金額以下を入力して下さい。"
    "ms_nego_msg16_title": "入力エラー"
    "ms_nego_msg16_message": "希望金額は商談相手の希望金額以上を入力して下さい。"
    "ms_nego_msg17_title": "入力エラー"
    "ms_nego_msg17_message": "希望金額は、商談相手の希望金額以下を入力して下さい。"

    "ms_nego_msg18_title": "入力エラー"
    "ms_nego_msg18_message": "希望金額は販売金額以下を入力して下さい。"

    "ms_apply_msg01_message": "有効なメールアドレスがありません。設定画面からメール登録を行い、メール認証をお願い致します。"
    "ms_apply_msg01_title": "申込エラー"
    "ms_apply_msg02_title": "商談申込"
    "ms_apply_msg02_message": "商談で使用するメールアドレスを１つ以上指定してください。"
    "ms_apply_msg03_message": "共有在庫規約の内容をご確認の上、同意にチェックを付けて下さい。"
    "ms_apply_msg04_message": "販売価格は{$}円です。即決価格で申し込みしますか？"
    "ms_apply_msg05_message": "希望価格を入力してください。"
    "ms_apply_msg06_message": "希望価格は最低申し込み価格以上の価格を入力してください。"
    "ms_apply_msg07_message": "回答期限を選択してください。"
    "ms_apply_msg07_message": "回答期限を選択してください。"
    "ms_apply_msg08_message": "与信残高が不足しいるため、申し込みできません。"
    "ms_apply_msg09_message": "対象車両はネット商談中のため、申し込みできません。"
    "ms_apply_msg10_message": "申し訳ありませんが、該当の車両は商談できません。"
    "ms_apply_msg11_message": "車両情報を取得できませんでした。"
    "ms_apply_msg12_message": "金額 : ￥{$}<br>回答期限 : {$}<br>メールアドレス : {$}<br><div style=\"width: 394px; background-color: WhiteSmoke; margin: 3px 0px 3px 3px; padding: 5px 0px 3px 5px;\">登録店が希望価格で成約に応じた場合、<span style=\"color: red;\">即成約</span>となります。<br>売却済などの理由により、購入できない場合があります。<br>結果は、ご登録のメールアドレスへ通知します。</div>以上の内容で商談を申し込みます。よろしいですか？"
    "ms_apply_msg13_message": "{$}単位で価格を入力してください。"
    "ms_apply_msg14_message": "選択した回答期限が無効です。{$}以降の選択が可能です。選択肢がない場合は再度画面を開き直してください。"
    "ms_apply_msg15_message": "申し込み車両に自社出品車両が存在します。"
    "ms_apply_msg16_message": "申し訳ありませんが、該当の車両はネット商談申込みできません。お手数をおかけしますが、電話商談をお申込み願います。"
    "ms_apply_msg17_message": "申し訳ありませんが、商談申込み処理がタイムアウトしました。<br />商談センターにお問合せ下さい。電話番号：{$}"

    "ms_webapi_err_1": "申し訳ございません。この車両は業販できません。エラーコード：{$0}"
    "ms_webapi_err_2": "申し訳ございません。この車両はネット商談できません。商談センターにお問合せ下さい。電話番号：{$1}　エラーコード：{$0}"
    "ms_webapi_err_3": "申し訳ございません。データ不整合により、成約できません。商談センターにお問合せ下さい。電話番号：{$1} エラーコード：{$0}"
    "ms_webapi_err_4": "申し訳ございません。データの不整合が発生しましたので、商談を中止します。エラーコード：{$0}"
    "ms_webapi_err_5": "申し訳ございません。現在システムメンテナンス中です。エラーコード：{$0}"
    "ms_webapi_err_local": 'LOCAL_NEGO_API_ERROR'

    "ms_mail_no_address": "追加するメールアドレスを入力してください。"
    "ms_mail_ng_address_1": "メールアドレスの形式が不正です。"
    "ms_mail_ng_address_2": "アドレスは１件ずつ入力してください。"
    "ms_mail_ng_address_3": "メールアドレスが長すぎます。"
    "ms_mail_mail_duplication": "そのメールアドレスは既に登録されています。"
    "ms_mail_error_title": "エラー"

    "ms_reconnect": "通信が不安定です。再接続中"
    "ms_reconnect_title": "システムメッセージ"
    "ms_reconnect_end": "再接続されました。"

    "ms_mail_count_over": "これ以上登録できません。"
    "ms_mail_delete_ng": "メールアドレスを削除できません。認証済みのメールを全て削除する事はできません。"
    "ms_mail_user_ng": "ログインユーザが不正です。"
    "ms_mail_count_over": "これ以上登録できません。"
    "ms_mail_mail_data_non": "該当するデータが存在しません。"
    "ms_mail_user_data_non": "該当するユーザが存在しません。"
    "ms_nego_content": "商談を申し込みしますか？"
    "ms_nego_error": "商談できない車両があります。"
    "ms_mail_activation_ok": "メールアドレスの登録が完了しました。"
    "ms_mail_activation_error": "認証エラー"
    "ms_aleart_msg_input_inv_no": "数字で入力してください"
    "ms_util_alert_title": "アラートメッセージ"

    "ms_indicator_apply_title": "商談申込"
    "ms_indicator_carname_title": "車両検索"
    "ms_indicator_maker_title": "メーカ検索"
    "ms_indicator_condition_title": "条件検索"
    
    # setting画面
    "ms_setting_title": "ネット商談受付不可設定"
    "ms_setting_success": "更新しました。"
    "ms_setting_failed": "更新に失敗しました。管理者に問い合わせください。"
    "ms_setting_agree": "ネット商談受付不可設定を更新しますか？"

    "ms_nego_agree": "万ご成約！ありがとうございました。"
    "ms_nego_respons_wait": "回答待ち"

    "ms_mail_re_sendmail_title": "メールアドレス再送信"
    "ms_mail_re_sendmail_text": " へ認証メールを再送信致します。よろしいですか？"
    "ms_how_to_buy_title": "購入方法のお知らせ"
    "ms_how_to_buy1": "この車両の購入方法は情報流通センター"
    "ms_how_to_buy2": "までお問い合わせ下さい。"
    "ms_how_to_buy3": "この車両の購入方法は情報流通センター(03-6440-2222)までお問い合わせ下さい。"

  }
]
module.exports = messages
