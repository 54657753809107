application = require 'application'
listener    = require 'lib/listener'
View        = require 'views/view'
template    = require 'views/templates/util_subheader'

###
 [�T�u�w�b�_�[] ���C��
###

module.exports = class SubHeaderView extends View
  id       : 'subheader-page'
  template : template

  events:
    'click #tab1'              : 'tab1'
    'click #searchtool'        : 'searchtool'
    'click #changeblockview'   : 'changeblockview'
    'click #changelistview'    : 'changelistview'

  initialize: ->
    #console.debug 'SubHeaderView#initialize'
    _.bindAll @

  afterRender: ->
    #console.debug 'SubHeaderView#afterRender'
    # cls = @model.get 'logoutBtn'
    # @$el.find('#logout').removeClass()
    # @$el.find('#logout').addClass(cls)

  tab1 : ->
    #console.debug 'SubHeaderView#tab1'

  searchtool: ->
    #console.debug 'SubHeaderView#searchtool'

  changelistview: ->
    #console.debug 'SubHeaderView#changelistview'

  changeblockview: ->
    #console.debug 'SubHeaderView#changeblockview'
