Application = require 'application'
View        = require 'views/view'
Template    = require 'views/templates/search_maker'
IndicatorView   = require 'views/indicator_view'

###
 [車両検索] メーカー選択ビュー
###
module.exports = class SearchMakerView extends View
  id          : 'search_maker'
  template    : Template

  events:
    'click #btnJpAllSelect'     : 'selectAllJpMaker'
    'click #btnJpAllClear'      : 'clearAllJpMaker'
    'click #btnImportAllSelect' : 'selectAllImportMaker'
    'click #btnImportAllClear'  : 'clearAllImportMaker'
    'click #btnPrev'            : 'goPrev'
    'click #btnNext'            : 'goNext'
    'change .Maker'             : 'changeCond'


  # 初期化
  initialize: ->
    _.bindAll @

  # 起動
  start: ->
    # コールバック解除
    @options.searchModel.unbind 'GetMaker'
    @indicator = new IndicatorView
      title   : Backbone.AUCNET.message.get "ms_indicator_maker_title"
    $('body').append(@indicator.render().el)

    # モデルにイベントとコールバックを設定
    @options.searchModel.bind 'GetMaker', @render
    # メーカー取得
    @options.searchModel.getMaker()

  # hbsに書き出すデータを指定
  getRenderData: ->
    jpMakerList     : @options.searchModel.get('Maker').jpMakerList
    importMakerList : @options.searchModel.get('Maker').importMakerList

  # 国産車全選択
  selectAllJpMaker: ->
    # 全選択処理
    @updateMakerCheck(".JpMaker", true)

  # 国産車全クリア
  clearAllJpMaker: ->
    # 全選択処理
    @updateMakerCheck(".JpMaker", false)

  # 輸入車全選択
  selectAllImportMaker: ->
    # 全選択処理
    @updateMakerCheck(".ImportMaker", true)

  # 輸入車全クリア
  clearAllImportMaker: ->
    # 全選択処理
    @updateMakerCheck(".ImportMaker", false)

  # 選択状態更新
  updateMakerCheck: (clazz, bool) ->
    # HTMLからメーカー取得
    makers = @$el.find(clazz)
    # チェック付加
    for item in makers
      item.checked = bool
    # 条件変更
    @changeCond()

  # 検索方法選択画面へ遷移
  goPrev: (event) ->
    # 条件をクリア
    @options.searchModel.setSelectedMakerList undefined
    # 検索方法選択画面を表示
    @options.parent.changeChildView 'search_method', false

  # 車名選択画面へ遷移
  goNext: (event) ->
    # HTMLからメーカー取得
    makers = @$el.find(".Maker")
    # チェック済みアイテム取得
    checkedCarNames = @getCheckedItem(makers)
    if _.isEmpty(checkedCarNames)
      @showMsgBox("ms_search_car_maker_error", "ms_search_car_maker_error_title")
    else
      # HTMLからメーカー取得
      makers = @$el.find(".Maker")
      # 選択メーカーをモデルに設定
      @options.searchModel.setSelectedMakerList @getCheckedItem(makers)
      # 車名選択画面を表示
      @options.parent.changeChildView 'search_car_name', true
      # アニメーションクラスを削除
      #@$el.find("#btnNext").removeClass("flash animated")

  # レンダリング後処理
  afterRender: ->
    #remove guruguru
    @indicator.suicide()

  # 条件変更
  changeCond: ->
    # チェック済みアイテム取得
    makers = @$el.find(".Maker")
    checkedCarNames = @getCheckedItem(makers)
    if _.isEmpty(checkedCarNames)
      # 未チェックの場合アニメーションクラスを削除
      @$el.find("#btnNext").removeClass("flash animated")
    else
      # チェック済みの場合アニメーションクラスを設定
      @$el.find("#btnNext").addClass("flash animated")

  # チェック済みアイテム取得
  getCheckedItem: (array) ->
    retArray = []
    for item in array
      if item.checked then retArray.push item.value
    return retArray
