View       = require 'views/view'
template   = require 'views/templates/request_conditions'

RequestConditionsLineView   = require 'views/request_conditions_line_view'


###
 リクエスト条件画面
###
module.exports = class RequestConditionsView extends View
  id       : 'request_conditions'
  template : template

  initialize: ->
    #console.debug "RequestConditionsView#initialize"
    _.bindAll @

  # このビューがrender()された後に実行される
  # View#renderを参照のこと
  afterRender: ->
    #console.debug 'RequestConditionsView#afterRender'
    # コンテンツの組み立て

    @collection.each (model) =>
      return if model.get('model_id') == 'request_conditions_key'
      requestConditionsLineView = new RequestConditionsLineView(model: model)
      @$el.find(".request_conditions_table")
          .append(requestConditionsLineView.render().el)
      return

  setCollection: (param)->
    @collection = param.requestCollection
