application = require 'application'

View     = require 'views/view'
template = require 'views/templates/error'

listener = require 'lib/listener'

###
 エラー画面
###
module.exports = class PageErrorView extends View
  id       : 'error-view'
  template : template
  errorID  : undefined

  events:
    'click #logoff' : 'logoff'

  LOGIN_ROLE_USER : '0'
  LOGIN_ROLE_ADMIN : '1'
  LOGIN_ROLE_HAKEN : '2'

  reasons:
    ''     : 'SYSTEM ERRPR'
    'E0001': 'WEB SOCKET ERROR'
    'E0002': 'WEB SOCKET DISCONNECT'
    'E0003': 'SYSTEM ERROR'
    'E0004': 'SYSTEM ERROR'
    'E0005': 'SYSTEM ERROR'
    'E0006': 'SYSTEM ERROR'
    'E0007': 'SYSTEM ERROR'
    'E0008': 'SYSTEM ERROR'
    'E0009': 'SYSTEM ERROR'
    'E0010': 'WEB SOCKET TIMEOUT'
    'E0011': 'SYSTEM ERROR'
    'E0012': 'SYSTEM ERROR'
    'E0013': 'SYSTEM ERROR'

  initialize: ->
    _.bindAll @
    #ソケット切断
    if Backbone.AUCNET.socket
      Backbone.AUCNET.socket.removeListener 'disconnect', listener.socket_disconnect
      Backbone.AUCNET.socket.disconnect()
      Backbone.AUCNET.waitOff()
      Backbone.AUCNET.socket = undefined

  getRenderData: ->
    if @errorID is undefined then errid = '' else errid = @errorID
    reason = @getInformation('in_' + errid)
    reason = @reasons[errid] if reason is ''
    reason = 'SYSTEM ERROR'  if reason is ''
    obj =
      errorID: errid
      reason : reason

  logoff: ->
    menuCd = ''
    if Backbone.AUCNET.roleID in [ @LOGIN_ROLE_ADMIN, @LOGIN_ROLE_HAKEN ] or
       sessionStorage.getItem('management_or_normal') is '1'
      menuCd = 'adminlogin'
    else
      menuCd = 'login'

    #localStorage.clear()
    # ↑ページングや、表示切替の情報も消えてしまう為↓に修正
    localStorage.removeItem 'sessionID'
    localStorage.removeItem 'roleID'
    localStorage.removeItem 'hashUserID'
    Backbone.AUCNET.sessionid = ''
    Backbone.AUCNET.roleID = ''
    Backbone.AUCNET.hashUserID = ''
    Backbone.history.fragment = ''
    # urlからスキーマとホスト名を取得して飛ばす
    # 定数定義はサーバから取得しているのでサーバが死んでいる時にトップに戻せない。
    # もし管理者だったら管理者ログインに飛ばす
    if menuCd is "adminlogin"
      window.location.href = "#{window.location.protocol}//#{window.location.host}/login-admin.html"
    else
      window.location.href = "#{window.location.protocol}//#{window.location.host}/login.html"
