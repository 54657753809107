Application = require 'application'
View = require 'views/view'
Template = require 'views/templates/setting_credit'
UtilConfirmView = require 'views/util_confirm_view'

###
  [ユーザ設定画面] 与信ビュー
###
module.exports = class SettingCreditView extends View

  id: 'setting_credit'
  template: Template
  parent: undefined
  className: 'SettingCreditView'

  events:
    'click .updateCredit': 'onClickUpdateCredit'

  initialize: ->
    _.bindAll @
    @listenTo @model, 'change', @render

  afterRender: ->
    undefined

  getRenderData: ->
    modelData: @model.toJSON()

  onClickUpdateCredit: (param) =>
    credit = @$el.find('input[id=creditText]').val()
    if credit and credit.length > 0
      @model.updateCredit credit
