var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"lineConfig\"></div>\n<div class=\"instructions\">\n  <h3 style=\"padding-top:12px;\">与信設定</h3>\n  <div class=\"emailInpCont\">\n    <span class=\"text\">設定する与信額を入力してください</span>\n    <div class=\"flo\">\n      <input type=\"text\" placeholder=\"\" id=\"creditText\">\n      <button type=\"button\" class=\"save updateCredit\" data-label=\"lb_update_credit\"></button>\n    </div>\n    <div class=\"clear\"></div>\n  </div>\n  <div class=\"confMsg\">\n    <span class=\"text\">\n      "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.modelData : depth0)) != null ? stack1.creditMessage : stack1), depth0))
    + "\n    </span>\n  </div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}