View       = require 'views/view'
template   = require 'views/templates/request_page_base'

RequestConditionsBaseView     = require 'views/request_conditions_base_view'
RequestDataListBaseView       = require 'views/request_data_list_base_view'
CarDetaiBaselView = require 'views/car_detail_base_view'

###
 リクエスト画面
###
module.exports = class RequestPageView extends View
  id       : 'request_page'
  className : ''
  template : template
  _invNo   : undefined
  _pageNo  : 0

  initialize: ->
    # console.debug "RequestPageView#initialize"
    _.bindAll @
    # @requestConditionsBaseView = new RequestConditionsBaseView
    @requestDataListBaseView = new RequestDataListBaseView

  # レンダリング後処理
  beforeRender: ->
    #console.log "リクエストのレンダリング処理"
    #console.log @_pageNo

    @requestConditionsBaseView = new RequestConditionsBaseView
      pageNo : @_pageNo
    @_pageNo = 0
    @.listenTo @requestConditionsBaseView, 'request_conditions_select', @requestNoSelect
    # @requestDataListBaseView = new RequestDataListBaseView
    @.listenTo @requestDataListBaseView, 'precedingPage', @precedingPage


  suicide : ->
    @requestDataListBaseView?.suicide()
    @requestConditionsBaseView?.suicide()
    @.remove()

  # このビューがrender()された後に実行される
  # View#renderを参照のこと
  afterRender: ->
    # console.debug 'RequestPageView#afterRender'
    $('#request_conditions_base').show()

    # リクエスト条件
    @$el.append(@requestConditionsBaseView.render().el)

  # リクエスト条件一覧から選択されたリクエスト条件を元に検索を行う
  requestNoSelect: (requestNo,pageNo) ->
    # console.debug "RequestPageView#requestNoSelect #{requestNo}"
    #console.debug "リクエスト条件のページナンバーは #{pageNo}　です"
    # コンテンツの組み立て
    $('#request_conditions_base').hide()
    # リクエスト画面レンダリング
    @requestDataListBaseView.setRequestId(requestNo,pageNo)
    @$el.append(@requestDataListBaseView.render().el)

  precedingPage: (pageNo) ->
    #console.log "戻るが押されました"
    #console.log pageNo
    @_pageNo = pageNo
    #application.router.initial_display_screen_negolist = 'o'
    $(".requestBtn.menus_link").click()
