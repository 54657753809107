application = require 'application'
listener = require 'lib/listener'
View = require 'views/view'

###
  [管理者ログイン] ログインビュー
###
module.exports = class LoginView extends View

  id: 'adminLogin_view'
  events:
    'click #btnLogin': 'login'
    'keypress input[id=txtAdmin]': 'keypressAdminID'
    'keypress input[id=txtAdminPassword]': 'keypressAdminPassword'
    'keypress input[id=txtUser]': 'keypressUserID'

  initialize: ->
    _.bindAll @
    # モデルにイベントとコールバックを設定
    @listenTo Backbone.AUCNET.loginUser,'adminLogin_success0', @loginSuccess0
    @listenTo Backbone.AUCNET.loginUser,'adminLogin_success1', @loginSuccess1
    @listenTo Backbone.AUCNET.loginUser,'adminLogin_success2', @loginSuccess2
    @listenTo Backbone.AUCNET.loginUser,'adminLogin_failed1', @loginFailed1
    @listenTo Backbone.AUCNET.loginUser,'adminLogin_failed2', @loginFailed2
    @listenTo Backbone.AUCNET.loginUser,'adminLogin_failed3', @loginFailed3
    @listenTo Backbone.AUCNET.loginUser,'adminLogin_failed4', @loginFailed4
    @listenTo Backbone.AUCNET.loginUser,'adminLogin_failed5', @loginFailed5
    @listenTo Backbone.AUCNET.loginUser,'adminLogin_failed6', @loginFailed6

  afterRender: ->
    # ユーザID入力欄にフォーカスを合わせる
    _.defer =>
      @$el.find('input[id=txtAdmin]').focus()

  login: ->
    # 二度押しブロック
    $loginBtn = @$el.find('btnLogin')
    return if $loginBtn.prop('disabled')
    $loginBtn.prop 'disabled', true

    Backbone.AUCNET.loginUser.set
      _adminid: @$el.find('input[id=txtAdmin]').val()
      _adminpassword: @$el.find('input[id=txtAdminPassword]').val()
      _userid: @$el.find('input[id=txtUser]').val()
    Backbone.AUCNET.loginUser.authenticate()

  loginSuccess0 : (data = {}) ->
    # admin画面に遷移
    application.router._createInstance()
    application.router.navigate 'negolistadmin',true

  loginSuccess1 : (data = {}) ->
    # haken画面に遷移
    application.router._createInstance()
    application.router.navigate 'negolistadmin',true

  loginSuccess2 : (data = {}) ->
    # narisumasi画面に遷移
    application.router._createInstance()
    application.router.navigate 'negolist',true

  loginFailed1: ->
    # 二度押しブロック解除
    @$el.find('btnLogin').prop 'disabled', false
    @$el.find('input[id=txtUser]').val('')
    @$el.find('input[id=txtAdminPassword]').val('').focus()
    @showMsgBox('ms_loginFailed_L01', "ms_loginFailed_title")

  loginFailed2: ->
    # 二度押しブロック解除
    @$el.find('btnLogin').prop 'disabled', false
    @$el.find('input[id=txtAdminPassword]').val('').focus()
    @$el.find('input[id=txtUser]').val('')
    @showMsgBox('ms_loginFailed_L02', "ms_loginFailed_title")

  loginFailed3: ->
    # 二度押しブロック解除
    @$el.find('btnLogin').prop 'disabled', false
    @$el.find('input[id=txtUser]').val('')
    @$el.find('input[id=txtAdminPassword]').val('').focus()
    @showMsgBox('ms_loginFailed_L03', "ms_loginFailed_title")

  loginFailed4: ->
    # 二度押しブロック解除
    @$el.find('btnLogin').prop 'disabled', false
    @$el.find('input[id=txtUser]').val('')
    @$el.find('input[id=txtAdminPassword]').val('')
    @$el.find('input[id=txtAdmin]').val('').focus()
    @showMsgBox('ms_loginFailed_L04', "ms_loginFailed_title")

  loginFailed5: ->
    # 二度押しブロック解除
    @$el.find('btnLogin').prop 'disabled', false
    @$el.find('input[id=txtUser]').val('')
    @$el.find('input[id=txtAdminPassword]').val('').focus()
    @showMsgBox('ms_loginFailed_L06', "ms_loginFailed_title")

  loginFailed6: ->
    # 二度押しブロック解除
    @$el.find('btnLogin').prop 'disabled', false
    @$el.find('input[id=txtUser]').val('')
    @$el.find('input[id=txtAdminPassword]').val('')
    @$el.find('input[id=txtAdmin]').val('').focus()
    @showMsgBox('ms_loginFailed_L05', "ms_loginFailed_title")

  keypressUserID: (event) ->
   if event.keyCode is 13 # EnterKey
      event.preventDefault()
      @login()

  keypressAdminPassword: (event) ->
    if event.keyCode is 13 # EnterKey
      event.preventDefault()
      @$el.find('input[id=txtUser]').focus()

  keypressAdminID: (event) ->
    if event.keyCode is 13 # EnterKey
      event.preventDefault()
      @$el.find('input[id=txtAdminPassword]').focus()
