var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "    <button type='button' style=\"overflow: hidden; display: inline-block;\" class=\"prevCond\" id=\"nego_page_exit\">\n      <span class=\"icon\"></span>\n      <span href=\"#non\" class=\"mobile_button\">Back</span>\n    </button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"negotiationSliderOuter\">\n  <div class=\"negotiationSlider\" style=\"left: 0%;\">\n    <div class=\"cont nego\">\n      <h1 class=\"ptitle\">商談</h1>\n      <div class=\"negolist_item\"></div>\n      <div class=\"chatBox\"></div>\n      <div class=\"chooseMsgeBox\"></div>\n    </div>\n  </div>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.exitFlag : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}