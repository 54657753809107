View        = require 'views/view'
Template    = require 'views/templates/mylist_tab'
MylistModel = require 'models/mylist_model'

###
 [マイリスト] マイリスト・タブビュー
###
module.exports = class MylistTabView extends View
  id         : 'mylist_tab'
  template   : Template

  # 初期化
  initialize: ->
    _.bindAll @
    # マイリストモデル生成
    @mylistModel = new MylistModel

  suicide : ->
    @mylistModel?.destroy()
    @.remove()

  # 起動
  start: ->
    # マイリストモデルからの通知待ち
    @listenTo @mylistModel, 'GetMylistSetting', @render
    # マイリスト要素(A,B,C...)取得
    @mylistModel.getMylistSetting()
    # アクティブタグを非アクティブにする
    @$el.find('.mylist_tab_select').removeClass()

    # イベント設定
    @delegateEvents
      'click .mylist_tab_select' : 'fireChangeTab'

  # レンダリングデータ返却
  getRenderData: ->
    mylist : @mylistModel.getMylistItem()

  # レンダリング後処理
  afterRender: ->
    array = @mylistModel.getMylistItem()
    # 1件以上の車両情報が登録されているマイリストを検索
    for mylist in array
      if mylist.count > 0
        $(@$el.find("#mylist_tab_select_#{mylist.id}")).addClass('active')
        @trigger "ChangeMylistTab"
        return
    # 見つからない場合、先頭を選択状態に設定
    $(@$el.find('.mylist_tab_select')[0]).addClass('active')
    @trigger "ChangeMylistTab"

  # タブ切替
  fireChangeTab: (param) ->
    # タブのアクティブ、非アクティブ切替
    if $(param.currentTarget).hasClass('active')
      # マイリストから全タブがチェックされないという状況を作らないよう、最後のアクティブの際にはキャンセルする
      active_length = $.find('.mylist_tab_select.active')
      if active_length.length <= 1
        return false

      $(param.currentTarget).removeClass('active')
      @trigger "ChangeMylistTab", true
    else
      $(param.currentTarget).addClass('active')
      @trigger "ChangeMylistTab", true

    # @trigger "ChangeMylistTab"

  # マイリストID取得
  getMylistId: ->
    selectedItems = @$el.find('.mylist_tab_select')
    retArray = []
    for item in selectedItems
      if $(item).hasClass('active')
        retArray.push $(item).data('value')
      #if item.checked
      #  retArray.push item.value
    return retArray
