###
  お知らせ一覧表示
  お知らせコレクションを表示する
###
View       = require 'views/view'
Template   = require 'views/templates/notice_list'

NoticeCollection = require 'models/notice_collection'

module.exports = class NoticeListView extends View
  className  : 'notice_list'
  template   : Template
  collection : {}
  events :
    'click li' : 'onClickNoticeTitle'

  # 定数宣言
  CLASS_NAME = 'NoticeListView'  # クラス名
  EV_SHOW_NOTICE_DETAIL = 'ShowNoticeDetail'
  EV_SHOW_UNREAD_COUNT = 'ShowUnreadCount'
  EV_HIDE_NOTICE_BLOCK = 'HideNoticeBlock'
  FLAG_OFF = '0'

  initialize: ->
    funcName = 'initialize'
    #console.debug CLASS_NAME + '#' + funcName

    _.bindAll @
    @noticeCollection = new NoticeCollection []
    @listenTo @noticeCollection, 'reset', @render

  getRenderData: ->
    funcName = 'getRenderData'
    #console.debug CLASS_NAME + '#' + funcName

    list: @noticeCollection.toJSON()

  afterRender: ->
    funcName = 'afterRender'
    #console.debug CLASS_NAME + '#' + funcName

    # 未読件数設定
    unreadCount = 0
    len = @noticeCollection.length
    for i in [0...len]
      unreadCount++   if @noticeCollection.at(i).get('already_read_flg') is FLAG_OFF
    @trigger EV_SHOW_UNREAD_COUNT, unreadCount

    # 先頭のお知らせをデフォルト表示する
    if len is 0
      @trigger EV_HIDE_NOTICE_BLOCK
    else
      #console.debug "1件目を表示"
      @$el.find('li:first').click()
    
  # 全お知らせ一覧取得処理
  getAllNoticelist: ->
    funcName = 'getAllNoticelist'
    #console.debug CLASS_NAME + '#' + funcName
    
    @noticeCollection.getAllNoticelist()

  # お知らせタイトル押下処理
  onClickNoticeTitle: (ev) ->
    funcName = 'onClickNoticeTitle'
    #console.debug CLASS_NAME + '#' + funcName

    # 選択タイトルをアクティブにする
    $(ev.currentTarget).addClass("active").siblings().removeClass("active")
    
    # お知らせ詳細表示
    noticeDate = $(ev.currentTarget).find('.date').text()
    noticeTitle = $(ev.currentTarget).find('.notice_title').text()
    noticeDetail = $(ev.currentTarget).find('.notice_detail').val()
    @trigger EV_SHOW_NOTICE_DETAIL, [noticeDate, noticeTitle, noticeDetail]
    
    # お知らせ既読フラグ登録
    noticeNo = $(ev.currentTarget).find('.notice_no').val()
    @noticeCollection.registNoticeReadFlg(noticeNo)
