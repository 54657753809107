# checkBrowser.js
#
# 未対応ブラウザ向けに警告文を表示する。
# JFNのcheckBrowser.jsをcoffeeに改修

module.exports = do ->

  # HTML
  # 警告表示用HTML
  HTML = '
   <div class="notSupportedBrowser">
   <div class="notSupportedBrowser-bg"></div>
   <div class="notSupportedBrowser-main">
   <p class="notSupportedBrowser-p">新システムで強化されるセキュリティを利用する為、対応するブラウザが必要になります。<br>下記サイトより対応ブラウザをダウンロードしてください。</p>
   <ul class="notSupportedBrowser-list">
   <li class="notSupportedBrowser-item notSupportedBrowser-item-chrome"><a href="http://www.google.com/intl/ja/chrome/">Google Chrome</a></li>
   <!-- <li class="notSupportedBrowser-item notSupportedBrowser-item-firefox"><a href="http://www.mozilla.jp/firefox/">Mozilla Firefox</a></li> -->
   <li class="notSupportedBrowser-item notSupportedBrowser-item-ie9"><a href="http://windows.microsoft.com/ja-jp/internet-explorer/ie-10-worldwide-languages">Microsoft<br>Internet Explorer 10</a></li>
   <!-- <li class="notSupportedBrowser-item notSupportedBrowser-item-safari"><a href="http://www.apple.com/jp/safari/">Safari</a></li> -->
   </ul>
   <!-- /.notSupportedBrowser-main --></div>
   <!-- /.notSupportedBrowser --></div>'



  # NotSupported
  NotSupported = do ->
    FILE_NAME = "./uaFile.txt"
    ua = window.navigator.userAgent;
    #console.log "UA  :" + ua
    if (ua.indexOf("Chrome" ) isnt -1 or
        ua.indexOf("Safari" ) isnt -1 or
        ua.indexOf("Firefox") isnt -1 or
        ua.indexOf("IE 10"  ) isnt -1 or
        ua.indexOf("MSIE 10.0") isnt -1 or
        ua.indexOf("Trident/7") isnt -1 or
        ua.indexOf("iPad") isnt -1 or
        ua.indexOf("iPhone") isnt -1)
      return false;
    else
      return true;

  # SELF
  SELF = do ->

    hide: ->
      SELF.$widget.hide();

    setup: ->
      SELF.$widget = $(HTML);
      SELF.$bg    = SELF.$widget.find('.notSupportedBrowser-bg');
      SELF.$main  = SELF.$widget.find('.notSupportedBrowser-main');
      SELF.$close = SELF.$widget.find('.notSupportedBrowser-closeButton');
      $window = $(window);
      SELF.$bg.css({
        opacity: 0.65
      });

      # SELF.$close.on 'click', ->
      #   SELF.hide();
      #   # クローズ後にオールドタイプのサイトへリダイレクト
      #   # location.href="./oldIE/"

    # サポートしていない場合、0以外を返す
    fire: ->
      if (NotSupported)
        SELF.setup();
        $('body').append(SELF.$widget);
        return 1
      else
        return 0

  return SELF;

# これをどっかに入れて発火させる
# ↓
# notSupportedBrowser.fire();
