View       = require 'views/view'
template   = require 'views/templates/request_conditions_base'

UtilPagingView              = require 'views/util_paging_view'
RequestConditionsView       = require 'views/request_conditions_view'

RequestConditionsCollection = require 'models/request_conditions_collection'

###
 リクエスト画面
###
module.exports = class RequestConditionsBaseView extends View
  id        : 'request_conditions_base'
  template  : template
  _limit : undefined
  _page  : 0

  initialize: ->
    if @options.pageNo
      if @options.pageNo isnt 0
        @_page = @options.pageNo

    # console.debug "RequestConditionsBaseView#initialize"
    @utilPagingView = new UtilPagingView id : @id
    @listenTo @utilPagingView, 'paging', @paging_select

    @requestConditionsCollection = new RequestConditionsCollection()
    @requestConditionsCollection.setSocket @
    @.listenTo @requestConditionsCollection, 'get_request_conditions', @requestConditionsRender

    @requestConditionsView = new RequestConditionsView(collection:@requestConditionsCollection)

    _.bindAll @

  suicide : ->
    @utilPagingView?.remove()
    @requestConditionsCollection?.stopListening()
    @requestConditionsCollection?.remove()
    @requestConditionsView?.remove()
    @.remove()

  # このビューがrender()された後に実行される
  # View#renderを参照のこと
  afterRender: ->
    # console.debug 'RequestConditionsBaseView#afterRender'
    # コンテンツの組み立て
　　　　@delegateEvents
    　　'click    li.request_select'    : 'requestConditionsSelect'

    # ページングの表示件数を取得するためrender
    @$el.find('.bgDes')
      .append(@utilPagingView.el)
    $ele = @$el.find('.paging_disp_num')

    # 各ビューのレンダリング
    @utilPagingView.render()

    # リスト部分の描画
    @$el.append(@requestConditionsView.el)

    if @_page isnt 0
      @utilPagingView.page = @_page

    @requestConditionsCollection.get_request_conditions
      _limit : @utilPagingView.limit
      _page  : @utilPagingView.getOffset()

  requestConditionsRender: ->
    # console.debug 'RequestConditionsPageView#requestConditionsRender'
    # ページングの表示件数等を反映する為、再render

    @utilPagingView.setPagingData
      dataCount  : @requestConditionsCollection.where({model_id : 'request_conditions_key'}).length
    @utilPagingView.render()

    # リクエスト条件リスト
    @requestConditionsView.setCollection
      requestCollection : @requestConditionsCollection
    @requestConditionsView.render()

  paging_select: (page,limit) ->
    # @_limit = limit
    # @_page  = page
    @requestConditionsCollection.get_request_conditions
      _limit : @utilPagingView.limit
      _page : @utilPagingView.getOffset()

  requestConditionsSelect: (param)->
    $ele = $(param.currentTarget)
    return if $ele.data('hit_count') == 0
    @trigger 'request_conditions_select' , $ele.data('request_no'), @utilPagingView.page
