var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <span class='cildHistory'>\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.childHistory : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </span>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "    <button class='breadCrumbLink' id='"
    + alias2(alias1(depth0, depth0))
    + "' value='"
    + alias2(alias1(depth0, depth0))
    + "' data-label='lb_breadcrumb_"
    + alias2(alias1(depth0, depth0))
    + "' ></button><span class=\"bread_crumb_separator\">＞</span>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "  <button type='button' class='precedingPage' data-label='lb_search_result_back'></button>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"child_search_tool_area\">\r\n\r\n<h1 class=\"ptitleResult\" style=\"z-index: auto;\">\r\n<br>\r\n<span class=\"ResultTitle\"/>\r\n<span class='title_font' data-label=\"lb_search_result\"/>\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.breadCrumbFlag : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + " <div class=\"clear\"></div>\r\n</h1>\r\n</div>\r\n<div class=\"bgDes\">\r\n</div>\r\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}