var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing, alias5="function";

  return "\n<li class=\"negolist_item_"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.id : stack1), depth0))
    + " item block\" data-negoid=\""
    + alias2(((helper = (helper = helpers.nego_id || (depth0 != null ? depth0.nego_id : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"nego_id","hash":{},"data":data}) : helper)))
    + "\">\n"
    + ((stack1 = (helpers.firstItem || (depth0 && depth0.firstItem) || alias4).call(alias3,(depth0 != null ? depth0.list : depth0),{"name":"firstItem","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</li>\n\n<li class=\"negolist_item_"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.id : stack1), depth0))
    + " item line\" data-negoid=\""
    + alias2(((helper = (helper = helpers.nego_id || (depth0 != null ? depth0.nego_id : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"nego_id","hash":{},"data":data}) : helper)))
    + "\">\n"
    + ((stack1 = (helpers.firstItem || (depth0 && depth0.firstItem) || alias4).call(alias3,(depth0 != null ? depth0.list : depth0),{"name":"firstItem","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</li>\n\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing, alias5="function";

  return "    <div class=\"box\">\n      <label data-inventory_year=\""
    + alias2(alias1((depth0 != null ? depth0.inventory_year : depth0), depth0))
    + "\" data-inventory_no=\""
    + alias2(alias1((depth0 != null ? depth0.inventory_no : depth0), depth0))
    + "\" class=\"showdetail\">\n"
    + ((stack1 = (helpers.equal || (depth0 && depth0.equal) || alias4).call(alias3,(depth0 != null ? depth0.image : depth0),"",{"name":"equal","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "      </label>\n      <div class=\"paddedBox\">\n        <div class=\"notice "
    + alias2((helpers.getRibbonClass || (depth0 && depth0.getRibbonClass) || alias4).call(alias3,(depth0 != null ? depth0.nego_status : depth0),(depth0 != null ? depth0.hash_owner_member_cd : depth0),(depth0 != null ? depth0.hash_buyer_member_cd : depth0),{"name":"getRibbonClass","hash":{},"data":data}))
    + "\"><label class='noPointer' data-label=\""
    + alias2(((helper = (helper = helpers.nego_status_lb || (depth0 != null ? depth0.nego_status_lb : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"nego_status_lb","hash":{},"data":data}) : helper)))
    + "\" /></div>\n        <div class=\"carDetails\">\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isFine : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <!-- ファインのみ表示 -->\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isSFine : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <!-- ファインのみ表示 -->\n        <div>\n          <span data-label=\"lb_nego_inventory_no\" class=\"notBlock\"></span>\n          <span class=\"inf notBlockValue\">"
    + alias2(((helper = (helper = helpers.inventory_no || (depth0 != null ? depth0.inventory_no : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"inventory_no","hash":{},"data":data}) : helper)))
    + "</span>\n        </div>\n        <span class=\"manufact\">"
    + alias2(((helper = (helper = helpers.carname_full || (depth0 != null ? depth0.carname_full : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"carname_full","hash":{},"data":data}) : helper)))
    + "</span>\n        <span class=\"carName\">"
    + alias2(((helper = (helper = helpers.grade_name_full || (depth0 != null ? depth0.grade_name_full : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"grade_name_full","hash":{},"data":data}) : helper)))
    + "</span>\n        <div class=\"clear\"></div>\n      </div>\n      <div class=\"priceDetails\">\n        <div>\n"
    + ((stack1 = (helpers.isContract || (depth0 && depth0.isContract) || alias4).call(alias3,(depth0 != null ? depth0.nego_status : depth0),{"name":"isContract","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"past_only\" style=\"display:none;\">\n"
    + ((stack1 = (helpers.isContract || (depth0 && depth0.isContract) || alias4).call(alias3,(depth0 != null ? depth0.nego_status : depth0),{"name":"isContract","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.program(17, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"ongoing_only\" style=\"display:none;\">\n          <span data-label=\"lb_nego_owner_hope_price\"></span>\n"
    + ((stack1 = (helpers.isBigger || (depth0 && depth0.isBigger) || alias4).call(alias3,(depth0 != null ? depth0.owner_price : depth0),(depth0 != null ? depth0.owner_hope_price : depth0),{"name":"isBigger","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.program(24, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "        </div>\n      </div>\n      <div>\n        <div class=\"bubbleWrap fl\">\n          <div class=\"bubble"
    + alias2(((helper = (helper = helpers.need_answerer_kbn || (depth0 != null ? depth0.need_answerer_kbn : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"need_answerer_kbn","hash":{},"data":data}) : helper)))
    + " ongoing_only\" style=\"display:none;\">\n            "
    + alias2((helpers.getNegoMessageLabel || (depth0 && depth0.getNegoMessageLabel) || alias4).call(alias3,(depth0 != null ? depth0.last_message_cd : depth0),(depth0 != null ? depth0.last_message_price : depth0),(depth0 != null ? depth0.limit_hour : depth0),{"name":"getNegoMessageLabel","hash":{},"data":data}))
    + "\n          </div>\n          <div class=\"bubble"
    + alias2(alias1((depth0 != null ? depth0.send_role_kbn : depth0), depth0))
    + " past_only\" style=\"display:none;\">\n            <div data-label=\"lb_nego_end_date\"></div>\n            "
    + alias2(alias1((depth0 != null ? depth0.history_end_date : depth0), depth0))
    + "\n          </div>\n\n            <button type='button' class=\"negotiationBtnSmall\" id='"
    + alias2(((helper = (helper = helpers.nego_id || (depth0 != null ? depth0.nego_id : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"nego_id","hash":{},"data":data}) : helper)))
    + "|"
    + alias2(((helper = (helper = helpers.inventory_year || (depth0 != null ? depth0.inventory_year : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"inventory_year","hash":{},"data":data}) : helper)))
    + "|"
    + alias2(((helper = (helper = helpers.inventory_no || (depth0 != null ? depth0.inventory_no : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"inventory_no","hash":{},"data":data}) : helper)))
    + "'>\n              <span class=\"gavelIcon_center icon_margin\"></span>\n              <div class=\"negotiationBtnSmall_div\" data-label=\"lb_nego\"></div>\n            </button>\n\n        </div>\n        <div class=\"fl\" style=\"text-align: center;\">\n          <div class=\"timerWrap count_down_timer_"
    + alias2(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"id","hash":{},"data":data}) : helper)))
    + " ongoing_only\" style=\"display:none;\">\n            <div class=\"mask\">\n              <div class=\"timerNotice\">\n                <span data-label=\"lb_nego_remain_time\"></span><br />\n                <span class=\"remain_time_little\">&nbsp;&nbsp;&nbsp;</span>    <!-- 30分以内の場合\"<br>わずか！\"を付与する (段落を確保する為「&nbsp;」を付与)-->\n              </div>\n              <div class=\"timeLabel\">\n                <span data-label=\"lb_nego_timelimit\"></span><br>\n                <span class=\"limitTime\"></span>\n              </div>\n            </div>\n            <div class=\"timer\">\n              <div class=\"timerInner1\"></div>\n              <div class=\"timerInner2\"></div>\n            </div>\n            <div class=\"timerSecWrap\">\n              <div class=\"timerSecInner\">\n                <div class=\"timerSec1\"></div>\n                <div class=\"timerSec2\"></div>\n              </div>\n            </div>\n          </div>\n          "
    + ((stack1 = (helpers.getAgreementWrap || (depth0 && depth0.getAgreementWrap) || alias4).call(alias3,(depth0 != null ? depth0.nego_status : depth0),{"name":"getAgreementWrap","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        </div>\n      </div>\n      <div class=\"otherDetails\">\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.owner_member_cd : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <div>\n          <span data-label=\"lb_nego_model_year\"></span>\n          <span class=\"inf\">"
    + alias2(alias1((depth0 != null ? depth0.model_year : depth0), depth0))
    + "</span>\n        </div>\n        <div>\n          <span data-label=\"lb_nego_style\"></span>\n          <span class=\"inf\">"
    + alias2(((helper = (helper = helpers.style || (depth0 != null ? depth0.style : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"style","hash":{},"data":data}) : helper)))
    + "</span>\n        </div>\n        <div>\n          <span data-label=\"lb_nego_estimation\"></span>\n          <span class=\"inf\">"
    + ((stack1 = (helpers.editEstimationDisplay || (depth0 && depth0.editEstimationDisplay) || alias4).call(alias3,(depth0 != null ? depth0.estimation : depth0),(depth0 != null ? depth0.estimation_flag : depth0),{"name":"editEstimationDisplay","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n        </div>\n        <div>\n          <span data-label=\"lb_nego_mileage\"></span>\n          <span class=\"inf\">"
    + alias2(alias1((depth0 != null ? depth0.mileage : depth0), depth0))
    + alias2(((helper = (helper = helpers.no_mileage || (depth0 != null ? depth0.no_mileage : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"no_mileage","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isMeterChangeFlg : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</span>\n        </div>\n        <div>\n          <span data-label=\"lb_nego_inspect\"></span>\n          <span class=\"inf\">"
    + alias2(alias1((depth0 != null ? depth0.inspect_expire_date : depth0), depth0))
    + "</span>\n        </div>\n        <div>\n          <span data-label=\"lb_nego_color\"></span>\n          <span class=\"inf\">"
    + ((stack1 = (helpers.getColorLabel || (depth0 && depth0.getColorLabel) || alias4).call(alias3,(depth0 != null ? depth0.outcolor : depth0),{"name":"getColorLabel","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n        </div>\n      </div>\n    </div>\n    <div class=\"clear\"></div>\n    "
    + ((stack1 = (helpers.firstItemBtn || (depth0 && depth0.firstItemBtn) || alias4).call(alias3,(depths[1] != null ? depths[1].list : depths[1]),{"name":"firstItemBtn","hash":{},"data":data})) != null ? stack1 : "")
    + "\n  </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "          <img src=\"../images/noPhoto_s.jpg\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          "
    + ((stack1 = (helpers.outputImageForList || (depth0 && depth0.outputImageForList) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.image : depth0),{"name":"outputImageForList","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "          <span class=\"fine\" style=\"float:right;\"></span>\n        ";
},"9":function(container,depth0,helpers,partials,data) {
    return "          <span class=\"sfine\" style=\"float:right;\"></span>\n        ";
},"11":function(container,depth0,helpers,partials,data) {
    return "            <span data-label=\"lb_nego_result_price1\"></span>\n            <span class=\"ammount\">"
    + container.escapeExpression((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.result_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "            <span data-label=\"lb_nego_buyer_hope_price\"></span>\n            <span class=\"ammount buyer\">"
    + container.escapeExpression((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.buyer_hope_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "            <span data-label=\"lb_nego_owner_hope_price\"></span>\n            <span class=\"oldAmmount\">"
    + container.escapeExpression((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.owner_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.isBigger || (depth0 && depth0.isBigger) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.owner_price : depth0),(depth0 != null ? depth0.owner_hope_price : depth0),{"name":"isBigger","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <span data-label=\"lb_nego_owner_hope_price\"></span>\n            <span class=\"oldAmmount\">"
    + alias3((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || alias2).call(alias1,(depth0 != null ? depth0.owner_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>\n            <span class=\"ammount\">"
    + alias3((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || alias2).call(alias1,(depth0 != null ? depth0.owner_hope_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "            <span data-label=\"lb_nego_owner_hope_price\"></span>\n            <span class=\"ammount\">"
    + container.escapeExpression((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.owner_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>\n            <span class=\"oldAmmount nonTextDeco\">&nbsp;</span>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <span class=\"oldAmmount\">"
    + alias3((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || alias2).call(alias1,(depth0 != null ? depth0.owner_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>\n            <span class=\"ammount\">"
    + alias3((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || alias2).call(alias1,(depth0 != null ? depth0.owner_hope_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"ammount\">"
    + container.escapeExpression((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.owner_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>\n            <span class=\"oldAmmount nonTextDeco\">&nbsp;</span>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "        <div class=\"admin_only\" style=\"display:none;\">\n          <span data-label=\"lb_nego_buyer_member_cd\"></span>\n          <span class=\"inf\">"
    + alias2(alias1((depth0 != null ? depth0.buyer_member_cd : depth0), depth0))
    + "</span>\n        </div>\n        <div class=\"admin_only\" style=\"display:none;\">\n          <span data-label=\"lb_nego_owner_member_cd\"></span>\n          <span class=\"inf\">"
    + alias2(alias1((depth0 != null ? depth0.owner_member_cd : depth0), depth0))
    + "</span>\n        </div>\n";
},"28":function(container,depth0,helpers,partials,data) {
    return " $";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "    <div class=\"box\">\n     <div class=\"notice "
    + alias3((helpers.getRibbonClass || (depth0 && depth0.getRibbonClass) || alias2).call(alias1,(depth0 != null ? depth0.nego_status : depth0),(depth0 != null ? depth0.hash_owner_member_cd : depth0),(depth0 != null ? depth0.hash_buyer_member_cd : depth0),{"name":"getRibbonClass","hash":{},"data":data}))
    + "\">\n      <label class='noPointer' data-label=\""
    + alias3(((helper = (helper = helpers.nego_status_lb || (depth0 != null ? depth0.nego_status_lb : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"nego_status_lb","hash":{},"data":data}) : helper)))
    + "\" />\n     </div>\n      <div class=\"paddedBox\">\n        <div class=\"showdetail\" data-inventory_year=\""
    + alias3(((helper = (helper = helpers.inventory_year || (depth0 != null ? depth0.inventory_year : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"inventory_year","hash":{},"data":data}) : helper)))
    + "\" data-inventory_no=\""
    + alias3(((helper = (helper = helpers.inventory_no || (depth0 != null ? depth0.inventory_no : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"inventory_no","hash":{},"data":data}) : helper)))
    + "\">\n"
    + ((stack1 = (helpers.equal || (depth0 && depth0.equal) || alias2).call(alias1,(depth0 != null ? depth0.image : depth0),"",{"name":"equal","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"details\">\n          <div class=\"carDetails carNameSec\">\n            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isFine : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <!-- ファインのみ表示 -->\n            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isSFine : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <!-- ファインのみ表示 -->\n            <div>\n              <div class=\"nego_list_inventory_no_area\">\n                <span class=\"notBlock grey\" data-label='lb_data_line_list_header_3'>問合番号</span>\n                <span class=\"notBlockValue blue\">"
    + ((stack1 = ((helper = (helper = helpers.inventory_no || (depth0 != null ? depth0.inventory_no : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"inventory_no","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n              </div>\n              <div class=\"nego_list_sales_ammount_area\">\n                <div style=\" float: right;\">\n                  <div style=\" float: left;\">\n"
    + ((stack1 = (helpers.isContract || (depth0 && depth0.isContract) || alias2).call(alias1,(depth0 != null ? depth0.nego_status : depth0),{"name":"isContract","hash":{},"fn":container.program(39, data, 0),"inverse":container.program(41, data, 0),"data":data})) != null ? stack1 : "")
    + "                  </div>\n\n"
    + ((stack1 = (helpers.isContract || (depth0 && depth0.isContract) || alias2).call(alias1,(depth0 != null ? depth0.nego_status : depth0),{"name":"isContract","hash":{},"fn":container.program(43, data, 0),"inverse":container.program(45, data, 0),"data":data})) != null ? stack1 : "")
    + "                </div>\n              </div>\n            </div>\n            <div>\n              <div class=\"manufact_carName_area\">\n                <span class=\"notBlockValue manufact\">"
    + ((stack1 = ((helper = (helper = helpers.carname_full || (depth0 != null ? depth0.carname_full : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"carname_full","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n                <span class=\"notBlockValue carName\">"
    + ((stack1 = ((helper = (helper = helpers.grade_name_full || (depth0 != null ? depth0.grade_name_full : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"grade_name_full","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n              </div>\n              <div class=\"model_district_area\">\n                <div style=\" float: right;\">\n                  <div style=\" float: left;\">\n                    <span class=\"grey\" data-label='lb_data_line_list_header_9'>型式</span>\n                    <span class=\"carName\">"
    + ((stack1 = ((helper = (helper = helpers.style || (depth0 != null ? depth0.style : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"style","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n                  </div>\n                  <div style=\" float: left;\">\n                    <span class=\"grey\" data-label='lb_data_line_list_header_4'> 地区 </span>\n                    <span class=\"\">"
    + alias3(((helper = (helper = helpers.region_name || (depth0 != null ? depth0.region_name : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"region_name","hash":{},"data":data}) : helper)))
    + "</span>\n                  </div>\n                </div>\n              </div>\n            </div>\n\n          </div>\n          <div class=\"negotiationBtn\">\n            <button type='button' class=\"negotiationBtnSmall\" id='"
    + alias3(((helper = (helper = helpers.nego_id || (depth0 != null ? depth0.nego_id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"nego_id","hash":{},"data":data}) : helper)))
    + "|"
    + alias3(((helper = (helper = helpers.inventory_year || (depth0 != null ? depth0.inventory_year : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"inventory_year","hash":{},"data":data}) : helper)))
    + "|"
    + alias3(((helper = (helper = helpers.inventory_no || (depth0 != null ? depth0.inventory_no : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"inventory_no","hash":{},"data":data}) : helper)))
    + "'>\n              <span class=\"gavelIcon_center icon_margin\"></span>\n              <div class=\"negotiationBtnSmall_div\" data-label=\"lb_nego\"></div>\n            </button>\n          </div>\n          <div class=\"carSpec nego\">\n            <ul>\n              <li>\n                <div data-label='lb_data_line_list_header_5'>登録年</div>\n                <div data-label='lb_data_line_list_header_6'>モデル</div>\n                <div data-label='lb_data_line_list_header_13'>走行距離</div>\n                <div data-label='lb_data_line_list_header_14'>車検</div>\n                <div data-label='lb_data_line_list_header_15'>色</div>\n                <div data-label='lb_data_line_list_header_16'>色No</div>\n                <div data-label='lb_data_line_list_header_12'>シフト</div>\n                <div data-label='lb_data_line_list_header_24'>駆動方式</div>\n                <div data-label='lb_data_line_list_header_10'>排気量</div>\n                <div data-label='lb_data_line_list_header_11'>ガソリン</div>\n                <div data-label='lb_data_line_list_header_17'>書類</div>\n                <div data-label='lb_data_line_list_header_18'>装備</div>\n                <div data-label='lb_data_line_list_header_19'>評価点</div>\n                <div data-label='lb_data_line_list_header_20'>外装</div>\n                <div data-label='lb_data_line_list_header_21'>内装</div>\n              </li>\n              <li>\n                <div>"
    + alias3(((helper = (helper = helpers.model_year || (depth0 != null ? depth0.model_year : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"model_year","hash":{},"data":data}) : helper)))
    + "</div>\n                <div>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isImportedCar : depth0),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n                <div>"
    + alias3(((helper = (helper = helpers.mileage || (depth0 != null ? depth0.mileage : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"mileage","hash":{},"data":data}) : helper)))
    + alias3(((helper = (helper = helpers.no_mileage || (depth0 != null ? depth0.no_mileage : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"no_mileage","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMeterChangeFlg : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n                <div>"
    + alias3(((helper = (helper = helpers.inspect_expire_date || (depth0 != null ? depth0.inspect_expire_date : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"inspect_expire_date","hash":{},"data":data}) : helper)))
    + "</div>\n                <div>"
    + ((stack1 = (helpers.getColorLabel || (depth0 && depth0.getColorLabel) || alias2).call(alias1,(depth0 != null ? depth0.outcolor : depth0),{"name":"getColorLabel","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n                <div>"
    + ((stack1 = ((helper = (helper = helpers.outcolor_no || (depth0 != null ? depth0.outcolor_no : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"outcolor_no","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n                <div>"
    + alias3(((helper = (helper = helpers.shift || (depth0 != null ? depth0.shift : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"shift","hash":{},"data":data}) : helper)))
    + "</div>\n                <div>"
    + alias3(((helper = (helper = helpers.drive_cd || (depth0 != null ? depth0.drive_cd : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"drive_cd","hash":{},"data":data}) : helper)))
    + "</div>\n                <div>"
    + alias3(((helper = (helper = helpers.exhaust || (depth0 != null ? depth0.exhaust : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"exhaust","hash":{},"data":data}) : helper)))
    + "</span></div>\n                <div>"
    + alias3(((helper = (helper = helpers.fuel_cd || (depth0 != null ? depth0.fuel_cd : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"fuel_cd","hash":{},"data":data}) : helper)))
    + "</div>\n                <div>\n                  <span style=\"float:left;\" class=\"mini_document_flag_"
    + alias3(((helper = (helper = helpers.one_owner || (depth0 != null ? depth0.one_owner : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"one_owner","hash":{},"data":data}) : helper)))
    + "\"></span>\n                  <span style=\"float:left;\" class=\"mini_document_flag_"
    + alias3(((helper = (helper = helpers.gurantee || (depth0 != null ? depth0.gurantee : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"gurantee","hash":{},"data":data}) : helper)))
    + "\"></span>\n                  <span style=\"float:left;\" class=\"mini_document_flag_"
    + alias3(((helper = (helper = helpers.manual || (depth0 != null ? depth0.manual : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"manual","hash":{},"data":data}) : helper)))
    + "\"></span>\n                </div>\n                <div>\n                  <span style=\"float:left;\" class=\"notBlock mini_equipment_flag_navi"
    + alias3(((helper = (helper = helpers.navi || (depth0 != null ? depth0.navi : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"navi","hash":{},"data":data}) : helper)))
    + "\"></span>\n                  <span style=\"float:left;\" class=\"notBlock mini_equipment_flag_sunroof"
    + alias3(((helper = (helper = helpers.sunroof || (depth0 != null ? depth0.sunroof : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"sunroof","hash":{},"data":data}) : helper)))
    + "\"></span>\n                  <span style=\"float:left;\" class=\"notBlock mini_equipment_flag_leather_seat"
    + alias3(((helper = (helper = helpers.leather_seat || (depth0 != null ? depth0.leather_seat : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"leather_seat","hash":{},"data":data}) : helper)))
    + "\"></span>\n                </div>\n                <div>"
    + ((stack1 = (helpers.editEstimationDisplay || (depth0 && depth0.editEstimationDisplay) || alias2).call(alias1,(depth0 != null ? depth0.estimation : depth0),(depth0 != null ? depth0.estimation_flag : depth0),{"name":"editEstimationDisplay","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n                <div>"
    + ((stack1 = (helpers.editEstimationDisplay || (depth0 && depth0.editEstimationDisplay) || alias2).call(alias1,(depth0 != null ? depth0.outward_value : depth0),(depth0 != null ? depth0.estimation_flag : depth0),{"name":"editEstimationDisplay","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n                <div>"
    + ((stack1 = (helpers.editEstimationDisplay || (depth0 && depth0.editEstimationDisplay) || alias2).call(alias1,(depth0 != null ? depth0.upholstery_value : depth0),(depth0 != null ? depth0.estimation_flag : depth0),{"name":"editEstimationDisplay","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n              </li>\n            </ul>\n          </div>\n          <div class=\"clear\"></div>\n        </div>\n      </div>\n      <div class=\"clear\"></div>\n    </div>\n";
},"31":function(container,depth0,helpers,partials,data) {
    return "        <div><img src=\"../images/noPhoto_s.jpg\"></div>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        "
    + ((stack1 = (helpers.outputImageForList || (depth0 && depth0.outputImageForList) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.image : depth0),{"name":"outputImageForList","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        </label>\n";
},"35":function(container,depth0,helpers,partials,data) {
    return "<span class=\"fine\"></span>";
},"37":function(container,depth0,helpers,partials,data) {
    return "<span class=\"sfine\"></span>";
},"39":function(container,depth0,helpers,partials,data) {
    return "                      <span class=\"grey\" data-label=\"lb_nego_result_price1\"></span>\n                      <span class=\"ammount red\">"
    + container.escapeExpression((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.result_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>\n";
},"41":function(container,depth0,helpers,partials,data) {
    return "                      <span class=\"grey\" data-label=\"lb_nego_buyer_hope_price\"></span>\n                      <span class=\"ammount green\">"
    + container.escapeExpression((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.buyer_hope_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>\n";
},"43":function(container,depth0,helpers,partials,data) {
    return "                  <div style=\" float: left;\">\n                    <span class=\"grey\" data-label='lb_nego_owner_hope_price'></span>\n                      <span class=\"oldAmmount\" style=\"text-decoration:line-through;color:red;\">\n                        "
    + container.escapeExpression((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.owner_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>\n                  </div>\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  <div style=\" float: left;\">\n                    <span class=\"grey\" data-label='lb_nego_owner_hope_price'></span>\n"
    + ((stack1 = (helpers.isBigger || (depth0 && depth0.isBigger) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.owner_price : depth0),(depth0 != null ? depth0.owner_hope_price : depth0),{"name":"isBigger","hash":{},"fn":container.program(46, data, 0),"inverse":container.program(48, data, 0),"data":data})) != null ? stack1 : "")
    + "                  </div>\n";
},"46":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                      <span class=\"oldAmmount\" style=\"text-decoration:line-through;color:red;\">\n                        "
    + alias3((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || alias2).call(alias1,(depth0 != null ? depth0.owner_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "\n                      </span>\n                      <span class=\"ammount red\">\n                        "
    + alias3((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || alias2).call(alias1,(depth0 != null ? depth0.owner_hope_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "\n                      </span>\n";
},"48":function(container,depth0,helpers,partials,data) {
    return "                      <span class=\"ammount red\">"
    + container.escapeExpression((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.owner_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>\n";
},"50":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.model || (depth0 != null ? depth0.model : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"model","hash":{},"data":data}) : helper)));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.list : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}