# 商談で使用するメッセージ
module.exports = [
  # 空文字
  {message_cd : "EMPTY" , hasprice : false , message_btn_label : "" , messagelabel : ""}
  # 落札希望店::商談申込
  {message_cd : "K01" , hasprice : true , message_btn_label : "lb_btn_nego01" , messagelabel : "ms_nego_buyer_start"}
  # 出品店::商談可価格提示
  {message_cd : "T04" , hasprice : true , message_btn_label : "lb_btn_nego09" , messagelabel : "ms_nego_owner_price_down"}
  # 出品店::商談可最終価格提示
  {message_cd : "T05" , hasprice : true , message_btn_label : "lb_btn_nego10" , messagelabel : "ms_nego_owner_price_last"}
  # 出品店::商談可即決成約
  {message_cd : "T03" , hasprice : false, message_btn_label : "lb_btn_nego08" , messagelabel : "ms_nego_owner_contracts_speedy"}
  # 出品店::商談不可在庫なし
  {message_cd : "T01" , hasprice : false, message_btn_label : "lb_btn_nego06" , messagelabel : "ms_nego_owner_cancel_no_stock"}
  # 出品店::商談不可小売商談中
  {message_cd : "T02" , hasprice : false, message_btn_label : "lb_btn_nego07" , messagelabel : "ms_nego_owner_cancel_retail"}
  # 落札希望店::価格提示
  {message_cd : "K03" , hasprice : true , message_btn_label : "lb_btn_nego03" , messagelabel : "ms_nego_buyer_price_up_again"}
  # 落札希望店::最終価格提示
  {message_cd : "K04" , hasprice : true , message_btn_label : "lb_btn_nego04" , messagelabel : "ms_nego_buyer_price_last"}
  # 落札希望店::即決
  {message_cd : "K02" , hasprice : false, message_btn_label : "lb_btn_nego02" , messagelabel : "ms_nego_buyer_contracts_speedy"}
  # 落札希望店::流札
  {message_cd : "K05" , hasprice : false, message_btn_label : "lb_btn_nego05" , messagelabel : "ms_nego_buyer_flow"}

  # 出品店::価格提示
  {message_cd : "T07" , hasprice : true , message_btn_label : "lb_btn_nego12" , messagelabel : "ms_nego_owner_price_down_again"}
  # 出品店::最終価格提示
  {message_cd : "T08" , hasprice : true , message_btn_label : "lb_btn_nego13" , messagelabel : "ms_nego_owner_price_last"}
  # 出品店::即決
  {message_cd : "T06" , hasprice : false, message_btn_label : "lb_btn_nego11" , messagelabel : "ms_nego_owner_contracts_speedy"}
  # 出品店::流札
  {message_cd : "T09" , hasprice : false, message_btn_label : "lb_btn_nego14" , messagelabel : "ms_nego_owner_flow"}
  # 管理者::キャンセル（商談申込中)
  {message_cd : "A01" , hasprice : false, message_btn_label : ""              , messagelabel : "ms_nego_admin_request_cancel"}
  # 管理者::キャンセル（商談中）
  {message_cd : "A02" , hasprice : false, message_btn_label : ""              , messagelabel : "ms_nego_admin_ongoing_cancel"}
  # 管理者::期限切れ流札（申込中）
  {message_cd : "A03" , hasprice : false, message_btn_label : ""              , messagelabel : "ms_nego_admin_request_time_out"}
  # 管理者::期限切れ流札（商談中）
  {message_cd : "A04" , hasprice : false, message_btn_label : ""              , messagelabel : "ms_nego_admin_ongoing_time_out"}

  # 短縮系メッセージの定義
  # 落札希望店::商談申込
  {message_cd : "K01" , hasprice : true , message_btn_label : "lb_btn_nego01" , messagelabel : "ms_short_nego_buyer_start" , short_message_kbn : "short"}
  # 出品店::商談不可在庫なし
  {message_cd : "T01" , hasprice : false, message_btn_label : "lb_btn_nego06" , messagelabel : "ms_short_nego_owner_cancel_no_stock" , short_message_kbn : "short"}
  # 出品店::商談不可小売商談中
  {message_cd : "T02" , hasprice : false, message_btn_label : "lb_btn_nego07" , messagelabel : "ms_short_nego_owner_cancel_retail" , short_message_kbn : "short"}
  # 出品店::商談可即決成約
  {message_cd : "T03" , hasprice : false, message_btn_label : "lb_btn_nego08" , messagelabel : "ms_short_nego_owner_contracts_speedy" , short_message_kbn : "short"}
  # 出品店::商談可価格提示
  {message_cd : "T04" , hasprice : true , message_btn_label : "lb_btn_nego09" , messagelabel : "ms_short_nego_owner_price_down" , short_message_kbn : "short"}
  # 出品店::商談可最終価格提示
  {message_cd : "T05" , hasprice : true , message_btn_label : "lb_btn_nego10" , messagelabel : "ms_short_nego_owner_price_last" , short_message_kbn : "short"}
  # 落札希望店::即決
  {message_cd : "K02" , hasprice : false, message_btn_label : "lb_btn_nego02" , messagelabel : "ms_short_nego_buyer_contracts_speedy" , short_message_kbn : "short"}
  # 落札希望店::価格提示
  {message_cd : "K03" , hasprice : true , message_btn_label : "lb_btn_nego03" , messagelabel : "ms_short_nego_buyer_price_up_again" , short_message_kbn : "short"}
  # 落札希望店::最終価格提示
  {message_cd : "K04" , hasprice : true , message_btn_label : "lb_btn_nego04" , messagelabel : "ms_short_nego_buyer_price_last" , short_message_kbn : "short"}
  # 落札希望店::流札
  {message_cd : "K05" , hasprice : false, message_btn_label : "lb_btn_nego05" , messagelabel : "ms_short_nego_buyer_flow" , short_message_kbn : "short"}
  # 出品店::即決
  {message_cd : "T06" , hasprice : false, message_btn_label : "lb_btn_nego11" , messagelabel : "ms_short_nego_owner_contracts_speedy" , short_message_kbn : "short"}
  # 出品店::価格提示
  {message_cd : "T07" , hasprice : true , message_btn_label : "lb_btn_nego12" , messagelabel : "ms_short_nego_owner_price_down_again" , short_message_kbn : "short"}
  # 出品店::最終価格提示
  {message_cd : "T08" , hasprice : true , message_btn_label : "lb_btn_nego13" , messagelabel : "ms_short_nego_owner_price_last" , short_message_kbn : "short"}
  # 出品店::流札
  {message_cd : "T09" , hasprice : false, message_btn_label : "lb_btn_nego14" , messagelabel : "ms_short_nego_owner_flow" , short_message_kbn : "short"}
  # 管理者::キャンセル（商談申込中)
  {message_cd : "A01" , hasprice : false, message_btn_label : ""              , messagelabel : "ms_short_nego_admin_request_cancel" , short_message_kbn : "short"}
  # 管理者::キャンセル（商談中）
  {message_cd : "A02" , hasprice : false, message_btn_label : ""              , messagelabel : "ms_short_nego_admin_ongoing_cancel" , short_message_kbn : "short"}
  # 管理者::期限切れ流札（商談中）
  {message_cd : "A03" , hasprice : false, message_btn_label : ""              , messagelabel : "ms_short_nego_admin_request_time_out" , short_message_kbn : "short"}
  # 管理者::期限切れ流札（商談中）
  {message_cd : "A04" , hasprice : false, message_btn_label : ""              , messagelabel : "ms_short_nego_admin_ongoing_time_out" , short_message_kbn : "short"}
  # APIerrorCD
  
]
