application = require 'application'
setting     = require 'setting'
notSupportedBrowser = require "lib/check_browser"

$ ->
  # ブラウザチェック
  notSupport = notSupportedBrowser.fire()
  if notSupport
    return false

  Backbone.AUCNET ?= {}

  setting.initialize (result) ->
    application.initialize()

    Backbone.history.start
      pushState: false

    # サロゲートペア対応
    String.prototype.getFullCharLength = ->
      @length - @split(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g).length + 1
