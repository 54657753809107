View        = require 'views/view'
template    = require 'views/templates/data_line_header'

###
 [検索]
###
module.exports = class DataLineHeaderView extends View
  template : template

  initialize: ->
    _.bindAll @

  afterRender: ->
