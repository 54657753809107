require 'lib/view_helper'

# Base class for all views.
module.exports = class View extends Backbone.View

  template: ->
    return

  getRenderData: ->
    return

  render:  ->
    @beforeRender()
    @$el.html @template @getRenderData()
    @afterRender()
    @drawLabel()
    this

  beforeRender : ->
    return

  afterRender: ->
    return

  showConfirmationMsg:(message)->
    confirm message


  ###
   言語の取得
  ###
  # ラベル
  getLabel: (labelid) ->
    label = Backbone.AUCNET.label.get labelid
    unless label?
      #console.error 'ラベルが宣言されていません[%s]', labelid
      label = ''
    return label
  # インフォメーション
  getInformation: (informationid) ->
    info = Backbone.AUCNET.info.get informationid
    unless info?
      #console.error 'インフォメーションが定義されていません[%s]', informationid
      info = ''
    return info
  # メッセージ
  getMessage: (messageid) ->
    msg = Backbone.AUCNET.message.get messageid
    unless msg?
      #console.error 'メッセージが定義されていません[%s]', messageid
      msg = ''
    return msg

  showMsgBox: (messageid, messeageTitle) ->
    title = if (temp = @getMessage messeageTitle)? and temp isnt "" then temp else @getMessage "ms_util_alert_title"
    UtilConfirmView = require './util_confirm_view'
    utilConfirmView = new UtilConfirmView
      title   : title
      message : @getMessage messageid
      cancelDelFlg: true
      callback : (ok) =>
        ;
    $('body').append utilConfirmView.render().el


  drawLabel: ->
    _this = @
    # 多言語対応
    @$el.find('[data-label]').each ->
      $ele = $(@)
      label = _this.getLabel $ele.data('label')
      $ele
        .text(label)
        .attr('placeholder', label)

  #自分自身を削除する。
  suicide: ->
    @.remove()


  hoverStart : (param) ->
    #console.log 'hoverStart'
    $(param.currentTarget).addClass "over_start"
    
  hoverEnd : (param) ->
    #console.log 'hoverEnd'
    $(param.currentTarget).removeClass "over_start"

  hoverStartMobile : (param) ->
    #console.log 'hoverStart'
    $(param.currentTarget).addClass "over_start_mobile"

  hoverEndMobile : (param) ->
    #console.log 'hoverEnd'
    $(param.currentTarget).removeClass "over_start_mobile"

#
# GETパラメータ取得
#
  getUrlVars : ->
    vars = []
    hash = undefined
    hashes = window.location.href.slice(window.location.href.indexOf("?") + 1).split("&")
    i = 0
    while i < hashes.length
      hash = hashes[i].split("=")
      vars.push hash[1]
      #console.log vars
      i++
    return vars

