var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "    <li class=\"nego_list_"
    + alias2(alias1((depth0 != null ? depth0.send_role_kbn : depth0), depth0))
    + " chatBubble"
    + alias2(alias1((depth0 != null ? depth0.send_role_kbn : depth0), depth0))
    + "\">\n      "
    + alias2(alias1((depth0 != null ? depth0.message : depth0), depth0))
    + "\n      <span>\n        "
    + ((stack1 = (helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.send_date : depth0),{"name":"formatDate","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        <labal data-label=\""
    + alias2(alias1((depth0 != null ? depth0.lb_send_role_kbn : depth0), depth0))
    + "\"></label>\n      </span>\n    </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <li class=\"nego_list_3 "
    + alias4(((helper = (helper = helpers.systemMessageClass || (depth0 != null ? depth0.systemMessageClass : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"systemMessageClass","hash":{},"data":data}) : helper)))
    + "\" >\n      "
    + alias4(((helper = (helper = helpers.systemMessage || (depth0 != null ? depth0.systemMessage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"systemMessage","hash":{},"data":data}) : helper)))
    + "\n      </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "      <li class=\"nego_list_3 chatBubble4\" >\n      "
    + container.escapeExpression(((helper = (helper = helpers.systemMessage || (depth0 != null ? depth0.systemMessage : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"systemMessage","hash":{},"data":data}) : helper)))
    + "\n      <span>\n        "
    + ((stack1 = (helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.agreeMessageTime : depth0),{"name":"formatDate","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        <labal data-label=\"lb_send_role_kbn_3\"></label>\n      </span>\n      </li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<ul class=\"scrollable\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.messages : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.systemMessageFlag : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.agreeFlag : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <div class=\"nego_list_preview_block\"></div>\n</ul>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}