Model        = require 'models/model'

###
 監視コントローラーからのメッセージ受信モデル
###
module.exports = class ControlReceiver extends Model

  socket: undefined

  # クラス名
  CLASS_NAME = 'ControlReceiver'

  initialize: ->
    _.bindAll @
    funcName = 'initialize'
    @socket = Backbone.AUCNET.socket
    @socket.on 'ReceiveControllerMessage', (d) =>
      data = JSON.parse d
      if data.controller_message?  && data.ret?
        @trigger data.controller_message, data.ret
