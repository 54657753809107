Application = require 'application'
View        = require 'views/view'
Template    = require 'views/templates/mail_activation'
MESSAGE = require 'data/messages'
util = require 'lib/util'
###
 [認証]メール認証画面
###
module.exports = class MailActivationView extends View
  id          : 'mail_activation'
  template    : Template

  className   : 'act_mail'

  events :
    "click button.toNegoApply" : "moveToNegoApply"

  # 初期化
  initialize: ->
    _.bindAll @

  getRenderData: =>
    #console.debug @options.message
    message       : @options.message

  afterRender: ->
    wHeight = $(window).height()
    $(".act_mail").css "height", wHeight + "px"
    #console.log "mailactiaaaa : " + @options.negoApplyParam
    #console.log "mailactiaaaa dotti: " + _.isEmpty(@options.negoApplyParam)
    unless _.isEmpty(@options.negoApplyParam)
      @$el.append('<button type="button" class="toNegoApply flash animated" data-label="lb_to_nego_apply"></button>')

  moveToNegoApply: ->
    # クエリ取得
    invYear = @options.negoApplyParam[1]
    invNo = @options.negoApplyParam[2]
    #console.debug "inventoryYear: " + invYear
    #console.debug "inventoryNo : " + invNo
    Backbone.AUCNET.RenderController.renderNegoApply [{inventoryYear: invYear, inventoryNo: invNo}]
    @$(".settingMail").remove('<button type="button" class="toNegoApply flash animated" data-label="lb_to_nego_apply"></button>')
  # locationからにせクエリを取得
  # http://localhost/#fragment?q=hoge&r=fuga
  # の場合 {q:hoge,r:fuga}をかえす
  getQueryFromUri: ->
    fragment = location.hash
    query = fragment.split("?")?[1] || ""
    param = util.getUrlParamsJson query
    return param
