Collection   = require 'models/collection'
IndicatorView   = require 'views/indicator_view'
###
 商談のログ
###
module.exports = class Message extends Collection

  socket: null

  # 商談ID
  negoID        : null
  inventoryYear : null
  inventoryNo   : null
  indicator     : null
  initialize: =>
    _.bindAll @
    @socket = Backbone.AUCNET.socket

    # 全てのメッセージ受け取り
    @socket.removeAllListeners 'GetNegoMessagesAll'
    @socket.on 'GetNegoMessagesAll', (d)=>
      data = JSON.parse d

      return if data.negoID        isnt @negoID
      return if data.inventoryYear isnt @inventoryYear
      return if data.inventoryNo   isnt @inventoryNo
      messageList = data.messagelist
      negoInfo    = data.negoinfo
      @reset messageList

    # 新着メッセージ受け取り
    @socket.removeAllListeners 'GetNegoMessage'
    @socket.on 'GetNegoMessage', (d)=>
      data = JSON.parse d
      @add data, {silent : true}

      # ソート条件設定
      @comparator = (model) ->
        model.get "send_date"
      @sort()

    # メッセージ送信成否
    @socket.removeAllListeners 'SetNegoMessage'
    @socket.on 'SetNegoMessage', (d)=>
      @indicator.suicide()
      data = JSON.parse d
      @trigger "receiveSetNegoMessageResult", data

  # すべてのメッセージを取得
  getMessageAll: ->
    @socket.emit 'GetNegoMessagesAll',
      nego_id : @negoID
      inventory_year : @inventoryYear
      inventory_no   : @inventoryNo

  # メッセージを登録
  setNegoMessage: (json, indicator) =>
    @indicator = indicator
    @socket.emit 'SetNegoMessage',
      nego_id        : @negoID
      inventory_year : @inventoryYear
      inventory_no   : @inventoryNo
      message_kbn    : json.message_kbn
      message_cd     : json.message_cd
      message_price  : json.message_price
