Model        = require 'models/model'

###
 [マイリスト] マイリストモデル
###
module.exports = class MylistModel extends Model

  # 初期化
  initialize: ->
    _.bindAll @
    # ソケット設定
    @socket = Backbone.AUCNET.socket

  # マイリスト設定取得
  getMylistSetting: ->
    # コールバック登録
    @socket.on 'GetMylistSetting', (d) =>
      # コールバック解除
      @socket.removeAllListeners 'GetMylistSetting'
      # 結果格納
      @mylistItem = JSON.parse(d)
      # 通知
      @trigger 'GetMylistSetting'

    # G/Wに送信
    @socket.emit 'GetMylistSetting',
      socketid   : Backbone.AUCNET.loginUser.get('_socketid')

  # マイリストアイテム取得
  getMylistItem: ->
    return @mylistItem
