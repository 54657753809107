var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"sliderOuter\">\n  <div class=\"slider\">\n    <div class=\"cont\">\n      <h1 class=\"ptitle\" >\n        <span data-label=\"lb_negolistadmin\" class=\"ptitle_title\"></span>\n        <div id='negolist_child_search_tool_area'></div>\n      </h1>\n\n      <div class=\"negotiationSliderOuter scrollable-x-only\">\n        <div class=\"negotiationSlider\">\n          <div id='negolist_child_area'>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div id=\"detail_container\"></div>\n  </div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}