var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<li>\n  <div class=\"date\">"
    + alias2(alias1((depth0 != null ? depth0.open_date : depth0), depth0))
    + "</div>\n  <div class=\"notice_title\">"
    + alias2(alias1((depth0 != null ? depth0.notice_title : depth0), depth0))
    + "</div>\n  <input type=\"hidden\" class=\"already_read_flg\" value=\""
    + alias2(alias1((depth0 != null ? depth0.already_read_flg : depth0), depth0))
    + "\"></input>\n  <input type=\"hidden\" class=\"notice_no\" value=\""
    + alias2(alias1((depth0 != null ? depth0.notice_no : depth0), depth0))
    + "\"></input>\n  <input type=\"hidden\" class=\"notice_detail\" value=\""
    + alias2(alias1((depth0 != null ? depth0.notice_detail : depth0), depth0))
    + "\"></input>\n</li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.list : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}