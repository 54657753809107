var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"sliderOuter\">\n  <div class=\"slider\">\n    <div class=\"cont\">\n      <DIV id=\"newsPageView\">\n        <button type='button' id=\"btnRefresh\" data-label=\"lb_refresh\"></button>\n        <label id=\"newDisplay\" data-label=\"lb_new_display\"></label>\n        <h1 class=\"ptitle\">\n          <div data-label=\"lb_notice\"></div>\n          <div class=\"unreadCount\"></div>\n        </h1>\n        <div class=\"notification\">\n          <ul id=\"noticeListView\" class=\"msgList scrollable\"></ul>\n          <div class=\"msgDetails scrollable\">\n            <div class=\"date\">2013/08/01 10:15:30</div>\n            <div>\n              <h2 class=\"notice_title\" data-label=\"lb_notice_detail\"></h2>\n              <p class=\"notice_detail\"></p>\n            </div>\n          </div>\n        </div>\n\n        <div class=\"negoSell\">\n          <h1 data-label=\"lb_negolist_my_new_sell\" class=\"ptitle\"></h1>\n          <ul class=\"listType1\"></ul>\n        </div>\n\n        <div class=\"negoBuy\">\n          <h1 data-label=\"lb_negolist_my_new_buy\" class=\"ptitle\"></h1>\n          <ul class=\"listType1\"></ul>\n        </div>\n      </DIV>\n    </div>\n    <div id=\"detail_container\"></div>\n  </div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}