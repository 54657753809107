var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    "
    + ((stack1 = (helpers.outputCarStatusIcon || (depth0 && depth0.outputCarStatusIcon) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.status : depth0),{"name":"outputCarStatusIcon","hash":{},"data":data})) != null ? stack1 : "")
    + "\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          "
    + ((stack1 = (helpers.getApplyBtnForCandidate || (depth0 && depth0.getApplyBtnForCandidate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.car_data24 : depth0),(depth0 != null ? depth0.inventory_year : depth0),(depth0 != null ? depth0.inventory_no : depth0),(depth0 != null ? depth0.carName : depth0),(depth0 != null ? depth0.gradeName : depth0),(depth0 != null ? depth0.imagePath : depth0),{"name":"getApplyBtnForCandidate","hash":{},"data":data})) != null ? stack1 : "")
    + "\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "        <img src=\"../images/noPhoto_l.jpg\">\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        "
    + ((stack1 = (helpers.outputImageForAlbum || (depth0 && depth0.outputImageForAlbum) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.image : depth0),{"name":"outputImageForAlbum","hash":{},"data":data})) != null ? stack1 : "")
    + "\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    return " <span class=\"fine\"  style=\"float:right;\"></span>";
},"13":function(container,depth0,helpers,partials,data) {
    return "<span class=\"sfine\" style=\"float:right;\"></span>";
},"15":function(container,depth0,helpers,partials,data) {
    return " $";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<li class=\"item block\">\r\n  <div class=\"box\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.checkbox_flag : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "    <div class=\"paddedBox\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.checkbox_flag : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      <div class=\"car_thumbnail\" id=\""
    + alias4(((helper = (helper = helpers.inventory_year || (depth0 != null ? depth0.inventory_year : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inventory_year","hash":{},"data":data}) : helper)))
    + "|"
    + alias4(((helper = (helper = helpers.inventory_no || (depth0 != null ? depth0.inventory_no : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inventory_no","hash":{},"data":data}) : helper)))
    + "\">\r\n        "
    + alias4((helpers.console || (depth0 && depth0.console) || alias2).call(alias1,(depth0 != null ? depth0.image : depth0),{"name":"console","hash":{},"data":data}))
    + "\r\n"
    + ((stack1 = (helpers.equal || (depth0 && depth0.equal) || alias2).call(alias1,(depth0 != null ? depth0.image : depth0),"",{"name":"equal","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "      </div>\r\n      <div class=\"album_inv_no_area\">\r\n        <span class=\"grey\" data-label='lb_data_line_list_header_3'>問合番号</span>\r\n        <span class=\"black\">"
    + ((stack1 = ((helper = (helper = helpers.inventory_no || (depth0 != null ? depth0.inventory_no : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inventory_no","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\r\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isFine : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <!-- ファインのみ表示 -->\r\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isSFine : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n      </div>\r\n      <div class=\"carNamearea\">\r\n        <span class=\"carName\">"
    + ((stack1 = ((helper = (helper = helpers.carName || (depth0 != null ? depth0.carName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"carName","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\r\n      </div>\r\n      <div class=\"gradearea\">\r\n        <span class=\"carName\">"
    + ((stack1 = ((helper = (helper = helpers.gradeName || (depth0 != null ? depth0.gradeName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"gradeName","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\r\n      </div>\r\n    <div class=\"saleAmmount\">\r\n      <span data-label='lb_nego_owner_hope_price'></span>\r\n      "
    + ((stack1 = (helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || alias2).call(alias1,(depth0 != null ? depth0.price : depth0),{"name":"numberWithYenComma","hash":{},"data":data})) != null ? stack1 : "")
    + "\r\n    </div>\r\n      <div class=\"carSpec\">\r\n        <div class=\"otherDetails\">\r\n          <div>\r\n            <span data-label='lb_data_line_list_header_5'></span>\r\n            <span>"
    + ((stack1 = ((helper = (helper = helpers.modelYear || (depth0 != null ? depth0.modelYear : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modelYear","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\r\n          </div>\r\n          <div>\r\n            <span data-label='lb_data_line_list_header_9'></span>\r\n            <span>"
    + ((stack1 = ((helper = (helper = helpers.style || (depth0 != null ? depth0.style : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"style","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\r\n          </div>\r\n          <div>\r\n            <span data-label='lb_data_line_list_header_19'></span>\r\n            <span>"
    + ((stack1 = (helpers.editEstimationDisplay || (depth0 && depth0.editEstimationDisplay) || alias2).call(alias1,(depth0 != null ? depth0.estimation : depth0),(depth0 != null ? depth0.estimation_flag : depth0),{"name":"editEstimationDisplay","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\r\n          </div>\r\n          <div>\r\n            <span data-label='lb_data_line_list_header_13'></span>\r\n            <span>"
    + alias4(((helper = (helper = helpers.mileage || (depth0 != null ? depth0.mileage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mileage","hash":{},"data":data}) : helper)))
    + alias4(((helper = (helper = helpers.no_mileage || (depth0 != null ? depth0.no_mileage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"no_mileage","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMeterChangeFlg : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</span>\r\n          </div>\r\n          <div>\r\n            <span data-label='lb_data_line_list_header_14'></span>\r\n            <span>"
    + ((stack1 = ((helper = (helper = helpers.inspect || (depth0 != null ? depth0.inspect : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inspect","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\r\n          </div>\r\n          <div>\r\n            <span data-label='lb_data_line_list_header_15'></span>\r\n            <span>"
    + ((stack1 = (helpers.getColorLabel || (depth0 && depth0.getColorLabel) || alias2).call(alias1,(depth0 != null ? depth0.outcolor : depth0),{"name":"getColorLabel","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\r\n          </div>\r\n        </div>\r\n      </div>\r\n      <div class=\"clear\"></div>\r\n    </div>\r\n    <div class=\"WatchListBtn_area\"></div>\r\n    <div class=\"clear\"></div>\r\n  </div>\r\n</li>\r\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}