###
  商談検索条件作成
###
View       = require 'views/view'

Template              = require 'views/templates/negolist_search'
CarNamesTemplate      = require 'views/templates/negolist_search_inputs'
GradesTemplate        = require 'views/templates/negolist_search_inputs'
ColorsTemplate        = require 'views/templates/negolist_search_colors'
InventoryYearTemplate = require 'views/templates/negolist_search_options'


module.exports = class NegolistSearchView extends View
  className  : 'negolist_search'
  template   : Template
  collection : null

  events:
    # 検索ボタン押下時に検索イベントをキック（直接検索は実行しない）
    "click #negolist_btn_search"                           : "fireSearch"
    # 検索ツールボタン押下時に検索ツール本体を表示
    "click #negolist_btn_show"                             : "show"
    # 車名選択時にグレードを取得する
    "click #negolist_search_carnames input[type=checkbox]" : "getNegolistGrades"
    # カレンダーのクリアボタン
    "click button.negolist_clear_input"                    : "clearDateInput"
    # 検索条件クリア
    "click #negolist_btn_clear_all"                        : "clearDisp"
    #'touchstart #negolist_btn_show' : 'hoverStartMobile'
    #'touchend   #negolist_btn_show' : 'hoverEndMobile'
    #'mouseover  #negolist_btn_show' : 'hoverStartMobile'
    #'mouseout   #negolist_btn_show' : 'hoverEndMobile'
    "change input" : 'searchConditionActive'


  # -------------------------
  # 初期化
  # -------------------------
  initialize: ->
    _.bindAll @
    # 検索用の車両データ
    # 商談テーブルにある車名、色、グレードを取得
    @listenTo @model, 'GetNegolistConditions' , @renderOptions
    @listenTo @model, 'GetNegolistGrades'     , @renderGrades
    @listenTo @model, 'ClearGrades'           , @renderGrades

    # サーバに車名色グレード取得をキック
    @model.emitGetNegolistConditions()


  reRender : (eventName) ->
    #console.log "aaaaaaaaaaaaaaa" + eventName
    @render()

  # -------------------------
  # レンダリング後
  # -------------------------
  afterRender:() ->
    # カレンダー作動
    @$el.find("input.datepicker").datepicker()
    # 検索初期条件セット
    @setSearchConditions()

  # -------------------------
  # renderデータ設定
  # -------------------------
  getRenderData: ->
    # console.log Backbone.AUCNET.DEFINE.get "ESTIMATION_KEY_VALUE"

    inventoryYear : []
    estimation    : Backbone.AUCNET.DEFINE.get "ESTIMATION_KEY_VALUE"
    carNames      : {}
    grades        : {}
    colors        : {}
    adminFlag     : if Backbone.AUCNET.roleID is '1' or Backbone.AUCNET.roleID is '2' then true else false
    currentScreenIsNowNego : @options.currentScreenIsNowNego

  # -------------------------
  # 車名、色、年式をレンダリング
  # -------------------------
  renderOptions: ->
    @$el.find("#negolist_search_carnames"      ).html  CarNamesTemplate      {list : @model.get "carNames"}
    @$el.find("#negolist_search_colors"        ).html  ColorsTemplate        {list : @model.get "colors"}
    @$el.find("#negolist_select_inventory_year").html  InventoryYearTemplate {list : @model.get "inventoryYear"}
    @drawLabel()

  # -------------------------
  # グレードをレンダリング
  # -------------------------
  renderGrades: ->
    @$el.find("#negolist_search_grades"        ).html  GradesTemplate        {list : @model.get "grades"}
    @drawLabel()

  # -------------------------
  # 車名のチェックボックス選択時にグレードを取得する
  # -------------------------
  getNegolistGrades:(event) ->
    conditions =
      carname : @$el.find("#negolist_search_carnames    input:checked").map((index, element) -> return $(@).val().trim()).get()
    if conditions.carname.length is 0
      @model.clearGrades()
    else
      @model.emitGetNegolistGrades @negolistKbn, conditions

  # -------------------------
  # 検索条件の変更
  # -------------------------
  setConditions:(negolistKbn) ->
    @negolistKbn = negolistKbn
    @model.clearGrades()
    @model.emitGetNegolistConditions(@negolistKbn)

  # -------------------------
  # limit/offsetをセット
  # -------------------------
  setLimitAndOffset: (obj = {}) ->
    # 現在値
    disp = @model.get "disp"
    # 入れなおし
    disp.limit  = obj.limit  ? disp.limit
    disp.offset = obj.offset ? disp.offset
    @model.set "disp" , disp

  # -------------------------
  # 現在選択中の検索条件を返す
  # -------------------------
  getSearchConditions: ->
    return @model.get "disp"

  # -------------------------
  # 画面上の検索条件をクリアする
  # -------------------------
  clearDisp : ->
    @$el.find("#negolist_search_radio_sort       input").attr("checked", false)
    if @negolistKbn in ["1", "2", "6", "7"]
      # ソート順は表示中の一番左にあるものを選択する
      @$el.find("#negolist_search_radio_sort label span:visible").siblings("input:first").attr("checked", true)
    else 
      # ラジオボタンリセット
      @$el.find("#negolist_search_radio_sort input[type=radio]").attr("checked", false)
    # @$el.find("#negolist_select_inventory_year "       ).val("")
    @$el.find("#negolist_select_inventory_year "       ).children("li").removeClass("active")
    @$el.find("#negolist_select_estimation       input").attr("checked", false)
    @$el.find("#negolist_input_entry_date_from"        ).val("")
    @$el.find("#negolist_input_entry_date_to"          ).val("")
    @$el.find("#negolist_input_end_date_from"          ).val("")
    @$el.find("#negolist_input_end_date_to"            ).val("")
    @$el.find("#negolist_input_inventory_no"           ).val("")
    @$el.find("#negolist_input_owner_member_cd"        ).val("")
    @$el.find("#negolist_input_buyer_member_cd"        ).val("")
    @$el.find("#negolist_select_buy_sell_kbn"          ).val("")
    @$el.find("#negolist_search_carnames         input").attr("checked", false)
    @$el.find("#negolist_search_grades           input").attr("checked", false)
    @$el.find("#negolist_search_colors           input").attr("checked", false)
    @$el.find("#negolist_search_nego_status      input").attr("checked", false)
    @searchConditionActive()

  # -------------------------
  # 検索条件を新着順のみにする
  # -------------------------
  setDispNewItemConditions: ->
    @clearDisp()
    @$el.find("#negolist_search_radio_sort input[value=#{Backbone.AUCNET.DEFINE.get('NEGOLIST_SORT_KBN_NEW')}]")
        .attr("checked", true)

  searchConditionActive:->
    #console.log "lllllll"
    inventoryNo  = if (temp = @$el.find("#negolist_input_inventory_no"   ).val())? and temp isnt "" then ("00000000"   + $.trim(temp)).slice(-8 ) else ""
    entryDateFrom  = $.trim(@$el.find("#negolist_input_entry_date_from"                 ).val())
    entryDateTo    = $.trim(@$el.find("#negolist_input_entry_date_to"                   ).val())
    endDateFrom    = $.trim(@$el.find("#negolist_input_end_date_from"                   ).val())
    endDateTo      = $.trim(@$el.find("#negolist_input_end_date_to"                     ).val())
    negoStatus     = @$el.find("#negolist_search_nego_status input:checked").map((index, element) -> return $.trim($(@).val())).get()
    sortKbn        = $.trim(@$el.find("#negolist_search_radio_sort        input:checked").val())

    if _.isEmpty(inventoryNo) and
    _.isEmpty(entryDateFrom) and
    _.isEmpty(entryDateTo) and
    _.isEmpty(endDateFrom) and
    _.isEmpty(endDateTo) and
    _.isEmpty(negoStatus) and
    ((@negolistKbn in ["1", "2", "6", "7"] and (sortKbn in ["1", "6"])) or 
    (@negolistKbn is "3" and _.isEmpty(sortKbn)))
      $("#negolist_btn_show").removeClass("refineSearchBtn_Red")
    else
      $("#negolist_btn_show").addClass("refineSearchBtn_Red")

      

  # -------------------------
  # 現在選択中の検索条件を画面上から取得してセット
  # -------------------------
  setSearchConditions: ->
    # 検索条件を画面上から取得
    buySellKbn     = $.trim(@$el.find("#negolist_select_buy_sell_kbn    option:selected").val())
    sortKbn        = $.trim(@$el.find("#negolist_search_radio_sort        input:checked").val())
    #inventoryYear  = $.trim(@$el.find("#negolist_select_inventory_year  option:selected").val())
    inventoryYear  = $.trim(@$el.find("#negolist_select_inventory_year").children("li.active").data('value'))
    entryDateFrom  = $.trim(@$el.find("#negolist_input_entry_date_from"                 ).val())
    entryDateTo    = $.trim(@$el.find("#negolist_input_entry_date_to"                   ).val())
    endDateFrom    = $.trim(@$el.find("#negolist_input_end_date_from"                   ).val())
    endDateTo      = $.trim(@$el.find("#negolist_input_end_date_to"                     ).val())
    # ゼロ埋めするもの
    inventoryNo    = if (temp = @$el.find("#negolist_input_inventory_no"   ).val())? and temp isnt "" then ("00000000"   + $.trim(temp)).slice(-8 ) else ""
    ownerMemberCD  = if (temp = @$el.find("#negolist_input_owner_member_cd").val())? and temp isnt "" then temp else ""
    buyerMemberCD  = if (temp = @$el.find("#negolist_input_buyer_member_cd").val())? and temp isnt "" then temp else ""
    # 配列で取得するもの
    carName        = @$el.find("#negolist_search_carnames    input:checked").map((index, element) -> return $.trim($(@).val())).get()
    grade          = @$el.find("#negolist_search_grades      input:checked").map((index, element) -> return $.trim($(@).val())).get()
    colors         = @$el.find("#negolist_search_colors      input:checked").map((index, element) -> return $.trim($(@).val())).get()
    negoStatus     = @$el.find("#negolist_search_nego_status input:checked").map((index, element) -> return $.trim($(@).val())).get()
    #流札が押下されている場合はキャンセルも同時に検索する
    if _.indexOf(negoStatus,Backbone.AUCNET.DEFINE.get('NEGO_STATUS_FLOW')) >= 0
      negoStatus.push Backbone.AUCNET.DEFINE.get('NEGO_STATUS_CANCEL')
    estimation     = @$el.find("#negolist_select_estimation  input:checked").map((index, element) -> return $.trim($(@).val())).get()

    # 商談申込み日時From Toチェック
    if (not _.isEmpty(entryDateTo)) and (not _.isEmpty(entryDateFrom)) and (entryDateFrom > entryDateTo)
      tmp           = entryDateFrom
      entryDateFrom = entryDateTo
      entryDateTo   = tmp
    # 商談終了日時From Toチェック
    if (not _.isEmpty(endDateTo)) and (not _.isEmpty(endDateFrom)) and (endDateFrom > endDateTo)
      tmp           = endDateFrom
      endDateFrom = endDateTo
      endDateTo   = tmp

    # 前回分のモデルを取得
    last = @model.get "disp"

    retObj =
      buysellkbn     : buySellKbn
      sortkbn        : sortKbn
      inventoryyear  : inventoryYear
      inventoryno    : inventoryNo
      carname        : carName
      grade          : grade
      estimation     : estimation
      colors         : colors
      negostatus     : negoStatus
      entrydatefrom  : entryDateFrom
      entrydateto    : entryDateTo
      enddatefrom    : endDateFrom
      enddateto      : endDateTo
      ownermembercd  : ownerMemberCD
      buyermembercd  : buyerMemberCD
      limit          : last?.limit # 表示件数は前回分を引き継ぐ
    # 空文字、から配列はnullにする
    retObj[key] = null for key, item of retObj when (not item?) or item is "" or item.length is 0
    @model.set "disp", retObj
    return retObj

  # -------------------------
  # 画面から検索条件を取得し、検索実行イベントを起こす
  # -------------------------
  fireSearch: ->
    @setLimitAndOffset({offset:0})
    @setSearchConditions()
    conditions = @getSearchConditions()
    @trigger "FireSearch", conditions
    @show()

  # -------------------------
  # 検索部分を表示
  # -------------------------
  show: ->
    if  @$el.find("#negolist_condition_section").hasClass('show')
      @$el.find("#negolist_condition_section").hide('fast')
    else
      $("html,body").animate({ scrollTop: 0 }, "fast");
      @$el.find("#negolist_condition_section").show('fast')

    @$el.find("#negolist_condition_section").toggleClass('show')

    #@$el.find("#negolist_condition_section").toggle("fast")

  # -------------------------
  # 日付インプット欄をクリア
  # -------------------------
  clearDateInput: (event) ->
    @$("##{event.currentTarget.value}").val("")
    @searchConditionActive()
