# サイドメニュー表示データ
module.exports = [
  # {
  #   # 新着
  #   id           : 'news'
  #   menucd       : 'news'
  #   label        : 'lb_news'
  #   class        : 'newsBtn'
  #   newitemcount : undefined
  #   menu_auth    : 0
  #  iconClass    : 'newarrivalsIcon'
  # }
  # {
  #  # 検索
  #  id           : 'search'
  #  menucd       : 'search'
  #  label        : 'lb_search'
  #  class        : 'searchBtn'
  #  newitemcount : undefined
  #  menu_auth    : 0
  #  iconClass    : 'searchIcon'
  # }
  # {
  #   # リクエスト
  #   id           : 'request'
  #   menucd       : 'request'
  #   label        : 'lb_request'
  #   class        : 'requestBtn'
  #   newitemcount : undefined
  #   menu_auth    : 0
  #   iconClass    : 'requestIcon'
  # }
  # {
  #   # マイリスト
  #   id           : 'mylist'
  #   menucd       : 'mylist'
  #   label        : 'lb_mylist'
  #   class        : 'mylistBtn'
  #   newitemcount : undefined
  #   menu_auth    : 0
  #   iconClass    : 'myListIcon'
  # }
  # {
  #   # 商談候補一覧
  #   id           : 'candidate'
  #   menucd       : 'candidate'
  #   label        : 'lb_candidate'
  #   class        : 'candidateBtn'
  #   newitemcount : undefined
  #   menu_auth    : 0
  #   iconClass    : 'candListIcon'
  # }
  {
    # マイ商談一覧
    id           : 'negolist'
    menucd       : 'negolist'
    label        : 'lb_negolist'
    class        : 'negolistBtn'
    newitemcount : undefined
    menu_auth    : 0
    iconClass    : 'myNegotiationIcon'
  }
  {
    # 他社商談一覧
    id           : 'negolistall'
    menucd       : 'negolistall'
    label        : 'lb_negolistall'
    class        : 'negolistallBtn'
    newitemcount : undefined
    menu_auth    : 0
    iconClass    : 'otherNegoIcon'
  }
 # {
 #   # 設定
 #   id           : 'config'
 #   menucd       : 'config'
 #   label        : 'lb_config'
 #   class        : 'configBtn'
 #   newitemcount : undefined
 #   menu_auth    : 1
 # }
  {
    # 商談一覧
    id           : 'negolistadmin'
    menucd       : 'negolistadmin'
    label        : 'lb_negolistadmin'
    class        : 'negolistadminBtn'
    newitemcount : undefined
    menu_auth    : 1
    iconClass    : 'otherNegoIcon'
  }
  {
    # メールアドレス設定
    id           : 'mailconfig'
    menucd       : 'config'
    label        : 'lb_mailconfig'
    class        : 'mailconfigBtn'
    newitemcount : undefined
    menu_auth    : 1
    iconClass    : 'otherNegoIcon'
  }
  {
    # 商談一覧
    id           : 'negolist_haken'
    menucd       : 'negolistadmin'
    label        : 'lb_negolistadmin'
    class        : 'negolistadminBtn'
    newitemcount : undefined
    menu_auth    : 2
    iconClass    : 'otherNegoIcon'
  }

]
