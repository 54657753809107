Collection   = require 'models/collection'

NegoMessage  = require 'data/nego_messages'

###
 商談のメッセージマスタ
###
module.exports = class Message extends Collection

  initialize: ->
    _.bindAll @
    @reset NegoMessage

