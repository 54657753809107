application = require 'application'
listener = require 'lib/listener'
View = require 'views/view'
util = require 'lib/util'
client_define = require 'lib/define'

###
  [ログイン] ログインビュー
###
module.exports = class LoginView extends View

  id: 'login_view'
  events:
    'click #btnLogin': 'login'
    'keypress input[id=txtUser]': 'keypressUserID'
    'keypress input[id=txtPassword]': 'keypressPassword'
    'click .forget_password': 'forget_password'
    'click .environment': 'environment'
    'click .login_banner_auc': 'login_banner_auc'
    'click .login_banner_aucneo': 'login_banner_aucneo'

  initialize: ->
    _.bindAll @
    # モデルにイベントを購読
    @listenTo Backbone.AUCNET.loginUser ,'login_success' ,@loginSuccess
    @listenTo Backbone.AUCNET.loginUser ,'login_failed1' ,@loginFailed1
    @listenTo Backbone.AUCNET.loginUser ,'login_failed2' ,@loginFailed2
    @listenTo Backbone.AUCNET.loginUser ,'login_failed3' ,@loginFailed3
    @listenTo Backbone.AUCNET.loginUser ,'login_failed4' ,@loginFailed4

  afterRender: ->
    # ユーザID入力欄にフォーカスを合わせる
    _.defer =>
      @$el.find('input[id=txtUser]').focus()

  login: ->
    # 二度押しブロック
    $loginBtn = @$el.find('btnLogin')
    return if $loginBtn.prop('disabled')
    $loginBtn.prop 'disabled', true

    Backbone.AUCNET.loginUser.set
      _userid   : @$el.find('input[id=txtUser]').val()
      _password : @$el.find('input[id=txtPassword]').val()
    Backbone.AUCNET.loginUser.authenticate()

  loginSuccess:(data = {}) ->
    # 各画面に遷移
    application.router._createInstance()

    # クエリパラメータのチェック
    query = @options.query
    queryObj = util.getUrlParamsJson query
    menu = queryObj?.tc
    switch menu
      # 商談
      when "1"
        jumpFragment = "#nego/#{queryObj.ni}/#{queryObj.iy}/#{queryObj.in}"
      else
        # クエリ無しでサーバからの返り値のマイ商談件数が1以上のとき、マイ商談画面に飛ばす
        jumpFragment = "#negolist"
    application.router.navigate jumpFragment, true

  # IDが入力されていない
  loginFailed1: ->
    # 二度押しブロック解除
    @$el.find('btnLogin').prop 'disabled', false
    @$el.find('input[id=txtPassword]').val('')
    @$el.find('input[id=txtUser]').val('').focus()
    @showMsgBox("ms_loginFailed_L05", "ms_loginFailed_title")

  # パスワードが入力されていない
  loginFailed2: ->
    # 二度押しブロック解除
    @$el.find('btnLogin').prop 'disabled', false
    @$el.find('input[id=txtPassword]').val('').focus()
    @showMsgBox("ms_loginFailed_L06", "ms_loginFailed_title")

  # ID or passが違う
  loginFailed3:(code) ->
    # 二度押しブロック解除
    @$el.find('btnLogin').prop 'disabled', false
    @$el.find('input[id=txtPassword]').val('').focus()
    messageLabel = if code? then  "ms_api_error_#{code}" else "ms_loginFailed_L01"
    @showMsgBox(messageLabel, "ms_loginFailed_title")

  # IDが無効
  loginFailed4: ->
    # 二度押しブロック解除
    @$el.find('btnLogin').prop 'disabled', false
    @$el.find('input[id=txtPassword]').val('')
    @$el.find('input[id=txtUser]').val('').focus()
    @showMsgBox("ms_loginFailed_L04", "ms_loginFailed_title")

  keypressPassword: (event) ->
    if event.keyCode is 13 # EnterKey
      event.preventDefault()
      @login()

  keypressUserID: (event) ->
    if event.keyCode is 13 # EnterKey
      event.preventDefault()
      @$el.find('input[id=txtPassword]').focus()

  forget_password: (event) ->
    window.open getDatetime() + client_define.FORGET_PASSWORD_URL

  environment: (event) ->
    window.open client_define.ENVIRONMENT_URL

  login_banner_auc: (event) ->
    window.open client_define.LOGIN_BANNER_AUC_URL

  login_banner_aucneo: (event) ->
    window.open client_define.LOGIN_BANNER_AUCNEO_URL
