Application = require 'application'
View = require 'views/view'
Template = require 'views/templates/setting_admin'
SettingCreditModel = require 'models/setting_credit_model'
SettingMailModel = require 'models/setting_mail_model'
NegoDisableModel = require 'models/nego_disable_model'
SettingCreditView = require 'views/setting_credit_view'
SettingMailView = require 'views/setting_mail_view'
UtilConfirmView = require 'views/util_confirm_view'
IndicatorView = require 'views/indicator_view'
MESSAGE = require 'data/messages'
util = require 'lib/util'

###
  [ユーザ設定画面] 管理者用ビュー
###
module.exports = class SettingAdminView extends View

  id: 'setting_admin'
  template: Template
  parent: undefined
  authority: 'admin'
  checked: '1'
  no_checked: '0'
  success: '0'

  className: "SettingAdminView settingsContainer"

  events:
    'click .userSearch': 'onClickUserSearch'
    'click .addEmail': 'onClickAddEmail'
    'click .delEmail': 'onClickDelEmail'
    'click .reSendEmail': 'onClickReSendEmail'
    'click #setStopBid': 'settingStopBid'

  # 初期化
  initialize: ->
    _.bindAll @

    # モデルをクラス変数にセット
    @creditModel = new SettingCreditModel
    @mailModel = new SettingMailModel
    @disableModel = new NegoDisableModel

    @listenTo @mailModel, 'user_data_non', @replyNoUser
    @listenTo @disableModel, 'resultStopBid', @resultStopBid
    @listenTo @disableModel, 'checkDisable', @checkDisable    # Viewのインスタンス化
    @listenTo @mailModel, 'getDisable', @getNegoDisable

  # レンダリング後の処理
  afterRender: ->
    # Viewのインスタンス化
    settingCreditView = new SettingCreditView
      model: @creditModel
      authority: @authority
    settingMailView = new SettingMailView
      model: @mailModel
      authority: @authority
    # Viewの要素をappend
    @$el.find(".settingCredit").append(settingCreditView.el)
    @$el.find(".settingMail").append(settingMailView.el)

  getRenderData: ->
    title: 'lb_user_id_config'

  onClickUserSearch: (param) =>
    # 検索が押下された時モデルの要素をクリアする。
    @mailModel.clear {silent: true}
    @creditModel.clear {silent: true}
    # ユーザIDを表示させるフラグをたてる
    @mailModel.set 'admin_flag': true, {silent: true}
    # 入力値を取得
    memberCd = @$el.find('input[id=userIdtxt]').val()
    @mailModel.searchEmail memberCd
    @creditModel.set 'memberCd': memberCd, {silent: true}

  onClickAddEmail: (param) =>
    mailAddress = @$el.find('input[id=mailText]').val()

    #入力値チェック
    errorMessage = util.chkRegEmail mailAddress
    unless errorMessage is ""
      @mailModel.set 'message': Backbone.AUCNET.message.get errorMessage,
        {silent: true}
      @mailModel.trigger 'message'
      return

    message = "アドレス: " + mailAddress + " を追加しますか？"
    confirmView = new UtilConfirmView
      title: 'メールアドレス登録確認'
      message: message
      callback: (ok) =>
        if ok
          @mailModel.addMailAdmin @$el.find('input[id=mailText]').val()
    @$el.append(confirmView.render().el)

  onClickDelEmail: (param) =>
    message = "アドレス: " + param.currentTarget.value + " を削除しますか？"
    confirmView = new UtilConfirmView
      title: 'メールアドレス削除確認'
      message: message
      callback: (ok) =>
        if ok
          @mailModel.delMailAdmin param.currentTarget.value
    @$el.append(confirmView.render().el)

  onClickReSendEmail: (param) =>
    confirmView = new UtilConfirmView
      title: Backbone.AUCNET.message.get('ms_mail_re_sendmail_title')
      message: param.currentTarget.value +
        Backbone.AUCNET.message.get('ms_mail_re_sendmail_text')
      callback: (ok) =>
        if ok
          @mailModel.sendMailUser param.currentTarget.value
    @$el.append(confirmView.render().el)

  # ユーザなしが返却された場合の処理
  replyNoUser: =>
    @$el.find('#setting_mail').html('')
    @$el.find('#setting_credit').html('')

  # ネット商談不可設定
  settingStopBid: =>
    # チェック確認
    checkDisapper = $('.disappear_bid').prop('checked')
    owner_member_cd = $("#userIdtxt").val()
    @disableModel.set 'owner_member_cd': owner_member_cd
    @disableModel.set 'disabled_flag':
      if checkDisapper is true then @checked else @no_checked

    confirmView = new UtilConfirmView
      title: Backbone.AUCNET.message.get("ms_setting_title")
      message: Backbone.AUCNET.message.get("ms_setting_agree")
      callback: (ok) =>
        if ok
          @indicator = new IndicatorView
            title: Backbone.AUCNET.message.get "ms_setting_title"
          $('body').append(@indicator.render().el)
          @disableModel.stopBid()
    @$el.append(confirmView.render().el)

  resultStopBid: =>
    result = @disableModel.get 'result'
    title_ms = Backbone.AUCNET.message.get("ms_setting_title")
    message_ms =
      if result is @success
        Backbone.AUCNET.message.get("ms_setting_success")
      else
        Backbone.AUCNET.message.get("ms_setting_failed")
    @indicator.suicide()
    confirmView = new UtilConfirmView
      title: title_ms
      message: message_ms
      cancelDelFlg: true
      callback: (ok) ->
        undefined
    @$el.append(confirmView.render().el)

  checkDisable: =>
    disable_flag = @disableModel.get 'disable_flag'
    # disable_flagが1のときチェックをつける
    if disable_flag is @checked
      $('.disappear_bid').attr("checked", true)
    else
      $('.disappear_bid').attr("checked", false)

  getNegoDisable: =>
    owner_member_cd = $("#userIdtxt").val()
    @disableModel.set 'owner_member_cd': owner_member_cd
    @disableModel.getNegoDisable()
