var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "  <div class=\"negListTabs\">\n    <button type='button' id=\"negolist_btn_my_ongoing\">"
    + alias3((helpers.getLabelStr || (depth0 && depth0.getLabelStr) || alias2).call(alias1,"lb_negolist_my_ongoing",{"name":"getLabelStr","hash":{},"data":data}))
    + "<span id=\"negolist_list_tab_past_car_num\">"
    + alias3(((helper = (helper = helpers.carNum || (depth0 != null ? depth0.carNum : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"carNum","hash":{},"data":data}) : helper)))
    + "</span>"
    + alias3((helpers.getLabelStr || (depth0 && depth0.getLabelStr) || alias2).call(alias1,"lb_unit_car",{"name":"getLabelStr","hash":{},"data":data}))
    + "</button>\n    <button type='button' id=\"negolist_btn_my_past\" class=\"active\">"
    + alias3((helpers.getLabelStr || (depth0 && depth0.getLabelStr) || alias2).call(alias1,"lb_negolist_my_past",{"name":"getLabelStr","hash":{},"data":data}))
    + alias3(((helper = (helper = helpers.past || (depth0 != null ? depth0.past : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"past","hash":{},"data":data}) : helper)))
    + "</button>\n  </div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}