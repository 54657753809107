Model        = require 'models/model'

###
　商談候補追加削除ボタン用モデル
###
module.exports = class WatchlistModel extends Model

  socket: undefined

  #持つべきデータをここに書く。
  defaults: undefined

  WATCHLIST_CHECK   = 'WatchListCheck'
  WATCHLIST_ADD     = 'WatchListAdd'
  WATCHLIST_DEL     = 'WatchListDel'
  WATCHLIST_DEL_ALL = 'WatchListDelAll'

  initialize: =>
    _.bindAll @
    @socket = Backbone.AUCNET.socket

  ###
  　商談候補存在確認
  ###
  checkWatchList : =>
    @socket.on WATCHLIST_CHECK, (d) =>
      retJson = JSON.parse d
      #状態を返す
      if retJson.invNo is @get('invNo') and retJson.invYear is @get('invYear')
        @set ret : if retJson.ret then 'del' else 'add'
        #@socket.removeAllListeners WATCHLIST_CHECK


    @socket.emit WATCHLIST_CHECK ,
    reqJson :
      invNo   : @get('invNo')
      invYear : @get('invYear')
      loginId : @get('loginId')
    return

  ###
  　商談候補追加ボタン
  ###
  addWatchlist : =>
    @socket.on WATCHLIST_ADD, (d) =>
      retJson = JSON.parse d
      #成功なら削除ボタンへ
      if retJson.invNo is @get('invNo') and retJson.invYear is @get('invYear')
        @set ret : if retJson.ret then 'del' else 'add'
        #@socket.removeAllListeners WATCHLIST_ADD

    @socket.emit WATCHLIST_ADD ,
    reqJson :
      invNo   : @get('invNo')
      invYear : @get('invYear')
      loginId : @get('loginId')
    return

  ###
  　商談候補削除
  ###
  delWatchlist : =>
    @socket.on WATCHLIST_DEL, (d) =>
      retJson = JSON.parse d
      #成功なら追加ボタンへ
      if retJson.invNo is @get('invNo') and retJson.invYear is @get('invYear')
        @set ret : if retJson.ret then 'add' else 'del'
        #@socket.removeAllListeners WATCHLIST_DEL

    @socket.emit WATCHLIST_DEL ,
    reqJson :
      invNo   : @get('invNo')
      invYear : @get('invYear')
      loginId : @get('loginId')
    return

  ###
    商談候補全削除
  ###
  removeAllWatchItem: =>
    # コールバック設定
    @socket.on WATCHLIST_DEL_ALL, (d) =>
      @socket.removeAllListeners WATCHLIST_DEL_ALL
      @trigger WATCHLIST_DEL_ALL
    # ソケット書込
    @socket.emit WATCHLIST_DEL_ALL
