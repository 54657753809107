listener = require 'lib/listener'
util = require 'lib/util'
LoginViewBase = require 'views/login_view'

###
  [ログイン] login.html用のビュー
###
module.exports = class LoginView extends LoginViewBase

  id: 'login_view'

  initialize: ->
    super
    @drawLabel()
    @$el.show()

  login:(event) ->
    event?.preventDefault?()
    super

  loginSuccess:(data = {}) ->
    # クエリパラメータのチェック
    query = window.location.search
    queryObj = util.getUrlParamsJson query
    menu = queryObj?.tc
    switch menu
      # 商談
      when "1"
        jumpFragment = "index.html#nego/#{queryObj.ni}/#{queryObj.iy}/#{queryObj.in}"
      else
        # クエリ無しでサーバからの返り値のマイ商談件数が1以上のとき、マイ商談画面に飛ばす
        if (data.myNegoCnt?) and (data.myNegoCnt > 0)
          jumpFragment = "index.html#negolist"
        else
          jumpFragment = "index.html#negolist"
    $("#loginForm").attr("action" ,jumpFragment)
    # .submit()だとIEのオートコンプリート機能が効かない
    # そのためsubmitボタンを擬似的にクリックしてsubmitさせる
    $("#dummySubmitBtn").click()
