Application = require 'application'
View        = require 'views/view'
Template    = require 'views/templates/setting_user'
SettingMailModel = require 'models/setting_mail_model'
NegoDisableModel = require 'models/nego_disable_model'
SettingMailView = require 'views/setting_mail_view'
UtilConfirmView = require 'views/util_confirm_view'
IndicatorView   = require 'views/indicator_view'
MESSAGE = require 'data/messages'
util            = require 'lib/util'

###
 [設定] ユーザ設定画面
###
module.exports = class SettingUserView extends View
  id          : 'setting_user'
  template    : Template
  parent      : undefined
  authority   : 'user'
  checked     :  '1'
  no_checked  :  '0'
  success     :  '0'

  className : "SettingUserView settingsContainer"

  events:
    'click .addEmail' : 'onClickAddEmail'
    'click .delEmail' : 'onClickDelEmail'
    'click .reSendEmail' : 'onClickReSendEmail'
    'click #setStopBid' : 'settingStopBid'

  # 初期化
  initialize: ->
    _.bindAll @
    #console.log 'iniaaaaaa : ' + @getUrlVars()
    #モデルをクラス変数にセット
    @mailModel = new SettingMailModel
    @disableModel = new NegoDisableModel
    @mailModel.setUrlVal @getUrlVars()
    @listenTo @disableModel, 'resultStopBid', @resultStopBid
    @listenTo @disableModel, 'checkDisable', @checkDisable

  # レンダリング後の処理
  afterRender: ->
    #Viewのインスタンス化
    settingMailView  = new SettingMailView
      model : @mailModel
      authority : @authority
    #Viewの要素をappend
    @$el.find(".settingMail").append(settingMailView.el);

    #test用
    @mailModel.getEmail()
    @listenTo settingMailView, 'getDisable', @getNegoDisable


    #@mailModel.getMailList Backbone.AUCNET.loginUser.get("_userid")

  getRenderData: ->
    title       : 'lb_mail_config'

  onClickAddEmail : (param) =>
    mailAddress = @$el.find('input[id=mailText]').val()

    #入力値チェック
    errorMessage = util.chkRegEmail mailAddress
    unless errorMessage is ""
      @mailModel.set 'message' : Backbone.AUCNET.message.get errorMessage, {silent : true}
      @mailModel.trigger 'message'
      return

    message = "アドレス :  " + mailAddress + " を追加しますか？"
    confirmView = new UtilConfirmView
      title    : 'メールアドレス登録確認'
      message  : message
      # =>(ファットアロー)で渡すとcontextがここになる
      callback : (ok)=>
        if ok
          @mailModel.addMailUser @$el.find('input[id=mailText]').val()
    @$el.append(confirmView.render().el)

  onClickDelEmail: (param) =>
    message = "アドレス :  " + param.currentTarget.value + " を削除しますか？"

    confirmView = new UtilConfirmView
      title    : 'メールアドレス削除確認'
      message  : message
      # =>(ファットアロー)で渡すとcontextがここになる
      callback : (ok)=>
        if ok
          @mailModel.delMailUser param.currentTarget.value
    @$el.append(confirmView.render().el)

  onClickReSendEmail: (param) =>
    confirmView = new UtilConfirmView
      title    : Backbone.AUCNET.message.get('ms_mail_re_sendmail_title')
      message  : param.currentTarget.value + Backbone.AUCNET.message.get('ms_mail_re_sendmail_text')
      # =>(ファットアロー)で渡すとcontextがここになる
      callback : (ok)=>
        if ok
          @mailModel.sendMailUser param.currentTarget.value
        #else
          #console.log  "CANCEL処理"
    @$el.append(confirmView.render().el)

  #ネット商談不可設定
  settingStopBid: =>
    #console.log("start stop bid!!!")
    #チェック確認
    checkDisapper = $('.disappear_bid').prop('checked')
    #console.log("checkDisapper = " , checkDisapper)
    owner_member_cd = Backbone.AUCNET.loginUser.get("_userid")
    #console.log("owner_member_cd = ", owner_member_cd)
    @disableModel.set 'owner_member_cd' : owner_member_cd
    @disableModel.set 'disabled_flag' : if checkDisapper is true then @checked else @no_checked
    
    confirmView = new UtilConfirmView
      title    : Backbone.AUCNET.message.get("ms_setting_title")
      message  : Backbone.AUCNET.message.get("ms_setting_agree")
      callback : (ok)=> 
        if ok
          @indicator = new IndicatorView
            title   : Backbone.AUCNET.message.get "ms_setting_title"
          $('body').append(@indicator.render().el)
          @disableModel.stopBid()
        #else
          #console.log "CANCEL処理"
    @$el.append(confirmView.render().el)

  resultStopBid : =>
    result = @disableModel.get 'result'
    title_ms = Backbone.AUCNET.message.get("ms_setting_title")
    message_ms = if result is @success then Backbone.AUCNET.message.get("ms_setting_success") else Backbone.AUCNET.message.get("ms_setting_failed")
    @indicator.suicide()
    confirmView = new UtilConfirmView
      title    : title_ms
      message  : message_ms
      cancelDelFlg : true
      callback : (ok)=> 
        #if ok
        #  console.log  "ok処理"
    @$el.append(confirmView.render().el)

  checkDisable : =>
    disable_flag = @disableModel.get 'disable_flag'
    #disable_flagが1のときチェックをつける
    if disable_flag is @checked
      $('.disappear_bid').attr("checked", true)
    else 
      $('.disappear_bid').attr("checked", false)

  getNegoDisable :=>
    owner_member_cd = Backbone.AUCNET.loginUser.get("_userid")
    @disableModel.set 'owner_member_cd' : owner_member_cd
    @disableModel.getNegoDisable()
