###
 [ログイン] 画面
###

# ビューの基底クラス
View       = require 'views/view'
# 本ビューで使用するhandlebarsファイル
template   = require 'views/templates/util_page_base'

listener   = require 'lib/listener'

# 本ビュー内で使用する子ビュー
AdminLoginView  = require 'views/admin_login_view'

# ビューの定義
module.exports = class AdminLoginPageView extends View
  # 本ビューでレンダリングするhtmlのidを指定
  id        : 'admin_login_page'
  className : 'loginBox'
  # 本ビューでレンダリングするhtmlのテンプレートを指定（handlebarsファイル）
  # handlebarsは関数として実体化する
  # 基底クラスview.coffee参照のこと
  template  : template

  # -----------------------------------
  # 初期化
  # -----------------------------------
  # ビューがインスタンス化されるときにBackbone.jsによって自動で呼ばれる
  # ログインの場合はapplication.coffeeで実体化されているためサイトアクセス時に
  # 実行されている
  initialize: ->
    #console.debug "AdminLoginPageView#initialize"
    # Undersocore.jsのメソッド
    # 第一引数のオブジェクトにオブジェクト内のthisを保存する
    _.bindAll @

    ## グローバル変数から各画面で使用する情報を取得
    #loginAdmin = Backbone.AUCNET.loginAdmin
    #sideMenu  = Backbone.AUCNET.sideMenu

    ## WebSocketをサーバに/buyerとして接続
    #Backbone.AUCNET.socket = io.connect(Backbone.AUCNET.CONST.WS_URI, Backbone.AUCNET.SOCKETIO_OPTION)
    ## コネクション異常時のイベントにコールバックをバインド
    #Backbone.AUCNET.socket.on 'error',      listener.socket_error
    #
    #Backbone.AUCNET.socket.on 'disconnect', listener.socket_disconnect
    #Backbone.AUCNET.socket.on 'connect', ->
    #  console.log "connect"

    #  loginAdmin.setSocket @
    #  sideMenu.setSocket @
    #  Backbone.AUCNET.loginAdmin.logincheck()

    #@getLanguage Backbone.AUCNET.LANGUAGE_ID

  # -----------------------------------
  # レンダリング後の処理
  # -----------------------------------
  # 本ビューがrender()された後に実行される
  # View#renderを参照のこと
  afterRender: ->
    #console.debug 'AdminLoginPageView#afterRender'
    # この画面で使用するコンテンツの組立
    adminLoginView    = new AdminLoginView
    # @elは本ビューが出力したDOMへの参照を持つ
    # また任意に設定し直すことが可能
    # @$elは@elのjQueryオブジェクト
    @$el.append(adminLoginView.render().el)
