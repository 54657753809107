var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "  <li class=\"negolist_item_"
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + " item\">\n    <div class=\"box\">\n      <label data-inventory_year=\""
    + alias2(alias1((depth0 != null ? depth0.inventory_year : depth0), depth0))
    + "\" data-inventory_no=\""
    + alias2(alias1((depth0 != null ? depth0.inventory_no : depth0), depth0))
    + "\" class=\"showdetail\">\n"
    + ((stack1 = (helpers.equal || (depth0 && depth0.equal) || alias4).call(alias3,(depth0 != null ? depth0.image : depth0),"",{"name":"equal","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "      </label>\n      <div class=\"paddedBox\">\n        <div class=\"notice "
    + alias2((helpers.getRibbonClass || (depth0 && depth0.getRibbonClass) || alias4).call(alias3,(depth0 != null ? depth0.nego_status : depth0),(depth0 != null ? depth0.hash_owner_member_cd : depth0),(depth0 != null ? depth0.hash_buyer_member_cd : depth0),{"name":"getRibbonClass","hash":{},"data":data}))
    + " noPointer\" data-label=\""
    + alias2(alias1((depth0 != null ? depth0.nego_status_lb : depth0), depth0))
    + "\"></div>\n        <div class=\"carDetails\">\n          "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isFine : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <!-- ファインのみ表示 -->\n          "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isSFine : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <!-- ファインのみ表示 -->\n          <span class=\"manufact\">"
    + alias2(alias1((depth0 != null ? depth0.carname_full : depth0), depth0))
    + "</span>\n          <span class=\"carName\">"
    + alias2(alias1((depth0 != null ? depth0.grade_name_full : depth0), depth0))
    + "</span>\n          <div class=\"clear\"></div>\n        </div>\n        <div>\n          <div class=\"bubbleWrap fl\">\n            <div class=\"bubble"
    + alias2(alias1((depth0 != null ? depth0.send_role_kbn : depth0), depth0))
    + "\">"
    + alias2((helpers.getNegoMessageLabel || (depth0 && depth0.getNegoMessageLabel) || alias4).call(alias3,(depth0 != null ? depth0.last_message_cd : depth0),(depth0 != null ? depth0.last_message_price : depth0),(depth0 != null ? depth0.limit_hour : depth0),{"name":"getNegoMessageLabel","hash":{},"data":data}))
    + "</div>\n            <button type='button' class=\"negotiationBtnSmall\" data-label=\"lb_nego\" id= \""
    + alias2(alias1((depth0 != null ? depth0.nego_id : depth0), depth0))
    + "|"
    + alias2(alias1((depth0 != null ? depth0.inventory_year : depth0), depth0))
    + "|"
    + alias2(alias1((depth0 != null ? depth0.inventory_no : depth0), depth0))
    + "\">\n              <span class=\"gavelIcon\"></span>\n            </button>\n          </div>\n          <div class=\"fl\">\n            <div class=\"timerWrap count_down_timer_"
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\">\n              <div class=\"mask\">\n                <div class=\"timerNotice\">\n                  <span data-label=\"lb_nego_remain_time\"></span><br />\n                  <span class=\"remain_time_little\"></span>    <!-- 30分以内の場合\"<br>わずか！\"を付与する -->\n                </div>\n                <div class=\"timeLabel\">\n                  <span data-label=\"lb_nego_timelimit\"></span><br>\n                  <span class=\"limitTime\">00:00</span>\n                </div>\n              </div>\n              <div class=\"timer\">\n                <div class=\"timerInner1\"></div>\n                <div class=\"timerInner2\"></div>\n              </div>\n              <div class=\"timerSecWrap\">\n                <div class=\"timerSecInner\">\n                  <div class=\"timerSec1\"></div>\n                  <div class=\"timerSec2\"></div>\n                </div>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class=\"otherDetails\">\n          <div>\n            <span data-label=\"lb_nego_inventory_no\"></span>\n            <span class=\"inf\">"
    + alias2(alias1((depth0 != null ? depth0.inventory_no : depth0), depth0))
    + "</span>\n          </div>\n          <div>\n            <span data-label=\"lb_nego_color\"></span>\n            <span class=\"inf\">"
    + ((stack1 = (helpers.getColorLabel || (depth0 && depth0.getColorLabel) || alias4).call(alias3,(depth0 != null ? depth0.outcolor : depth0),{"name":"getColorLabel","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n          </div>\n          <div>\n            <span data-label=\"lb_nego_inventory_year\"></span>\n            <span class=\"inf\">"
    + alias2(alias1((depth0 != null ? depth0.model_year : depth0), depth0))
    + "</span>\n          </div>\n          <div>\n            <span data-label=\"lb_nego_mileage\"></span>\n            <span class=\"inf\">"
    + alias2(alias1((depth0 != null ? depth0.mileage : depth0), depth0))
    + "</span>\n          </div>\n          <div>\n            <span data-label=\"lb_nego_estimation\"></span>\n            <span class=\"inf\">"
    + alias2(alias1((depth0 != null ? depth0.estimation : depth0), depth0))
    + "</span>\n          </div>\n          <div>\n            <span data-label=\"lb_nego_inspect\"></span>\n            <span class=\"inf\">"
    + alias2(alias1((depth0 != null ? depth0.inspect_expire_date : depth0), depth0))
    + "</span>\n          </div>\n        </div>\n      </div>\n      <div class=\"clear\"></div>\n      <div class=\"priceDetails\">\n        <div>\n          <span data-label=\"lb_nego_owner_price1\"></span>\n"
    + ((stack1 = (helpers.isBigger || (depth0 && depth0.isBigger) || alias4).call(alias3,(depth0 != null ? depth0.owner_price : depth0),(depth0 != null ? depth0.owner_hope_price : depth0),{"name":"isBigger","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\n        <div>\n"
    + ((stack1 = (helpers.isContract || (depth0 && depth0.isContract) || alias4).call(alias3,(depth0 != null ? depth0.nego_status : depth0),{"name":"isContract","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "")
    + "          <span class=\"ammount\">"
    + alias2((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || alias4).call(alias3,(depth0 != null ? depth0.buyer_hope_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>\n        </div>\n      </div>\n    </div>\n    <input class=\"nego_id\" type=\"hidden\" value=\""
    + alias2(alias1((depth0 != null ? depth0.nego_id : depth0), depth0))
    + "\"></input>\n    <label class=\"new_flag\" value=\"\"></label>\n  </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "      <div><img src=\"../images/noPhoto_s.jpg\"></div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      "
    + ((stack1 = (helpers.outputImageForList || (depth0 && depth0.outputImageForList) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.image : depth0),{"name":"outputImageForList","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      </label>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "<span class=\"fine\"></span>";
},"8":function(container,depth0,helpers,partials,data) {
    return "<span class=\"sfine\"></span>";
},"10":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <span class=\"oldAmmount\">"
    + alias3((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || alias2).call(alias1,(depth0 != null ? depth0.owner_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>\n            <span class=\"ammount\">"
    + alias3((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || alias2).call(alias1,(depth0 != null ? depth0.owner_hope_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"ammount\">"
    + container.escapeExpression((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.owner_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "            <span data-label=\"lb_nego_result_price1\"></span>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "            <span data-label=\"lb_nego_buyer_price1\"></span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<ul class=\"listType1\" style='overflow:hidden;'>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.list : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</ul>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}