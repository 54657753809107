Application = require 'application'
View        = require 'views/view'
Template    = require 'views/templates/search_car_name'
IndicatorView   = require 'views/indicator_view'
###
 [車両検索] 車名選択ビュー
###
module.exports = class SearchCarNameView extends View
  id          : 'search_car_name'
  template    : Template

  events:
    'click #btnPrev'             : 'goPrev'
    'click #btnSearchConditions' : 'goNext'
    # 'click #btnSearchExec'       : 'goResult'
    'click #btnAllClear'         : 'clearAll'
    'click #btnAllCheck'         : 'checkAll'
    'click .CarName'            : 'appendSelectCar'
    'click .checkedCarName'     : 'checkChancel'


  # 初期化
  initialize: ->
    _.bindAll @

  # 起動
  start: ->
    # コールバック解除
    @options.searchModel.unbind 'GetCarName'
    #@options.searchModel.unbind 'GetSearchCount'
    @indicator = new IndicatorView
      title   : Backbone.AUCNET.message.get "ms_indicator_carname_title"
    $('body').append(@indicator.render().el)
    # モデルにイベントとコールバックを設定
    @options.searchModel.bind 'GetCarName', @render
    #@options.searchModel.bind 'GetSearchCount', @setSearchCount
    $("#searchCount").remove()

    # 車名取得
    @options.searchModel.getCarName()

  # hbsに書き出すデータを指定
  getRenderData: ->
    carNameList : @options.searchModel.get('CarName')


  # レンダリング後の処理
  afterRender: ->
    @indicator.suicide()
    # コールバック解除
    @options.searchModel.unbind 'GetCarName'
    @setSearchCount "- - - - - -"

  # メーカー選択画面へ遷移
  goPrev: (event) ->
    # 条件をクリア
    @options.searchModel.setSelectedCarNameList undefined
    # メーカー選択画面を表示
    @options.parent.changeChildView 'search_maker', false

  # 条件選択画面へ遷移
  goNext: (event) ->
    # HTMLから車名取得
    carNames = @$el.find(".CarName")
    # チェック済みアイテム取得
    checkedCarNames = @getCheckedItem(carNames)
    if _.isEmpty(checkedCarNames)
      @showMsgBox("ms_search_car_name_error", "ms_search_car_name_error_title")
    else
      if @getCheckedItemCount(carNames) > Backbone.AUCNET.DEFINE.get "SEARCH_LIMIT"
        # 検索上限を超えた場合はメッセージを表示
        @showMsgBox("ms_search_car_name_limit_over", "ms_search_car_name_error_title")
      else
        # HTMLから車名取得
        carNames = @$el.find(".CarName")
        # 選択車名をモデルに設定
        @options.searchModel.setSelectedCarNameList @getCheckedItem(carNames)
        # 検索条件画面を表示
        @options.parent.changeChildView 'search_conditions', true
        # アニメーションクラスを削除
        #@$el.find("#btnSearchConditions").removeClass("flash animated")

  # 検索結果画面へ遷移
  goResult: (event) ->
    # HTMLから車名取得
    carNames = @$el.find(".CarName")
    # チェック済みアイテム取得
    checkedCarNames = @getCheckedItem(carNames)
    if _.isEmpty(checkedCarNames)
      @showMsgBox("ms_search_car_name_error", "ms_search_car_name_error_title")
    else
      if @getCheckedItemCount(carNames) > Backbone.AUCNET.DEFINE.get "SEARCH_LIMIT"
        # 検索上限を超えた場合はメッセージを表示
        @showMsgBox("ms_search_car_name_limit_over", "ms_search_car_name_error_title")
      else
        # HTMLから車名取得
        carNames = @$el.find(".CarName")
        # 選択車名をモデルに設定
        @options.searchModel.setSelectedCarNameList @getCheckedItem(carNames)
        # 検索結果画面を表示
        @options.parent.changeChildView 'search_result', true
        # アニメーションクラスを削除
        #@$el.find("#btnSearchConditions").removeClass("flash animated")

  # 選択クリア
  clearAll: ->
    $("#checkedCarName").empty()
    carNames = @$el.find('.CarName')
    for item in carNames
      item.checked = false
    @changeCond()

  checkAll: ->
    carNames = @$el.find('.CarName')
    checkedCar = []
    for item in carNames
      if item.checked is false
        item.checked = true
        checkedCar.push item.value

    for carData in @options.searchModel.get('CarName')
      if _.indexOf(checkedCar, carData.car_name_id) >= 0
        checkdCarName = "<li class='checkedCarName' id='" + carData.car_name + "' >"
        checkdCarName += carData.car_name
        checkdCarName += " [" + carData.count + "]"
        checkdCarName += "</li>"
        $("#checkedCarName").append(checkdCarName)
    @changeCond()


  # 条件変更
  changeCond: ->
    # HTMLから車名取得
    carNames = @$el.find(".CarName")
    # チェック済みアイテム取得
    checkedCarNames = @getCheckedItem(carNames)
    if _.isEmpty(checkedCarNames)
      @setSearchCount "- - - - - -"
      # 未チェックの場合アニメーションクラスを削除
      @$el.find("#btnSearchConditions").removeClass("flash animated")
      $("#btnSearchExec").removeClass("flash animated")
    else
      # カウンタ取得
      @setSearchCount(@getCheckedItemCount(carNames))
      #@options.searchModel.setSelectedCarNameList checkedCarNames
      #@options.searchModel.getSearchCount @options.searchModel.getSearchConditions()
      # チェック済みの場合アニメーションクラスを設定
      @$el.find("#btnSearchConditions").addClass("flash animated")
      #$("#btnSearchExec").addClass("flash animated")


  checkChancel : (param) ->
    $("[data-name=" + param.currentTarget.id + "]").attr("checked", false)
    $("##{param.currentTarget.id}").remove()
    @changeCond()

  appendSelectCar : (param) ->
    ua = navigator.userAgent
    isIE = ua.match(/msie/i)
    if isIE
      if event.srcElement.checked
        checkdCarName = "<li class='checkedCarName' id='" + event.srcElement.getAttribute('data-name') + "' >"
        checkdCarName += event.srcElement.getAttribute('data-name')
        checkdCarName += " [" + event.srcElement.getAttribute('data-count') + "]"
        checkdCarName += "</li>"
        $("#checkedCarName").append(checkdCarName)
      else
        $("##{event.srcElement.getAttribute('data-name')}").remove()

    else
      if param.currentTarget.checked
        checkdCarName = "<li class='checkedCarName' id='" + param.currentTarget.dataset.name + "' >"
        checkdCarName += param.currentTarget.dataset.name
        checkdCarName += " [" + param.currentTarget.dataset.count + "]"
        checkdCarName += "</li>"
        $("#checkedCarName").append(checkdCarName)
      else
        $("##{param.currentTarget.dataset.name}").remove()

    @changeCond()

  # カウント設定
  setSearchCount: (param) ->
    #@$el.find("#searchCount").remove()
    #@$el.find("#btnSearchExec").append('<span id="searchCount">' + param + '</span>')
    $("#searchCount").remove()
    $("#btnSearchExec").append('<span id="searchCount" class="searchCountClass">' + param + '</span>')
    if param > 0
      $("#btnSearchExec").addClass("flash animated")
    else
      $("#btnSearchExec").removeClass("flash animated")

  # ボタン制御
  #setButtonEnable: (bool) ->
  #  btnSearch = @$el.find("#btnSearchExec")
  #  btnSearchCond = @$el.find("#btnSearchConditions")
  #  if bool
  #    btnSearch.addClass("btn_enabled").removeClass("btn_disabled")
  #    btnSearch.removeAttr("disabled")
  #    btnSearchCond.addClass("btn_enabled").removeClass("btn_disabled")
  #    btnSearchCond.removeAttr("disabled")
  #  else
  #    btnSearch.addClass("btn_disabled").removeClass("btn_enabled")
  #    btnSearch.attr("disabled", "disabled")
  #    btnSearchCond.addClass("btn_disabled").removeClass("btn_enabled")
  #    btnSearchCond.attr("disabled", "disabled")

  # チェック済みアイテム取得
  getCheckedItem: (array) ->
    retArray = []
    for item in array
      if item.checked then retArray.push item.value
    return retArray

  # チェック済みアイテムの件数取得
  getCheckedItemCount: (array) ->
    retSum = 0
    for item in array
      if item.checked then retSum+=parseInt(item.getAttribute('data-count'))
    return retSum
