View        = require 'views/view'
template    = require 'views/templates/request_conditions_line'

###
 [リクエスト条件の一つを表示]
###
module.exports = class RequestConditionsLineView extends View
  template : template


  initialize: ->
    #console.debug "RequestConditionsLineView#initialize"
    _.bindAll @

  afterRender: ->



  getRenderData: ->
    # データ編集
    DEFINE = Backbone.AUCNET.DEFINE
    @_transmission_type = switch  @model.get("transmission_type")
      when DEFINE.get "TRANSMISSION_TYPE_OTHER"   then DEFINE.get "TRANSMISSION_TYPE_OTHER_LABEL"
      when DEFINE.get "TRANSMISSION_TYPE_MT"      then DEFINE.get "TRANSMISSION_TYPE_MT_LABEL"
      when DEFINE.get "TRANSMISSION_TYPE_AT"      then DEFINE.get "TRANSMISSION_TYPE_AT_LABEL"
      when DEFINE.get "TRANSMISSION_TYPE_UNKNOWN" then DEFINE.get "TRANSMISSION_TYPE_UNKNOWN_LABEL"
      else ""

    # 車検(０：なし　１：あり)
    if @model.get("inspect_flag") == '1'
        @_inspect_on = DEFINE.get 'INSPECT_FLAG_ON'
    else
        @_inspect_on = DEFINE.get 'INSPECT_FLAG_OFF'

    # メール通知(０：なし　１：あり)
    if @model.get("mail_notice_flag") == '1'
        @_mail_notice_flag = DEFINE.get 'INSPECT_FLAG_ON'
    else
        @_mail_notice_flag = DEFINE.get 'INSPECT_FLAG_OFF'

    # リクエスト番号
    request_no         : @model.get("request_no")
    # リクエスト名
    request_name       : @model.get("request_name")
    # メーカグループ名
    maker_group_name   : @model.get("maker_group_name")
    # 車種大分類名
    vsyasyuclass_nm    : @model.get("vsyasyuclass_nm")
    # 年式（最小値）
    model_year_min     : @model.get("model_year_min").substr(0, 2) + DEFINE.get "YEAR" if @model.get("model_year_min")
    # 年式（最大値）
    model_year_max     : @model.get("model_year_max").substr(0, 2) + DEFINE.get "YEAR" if @model.get("model_year_max")
    # 型式
    style_list         : @model.get("style_list")
    # 走行距離（最小値）
    mileage_min        : @model.get("mileage_min") + 'km' if @model.get("mileage_min")
    # 走行距離（最大値）
    mileage_max        :  @model.get("mileage_max") + 'km' if @model.get("mileage_max")
    # 変速機タイプ（空白、MT、AT）（シフト）
    transmission_type  : @_transmission_type
    # 車検(あり)(０or１)
    inspect_flag       : @_inspect_on
    # 画面評価点（最小値）
    raw_estimation_min : @model.get("raw_estimation_min")
    # 画面評価点（最大値）
    raw_estimation_max : @model.get("raw_estimation_max")
    # リクエスト日
    request_date       : @model.get("request_date")
    # 有効回数
    basis_count        : @model.get("basis_count")
    # 残り回数
    remaining_count    : @model.get("remaining_count")
    # ヒット台数
    hit_count          : @model.get("hit_count")
    # メール通知
    mail_notice_flag   : @_mail_notice_flag
    # グレード名（複数選択）
    grade_name_list    : @model.get("grade_name_list")
    # 系統色名称（半角カタカナ・半角英字で記述する）
    outcolor_name_list : @model.get("outcolor_name_list")
