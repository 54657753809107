var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "          <option value=\""
    + alias2(alias1((depth0 != null ? depth0.message_cd : depth0), depth0))
    + "\" data-hasprice=\""
    + alias2(alias1((depth0 != null ? depth0.hasprice : depth0), depth0))
    + "\" data-message=\""
    + alias2(alias1((depth0 != null ? depth0.message : depth0), depth0))
    + "\" data-label=\""
    + alias2(alias1((depth0 != null ? depth0.lbbutton : depth0), depth0))
    + "\">\n          </option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <button type='button' id=\"btn_nego_admin_cancel\" class=\"sendBtn\" data-label=\"lb_nego_admin_cancel\"></button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\" nego_send_block\"> 商談メッセージを選んでください\n  <div>\n    <div class=\"\">\n      <select id=\"nego_select_send_messages\" size=\"5\" style=\"padding:10px;\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.messages : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </select>\n    </div>\n    <div class=\"clear\"></div>\n  </div>\n  <div class=\"chatInput\">\n    <span class=\"zeros2 chat_input_span\"  data-label=\"lb_input_price\"></span>\n    <input type=\"text\" class=\"ammountInput_ten\" id=\"nego_input_ten_price\" maxlength=\"5\">\n    <span class=\"zeros2 chat_input_span\" data-label=\"lb_unit_ten_thousand\">万</span>\n    <input type=\"text\" class=\"ammountInput\" id=\"nego_input_price\" maxlength=\"1\" value=\"0\">\n    <span class=\"zeros2 chat_input_span\" data-label=\"lb_unit_thousand\">千</span>\n    <span class=\"zeros2 chat_input_span\" data-label=\"lb_unit_currency\">円</span>\n    <div class=\"clear\"></div>\n  </div>\n  <button type='button' class=\"nego_btn_preview sendBtn\" data-label=\"lb_btn_preview\"></button>\n"
    + ((stack1 = (helpers.isAdmin || (depth0 && depth0.isAdmin) || helpers.helperMissing).call(alias1,"",{"name":"isAdmin","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}