Model = require 'models/model'
labelData = require 'data/labels'

###
 ラベル
###
module.exports = class Label extends Model

  initialize: ->
    _.bindAll @

  getLabel: (languageid = "") ->
    # 言語IDに合致するラベル情報を取得
    data = (item for item in labelData when item.id is languageid)
    @set data[0]
