Model   = require 'models/model'

###
  商談一覧検索条件モデル
###
module.exports = class NegolistSearchModel extends Model

  socket: null
  defaults :
    disp :
      limit : 50

  GET_NEGOLIST_CONDITIONS = "GetNegolistConditions"
  GET_NEGOLIST_GRADES     = "GetNegolistGrades"

  initialize: ->
    _.bindAll @
    @socket = Backbone.AUCNET.socket
    @offSockets()
    @onSockets()

  offSockets: ->
    @socket.removeAllListeners GET_NEGOLIST_CONDITIONS
    @socket.removeAllListeners GET_NEGOLIST_GRADES

  onSockets:->
    @socket.on GET_NEGOLIST_CONDITIONS    , (d) =>
      data = JSON.parse d
      @set data
      @trigger GET_NEGOLIST_CONDITIONS
    @socket.on GET_NEGOLIST_GRADES        , (d) =>
      data = JSON.parse d
      @set "grades", data.grades
      @trigger GET_NEGOLIST_GRADES

  emitGetNegolistConditions:(negolistKbn, conditions) ->
    @socket.emit GET_NEGOLIST_CONDITIONS,
      negolistkbn : negolistKbn
      conditions  : conditions

  # グレード取得
  emitGetNegolistGrades:(negolistKbn, conditions) ->
    @socket.emit GET_NEGOLIST_GRADES,
      negolistkbn : negolistKbn
      conditions  : conditions

  clearGrades: ->
    @set "grades" , []
    @trigger GET_NEGOLIST_GRADES
