var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div id=\"subheader\">\n  <div class=\"subheaderTitle\" data-label=\"lb_subheadertitle\"></div>\n  <div id=\"tabmenubuttons\">\n    <button type='button' id=\"tab1\" data-label=\"lb_tab1\"></button>\n  </div>\n  <button type='button' id=\"searchtool\" data-label=\"lb_searchtool\"></button>\n  <div id=\"changeviewbuttons\">\n    <button type='button' id=\"changeblockview\" data-label=\"lb_changeblockview\"></button>\n    <button type='button' id=\"changelistview\" data-label=\"lb_changelistview\"></button>\n  </div>\n</div>";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}