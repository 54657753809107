Collection   = require 'models/collection'

###
 お知らせコレクション
###
module.exports = class NoticeCollection extends Collection

  socket: undefined

  # クラス名
  CLASS_NAME = 'NoticeCollection'

  #イベント名
  EV_GET_ALL_NOTICE_LIST = 'GetAllNoticelist'
  EV_REGIST_NOTICE_READ_FLG = 'RegistNoticeReadFlg'

  initialize:(models, options) =>
    funcName = 'initialize'
    #console.debug CLASS_NAME + '#' + funcName
    _.bindAll @
    @socket = Backbone.AUCNET.socket
    
    @socket.removeAllListeners EV_GET_ALL_NOTICE_LIST
    @socket.on EV_GET_ALL_NOTICE_LIST, (d)=>
      @reset JSON.parse d

  # お知らせ情報を取得
  getAllNoticelist: (memberCD) =>
    funcName = 'getAllNoticelist'
    #console.debug CLASS_NAME + '#' + funcName
    @socket.emit EV_GET_ALL_NOTICE_LIST

  # お知らせ既読フラグ登録
  registNoticeReadFlg: (noticeNo) =>
    funcName = 'registNoticeReadFlg'
    #console.debug CLASS_NAME + '#' + funcName
    @socket.emit EV_REGIST_NOTICE_READ_FLG,
      noticeno : noticeNo
