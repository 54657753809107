View       = require 'views/view'
template   = require 'views/templates/negolistadmin_page'
application = require 'application'

# 過去/進行中切り替え
NegolistTab        = require 'views/negolist_tab_view'
# 検索ツール
NegolistSearch     = require 'views/negolist_search_view'
# ページング付き商談一覧表示
NegolistList       = require 'views/negolist_list_inc_paging_view'
# 商談一覧コレクション
NegoCollection     = require 'models/nego_collection'
# 検索条件モデル
NegolistSearchModel = require 'models/negolist_search_model'


###
 管理者商談一覧画面
###
module.exports = class NegolistPageView extends View
  id        : 'negolist_page'
  template  : template
  className : ''

  # ブロック（b）、リスト(l)表示
  mode : "b"
  mode_my_past : "b"
  mode_my_ongoing : "b"
  # 画面初期化時にどちらを表示するのかを選択する（o：商談中画面　p：商談履歴）
  initial_display_screen : "o"

  # 現在表示中のリスト
  showingView : null

  initialize: ->
    _.bindAll @
    #console.log "管理者商談一覧画面の初期化"
    if @options.display_screen
      @initial_display_screen = @options.display_screen

  beforeRender: ->
    # GETパラメータの除去
    window.history.pushState null, null, location.pathname + location.hash

  afterRender: ->
    # 過去商談
    @negoCollectionAdminPast      = new NegoCollection [],
      eventName : "GetNegolistadminPast"
    # 進行中商談
    @negoCollectionAdminOngoing   = new NegoCollection [],
      eventName : "GetNegolistadminOngoing"
    # 過去商談/進行中商談切り替え
    @negolistTab        = new NegolistTab
      collection : @negoCollectionAdminOngoing

    # タイマーの初期化
    if Backbone.AUCNET.timerArray_list.length > 0
      for timer_item in Backbone.AUCNET.timerArray_list
        clearInterval timer_item
      Backbone.AUCNET.timerArray_list = new Array()

    @negolistListAdminOngoing  = new NegolistList
      collection : @negoCollectionAdminOngoing
      id         : "negolist_list_admin_ongoing"
      ongoing    : true
      past       : false
      admin      : true
    if @initial_display_screen is 'p'
      @negolistListAdminPast     = new NegolistList
        collection : @negoCollectionAdminPast
        id         : "negolist_list_admin_past"
        ongoing    : false
        past       : true
        admin      : true

    # 検索条件過去
    @searchPastModel = new NegolistSearchModel
    # 検索条件進行
    @searchOngoingModel = new NegolistSearchModel
    # 検索ツール
    @negolistSearchView = new NegolistSearch
      model : @searchOngoingModel
      currentScreenIsNowNego : if @initial_display_screen is 'o' then true else false
    # イベントリスナ
    # ヘッダでの切り替えイベント
    #@listenTo @negolistTab           , "ShowMyOngoing"     , @showMyOngoing
    @listenTo @negolistTab           , "ShowMyOngoing"     , @showMyOngoingReload
    #@listenTo @negolistTab           , "ShowMyPast"        , @showAdminPast
    @listenTo @negolistTab           , "ShowMyPast"        , @showAdminPastReload
    @listenTo @negolistTab           , "ShowAllOngoing"    , @showAllOngoing
    # 検索条件取得
    @listenTo @negolistSearchView    , "FireSearch"        , @kickSearch
    if @initial_display_screen is 'o'
      # ページング
      @listenTo @negolistListAdminOngoing , "ChangePage"     , @changePage
      # 新着
      @listenTo @negolistListAdminOngoing , "GetNewItems"    , @getNewItems
    else
      # ページング
      @listenTo @negolistListAdminPast    , "ChangePage"     , @changePage
      # 新着
      @listenTo @negolistListAdminPast    , "GetNewItems"    , @getNewItems

    # コンテンツの組み立て
    @$el.find("#negolist_child_search_tool_area")
        .append(@negolistSearchView.render().el)

    # 商談中表示か商談履歴のどちらを表示するのか決める
    if @initial_display_screen is 'o'
      @$el.find("#negolist_child_area")
        .append(@negolistTab.render().el)
        .append(@negolistListAdminOngoing.el)

      @showAdminOngoing()
    else
      @$el.find("#negolist_child_area")
        .append(@negolistTab.render().el)
        .append(@negolistListAdminPast.el)

      @showAdminPast()

    @setSearchDisp()

  # -------------------------
  # 商談履歴の画面から、商談中への画面遷移の場合は再描画を起こす
  # 商談が終了したデータを表示させない為
  # -------------------------
  showMyOngoingReload: ()->
    #console.log "商談中表示か商談履歴のどちらを表示するのか決める  showMyOngoingReload"
    application.router.initial_display_screen_negolist = 'o'
    # メニューのボタンを押したのと同じ動きをします
    $(".negolistadminBtn.menus_link").click()

  showAdminPastReload: ()->
    #console.log "商談中表示か商談履歴のどちらを表示するのか決める  showAdminPastReload"
    application.router.initial_display_screen_negolist = 'p'
    # メニューのボタンを押したのと同じ動きをします
    $(".negolistadminBtn.menus_link").click()

  # -------------------------
  # 検索部分の表示制御
  # -------------------------
  setSearchDisp: ()->
    # 売り店舗コード
    @$el.find("#negolist_input_owner_member_div").show()
    # 買い店舗コード
    @$el.find("#negolist_input_buyer_member_div").show()
    # 売り買い区分
    @$el.find("#negolist_search_nego_buy_sell_kbn_div").hide()
    # 検索ツール表示変更
    @$el.find("#negolist_search_nego_status_div").hide()

  # -------------------------
  # 検索実行
  # -------------------------
  kickSearch:(conditions) ->
    @showingView.setPageNum 0
    @showingView.getSearchNegolist conditions

  # -------------------------
  # ページング
  # -------------------------
  changePage:(ret) ->
    @negolistSearchView.setLimitAndOffset ret
    conditions = @negolistSearchView.getSearchConditions()
    @showingView.getSearchNegolist conditions

  # -------------------------
  # 新着順でアイテム取得
  # -------------------------
  getNewItems: ->
    # サーチビューの検索条件をリセット
    @negolistSearchView.setDispNewItemConditions()
    conditions =
      sortkbn : Backbone.AUCNET.DEFINE.get 'NEGOLIST_SORT_KBN_NEW'
    @showingView.getSearchNegolist conditions

  # -------------------------
  # ブロック、リスト表示切替
  # -------------------------
  #changeDisplayMode:(mode) ->
  #  console.log "ここ入ってんの？"
  #  @mode = mode
  #  @showingView.changeDisplayMode(@mode)

  # -------------------------
  # 現在進行中商談表示
  # -------------------------
  showAdminOngoing: ->
    # 表示中のビューを変更
    @showingView = @negolistListAdminOngoing
    #@showingView.changeDisplayMode(@mode)

    lsStringMyOngoing = localStorage.getItem('negolist_list_admin_ongoing.list_disp_mode')
    if lsStringMyOngoing?
      @mode_my_ongoing = lsStringMyOngoing

    @showingView.changeDisplayMode(@mode_my_ongoing)

    # 検索ツール設定変更
    @negolistSearchView.setConditions(Backbone.AUCNET.DEFINE.get "NEGOLIST_KBN_ADMIN_ONGOING")
    # 検索ツール表示変更
    @negolistSearchView.clearDisp()
    @$el.find("#negolist_search_nego_status_div").hide()
    @$el.find("label.past_only").hide()
    @$el.find("#negolist_search_radio_sort input").attr("checked", false)
    @$el.find("label.ongoing_only").show().children("input:first").attr("checked", true)
    # モデル入れ替え
    @searchPastModel.offSockets()
    @searchOngoingModel.onSockets()
    @negolistSearchView.model = @searchOngoingModel

    @$el.find("#negolist_btn_my_ongoing"  ).css({color:"red"}).addClass('active')
    @$el.find("#negolist_btn_my_past"     ).css({color:"black"}).removeClass('active')
    @$el.find("#negolist_btn_all_ongoing" ).css({color:"black"})
    @$el.find("#negolist_list_admin_ongoing" ).show()
    @$el.find("#negolist_list_admin_past"    ).hide()
    # 車輌の項目
    @$el.find("div.ongoing_only").show()
    @$el.find("div.past_only"   ).hide()

  # -------------------------
  # 過去商談表示
  # -------------------------
  showAdminPast: ->
    @showingView = @negolistListAdminPast
    #@showingView.changeDisplayMode(@mode)

    lsStringMyPast = localStorage.getItem('negolist_list_admin_past.list_disp_mode')
    if lsStringMyPast?
      @mode_my_past = lsStringMyPast

    @showingView.changeDisplayMode(@mode_my_past)

    @negolistSearchView.setConditions(Backbone.AUCNET.DEFINE.get "NEGOLIST_KBN_ADMIN_PAST")
    # 検索ツール表示変更
    @negolistSearchView.clearDisp()
    @$el.find("#negolist_search_nego_status_div").show()
    @$el.find("label.ongoing_only").hide()
    @$el.find("#negolist_search_radio_sort input").attr("checked", false)
    @$el.find("label.past_only").show().children("input:first").attr("checked", true)
    # モデル入れ替え
    @searchOngoingModel.offSockets()
    @searchPastModel.onSockets()
    @negolistSearchView.model = @searchPastModel

    @$el.find("#negolist_btn_my_ongoing"  ).css({color:"black"}).removeClass('active')
    @$el.find("#negolist_btn_my_past"     ).css({color:"red"}).addClass('active')
    @$el.find("#negolist_btn_all_ongoing" ).css({color:"black"})
    @$el.find("#negolist_list_admin_ongoing" ).hide()
    @$el.find("#negolist_list_admin_past"    ).show()
    # 車輌の項目
    @$("div.ongoing_only").hide()
    @$("div.past_only"   ).show()

  # -------------------------
  # 後始末
  # -------------------------
  suicide: ->
    @negolistTab?.remove()
    @negoCollectionAdminPast?.stopListening()
    @negoCollectionAdminPast?.remove()
    @negoCollectionAdminOngoing?.stopListening()
    @negoCollectionAdminOngoing?.remove()
    @negolistListAdminPast?.remove()
    @negolistListAdminOngoing?.remove()
    @negolistSearchView?.remove()
    @remove()
