var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "  <button type='button' class=\"clear btnChkAllClear\" data-label=\"lb_all_clear\">全て解除</button>\n  <button type='button' class=\"select btnChkAllSelect\" data-label=\"lb_all_select\">全て選択</button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"negoBtn btnApplyNego\">\n    <span class=\"gavelIcon\"> </span>\n    <label class=\"\" data-label=\"lb_candidate_nego_apply_btn\"></label>\n  </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"clear\"></div>\n<div class=\"negoBtnCont\" id=\"negoBtn\">\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMultiNego : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  <button type='button' class=\"items btnRemoveAll\">\n    <span class=\"icon2label\"data-label=\"lb_candidate_remove_all\"></span>\n  </button>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMultiNego : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}