Application = require 'application'
View        = require 'views/view'
Template    = require 'views/templates/search_method'

###
 [車両検索] 検索方法選択ビュー
###
module.exports = class SearchMethodView extends View
  id          : 'search_method'
  template    : Template

  events:
    'click #btnSearchMaker' : 'gotoSearchMaker'
    'click #btnSearchInvNo' : 'gotoSearchInvNo'

  # 初期化
  initialize: ->
    _.bindAll @

  # 起動
  start: ->
    # コールバック解除
    @options.searchModel.unbind 'GetCarName'
    @options.searchModel.unbind 'GetSearchCount'
    #@indicator = new IndicatorView
    #  title   : Backbone.AUCNET.message.get "ms_indicator_carname_title"
    #  message : Backbone.AUCNET.message.get "ms_indicator_carname_message"
    #@$el.append(@indicator.render().el)
    # モデルにイベントとコールバックを設定
    @options.searchModel.bind 'GetCarName', @render
    @options.searchModel.bind 'GetSearchCount', @setSearchCount

    # 車名取得
    @options.searchModel.getSearchCount @options.searchModel.getSearchConditions()
    # レンダリング
    @render()

  getRenderData: ->
    count : @options.searchModel.get('GetSearchCount')

  # メーカー選択画面へ遷移
  gotoSearchMaker: ->
    @options.searchModel.unbind 'GetCarName'
    @options.searchModel.unbind 'GetSearchCount'
    # メーカー選択画面を表示
    @options.parent.changeChildView 'search_maker', true

  # 問合番号入力画面へ遷移
  gotoSearchInvNo: ->
    @options.searchModel.unbind 'GetCarName'
    @options.searchModel.unbind 'GetSearchCount'
    # 問合番号入力画面を表示
    @options.parent.changeChildView 'search_inv_no', true

  # カウント設定
  setSearchCount: (param) ->
    @$el.find("#searchCarCount").html param
