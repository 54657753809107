var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <li>\n    <span class=\"color1\"></span>\n    <label>"
    + ((stack1 = (helpers.getColorLabel || (depth0 && depth0.getColorLabel) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.name : depth0),{"name":"getColorLabel","hash":{},"data":data})) != null ? stack1 : "")
    + "</label>\n    <input type=\"checkbox\" value=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.value : depth0), depth0))
    + "\">\n  </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "  <span data-label=\"\"></span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.eachArray || (depth0 && depth0.eachArray) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.list : depth0),{"name":"eachArray","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}