Model        = require 'models/model'

###
　メニュー遷移チェックを行うモデル
###
module.exports = class SendMenuCheckModel extends Model

  socket: undefined

  #持つべきデータをここに書く。
  defaults: 
    _login_id       : undefined
    _send_menu_auth : undefined
    _send_menu_flg  : undefined

  initialize: ->
    _.bindAll @

  sendMenuCheck: (param) ->
    #console.debug 'SendMenuCheckModel#sendMenuCheck'
    if Backbone.AUCNET.socket
      @socket = Backbone.AUCNET.socket

      @socket.on 'SendMenuCheck', (d) =>
        @socket.removeAllListeners 'SendMenuCheck'
        @socket = undefined
        @_send_menu_flg = JSON.parse(d)
        if @_send_menu_flg isnt true
          application.router.navigate 'error/E0002', true
          @trigger 'SendMenuCheck_NG'
        else
          @trigger 'SendMenuCheck_OK'

      @socket.emit 'SendMenuCheck',
        login_id       : @get('_login_id')
        send_menu_auth : @get('_send_menu_auth')
