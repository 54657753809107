View       = require 'views/view'
template   = require 'views/templates/nego_receive'
UtilConfirmView = require 'views/util_confirm_view'
MESSAGE         = require 'data/messages'
util            = require 'lib/util'

###
 [商談] 受信メッセージ表示
###
module.exports = class NegoReceiveView extends View
  id       : 'nego_receive'
  template : template

  collection :
    # メッセージログ
    log      : null
    # メッセージマスタ
    messages : null

  # --------------
  # 定数定義
  # --------------
  RC_OK                   : "0"  # 正常終了
  RC_NG_SYSTEMERR         : "9"  # 異常終了
  RC_NG_MULTIPLE_START    : "2"  # 多重起動エラー

  POPUP_MESSAGE_CD :
    SEND_OK                       : "08"
    SEND_NG                       : "09"
    NEGO_DISPLAY_ERR              : "10"
    START_NG                      : "11"

  # 商談ステータス
  NEGO_STATUS_REQUEST  : '1' # 申込中
  NEGO_STATUS_ONGOING  : '2' # 商談中
  NEGO_STATUS_CONTRACT : '3' # 成約
  NEGO_STATUS_FLOW     : '4' # 流れ
  NEGO_STATUS_CANCEL   : '5' # キャンセル

  events :
    "click button.nego_btn_renew" : "renewNegoMessage"    # 最新ボタン
    #"click button.nego_btn_send"  : "sendNegoMessage"     # 送信ボタン

  initialize: ->
    _.bindAll @
    @listenTo @collection.log, 'reset'               , @render
    @listenTo @collection.log, 'sort'                , @render
    @listenTo @collection.log, 'receiveSetNegoMessageResult', @receiveSetNegoMessageResult
    @listenTo @model         , 'SuccessJoinNegoRoom' , @successJoin
    @listenTo @model         , 'FailedJoinNegoRoom'  , @failedJoin

  getRenderData: ->
    messages = @collection.log.models
    _.each messages, (value, key, list) ->
      # メッセージラベルを文言を当てる
      options =
        price            : value.get("message_price")
        limithour        : value.get("limit_hour")
        api_err_cd       : value.get("api_err_cd")
        api_err_kbn      : value.get("api_err_kbn")
        admin_api_err_cd : value.get("admin_api_err_cd")

      if value.get("message_cd") is "A05"
        msg = util.editNegoMessage(@collection.messages, value.get("message_cd"), options)
      else
        msg = util.editNegoMessage(@collection.messages, value.get("message_cd"), options)
      value.set 'message', msg, {silent : true}

      # 発信者名を表示する
      if @model.get("role") is value.get("send_role_kbn")
        if @model.get("role") is "3"
          sendRoleKbn = "lb_send_role_kbn_" + value.get("send_role_kbn")
        else
          sendRoleKbn = "lb_send_role_kbn_4"
      else
        sendRoleKbn = "lb_send_role_kbn_" + value.get("send_role_kbn")

      value.set 'lb_send_role_kbn', sendRoleKbn, {silent : true}


    , @

    #確認待ち・成約メッセージを出力するかどうか
    agreeFlag = false
    systemMessageFlag = false

    #成約メッセージ時間
    agreeMessageTime = ""
    unless _.isEmpty(messages)
      #最終メッセージ取得
      lastMessage = messages[messages.length - 1]
      #console.log lastMessage
      #最終メッセージが成約メッセージの場合
      if lastMessage.get("nego_status") is "3"
        agreeFlag = true
        agreeMessageTime = lastMessage.get("send_date")
        message_price = Number(lastMessage.get("message_price"))

        man = message_price / 10000
        systemMessage = man + Backbone.AUCNET.message.get "ms_nego_agree"

      #成約メッセージが申し込み中、もしくは商談中の場合
      else if lastMessage.get("nego_status") is "1" or lastMessage.get("nego_status") is "2"
        systemMessage = Backbone.AUCNET.message.get "ms_nego_respons_wait"
        systemMessageFlag = true
        systemMessageClass = "chatBubbleResWait" + lastMessage.get("send_role_kbn")

    return {
      messages : @collection.log.toJSON()
      systemMessageFlag : systemMessageFlag
      systemMessageClass: systemMessageClass
      systemMessage     : systemMessage
      agreeFlag         : agreeFlag
      agreeMessageTime  : agreeMessageTime
    }

  afterRender: ->
    negoStatus = @model.get("nego_status")
    if negoStatus in [@NEGO_STATUS_CONTRACT, @NEGO_STATUS_FLOW, @NEGO_STATUS_CANCEL]
      @$el.find("button.nego_btn_renew").css("display","none")
    else
      @$el.find("button.nego_btn_renew").css("display","")

    # メッセージを最下部に
    $('#nego_receive .scrollable').scrollTop(9999999)

  receiveSetNegoMessageResult: (json) ->
    msgcd = null
    switch json.rc
      when @RC_OK
        # 送信成功の時はポップアップでユーザに通知しない
        msgcd = null #@POPUP_MESSAGE_CD.SEND_OK
      when @RC_NG_MULTIPLE_START
        msgcd = @POPUP_MESSAGE_CD.START_NG
      else
        msgcd = @POPUP_MESSAGE_CD.SEND_NG

    if json.rc.api_errflg
      @trigger 'alert_api', json.rc

    else
      @_alert(msgcd)     if msgcd?

  successJoin: ->
    #console.info "NegoReceiveView#successJoin"

  failedJoin: ->
    #console.info "NegoReceiveView#failedJoin"
    @_alert(@POPUP_MESSAGE_CD.NEGO_DISPLAY_ERR)

  renewNegoMessage: ->
    #console.log "NegoReceiveView#renewNegoMessage"
    @collection.log.getMessageAll()

  # -------------------------------------
  # 商談画面 OK専用のメッセージポップアップ表示
  # _id : "01"～"99"
  # -------------------------------------
  _alert: (_id, _param = null) ->
    title = @getMessage("ms_nego_msg" + _id + "_title")
    message = @getMessage("ms_nego_msg" + _id + "_message")
    if _param?
      message = message.replace(/%s/, _param)

    confirmView = new UtilConfirmView
      title   : title
      message : message
      cancelDelFlg : true
      callback : (ok)=>
        return ok
    @$el.append(confirmView.render().el)
