View       = require 'views/view'
template   = require 'views/templates/negolist_change_disp'

###
 商談一覧ヘッダ
 ブロック表示/リスト表示切り替えボタン
###
module.exports = class NegolistHeaderView extends View
  className : 'negolist_header'
  template  : template
  events :
    "click #negolist_header_input input[type=radio]" : "fireChangeDisplayMode"

  initialize: ->
    _.bindAll @

  # 表示切替
  fireChangeDisplayMode:(event) ->
    mode = @$(event.currentTarget).val()
    @trigger "ChangeDisplayMode", mode