# サイドページのコントローラ
View               = require 'views/view'
template           = require 'views/templates/util_side_page'

UserMenuView       = require 'views/util_user_menu_view'
SideNavigationView = require 'views/util_side_navigation_view'

module.exports = class SidePageView extends View
  id       : 'util_side_page'
  template : template

  # イベント設定
  events:
    # 画像クリック　＞　車両詳細表示
    'click .navBtn' : 'mobileHeaderMenuOpne'
    'touchstart .navBtn' : 'hoverStartMobile'
    'touchend   .navBtn' : 'hoverEndMobile'
    'mouseover  .navBtn' : 'hoverStartMobile'
    'mouseout   .navBtn' : 'hoverEndMobile'

  initialize: ->
    #console.debug "UtilSidePage#initialize"
    _.bindAll @

  # 縦横対応
  scrollfunction: =>
    scrollBy(0,1)
    #$("body").removeClass("scrollBlock");
    #_.delay @mobileHeaderMenuClose, 200

    #おぼつかない対応
    unless $(".lsidebar").hasClass("indent_menu")
      if Math.abs(window.orientation) is 90
        @render()
        $(".lsidebar").removeClass("indent_menu");

    @hoverEndMobile()
    

  # サイドページの組立
  afterRender: ->
    # モバイルヘッダーの再描画event設定
    @$el.find('.mobileHeader').off() 
    @$el.find('.mobileHeader').on 'orientationchange' , @scrollfunction

    @userMenuView.remove()       unless _.isEmpty(@userMenuView)
    @sideNavigationView.remove() unless _.isEmpty(@sideNavigationView)

    @userMenuView       = new UserMenuView
    @sideNavigationView = new SideNavigationView
    @$el.find(".menu_list_area")
        .append(@userMenuView.render().el)
        .append(@sideNavigationView.render().el)

    # メニューの背景色を変える
    @$el.find('.lsidebar').removeClass 'lsidebar_admin'
    @$el.find('.lsidebar').removeClass 'lsidebar_pretense'
    @$el.find('.mobileHeader').removeClass 'lsidebar_admin','lsidebar_pretense'

    # management_or_normal (0:一般会員、1:成り代わりログイン)
    # management_or_normal = sessionStorage.getItem('management_or_normal')
    # #11446 対応
    management_or_normal = localStorage.getItem('management_or_normal')
    # 通常会員画面へ
    if Backbone.AUCNET.roleID is '0'
      if management_or_normal is '1'
        @$el.find('.lsidebar').addClass 'lsidebar_pretense'
        @$el.find('.mobileHeader').addClass 'lsidebar_pretense'

    # 管理者画面へ
    else if Backbone.AUCNET.roleID is '1'
      if localStorage.getItem('roleID') is '1'
        @$el.find('.lsidebar').addClass 'lsidebar_admin'
        @$el.find('.mobileHeader').addClass 'lsidebar_admin'
      # 派遣さん画面へ
      else if localStorage.getItem('roleID') is '2'
        @$el.find('.lsidebar').addClass 'lsidebar_dispatch'
        @$el.find('.mobileHeader').addClass 'lsidebar_dispatch'
    else if Backbone.AUCNET.roleID is '2'
      @$el.find('.lsidebar').addClass 'lsidebar_admin'
      @$el.find('.mobileHeader').addClass 'lsidebar_admin'


  mobileHeaderMenuOpne: ->
    #$(window).off 'resize' , @mobileHeaderMenuClose
    #$(window).on 'resize' , @mobileHeaderMenuClose
    #$(".lsidebar").toggleClass("menu_scroll"); 
    $(".lsidebar").toggleClass("indent_menu"); 

  hoverStartMobile : (param) ->
    $(param.currentTarget).addClass "over_start_mobile_navBtn"

  hoverEndMobile : (param) ->
    $(param.currentTarget).removeClass "over_start_mobile_navBtn"
