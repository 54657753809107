Application = require 'application'
Model = require 'models/model'

###
 ログインユーザ
###
module.exports = class LoginUserModel extends Model

  socket: undefined

  defaults:
    _userid   : undefined
    _password : undefined
    _sessionID : undefined
    _userrole  : undefined
    loginCheckResult : undefined

  LOGIN_KBN_USER : 0
  LOGIN_ROLE_USER : '0'

  initialize: ->
    # ** 注意 **
    # ソケットに対するイベントハンドラはsetSocketで設定する
    _.bindAll @
    @socket = Backbone.AUCNET.socket

  ###
   ソケットをセットする
  ###
  setSocket : =>
    @socket.on 'login', (d) =>

      data = JSON.parse(d)
      if data.loginKbn is @LOGIN_KBN_USER
        @unset '_password', silent: true
        @set data
        localStorage.sessionID = data.sessionID
        localStorage.roleID = @LOGIN_ROLE_USER
        localStorage.hashUserID = data.hashUserID

        Backbone.AUCNET.sessionid = data.sessionID
        Backbone.AUCNET.roleID = @LOGIN_ROLE_USER
        Backbone.AUCNET.hashUserID = data.hashUserID
        #ソケット破棄
        @socket.removeAllListeners 'login'
        @trigger 'login_success', data

      if data.loginKbn is 1
        @trigger 'login_failed1'

      if data.loginKbn is 2
        @trigger 'login_failed2'

      if data.loginKbn is 3
        # 3(APIは投げたが認証失敗)の時はエラーコードが付加されている場合がある
        @trigger 'login_failed3', data.code

      if data.loginKbn is 4
        @trigger 'login_failed4'

    @socket.on 'logincheck', (d) =>
      #ソケット破棄
      @socket.removeAllListeners 'logincheck'
      data = JSON.parse(d)
      if data is true
        Backbone.AUCNET.sessionid = localStorage.sessionID
        Backbone.AUCNET.roleID = localStorage.roleID
        Backbone.AUCNET.hashUserID = localStorage.hashUserID
        @trigger 'login_success'

  ###
   ログインチェック用ソケットON(コールバック必須)
  ###
  logincheckSoketOn : (callback) =>
    @socket.on 'userlogincheck', (d) =>
      #ソケット破棄
      @socket.removeAllListeners 'userLoginCheck'
      data = JSON.parse(d)
      Backbone.AUCNET.sessionid = localStorage.sessionID
      Backbone.AUCNET.roleID = localStorage.roleID
      Backbone.AUCNET.hashUserID = localStorage.hashUserID
      Backbone.AUCNET.loginUser.set
        _userid   : data.userID
      callback data


  ###
   ログインチェック発火
  ###
  logincheck: ->
    @socket.emit 'userLoginCheck',
     sessionID : localStorage.sessionID

  # ログイン認証
  authenticate: ->
    @socket.emit 'login',
      userid   : @get('_userid')
      password : @get('_password')

  # ログオフ
  logoff: ->
    # タイマー監視関数の後始末
    if Backbone.AUCNET.timerArray.length > 0
      for timer_item in Backbone.AUCNET.timerArray
        clearInterval timer_item
      Backbone.AUCNET.timerArray = new Array()

    if Backbone.AUCNET.timerArray_list.length > 0
      for timer_item in Backbone.AUCNET.timerArray_list
        clearInterval timer_item
      Backbone.AUCNET.timerArray_list = new Array()

    @socket.on 'userlogoff', (d) =>
      @socket.removeAllListeners 'userlogoff'
      data = JSON.parse(d)

      #localStorage.clear()
      # ↑ページングや、表示切替の情報も消えてしまう為↓に修正
      localStorage.removeItem 'sessionID'
      localStorage.removeItem 'roleID'
      localStorage.removeItem 'hashUserID'
      Backbone.AUCNET.sessionid = ''
      Backbone.AUCNET.roleID = ''
      Backbone.AUCNET.hashUserID = ''

      Backbone.AUCNET.RenderController._killSubView()
      Application.router.navigate 'login', true

    @socket.emit 'userlogoff',
      sessionID : localStorage.sessionID

  ###
   メール認証
  ###
  mailActivate : (activationCode,callback) ->
    #メール関連設定の戻りは全てここになる。
    @socket.on 'mailActivation', (d) =>
      retJson = JSON.parse(d)
      negoApplyParam = retJson.negoApplyParam
      message = ""
      if retJson.error
        message = 'ms_mail_activation_error'
      else
        message = 'ms_mail_activation_ok'
        localStorage.sessionID = retJson.sessionID
        localStorage.roleID = @LOGIN_ROLE_USER
        localStorage.hashUserID = retJson.hashUserID

        Backbone.AUCNET.sessionid = retJson.sessionID
        Backbone.AUCNET.roleID = @LOGIN_ROLE_USER
        Backbone.AUCNET.hashUserID = retJson.hashUserID
        Backbone.AUCNET.loginUser.set
          _userid   : retJson.userID
      #ソケット破棄
      @socket.removeAllListeners 'mailActivation'
      return callback retJson.error , Backbone.AUCNET.message.get(message) , negoApplyParam
      #
    #発火
    @socket.emit 'mailActivation',
        activationCode : activationCode

  ###
   ANS認証
  ###
  ansLogin: (ansParams, callback) ->
    @socket.on 'ansLogin', (d) =>
      data = JSON.parse(d)
      #ソケット破棄
      @socket.removeAllListeners 'ansLogin'
      if data.loginKbn isnt @LOGIN_KBN_USER
        Application.router.navigate 'login', true
      else
        callback()
        @unset '_userid', silent: true
        @set data
        localStorage.sessionID = data.sessionID
        localStorage.roleID = @LOGIN_ROLE_USER
        localStorage.hashUserID = data.hashUserID

        Backbone.AUCNET.sessionid = data.sessionID
        Backbone.AUCNET.roleID = @LOGIN_ROLE_USER
        Backbone.AUCNET.hashUserID = data.hashUserID
        Backbone.AUCNET.loginUser.set
          _userid   : data.userID

        negoID   = data.negoID
        param_iy = data.ansParams.param_iy  # 車両問合番号(登録年)
        param_in = data.ansParams.param_in  # 車両問合番号(登録番号)
        switch data.ansParams.param_tc
          when "0" # 商談申込
            Application.router.navigate "negoapply/#{param_iy}/#{param_in}", true
          when "1" # 商談
            if _.isEmpty negoID
              Application.router.navigate Backbone.AUCNET.setting.init_disp, trigger: true
            else
              Application.router.navigate "nego/#{negoID}/#{param_iy}/#{param_in}", true
          when "2" # 新着
            #Application.router.navigate 'news', true
            Application.router.navigate Backbone.AUCNET.setting.init_disp, trigger: true
          when "3" # マイ商談
            Application.router.navigate 'negolist', true

    @socket.emit 'ansLogin',
      ansParams : ansParams


  reconnect : (callback) =>
    @socket.on 'userReconnect', (d) =>
      #ソケット破棄
      @socket.removeAllListeners 'userReconnect'
      data = JSON.parse(d)
      callback data

    @socket.emit 'userReconnect',
      sessionID : localStorage.sessionID
