application  = require 'application'

exports.socket_error = ->
  #console.error 'socket_error'
  #console.error 'websocket error[%s]', Date.now()
  # Websocket　の通信速度監視をオフ
  Backbone.AUCNET.utilConnectView.setStopingFlg undefined if Backbone.AUCNET.utilConnectView
  application.router.navigate 'error/E0001', true

exports.socket_disconnect = ->
  # Websocket　の通信速度監視をオフ
  Backbone.AUCNET.utilConnectView.setStopingFlg undefined if Backbone.AUCNET.utilConnectView
  application.router.navigate 'error/E0002', true

exports.socket_systemError = (d) ->
  json = JSON.parse d
  #console.error '[error message]' + json.errorcd
  @socket.emit 'disconnect'
  sessionID : localStorage.sessionID
  # Websocket　の通信速度監視をオフ
  Backbone.AUCNET.utilConnectView.setStopingFlg undefined if Backbone.AUCNET.utilConnectView
  application.router.navigate 'error/E0002', true
