Application = require 'application'
View        = require 'views/view'
Template    = require 'views/templates/setting_mail'
UtilConfirmView = require 'views/util_confirm_view'

###
 [車両検索] 検索方法選択ビュー
###
module.exports = class SettingMailView extends View
  id          : 'setting_mail'
  template    : Template
  parent      : undefined
  className : "SettingMailView"

  # 初期化
  initialize: ->
    _.bindAll @
    @listenTo @model, 'change', @render
    @listenTo @model, 'message', @renderErrorPopup

  # レンダリング後の処理
  afterRender: ->
    @trigger 'getDisable'

  getRenderData: ->
    title       : 'lb_mail_config'
    modelData     : @model.toJSON()

  renderErrorPopup : ->
    confirmView = new UtilConfirmView
      title    : Backbone.AUCNET.message.get 'ms_mail_error_title'
      message  : @model.get('message')
      cancelDelFlg : true
      # =>(ファットアロー)で渡すとcontextがここになる
      callback : (ok)=>
        # if ok
          #console.log  "CANCEL処理"
    @$el.append(confirmView.render().el)




