View       = require 'views/view'
Template   = require 'views/templates/news_page'
IndicatorView = require 'views/indicator_view'
NoticeList     = require 'views/notice_list_view'
NegolistList   = require 'views/negolist_list_view'
NegoCollection   = require 'models/nego_collection'

###
 新着画面
###
module.exports = class NewsPageView extends View
  id        : 'news_page'
  template  : Template
  className : ''

  events:
    'click #btnRefresh' : 'onClickRefresh'

  # 定数宣言
  CLASS_NAME = 'NewsPageView'  # クラス名
  EV_GET_NEGOLIST_MY_NEW_SELL = 'GetNegolistMyNewSell'
  EV_GET_NEGOLIST_MY_NEW_BUY = 'GetNegolistMyNewBuy'
  EV_SHOW_NOTICE_DETAIL = 'ShowNoticeDetail'
  EV_SHOW_UNREAD_COUNT = 'ShowUnreadCount'
  EV_HIDE_NOTICE_BLOCK = 'HideNoticeBlock'

  initialize: ->
    funcName = 'initialize'
    #console.debug CLASS_NAME + '#' + funcName

    _.bindAll @

    # お知らせ情報
    @noticeList = new NoticeList
      id         : 'notice_collection'

    # 商談情報（新着＆売り）
    @negoCollectionMyNewSell = new NegoCollection [],
      eventName : EV_GET_NEGOLIST_MY_NEW_SELL

    # 商談情報（新着＆買い）
    @negoCollectionMyNewBuy = new NegoCollection [],
      eventName : EV_GET_NEGOLIST_MY_NEW_BUY

    @listenTo @noticeList, EV_SHOW_NOTICE_DETAIL, @showNoticeDetail
    @listenTo @noticeList, EV_SHOW_UNREAD_COUNT, @showUnreadCount
    @listenTo @noticeList, EV_HIDE_NOTICE_BLOCK, @hideNoticeBlock
    @listenTo Backbone.AUCNET.receiveModel, 'nego_update', @updateNewDisplay

  afterRender: ->
    funcName = 'afterRender'
    #console.debug CLASS_NAME + '#' + funcName

    # コンテンツの組み立て
    @$el.find('#noticeListView').append(@noticeList.el)

    # お知らせ情報取得
    @noticeList.getAllNoticelist()

    # 商談情報（新着＆売り）取得
    @negolistListMyNewSell.remove() if @negolistListMyNewSell?
    @negolistListMyNewSell = new NegolistList
      collection : @negoCollectionMyNewSell
      id         : EV_GET_NEGOLIST_MY_NEW_SELL
    @listenTo @negolistListMyNewSell, 'checkNew', @checkNegoMyNewSell

    # 商談情報（新着＆買い）取得
    @negolistListMyNewBuy.remove()  if @negolistListMyNewBuy?
    @negolistListMyNewBuy = new NegolistList
      collection : @negoCollectionMyNewBuy
      id         : EV_GET_NEGOLIST_MY_NEW_BUY
    @listenTo @negolistListMyNewBuy, 'checkNew', @checkNegoMyNewBuy

    @$el.find('.negoSell').find('.listType1').append(@negolistListMyNewSell.render().el)
    @$el.find('.negoBuy').find('.listType1').append(@negolistListMyNewBuy.render().el)

    # 「最新情報あり！」を非表示にする
    @$el.find('#newDisplay').css('display','none')

  # 未読件数表示
  showUnreadCount: (data) ->
    funcName = 'showUnreadCount'
    #console.debug CLASS_NAME + '#' + funcName

    str = ""
    str = "<span>未読<strong>#{data}</strong></span>" if data isnt 0
    @$el.find('.unreadCount').html str
      
  # お知らせ欄0件表示
  hideNoticeBlock: ->
    funcName = 'hideNoticeBlock'
    #console.debug CLASS_NAME + '#' + funcName

    @$el.find('.notification').html "現在、お知らせはありません。"

  # お知らせ詳細表示変更
  showNoticeDetail: (data) ->
    funcName = 'showNoticeDetail'
    #console.debug CLASS_NAME + '#' + funcName

    # お知らせ詳細の表示内容を変更
    @$el.find('.msgDetails .date').html(data[0])
    @$el.find('.msgDetails .notice_title').html(data[1])
    @$el.find('.msgDetails .notice_detail').html(data[2])

  # 最新化ボタン押下処理
  onClickRefresh: ->
    funcName = 'onClickRefresh'
    #console.debug CLASS_NAME + '#' + funcName

    # インディケーター表示
    indicator = new IndicatorView
    @$el.append(indicator.render().el)

    # 商談情報（新着＆売り）最新化
    @$el.find('.negoSell').find('.listType1').children().remove()
    @$el.find('.negoSell').find('.listType1').append(@negolistListMyNewSell.el)

    # 商談情報（新着＆買い）最新化
    @$el.find('.negoBuy').find('.listType1').children().remove()
    @$el.find('.negoBuy').find('.listType1').append(@negolistLitMyNewBuy.el)

    # 商談情報（新着＆売り）取得
    @negolistListMyNewSell = new NegolistList
      collection : @negoCollectionMyNewSell
      id         : EV_GET_NEGOLIST_MY_NEW_SELL

    # 商談情報（新着＆買い）取得
    @negolistListMyNewBuy = new NegolistList
      collection : @negoCollectionMyNewBuy
      id         : EV_GET_NEGOLIST_MY_NEW_BUY

    # 「最新情報あり！」を非表示にする
    @$el.find('#newDisplay').css('display','none')

  # 「最新情報あり！」表示更新
  updateNewDisplay: (json) ->
    funcName = 'updateNewDisplay'
    #console.debug CLASS_NAME + '#' + funcName

    # TODO : ログイン処理の単体完了後に組み込む
    userid = Backbone.AUCNET.loginUser.get("_userid")
    NEW_FLG = '1'

    # 新着に掲載される商談情報の場合、「最新情報あり！」を表示する
    #   自社の買い商談＆（商談申込中or商談中で、自社が要回答or期限切れまで間近　または　商談が決着した）
    if json.buyer_member_cd is userid
      if json.buyer_new_flg is NEW_FLG
        @$el.find('#newDisplay').css('display','')

    #   自社の売り商談＆（商談申込中or商談中で、自社が要回答or期限切れまで間近　または　商談が決着した）
    if json.owner_member_cd? && json.owner_member_cd is userid
      if json.owner_new_flg is NEW_FLG
        @$el.find('#newDisplay').css('display','')

  #　商談情報（新着＆売り） NEWチェック
  checkNegoMyNewSell: ->
    funcName = 'checkNegoMyNewSell'
    #console.debug CLASS_NAME + '#' + funcName
    #console.debug '[local storage]news_new_sell=' + localStorage.getItem "negolist_new_sell"
    str = localStorage.getItem "negolist_new_sell"
    arrPreNegoMyNewSell = (if str? then str.split "," else [])
    arrNowNegoMyNewSell = []

    nego_id = @$el.find('.negoSell').find('.listType1').find('.nego_id')
    nego_id_len = nego_id.length
    for i in [0...nego_id_len]
      nego_id_val = nego_id.eq(i).val()
      arrNowNegoMyNewSell.push nego_id_val
      if $.inArray(nego_id_val, arrPreNegoMyNewSell) < 0
        nego_id.eq(i).parent().children(".new_flag").val("1")
        #console.debug nego_id.eq(i).next()
    localStorage.setItem "negolist_new_sell", arrNowNegoMyNewSell.join(",")

  # 商談情報（新着＆買い） NEWチェック
  checkNegoMyNewBuy: ->
    funcName = 'checkNegoMyNewBuy'
    #console.debug CLASS_NAME + '#' + funcName
