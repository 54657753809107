View        = require 'views/view'
Template    = require 'views/templates/candidate_page_base'

CarDataCollection   = require 'models/car_data_collection'
DataLineListView    = require 'views/data_line_list_view'
UtilPagingView      = require 'views/util_paging_view'
UtilChangeDispView  = require 'views/util_change_disp_view'
SearchToolView      = require 'views/search_tool_view'
CarDetailBaseView   = require 'views/car_detail_base_view'
CandidateFooterView = require 'views/candidate_footer_view'
UtilConfirmView     = require 'views/util_confirm_view'
NegoApplyView       = require 'views/nego_apply_view'
WatchlistModel      = require 'models/watchlist_model'
util            = require 'lib/util'
IndicatorView   = require 'views/indicator_view'
###
 [商談候補] 商談候補ビュー
###
module.exports = class CandidatePageView extends View
  id           : 'candidate_page'
  className    : ''
  template     : Template

  # 検索条件
  searchcond   : null
  # ソート条件
  sortcond     : null
  # 表示モード (l:リスト, b:ブロック)
  mode         : "b"
  # 選択済み車輌配列
  selectedItem : []

  # 初期化
  initialize: ->
    # 車両データコレクション生成
    @carDataCollection = new CarDataCollection
    @listenTo @carDataCollection, 'GetCandidateCarDataList', @listRender
    # 商談候補モデル生成
    @watchlistModel = new WatchlistModel
    # 複数商談申し込みの設定
    if Backbone.AUCNET.DEFINE.get('PARA_NEGO_COUNT') > 1
      @carDataCollection.addCheckFlag()
      @listenTo @carDataCollection, 'change', @updateNegoReqList
    # 表示切替ビュー生成
    @utilChangeDispView = new UtilChangeDispView ls_key_id : @id
    @listenTo @utilChangeDispView, "ChangeDisplayMode", @changeDisplayMode, @
    # 検索ツールビュー生成
    @searchToolView = new SearchToolView
    @listenTo @searchToolView, 'ReflectSearchToolConditions', @reflectSearchToolConditions
    # ページングビュー生成
    @utilPagingView = new UtilPagingView id : @id
    @listenTo @utilPagingView, 'paging', @getCandidate
    # データリストビュー生成
    lsStringList = localStorage.getItem('candidate_page.list_disp_mode')
    if lsStringList?
      @mode = lsStringList
    @dataLineListView = new DataLineListView
      collection : @carDataCollection
      mode : @mode
    # 商談候補フッタビュー生成
    @candidateFooterView = new CandidateFooterView
    @listenTo @candidateFooterView, 'RemoveAllWatchItem', @removeAllWatchItem
    @listenTo @candidateFooterView, 'ApplyNego',          @applyNego
    @listenTo @candidateFooterView, 'SelectAllCheck',     @selectAllCheck
    @listenTo @candidateFooterView, 'ClearAllCheck',      @clearAllCheck
    _.bindAll @

  suicide : ->
    @carDataCollection?.stopListening()
    @carDataCollection?.remove()
    @watchlistModel?.destroy()
    @utilChangeDispView?.stopListening()
    @utilChangeDispView?.remove()
    @searchToolView?.stopListening()
    @searchToolView?.remove()
    @utilPagingView?.stopListening()
    @utilPagingView?.remove()
    @dataLineListView.remove()
    @candidateFooterView?.stopListening()
    @candidateFooterView?.remove()
    @.remove()


  # レンダリング前処理
  beforeRender: ->
    # # 車両データコレクション生成
    # @carDataCollection = new CarDataCollection
    # # 商談候補モデル生成
    # @watchlistModel = new WatchlistModel
    # # 複数商談申し込みの設定
    # if Backbone.AUCNET.DEFINE.get('PARA_NEGO_COUNT') > 1
    #   @carDataCollection.addCheckFlag()
    #   @listenTo @carDataCollection, 'change', @updateNegoReqList
    # # 表示切替ビュー生成
    # @utilChangeDispView = new UtilChangeDispView ls_key_id : @id
    # @listenTo @utilChangeDispView, "ChangeDisplayMode", @changeDisplayMode, @
    # # 検索ツールビュー生成
    # @searchToolView = new SearchToolView
    # @listenTo @searchToolView, 'ReflectSearchToolConditions', @reflectSearchToolConditions
    # # ページングビュー生成
    # @utilPagingView = new UtilPagingView id : @id
    # @listenTo @utilPagingView, 'paging', @getCandidate
    # # データリストビュー生成
    # lsStringList = localStorage.getItem('mylist_page.list_disp_mode')
    # if lsStringList?
    #   @mode = lsStringList
    # @dataLineListView = new DataLineListView
    #   collection : @carDataCollection
    #   mode : @mode
    # # 商談候補フッタビュー生成
    # @candidateFooterView = new CandidateFooterView
    # @listenTo @candidateFooterView, 'RemoveAllWatchItem', @removeAllWatchItem
    # @listenTo @candidateFooterView, 'ApplyNego',          @applyNego
    # @listenTo @candidateFooterView, 'SelectAllCheck',     @selectAllCheck
    # @listenTo @candidateFooterView, 'ClearAllCheck',      @clearAllCheck

  # リストレンダ
  listRender: ->
    # データリストビューのレンダリング
    @dataLineListView.setCollection
      carDataCollection : @carDataCollection
    @dataLineListView.render()
    # ページングビューのレンダリング
    @utilPagingView.setPagingData
      dataCount  : @carDataCollection.count.search_car_data_count
    @utilPagingView.render()
    @indicator.suicide()
    # チェックボックス復旧
    array = @$el.find('.data_line_check')
    for item in array
      if @selectedItem.indexOf(item.value) >= 0
        item.checked = true

    # データリストビュー生成
    lsStringList = localStorage.getItem('candidate_page.list_disp_mode')
    if lsStringList?
      @dataLineListView.changeDisplayModeOnly(lsStringList)

  # レンダリング後処理
  afterRender: ->
    # コンテンツ組立
    @$el.find('.searchTool_area')
      .append(@searchToolView.el)

    @$el.find('.bgDes')
      .append(@utilChangeDispView.el)
      .append(@utilPagingView.el)

    @$el.find('.candidate_list_area')
      .append(@dataLineListView.el)

    @$el.find('.candidate_footer_area')
      .append(@candidateFooterView.el)

    # 各ビューのレンダリング
    @searchToolView.render()
    @utilChangeDispView.render()
    @utilPagingView.render()
    @candidateFooterView.render()

    @searchcond = null
    @sortcond   = null
    # 車両一覧取得
    @getCandidate()
    # イベント設定
    @delegateEvents
      'click .car_thumbnail' : 'showCarDetail'
      'click .watchListBtn'  : 'changeWatchListBtn'
    @searchToolView.dispCandidateSearchToolCond()

  # 表示切替
  changeDisplayMode: (mode) ->
    @mode = mode
    # データリストビュー呼び出し
    @dataLineListView.changeDisplayMode(@mode)

  # 検索ツール条件反映
  reflectSearchToolConditions: ->
    # 検索条件取得
    conditions  = @searchToolView.getSearchToolConditions()
    @searchcond = conditions.searchcond
    @sortcond   = conditions.sortcond
    # カレントページ初期化
    @utilPagingView.page = 0
    # 商談候補取得
    @getCandidate()

  # 商談候補取得
  getCandidate: ->
    @indicator = new IndicatorView
      title   : Backbone.AUCNET.message.get "ms_indicator_carname_title"
    $('body').append(@indicator.render().el)
    # 商談候補取得
    @carDataCollection.getCandidateCarDataList
      limit      : @utilPagingView.limit
      offset     : @utilPagingView.getOffset()
      searchcond : @searchcond
      sortcond   : @sortcond

  # 商談候補クリア
  removeAllWatchItem: ->
    # 確認メッセージ
    confirmView = new UtilConfirmView
      title   : util.getMessage('ms_candidate_del_confirm_title',[])
      message : util.getMessage('ms_candidate_del_confirm_msg',[])
      callback : (ok) =>
        if ok
          # 商談候補クリア
          @listenToOnce @watchlistModel, 'WatchListDelAll', @getCandidate
          @watchlistModel.removeAllWatchItem()
          @selectedItem = []
    @$el.append confirmView.render().el

  # 商談申込
  applyNego: ->
    # 未選択の場合は処理終了
    if @selectedItem.length is 0 then return
    # 配列組み立て
    param = []
    for item in @selectedItem
      value = item.split('|')
      json =
        inventoryYear : value[0]
        inventoryNo   : value[1]
      param.push json
    # 設定値以上の場合は処理終了
    maxNegoCnt = Backbone.AUCNET.setting.max_nego_cnt
    if param.length > maxNegoCnt
      confirmView = new UtilConfirmView
        title        : util.getMessage('ms_candidate_del_confirm_title',[])
        message      : util.getMessage('ms_candidate_max_nego_cnt', [maxNegoCnt])
        cancelDelFlg : true
        callback : (ok) =>
          ;
      @$el.append confirmView.render().el
      return

    # 商談申込画面の生成
    #View生成、商談申し込みを行うデータをSetする。
    negoApplyView = new NegoApplyView
      sliderParam : "-100%"
    negoApplyView.setApplyData param
    @$el.append(negoApplyView)

  # 全選択
  selectAllCheck: ->
    array = @$el.find('.data_line_check')
    for item in array
      item.checked = true
      @selectedItem.push item.value
    @selectedItem = _.uniq @selectedItem

  # 全選択解除
  clearAllCheck: ->
    ###
      @TODO
    ###
    array = @$el.find('.data_line_check')
    for item in array
      item.checked = false
      @selectedItem = _.without @selectedItem, item.value

  # 車両詳細表示
  showCarDetail: (param) ->
    invKey = param.currentTarget.id.split('|')
    Backbone.AUCNET.RenderController.renderCarDetailPaging(invKey[0],invKey[1],@carDataCollection ,
     @utilPagingView, 'GetCandidateCarDataList')


  # 商談申込リスト更新
  updateNegoReqList: (param) ->
    value = @createValue param.attributes
    if param.attributes.checked
      @selectedItem.push value
    else
      @selectedItem = _.without @selectedItem, value

  changeWatchListBtn: (param) ->
    par = $(param.currentTarget).parents('li')
    value = par.find('.car_thumbnail').attr('id')
    @selectedItem = _.without @selectedItem, value
    par.find('.data_line_check')[0].checked = false

  # 商談申込に引き渡す情報の生成
  createValue: (json) ->
    key = json.inventory_year + '|' +
          json.inventory_no
