###
 [ログイン] 画面
###
# ビューの基底クラス
View       = require 'views/view'
# 本ビューで使用するhandlebarsファイル
template   = require 'views/templates/util_userpage_base'

listener   = require 'lib/listener'

# 本ビュー内で使用する子ビュー
LoginView  = require 'views/login_view'

# ビューの定義
module.exports = class LoginPageView extends View
  # 本ビューでレンダリングするhtmlのidを指定
  id        : 'login_page'
  className : 'loginBox'
  # 本ビューでレンダリングするhtmlのテンプレートを指定（handlebarsファイル）
  # handlebarsは関数として実体化する
  # 基底クラスview.coffee参照のこと
  template  : template

  # -----------------------------------
  # 初期化
  # -----------------------------------
  # ビューがインスタンス化されるときにBackbone.jsによって自動で呼ばれる
  # ログインの場合はapplication.coffeeで実体化されているためサイトアクセス時に
  # 実行されている
  initialize: ->
    #console.debug "LoginPageView#initialize"
    # Undersocore.jsのメソッド
    # 第一引数のオブジェクトにオブジェクト内のthisを保存する
    _.bindAll @

    ## emitを置き換えて、パラメータを操作
    #emitNew = (name, json) ->
    #  paramJson = _.clone(json)
    #  if Backbone.AUCNET.sessionid?
    #    if json?
    #      paramJson = _.extend(json, {loginid : Backbone.AUCNET.loginUser.get("_userid"), sessionID : Backbone.AUCNET.sessionid})
    #    else
    #      paramJson = {loginid : Backbone.AUCNET.loginUser.get("_userid"), sessionID : Backbone.AUCNET.sessionid}
    #  Backbone.AUCNET.socket.emitOriginal name, paramJson
    ## オリジナルのemitを退避
    #Backbone.AUCNET.socket.emitOriginal = Backbone.AUCNET.socket.emit
    ## 置き換え
    #Backbone.AUCNET.socket.emit = emitNew

    ## コネクション異常時のイベントにコールバックをバインド
    #Backbone.AUCNET.socket.on 'error',      listener.socket_error
    #Backbone.AUCNET.socket.on 'disconnect', listener.socket_disconnect
    #Backbone.AUCNET.socket.on 'SendSystemError',  listener.socket_systemError
    #Backbone.AUCNET.socket.on 'connect', ->
    #  # 接続されたらソケットをグローバルなモデルに保存する
    #  loginUser.setSocket
    #  sideMenu.setSocket

    #  Backbone.AUCNET.loginUser.logincheck()
    #  console.log "login page start"


  # -----------------------------------
  # レンダリング後の処理
  # -----------------------------------
  # 本ビューがrender()された後に実行される
  # View#renderを参照のこと
  afterRender: ->
    #console.debug 'LoginPageView#afterRender'
    # この画面で使用するコンテンツの組立
    loginView    = new LoginView({
      query : @options.query
    })
    # @elは本ビューが出力したDOMへの参照を持つ
    # また任意に設定し直すことが可能
    # @$elは@elのjQueryオブジェクト
    @$el.append(loginView.render().el)
