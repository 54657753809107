Model = require 'models/model'

###
  メール設定モデル
###
module.exports = class SettingMailModel extends Model

  socket: undefined

  # 持つべきデータをここに書く。
  defaults:
    maillist_flag: false  #メールリストを表示するフラグ
    admin_flag: false     #ユーザ名等表示するフラグ
    message: undefined    #エラーメッセージ

  adrArray: []

  initialize: ->
    _.bindAll @
    @socket = Backbone.AUCNET.socket
    # ソケット破棄
    @socket.removeAllListeners 'SettingMailResult'

    # メール関連設定の戻りは全てここになる。
    @socket.on 'SettingMailResult', (d) =>
      retJson = JSON.parse(d)

      unless _.isEmpty(retJson.activationMailList)
        if retJson.activationMailList.length <= 1
          @set 'delNonFlag': true, {silent: true}
        else
          @set 'delNonFlag': false, {silent: true}
      else
        @set 'delNonFlag': false, {silent: true}

      if retJson.error
        if retJson.message is 'ms_mail_user_data_non'
          @set 'maillist_flag': false, {silent: true}
          @trigger 'user_data_non'
        else
          @set 'maillist_flag': true, {silent: true}

        if retJson.message is 'ms_mail_mail_data_non'
          @set 'address': retJson.address
          @set 'mailZero': true, {silent: true}
          @set 'message': Backbone.AUCNET.message.get retJson.message
        else
          @set 'message': Backbone.AUCNET.message.get retJson.message,
            {silent: true}
          @trigger 'message'
      else
        @unset 'message', {silent: true}
        @set 'maillist_flag': true, {silent: true}

        @set 'address': retJson.address

  # =================
  # 管理者用メソッド
  # =================
  # -----------------
  # メール検索
  # -----------------
  searchEmail: (memberCd) =>
    # 自身の要素をクリアし、memberCdを追加
    @unset 'memberCd', {silent: true}
    @set 'memberCd': memberCd, {silent: true}
    @socket.emit 'searchEmail',
      memberCd: memberCd

  # -----------------
  # メールアドレス追加
  # -----------------
  addMailAdmin: (mailAddress) =>
    @socket.emit 'addMailAdmin',
      memberCd: @get 'memberCd'
      mailAddress: mailAddress

  # -----------------
  # メール削除
  # -----------------
  delMailAdmin: (mailAddress) =>
    @socket.emit 'delMailAdmin',
      memberCd: @get 'memberCd'
      mailAddress: mailAddress
    return

  # =================
  # ユーザ用メソッド
  # =================
  # -----------------
  # メールリスト取得
  # -----------------
  getEmail: =>
    @socket.emit 'getEmail'
    return

  # -----------------
  # メールアドレス追加
  # -----------------
  addMailUser: (mailAddress) =>
    @socket.emit 'addMailUser',
      loginId: Backbone.AUCNET.loginUser.get("_userid")
      memberCd: @get 'memberCd'
      mailAddress: mailAddress
      negoApplyParam: @negoApplyParam

  # -----------------
  # メール削除
  # -----------------
  delMailUser: (mailAddress) =>
    @socket.emit 'delMailUser',
      mailAddress: mailAddress

  sendMailUser: (mailAddress) =>
    @socket.emit 'sendMailUser',
      mailAddress: mailAddress
      loleId: Backbone.AUCNET.roleID
      memberCd: @get 'memberCd'
      negoApplyParam: @negoApplyParam

  setUrlVal: (negoApplyParam) =>
    @negoApplyParam = negoApplyParam

  #自分自身を削除する。
  suicide: ->
    @socket.removeAllListeners 'SettingMailResult'
    @.remove
