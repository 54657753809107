View       = require 'views/view'
template   = require 'views/templates/negolistall_page'

NegolistChangeDisp = require 'views/negolist_change_disp_view'
NegolistSearch     = require 'views/negolist_search_view'
NegolistList       = require 'views/negolist_list_inc_paging_view'

NegoCollection = require 'models/nego_collection'
# 検索条件モデル
NegolistSearchModel = require 'models/negolist_search_model'

###
 他社商談一覧画面
###
module.exports = class NegolistAllPageView extends View
  id        : 'negolist_page'
  template  : template
  className : ''

  # ブロック（b）、リスト(l)表示
  mode : "b"

  # 現在表示中のリスト
  showingView : null

  initialize: ->
    _.bindAll @

  afterRender: ->
    # ブロック/リスト表示切替
    @negolistChangeDisp = new NegolistChangeDisp

    # 全ての進行中商談
    @negoCollectionAllOngoing  = new NegoCollection [],
      eventName : "GetNegolistAllOngoing"

    # タイマーの初期化
    if Backbone.AUCNET.timerArray_list.length > 0
      for timer_item in Backbone.AUCNET.timerArray_list
        clearInterval timer_item
      Backbone.AUCNET.timerArray_list = new Array()

    @negolistListAllOngoing = new NegolistList
      collection : @negoCollectionAllOngoing
      id         : "negolist_list_all_ongoing"
      ongoing    : true
      past       : false
      admin      : false

    # 検索条件過去
    @searchModel = new NegolistSearchModel
    # 検索ツール
    @negolistSearchView = new NegolistSearch
      model : @searchModel
      currentScreenIsNowNego : true
    # イベントリスナ
    # ヘッダでの切り替えイベント
    # 検索条件取得
    @listenTo @negolistChangeDisp     , "ChangeDisplayMode"   , @changeDisplayMode , @
    @listenTo @negolistSearchView     , "FireSearch"          , @kickSearch        , @
    @listenTo @negolistListAllOngoing , "ChangePage"          , @changePage        , @

    ###
     @TODO 一時的に管理者のみ表示
    ###
    #if Backbone.AUCNET.roleID is '1' or Backbone.AUCNET.roleID is '2'
      # コンテンツの組み立て
    @$el.find("#negolist_child_search_tool_area")
        .append(@negolistSearchView.render().el)

    @$el.find("#negolist_child_area")
        #.append(@negolistChangeDisp.render().el)
        .append(@negolistListAllOngoing.el)

    @showAllOngoing()
    @setSearchDisp()

  # -------------------------
  # 検索部分の表示制御
  # -------------------------
  setSearchDisp: ()->
    # 売り店舗コード
    @$el.find("#negolist_input_owner_member_div").hide()
    # 買い店舗コード
    @$el.find("#negolist_input_buyer_member_div").hide()
    # 売り買い区分
    @$el.find("#negolist_search_nego_buy_sell_kbn_div").hide()

  # -------------------------
  # 検索実行
  # -------------------------
  kickSearch:(conditions) ->
    @showingView.getSearchNegolist conditions

  # -------------------------
  # ページング
  # -------------------------
  changePage:(ret) ->
    @negolistSearchView.setLimitAndOffset ret
    conditions = @negolistSearchView.getSearchConditions()
    @showingView.getSearchNegolist conditions

  # -------------------------
  # ブロック、リスト表示切替
  # -------------------------
  #changeDisplayMode:(mode) ->
  #  console.log "ここ入ってんの？"
  #  @mode = mode
  #  @showingView.changeDisplayMode(@mode)

  # -------------------------
  # すべての商談表示
  # -------------------------
  showAllOngoing: ->
    @showingView = @negolistListAllOngoing
    lsString = localStorage.getItem('negolist_list_all_ongoing.list_disp_mode')
    if lsString?
      @mode = lsString
    @showingView.changeDisplayMode(@mode)
    @negolistSearchView.setConditions(Backbone.AUCNET.DEFINE.get "NEGOLIST_KBN_ALL_ONGOING")

  # -------------------------
  # 後始末
  # -------------------------
  suicide: ->
    @negolistSearchView?.remove?()
    @negolistListAllOngoing?.remove?()
    @negoCollectionAllOngoing?.stopListening()
    @negoCollectionAllOngoing?.remove?()
    @negolistChangeDisp?.remove?()
    @remove()