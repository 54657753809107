Application    = require 'application'
View           = require 'views/view'
template       = require 'views/templates/watchlist_btn'
WatchlistModel = require 'models/watchlist_model'

module.exports = class WatchListBtnView extends View
  className : 'watchlist_btn'
  template  : template

  BTN_ADD = 'lb_watchlist_add'
  BTN_DEL = 'lb_watchlist_del'
  BTN_IMPROPER = 'lb_watchlist_improper'

  events:
    'click .watchListBtn' : 'btnClickWatchList'

  initialize: =>
    #モデルをクラス変数にセット
    @model = new WatchlistModel
      invNo   : @options.invNo
      invYear : @options.invYear
      loginId : @options.loginId
    @listenTo @model, 'change', @render
    return

  afterRender: ->
    if @options.btnClass is 'detailsaddToBtn'
      @$el.find("button").removeClass('addToBtn').addClass('detailsaddToBtn')

  checkWatchList : =>
    @model.checkWatchList()

  getRenderData: =>
    retJson = @model.toJSON()
    if retJson.ret is 'add'
      return BtnLabel : BTN_ADD
    else
      return BtnLabel : BTN_DEL


  btnClickWatchList : (param) =>
    makers = param.currentTarget.value
    if makers is BTN_ADD
      @model.addWatchlist()
    else if makers is BTN_DEL
      @model.delWatchlist()
    return
