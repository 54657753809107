View                 = require 'views/view'
Template             = require 'views/templates/search_tool'
SearchToolModel      = require 'models/search_tool_model'

###
 [共通] 検索ツールビュー
###
module.exports = class SearchToolView extends View
  id                   : 'search_tool'
  template             : Template

  # 駆動方式リスト要素
  DRIVE_ITEM :
    [
      {"cd" : "0",   "disp" : "lb_search_tool_none"    }
      {"cd" : "801", "disp" : "lb_search_tool_dirve_1" }
      {"cd" : "802", "disp" : "lb_search_tool_dirve_2" }
    ]

  # ハンドルリスト要素
  HANDLE_ITEM :
    [
      {"cd" : "0",   "disp" : "lb_search_tool_none"     }
      {"cd" : "901", "disp" : "lb_search_tool_handle_1" }
      {"cd" : "902", "disp" : "lb_search_tool_handle_2" }
    ]

  # ドアリスト要素
  DOOR_ITEM :
    [
      {"cd" : "0",   "disp" : "lb_search_tool_none"   }
      {"cd" : "101", "disp" : "lb_search_tool_door_1" }
      {"cd" : "102", "disp" : "lb_search_tool_door_2" }
      {"cd" : "103", "disp" : "lb_search_tool_door_3" }
      {"cd" : "104", "disp" : "lb_search_tool_door_4" }
      {"cd" : "105", "disp" : "lb_search_tool_door_5" }
      {"cd" : "106", "disp" : "lb_search_tool_door_6" }
    ]

  # ボディタイプリスト要素
  BODY_ITEM :
    [
      {"cd" : "0",   "disp" : "lb_search_tool_none" }
      {"cd" : "131", "disp" : "car_category_1"      }
      {"cd" : "132", "disp" : "car_category_2"      }
      {"cd" : "133", "disp" : "car_category_3"      }
      {"cd" : "134", "disp" : "car_category_4"      }
    ]
  #車両の仕様要素
  EIGHT_NO_ITEM:
    [
      {"cd" : "061", "disp" : "lb_doc_equip_61"        }
      {"cd" : "062", "disp" : "lb_doc_equip_62"        }
      {"cd" : "063", "disp" : "lb_doc_equip_63"        }
    ]


  IMPORT_ITEM :
    [
      {"cd" : "071", "disp" : "lb_doc_equip_71"     }
      {"cd" : "072", "disp" : "lb_doc_equip_72"     }
      {"cd" : "073", "disp" : "lb_doc_equip_73"     }
    ]



  # 燃料要素
  FUEL_ITEM :
    [
      {"cd" : "111", "disp" : "detail_fuel_type_1"  }
      {"cd" : "112", "disp" : "detail_fuel_type_2"  }
      {"cd" : "113", "disp" : "detail_fuel_type_3"  }
      {"cd" : "114", "disp" : "detail_fuel_type_4"  }
      {"cd" : "115", "disp" : "detail_fuel_type_5"  }
      {"cd" : "116", "disp" : "detail_fuel_type_6"  }
      {"cd" : "117", "disp" : "detail_fuel_type_8"  }
    ]

  CAPACITY_ITEM :
    [
      {"cd" : "121", "disp" : "lb_doc_equip_121"    }
      {"cd" : "122", "disp" : "lb_doc_equip_122"    }
      {"cd" : "123", "disp" : "lb_doc_equip_123"    }
      {"cd" : "124", "disp" : "lb_doc_equip_124"    }
      {"cd" : "125", "disp" : "lb_doc_equip_125"    }
      {"cd" : "126", "disp" : "lb_doc_equip_126"    }
      {"cd" : "127", "disp" : "lb_doc_equip_127"    }
      {"cd" : "128", "disp" : "lb_doc_equip_128"    }
    ]

  # ソートリスト要素
  SORT_ITEM :
    [
      {"cd" : "0",  "disp" : "lb_search_tool_none"    }
      {"cd" : "1",  "disp" : "lb_search_tool_sort_1"  }
      {"cd" : "2",  "disp" : "lb_search_tool_sort_2"  }
      {"cd" : "3",  "disp" : "lb_search_tool_sort_3"  }
      {"cd" : "4",  "disp" : "lb_search_tool_sort_4"  }
      {"cd" : "5",  "disp" : "lb_search_tool_sort_5"  }
      {"cd" : "6",  "disp" : "lb_search_tool_sort_6"  }
      {"cd" : "7",  "disp" : "lb_search_tool_sort_7"  }
      {"cd" : "8",  "disp" : "lb_search_tool_sort_8"  }
      {"cd" : "9",  "disp" : "lb_search_tool_sort_9"  }
      {"cd" : "10", "disp" : "lb_search_tool_sort_10" }
      {"cd" : "11", "disp" : "lb_search_tool_sort_11" }
      {"cd" : "12", "disp" : "lb_search_tool_sort_12" }
      {"cd" : "13", "disp" : "lb_search_tool_sort_13" }
      {"cd" : "14", "disp" : "lb_search_tool_sort_14" }
      {"cd" : "15", "disp" : "lb_search_tool_sort_15" }
      {"cd" : "16", "disp" : "lb_search_tool_sort_16" }
    ]

  # 初期化
  initialize: ->
    _.bindAll @
    # 検索ツールモデル生成
    @searchToolModel = new SearchToolModel

  render:  ->
    @beforeRender()
    @$el.html @template @getRenderData()
    @afterRender()
    @drawLabel()
    this

  suicide : ->
    @searchToolModel?.destroy()
    @.remove()

  # 検索条件取得
  dispSearchSearchToolCond: (conditions) ->
    # 検索ツールモデルからの通知待ち
    @listenToOnce @searchToolModel, 'GetSearchSearchToolCond', @render
    # 検索条件取得
    @searchToolModel.getSearchSearchToolCond(conditions)

  # 検索条件取得(マイリスト)
  dispMylistSearchToolCond: (param) ->
    # 検索ツールモデルからの通知待ち
    @listenToOnce @searchToolModel, 'GetMylistSearchToolCond', @render
    # 検索条件取得
    @searchToolModel.getMylistSearchToolCond(param)

  # 検索条件取得(リクエスト)
  dispRequestSearchToolCond: (param) ->
    # 検索ツールモデルからの通知待ち
    @listenToOnce @searchToolModel, 'GetRequestSearchToolCond', @render
    # 検索条件取得
    @searchToolModel.getRequestSearchToolCond(param)

  # 検索条件取得(商談候補)
  dispCandidateSearchToolCond: (param) ->
    # 検索ツールモデルからの通知待ち
    @listenToOnce @searchToolModel, 'GetCandidateSearchToolCond', @render
    # 検索条件取得
    @searchToolModel.getCandidateSearchToolCond(param)

  # レンダリングデータ返却
  getRenderData: ->
    region       : if @searchToolModel.conditions? then @searchToolModel.conditions.region     else ''
    modelYear    : if @searchToolModel.conditions? then @searchToolModel.conditions.modelYear  else ''
    carName      : if @searchToolModel.conditions? then @searchToolModel.conditions.carName    else ''
    grade        : if @searchToolModel.conditions? then @searchToolModel.conditions.grade      else ''
    certify      : if @searchToolModel.conditions? then @searchToolModel.conditions.certify    else ''
    exhaust      : if @searchToolModel.conditions? then @searchToolModel.conditions.exhaust    else ''
    mission      : if @searchToolModel.conditions? then @searchToolModel.conditions.mission    else ''
    color1       : if @searchToolModel.conditions? then @searchToolModel.conditions.color1      else ''
    drive        : @DRIVE_ITEM
    handle       : @HANDLE_ITEM
    door         : @DOOR_ITEM
    body         : @BODY_ITEM
    fuel         : @FUEL_ITEM
    import       : @IMPORT_ITEM
    capacity     : @CAPACITY_ITEM
    eight        : @EIGHT_NO_ITEM
    estimation   : if @searchToolModel.conditions? then @searchToolModel.conditions.estimation else ''
    status       : if @searchToolModel.conditions? then @searchToolModel.conditions.status     else ''
    orderby      : @SORT_ITEM

  # レンダリング後の処理
  afterRender: ->
    @$('#search_tool_handle_item_id_0')[0].checked = true
    @$('#search_tool_drive_item_id_0')[0].checked = true
    @$('#search_tool_door_item_id_0')[0].checked = true
    @$('#search_tool_body_item_id_0')[0].checked = true
    @$('#search_tool_sort_item_id_0')[0].checked = true
    @$('#order_1').attr('checked', true )
    # 処理なし
    #$(".refineSearchBtn").css "top", ($(".cont").scrollTop() + 2) + "px"
    # wHeight = $(window).height()
    # $(".cont").css "height", wHeight + "px"
    #$(window).resize ->
    #  wHeight = $(window).height()
    #  $("#main_container .cont").css "height", wHeight + "px"

    $(".cont").bind "scroll", ->
      scrollVal = $(".cont").scrollTop()
      $(".refineSearchBtn").css "top", (scrollVal + 2) + "px"


    # イベント設定
    @delegateEvents
      "click #btn_show_search_tool"   : "showSearchTool"
      "click #btn_search_tool_filter" : "filterResult"
      "click #btn_search_tool_clear"  : "filterClear"
      "click #navi"                   : "selectAllNavi"
      "click #TV"                     : "selectAllTv"
      "click .selectAllItem"          : "selectAllItem"
      "blur  #search_tool_price_from" : "checkPrice"
      "blur  #search_tool_price_to"   : "checkPrice"
      #'touchstart .searchToolList li' : 'hoverStart'
      #'touchend   .searchToolList li' : 'hoverEnd'
      'mouseover  .searchToolList li' : 'hoverStart'
      'mouseout   .searchToolList li' : 'hoverEnd'
      #'touchstart #btn_show_search_tool' : 'hoverStartMobile'
      #'touchend   #btn_show_search_tool' : 'hoverEndMobile'
      #'mouseover  #btn_show_search_tool' : 'hoverStartMobile'
      #'mouseout   #btn_show_search_tool' : 'hoverEndMobile'
      'click .toolItemOpenAndClos' : 'toolItemOpenAndClos'
      'click .toolItemOpenAndClos2' : 'toolItemOpenAndClos'
      'blur #search_tool_price_from'  : 'blurPrice'
      'blur #search_tool_price_to'    : 'blurPrice'
      'blur #search_tool_price_from'  : 'searchConditionActive'
      'blur #search_tool_price_to'    : 'searchConditionActive'
      "click input" : 'searchConditionActive'

  ###
    チェックされている項目のメニューの色を変更する
  ###
  searchConditionActive : ->
    # 条件取得
    region        = @getCheckedItem(@$el.find('.search_tool_region'))
    toolModelYear = @getCheckedItem(@$el.find('.search_tool_modelyear'))
    carName       = @getCheckedItem(@$el.find('.search_tool_carname'))
    grade         = @getCheckedItem(@$el.find('.search_tool_grade'))
    certify       = @getCheckedItem(@$el.find('.search_tool_certify'))
    exhaust       = @getCheckedItem(@$el.find('.search_tool_exhaust'))
    toolMission   = @getCheckedItem(@$el.find('.search_tool_shift'))
    toolMileage   = @getCheckedItem(@$el.find('.search_tool_mileage'))
    toolInspect   = @getCheckedItem(@$el.find('.search_tool_inspect'))
    color1        = @getCheckedItem(@$el.find('.search_tool_color'))
    docEquipment  = @getCheckedItem(@$el.find('.search_tool_doc_equipment.docEquipment'))
    carCondition  = @getCarCondition()
    estimation    = @getCheckedItem(@$el.find('.search_tool_estimation'))
    status        = @getCheckedItem(@$el.find('.search_tool_status'))

    from = @$el.find('#search_tool_price_from')[0].value
    to   = @$el.find('#search_tool_price_to'  )[0].value
    if from <= 0 and to <= 0
      price = false
    else
      price = true

    sortItem = @getCheckedItem(@$el.find('.search_tool_sort_item'))
    sortItem = _.without(sortItem, '0')
    # 返却値設定
    # * 設定が存在するものだけ返却値に含め、ベースとなる検索条件がnullで上書きされないよう工夫している
    @toggleClass "region" , (region? and region.length > 0)
    @toggleClass "toolModelYear" , (toolModelYear? and toolModelYear.length > 0)
    @toggleClass "carName" , (carName? and carName.length > 0)
    @toggleClass "toolItemOpenAndClosGrade" , (grade? and grade.length > 0)
    @toggleClass "certify" , (certify? and certify.length > 0)
    @toggleClass "exhaust" , (exhaust? and exhaust.length > 0)
    @toggleClass "toolMission" , (toolMission? and toolMission.length > 0)
    @toggleClass "toolMileage" , (toolMileage? and toolMileage.length > 0)
    @toggleClass "toolInspect" , (toolInspect? and toolInspect.length > 0)
    @toggleClass "estimation" , (estimation? and estimation.length > 0)
    @toggleClass "status" , (status? and status.length > 0)
    @toggleClass "color1" , (color1? and color1.length > 0)
    @toggleClass "docEquipment" , (docEquipment? and docEquipment.length > 0)
    @toggleClass "carCondition" , (carCondition? and carCondition.length > 0)
    @toggleClass "price" , price
    @toggleClass "sortItem" , (sortItem? and sortItem.length > 0)


    #検索ツールボタン用
    if (region? and region.length > 0) or
    (toolModelYear? and toolModelYear.length > 0) or
    (carName? and carName.length > 0) or
    (grade? and grade.length > 0) or
    (certify? and certify.length > 0) or
    (exhaust? and exhaust.length > 0) or
    (toolMission? and toolMission.length > 0) or
    (toolMileage? and toolMileage.length > 0) or
    (toolInspect? and toolInspect.length > 0) or
    (estimation? and estimation.length > 0) or
    (status? and status.length > 0) or
    (color1? and color1.length > 0) or
    (docEquipment? and docEquipment.length > 0) or
    (carCondition? and carCondition.length > 0) or
    price or
    (sortItem? and sortItem.length > 0)
      $("#btn_show_search_tool").addClass("refineSearchBtn_Red")
    else
      $("#btn_show_search_tool").removeClass("refineSearchBtn_Red")



  toggleClass : (className,flag)->
    if flag
      $(".toolItemOpenAndClos.#{className}").addClass("toolItemOpenAndClosRed")
      $(".toolItemOpenAndClos2.#{className}").addClass("toolItemOpenAndClosRed")

    else
      $(".toolItemOpenAndClos.#{className}").removeClass("toolItemOpenAndClosRed")
      $(".toolItemOpenAndClos2.#{className}").removeClass("toolItemOpenAndClosRed")
  # 検索ツール表示
  showSearchTool: ->
    if $("#search_tool_section").hasClass('show')
      $("#search_tool_section").slideUp("fast");
      @trigger 'closeSearchToolConditions'
    else
      $("html,body").animate({ scrollTop: 0 }, "fast");
      $("#search_tool_section").slideDown("fast");

    $("#search_tool_section").toggleClass('show')
    $("#search_tool_section").css("overflow", "") 
    $("#search_tool_section").addClass("scrollable") 
    #$('#search_tool_section').resizable({ handles:'none' })

  # 検索ツール折り畳み開閉
  toolItemOpenAndClos: (param) ->
    targetList = $(param.currentTarget).parent()
    toggleBox = targetList.find('.searchToolIconPlus')
    targetList.find('.searchToolList').toggle 'slow', ->
      targetList.find('.searchToolList').css 'overflow',''
      if toggleBox.css('height') is '0px'
        targetList.find('.searchToolIconPlus').css 'height', '14px'
      else
        targetList.find('.searchToolIconPlus').css 'height','0px'

    targetList.find('.inquiryNumber3').toggle 'slow', ->
      targetList.find('.inquiryNumber3').css 'overflow',''
      if toggleBox.css('height') is '0px'
        targetList.find('.searchToolIconPlus').css 'height', '14px'
      else
        targetList.find('.searchToolIconPlus').css 'height','0px'

    targetList.find('.colorsList').toggle 'slow', ->
      targetList.find('.colorsList').css 'overflow',''
      if toggleBox.css('height') is '0px'
        targetList.find('.searchToolIconPlus').css 'height', '14px'
      else
        targetList.find('.searchToolIconPlus').css 'height','0px'

    targetList.find('.etcCond1').toggle 'slow', ->
      targetList.find('.etcCond1').css 'overflow',''
      if toggleBox.css('height') is '0px'
        targetList.find('.searchToolIconPlus').css 'height', '14px'
      else
        targetList.find('.searchToolIconPlus').css 'height','0px'

    targetList.find('.etcCond2').toggle 'slow', ->
      targetList.find('.etcCond2').css 'overflow',''
      if toggleBox.css('height') is '0px'
        targetList.find('.searchToolIconPlus').css 'height', '14px'
      else
        targetList.find('.searchToolIconPlus').css 'height','0px'

    targetList.find('.sort').toggle 'slow', ->
      targetList.find('.sort').css 'overflow',''
      if toggleBox.css('height') is '0px'
        targetList.find('.searchToolIconPlus').css 'height', '14px'
      else
        targetList.find('.searchToolIconPlus').css 'height','0px'

    #$('#search_tool_section').resizable({ handles:'none' })


  # 絞り込み実行
  filterResult: ->
    @trigger 'ReflectSearchToolConditions'
    @showSearchTool()


  # クリア
  filterClear: ->
    @$('#search_tool_section input').removeAttr('checked');
    @$('#search_tool_section select').val('0')
    @$('#search_tool_section :text').val('')
    @$('#search_tool_handle_item_id_0')[0].checked = true
    @$('#search_tool_drive_item_id_0')[0].checked = true
    @$('#search_tool_door_item_id_0')[0].checked = true
    @$('#search_tool_body_item_id_0')[0].checked = true
    @$('#search_tool_sort_item_id_0')[0].checked = true
    @$('#order_1').attr('checked', true )
    @searchConditionActive()
    #@trigger 'ReflectSearchToolConditions'
    #@showSearchTool()

  mylistFilterClear:->
    @$('#search_tool_section input').removeAttr('checked');
    @$('#search_tool_section select').val('0')
    @$('#search_tool_section :text').val('')
    @$('#search_tool_handle_item_id_0')[0].checked = true
    @$('#search_tool_drive_item_id_0')[0].checked = true
    @$('#search_tool_door_item_id_0')[0].checked = true
    @$('#search_tool_body_item_id_0')[0].checked = true
    @$('#search_tool_sort_item_id_0')[0].checked = true
    @$('#order_1').attr('checked', true )



  # 価格チェック
  checkPrice: (param) ->
    value = param.currentTarget.value
    if value? and value.length > 0 and value.match(/^[0-9]+$/g) is null
      @$('.aleart_msg').show()
      param.currentTarget.focus()
    else
      @$('.aleart_msg').hide()

  # 検索条件取得
  getSearchToolConditions: ->
    # 条件取得
    region        = @getCheckedItem(@$el.find('.search_tool_region'))
    toolModelYear = @getCheckedItem(@$el.find('.search_tool_modelyear'))
    carName       = @getCheckedItem(@$el.find('.search_tool_carname'))
    grade         = @getCheckedItem(@$el.find('.search_tool_grade'))
    certify       = @getCheckedItem(@$el.find('.search_tool_certify'))
    exhaust       = @getCheckedItem(@$el.find('.search_tool_exhaust'))
    toolMission   = @getCheckedItem(@$el.find('.search_tool_shift'))
    toolMileage   = @getCheckedItem(@$el.find('.search_tool_mileage'))
    toolInspect   = @getCheckedItem(@$el.find('.search_tool_inspect'))
    color1        = @getCheckedItem(@$el.find('.search_tool_color'))
    docEquipment  = @getDocEquipment()
    estimation    = @getCheckedItem(@$el.find('.search_tool_estimation'))
    status        = @getCheckedItem(@$el.find('.search_tool_status'))
    price         = @getPrice()
    sortItem      = @getCheckedItem(@$el.find('.search_tool_sort_item'))
    sortOrder     = @getCheckedItem(@$el.find('.search_tool_sort_order'))[0]

    # 返却値設定
    # * 設定が存在するものだけ返却値に含め、ベースとなる検索条件がnullで上書きされないよう工夫している
    searchCond = {}
    if region?        and region.length > 0        then searchCond = _.extend(searchCond, {region        : region        })
    if toolModelYear? and toolModelYear.length > 0 then searchCond = _.extend(searchCond, {toolModelYear : toolModelYear })
    if carName?       and carName.length > 0       then searchCond = _.extend(searchCond, {carName       : carName       })
    if grade?         and grade.length > 0         then searchCond = _.extend(searchCond, {grade         : grade         })
    if certify?       and certify.length > 0       then searchCond = _.extend(searchCond, {certify       : certify       })
    if exhaust?       and exhaust.length > 0       then searchCond = _.extend(searchCond, {exhaust       : exhaust       })
    if toolMission?   and toolMission.length > 0   then searchCond = _.extend(searchCond, {toolMission   : toolMission   })
    if toolMileage?   and toolMileage.length > 0   then searchCond = _.extend(searchCond, {toolMileage   : toolMileage   })
    if toolInspect?   and toolInspect.length > 0   then searchCond = _.extend(searchCond, {toolInspect   : toolInspect   })
    if color1?         and color1.length > 0         then searchCond = _.extend(searchCond, {color1         : color1         })
    if docEquipment?                               then searchCond = _.extend(searchCond, {docEquipment  : docEquipment  })
    if estimation?    and estimation.length > 0    then searchCond = _.extend(searchCond, {estimation    : estimation    })
    if status?        and status.length > 0        then searchCond = _.extend(searchCond, {status        : status        })
    if price?                                      then searchCond = _.extend(searchCond, {price         : price         })
    conditions =
      searchcond : searchCond
      sortcond   : if sortItem is 0 then null else sortItem:sortItem, sortOrder:sortOrder
    return conditions

  # チェック済みアイテム取得
  getCheckedItem: (array) ->
    retArray = []
    for item in array
      if item.checked
        retArray.push item.value
    return retArray

  # 価格取得
  ###
    @TODO 大小逆なら入れ替える
    @TODO 出来れば入力直後に実装したい
  ###
  getPrice: ->
    # 値取得
    from = @$el.find('#search_tool_price_from')[0].value
    to   = @$el.find('#search_tool_price_to'  )[0].value
    wrkFrom = from
    wrkTo = to
    if from > to
      from = wrkTo
      to = wrkFrom
    from : if from? and from isnt '' then from * 10000 else null
    to   : if to?   and to   isnt '' then to   * 10000 else null

  blurPrice:->
    from = @$el.find('#search_tool_price_from').val()
    to   = @$el.find('#search_tool_price_to'  ).val()
    if from > to and to isnt '' and from isnt ''
      $("#search_tool_price_from").val(to)
      $("#search_tool_price_to").val(from)

  # 書類・装備取得
  getDocEquipment: ->
    # チェック済みアイテム取得
    retArray = @getCheckedItem(@$el.find('.search_tool_doc_equipment'))
    ###
    # ナビ再構築(ナビが選択されたらHDD以下は検索条件としない)
    if _.indexOf(retArray, '001') >= 0
      retArray = _.without(retArray, '002', '003', '004', '005')
      #console.log "Navi OK!"
    # TV再構築(TVが選択されたらワンセグ以下は検索条件としない)
    if _.indexOf(retArray, '011') >= 0
      retArray = _.without(retArray, '012', '013', '014', '015')
      #console.log "TV OK!!!!!"
    ###
    # リストアイテム取得
    driveItem  = @getCheckedItem(@$el.find('.search_tool_drive_item'))[0]
    handleItem = @getCheckedItem(@$el.find('.search_tool_handle_item'))[0]
    doorItem   = @getCheckedItem(@$el.find('.search_tool_door_item'))[0]
    bodyItem   = @getCheckedItem(@$el.find('.search_tool_body_item'))[0]


    # 指定なし以外の場合は配列に追加
    if driveItem  isnt 0 then retArray.push driveItem
    if handleItem isnt 0 then retArray.push handleItem
    if doorItem   isnt 0 then retArray.push doorItem
    if bodyItem   isnt 0 then retArray.push bodyItem
    return _.without(retArray, '0')

    # 書類・装備取得
  getCarCondition: ->
    # チェック済みアイテム取得
    retArray = @getCheckedItem(@$el.find('.search_tool_doc_equipment.carCondition'))
    # リストアイテム取得
    driveItem  = @getCheckedItem(@$el.find('.search_tool_drive_item'))[0]
    handleItem = @getCheckedItem(@$el.find('.search_tool_handle_item'))[0]
    doorItem   = @getCheckedItem(@$el.find('.search_tool_door_item'))[0]
    bodyItem   = @getCheckedItem(@$el.find('.search_tool_body_item'))[0]
    # 指定なし以外の場合は配列に追加
    if driveItem  isnt 0 then retArray.push driveItem
    if handleItem isnt 0 then retArray.push handleItem
    if doorItem   isnt 0 then retArray.push doorItem
    if bodyItem   isnt 0 then retArray.push bodyItem
    return _.without(retArray, '0')

  selectAllTv : ->
    @itemCheck(".chk_base_tv", true)


  selectAllNavi : ->
    @itemCheck(".chk_base_navi", true)

  selectAllItem : (param) ->
    #console.debug "param = " , param
    if param.currentTarget.checked
      $(".#{param.currentTarget.id}").prop( 'checked' : true)
    else
      $(".#{param.currentTarget.id}").prop( 'checked' : false)


  itemCheck: (clazz, bool) ->
    # HTMLからメーカー取得
    equip = @$el.find(clazz)
    # チェック付加
    for item in equip
      if item.id isnt 'navi' or item.id isnt 'TV'
        if equip[0].checked is true
          item.checked = bool
        if equip[0].checked is false
          item.checked = false
