Model = require 'models/model'

###
 [エラー]キーが入力された場合の処理を実装
###
module.exports = class ErrKeyInput extends Model

  keyMap =
    103: 'logoff' # 7

  initialize: ->
    _.bindAll @

  triggerKeyEvents: (event) ->
    keyCode = event.keyCode
    if keyMap[keyCode]
      return @trigger keyMap[keyCode]
    #else
    #  console.log keyCode
