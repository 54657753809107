application  = require 'application'
ErrorView = require '../views/page_error_view'

exports.socket_error = ->
  #console.error 'websocket error[%s]', Date.now()
  # Websocket　の通信速度監視をオフ
  Backbone.AUCNET.utilConnectView.setStopingFlg undefined if Backbone.AUCNET.utilConnectView
  _drawErrorView 'error/E0001'

exports.socket_disconnect = ->
  # Websocket　の通信速度監視をオフ
  Backbone.AUCNET.utilConnectView.setStopingFlg undefined if Backbone.AUCNET.utilConnectView
  _drawErrorView 'error/E0002'

exports.socket_systemError = (d) ->
  json = JSON.parse d
  #console.error '[error message]' + json.errorcd
  @socket.emit 'disconnect'
  sessionID : localStorage.sessionID
  # Websocket　の通信速度監視をオフ
  Backbone.AUCNET.utilConnectView.setStopingFlg undefined if Backbone.AUCNET.utilConnectView
  _drawErrorView 'error/E0002'

_drawErrorView = (id)  ->
  errorView = new ErrorView
  errorView.errorID = id
  $('body').html errorView.render().el
