###
  商談一覧表示(ページング対応)
  商談コレクションを表示する
###
# ブロック/リスト切り替えビュー
UtilChangeDispView = require 'views/util_change_disp_view'
# ページングなしの商談一覧表示
NegolistList       = require 'views/negolist_list_view'
# ページング
UtilPagingView     = require 'views/util_paging_view'
# 商談一覧テンプレート
Template           = require 'views/templates/negolist_list_multi'
Template_Frame           = require 'views/templates/negolist_list_multi_frame'
Template_Main           = require 'views/templates/negolist_list_multi_main'
# 複数商談用テンプレート
TemplatePop        = require 'views/templates/negolist_list_popup'
# 新着ボタンテンプレート
ButtonNewTemplate  = require 'views/templates/negolist_button_new'

module.exports = class NegolistListView extends NegolistList
  className  : 'negolist_list'
  id: null
  collection : {}
  template   : Template_Frame
  events :
    # 新着ボタン押下
    "click button.negolist_button_new"  : "getNewItems"
    # 複数商談表示ボタン押下
    "click button.negolist_multi_show"  : "showMultiNego"
    # 複数商談非表示ボタン押下
    "click button.negolist_multi_hide"  : "hideMultiNego"
    # 車両詳細表示
    # 'click .box label.showdetail, div.paddedBox div.showdetail' : 'onClickImage'
    # 商談画面遷移
    'click .negotiationBtnSmall'   : 'showNegoPage'
  # プッシュされた新着アイテム数
  itemcount:0
  options :
    # 進行中商談か
    ongoing : false
    # 過去商談か
    past    : false
    # 管理者画面か
    admin   : false

  # -------------------------
  # イニシャライズ
  # -------------------------
  initialize: ->
    _.bindAll @
    # ブロック/リスト切り替え
    @utilChangeDispView = new UtilChangeDispView ls_key_id : @id
    @listenTo @utilChangeDispView, "ChangeDisplayMode", @changeDisplayMode, @
    # ページングの準備
    @utilPagingView = new UtilPagingView  id : @id
    @listenTo @collection    , 'reset'      , @setPaging
    @listenTo @utilPagingView, 'paging'     , @paging_select
    # アイテムプッシュの準備
    @listenTo @collection    , 'addNego'    , @addNego
    @listenTo @collection    , 'removeNego' , @removeNego
    @listenTo @collection    , 'updateNego' , @updateNego

    # 商談データ取得
    # 0ページヘのページングを起こしてトリガーの代わりにする
    # listentoが終わる前に走ってしまうのでdefer()させる
    @collection.getNegolist {
      limit      : @utilPagingView.limit
      offset     : @utilPagingView.getOffset()
    }

  beforeRender: ->
    if Backbone.AUCNET.negoArray_list.length > 0
      for list_item in Backbone.AUCNET.negoArray_list
        window.clearTimeout list_item
        Backbone.AUCNET.negoArray_list = new Array()

  # -------------------------
  # レンダリング後処理
  # -------------------------
  afterRender: ->
    super()
    # 過去商談、現在商談の表示だしわけ
    if @options.ongoing
      @$el.find(".ongoing_only").show()
    if @options.past
      @$el.find(".past_only").show()
    if @options.admin
      @$el.find(".admin_only").show()

    @$el.find('.bgDes')
      .prepend(@utilPagingView.render().el)
      .children()
       # ブロック/リスト切り替え
      .prepend( @utilChangeDispView.render().el)
    @$el# 新着ボタン
       .prepend(ButtonNewTemplate(count:@itemcount))

  # -------------------------
  # ページングクラスのセッティング
  # -------------------------
  setPaging: ->
    @utilPagingView.setPagingData
      dataCount  : @collection.getDataCount()
    @utilPagingView.render()
    @render()
    @listRender()

  # -------------------------
  # ページ指定
  # -------------------------
  setPageNum:(num = 0) ->
    @utilPagingView.page = num

  # -------------------------
  # ページ選択
  # -------------------------
  paging_select: ->
    ret =
      limit      : @utilPagingView.limit
      offset     : @utilPagingView.getOffset()
    # このビューから検索条件を取得できないため
    # 親ページに発火して検索してもらう
    @trigger "ChangePage", ret

  # -------------------------
  # 複数商談を見せる(アコーディオン風)
  # -------------------------
  showMultiNego:(event) ->
    # 親のli要素
    $li = @$(event.currentTarget).parents("li")
    $button = $li.find("button.negolist_multi_show")

    # 2度押し防止チェック
    return false if $button.length is 0
    # showボタンをhideボタンにする
    $button.removeClass("negolist_multi_show")
    $button.addClass("negolist_multi_hide")

    # コレクションから該当商談を取得
    nego_id = $li.data("negoid") + ""
    negos = @collection.where nego_id : nego_id
    # 取得できなかったら何もしない
    return false if negos.length is 0

    # 複数商談を表示
    html = TemplatePop negos[0].toJSON()
    $li.after(html)
    @drawLabel()
    # アニメーション
    $li.nextAll("li.negolist_multi_child[data-negoid=#{nego_id}]")
       .animate({width : "show"}, "fast")

  # -------------------------
  # 複数商談を隠す
  # -------------------------
  hideMultiNego:(event) ->
    $li = @$(event.currentTarget).parents("li")
    $button = $li.find("button.negolist_multi_hide")
    nego_id = $li.data("negoid") + ""
    # クリックされたボタンの子商談を取得し閉じる
    $multiNego = $li.nextAll("li.negolist_multi_child[data-negoid=#{nego_id}]")
    $multiNego.animate {width : "hide"}, "fast",  ->
      $multiNego.remove()
      $button.removeClass("negolist_multi_hide")
      $button.addClass("negolist_multi_show")
      $button.text(Backbone.AUCNET.label.get("lb_negolist_more_item"))

  # -------------------------
  # 新着アイテムを取得し、新着順にソート
  # -------------------------
  getNewItems: ->
    @itemcount = 0
    @trigger "GetNewItems"

  # -------------------------
  # サーバからのプッシュ通知・アイテム追加
  # -------------------------
  addNego:(id) ->
    @itemcount++
    @$el.find(".negolist_new_count").text(@itemcount)

  # -------------------------
  # サーバからのプッシュ通知・画面上のアイテム削除
  # -------------------------
  # 実際に画面上から削除するのではなく、白く網かけする
  removeNego:(id) ->
    @$el.find("li.negolist_item_#{id}").addClass("removed")

  # -------------------------
  # サーバからのプッシュ通知・アイテム変更
  # -------------------------
  updateNego:(id) ->

    @render()
    @$el.find("li.negolist_item_#{id}").addClass("updated")
    @listRender()

  #商談へ遷移
  showNegoPage : (param) ->
    negoKey = param.currentTarget.id.split('|')
    Backbone.AUCNET.RenderController.renderNegoPage(negoKey[0],negoKey[1],negoKey[2],true)

  listRender: ->
    if @collection.length isnt 0
      @collection.each (model) =>
        that = @
        timeoutid = window.setTimeout ->
          list_item = Template_Main (list: [model.toJSON()])

          list_item_html = $(list_item)
          list_item_html.find('[data-label]').each ->
            $ele = $(@)
            label = _this.getLabel $ele.data('label')
            $ele
              .text(label)
              .attr('placeholder', label)

          that.$el.find(".displayList").append list_item_html

          # 過去商談、現在商談の表示だしわけ
          if that.options.ongoing
            list_item_html.find(".ongoing_only").show()
          if that.options.past
            list_item_html.find(".past_only").show()
          if that.options.admin
            list_item_html.find(".admin_only").show()

          #if that.id isnt 'negolist_list_my_past'
          if !that.options.past
            nego_status_param = model.get("list")?[0].nego_status
            remainTime = model.get("list")?[0].remain_time
            timerLastUpdateDate = model.get("list")?[0].timer_lastupdate_date
            endTimeDate = model.get("list")?[0].timelimit_date_to_ymd
            item_id = model.get("list")?[0].id

            switch nego_status_param
              when Backbone.AUCNET.DEFINE.get("NEGO_STATUS_CONTRACT"),Backbone.AUCNET.DEFINE.get("NEGO_STATUS_FLOW"),Backbone.AUCNET.DEFINE.get("NEGO_STATUS_CANCEL")
                $('.negolist_item_' + item_id + ' .ongoing_only').hide()
                $('.negolist_item_' + item_id + ' .past_only').show()

            if remainTime > 0
              # @timer 第一引数：タイマーエレメント,第二引数：カウントダウン秒数
              that.timer $(".count_down_timer_" + item_id), remainTime, timerLastUpdateDate, endTimeDate
        , 1
        Backbone.AUCNET.negoArray_list[Backbone.AUCNET.negoArray_list.length] = timeoutid
