exports.formatMoney = (num) ->
  return num unless num?
  i = Math.floor(num / 10000) #整数部分
  f = num - (i * 10000) #小数部分
  f = Math.floor(f / 100)
  ""
    .concat(i)
    .concat(".")
    .concat(("00" + f).slice(-2))
###
 日時フォーマット
   現在対応しているフォーマットは以下のみ
   yyyy: 年
   MM  : 月
   dd  : 日
   HH  : 時(00 ~ 23)
   mm  : 分
   ss  : 秒
   SSS : ミリ秒
###
exports.formatDate = (date, format = 'yyyy-MM-dd HH:mm:ss.SSS') ->
  date = new Date(date) if typeof date is 'number'
  date.toString()
  yyyy = date.getFullYear()
  M    = date.getMonth() + 1
  d    = date.getDate()
  H    = date.getHours()
  m    = date.getMinutes()
  s    = date.getSeconds()
  S    = date.getMilliseconds()
  MM   = if M < 10 then '0' + M else M
  dd   = if d < 10 then '0' + d else d
  HH   = if H < 10 then '0' + H else H
  mm   = if m < 10 then '0' + m else m
  ss   = if s < 10 then '0' + s else s
  SS   = if S < 10 then '0' + S else S
  if S < 10
    SSS = '00' + S
  else if S < 100
    SSS = '0' + S
  else
    SSS = S

  ret = format
  ret = ret.replace /yyyy/g, yyyy
  ret = ret.replace /MM/g, MM
  ret = ret.replace /M/g, M
  ret = ret.replace /dd/g, dd
  ret = ret.replace /d/g, d
  ret = ret.replace /HH/g, HH
  ret = ret.replace /H/g, H
  ret = ret.replace /mm/g, mm
  ret = ret.replace /m/g, m
  ret = ret.replace /ss/g, ss
  ret = ret.replace /s/g, s
  ret = ret.replace /SSS/g, SSS
  ret = ret.replace /SS/g, SS
  ret = ret.replace /S/g, S
  return ret

exports.formatDateString = (str) ->
  date = new Date str.replace(/-/g, '/')
  dateFormat = Backbone.AUCNET.label.get "lb_date_format"
  ret = exports.formatDate date, dateFormat
  return ret

# 数値をカンマ区切りに変換
exports.numberWithComma = (num) ->
  return num unless num?
  n = num.toString().split('.')
  n[0] = n[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  n.join('.')

# ユニコードのエスケープ解除処理を行う
exports.unescapeUnicode = (str) ->
  return '' unless str?
  return unescape(str)

# ユニコードのエスケープ処理を行う
exports.escapeUnicode = (str) ->
  return '' unless str?
  return escape(str)

# 日付妥当性チェックを行う
exports.checkDate = (year, month, day) ->
  dt = new Date(year, month - 1, day)
  dt.setFullYear year  unless dt.getFullYear() is year
  return false  if not dt? or dt.getFullYear() isnt year or dt.getMonth() + 1 isnt month or dt.getDate() isnt day
  true

# カタログのステータスをテキストに変換
exports.getStatusText = (status) ->
  st = ''
  switch status
    when '0' then st = 'nobid'
    when '1' then st = 'bidding'
    when '2' then st = 'sell'
    when '3' then st = 'pass'
    when '4' then st = 'cancel'
    #else console.log 'util#getStatusText 不明なステータス [' + status + ']'
  return st

# 金額欄の入力チェック
# ToDo :後で追加(by市橋)

# 前後の空白トリム
exports.ltrim = (str) ->
  return str.replace(/^\s+/, "")
exports.rtrim = (str) ->
  return str.replace(/\s+$/, "")
exports.trim = (str) ->
  return str.replace(/^\s+|\s+$/g, "")
exports.jtrim = (str) ->
  return unescapeUnicode(escapeUnicode(str).replace(/^(%u3000|%20|%09)+|(%u3000|%20|%09)+$/g, ""))

# 全角英数字文字列を半角文字列に変換する
exports.toOneByteAlphaNumeric = (str) ->
  str.replace /[Ａ-Ｚａ-ｚ０-９]/g, (s) ->
      String.fromCharCode(s.charCodeAt(0) - 0xFEE0)

# 半角英数字文字列を全角文字列に変換する
exports.toTwoByteAlphaNumeric = (str) ->
  str.replace /[A-Za-z0-9]/g, (s) ->
      String.fromCharCode(s.charCodeAt(0) + 0xFEE0)

# 整数値判定
exports.isInteger = (str) ->
  len = str?.length
  for i in [0...len]
    c = str.substr(i, 1)
    if !("0" <= c <= "9")
      return false
  return true

# -------------------------------------
# 商談メッセージ組み立て
#  _messageMaster : models/nego_message_collectionクラスのインスタンス
#  _messageCd     : メッセージコード
#  _options :
#    price     : 金額(単位:円)
#    limithour : 申込期限の時刻(hh形式)
# -------------------------------------
exports.editNegoMessage = (_messageMaster, _messageCd, _options) ->
  util            = require 'lib/util'
  if !_messageMaster?
    return ""

  _messageMasterLong = _messageMaster.filter((item) ->
    item.get("short_message_kbn") isnt "short"
  )
  # メッセージ取得
  if _messageCd is "A05"
    msg = util.getApiErrMsg _options.api_err_kbn, _options.api_err_cd
  else
    msglabel = (item for item in _messageMasterLong when item.get("message_cd") is _messageCd)[0]?.get("messagelabel")
    msg = Backbone.AUCNET.message.get(msglabel)

  unless msg?
    #console.error 'メッセージが定義されていません[%s]', msglabel
    return ""

  # オプション編集
  if _options.price?
    price = this.editMoneyFormat(_options.price)
    msg = msg.replace(/%s/, price)    # %sを置換

  if _options.limithour?
    limitHour = _options.limithour.replace(/[ ]/, Backbone.AUCNET.DEFINE.get 'DAY')
    msg = msg.replace(/%h/, limitHour)  # %hを時間

  DEFINE = Backbone.AUCNET.DEFINE
  if _messageCd is "A05"
    if Backbone.AUCNET.roleID is '1'
      _options.api_err_cd = _.compact((_options.api_err_cd + _options.admin_api_err_cd).split(/(.....)/g))
      # "ER0001,ER0002,ER0003"のような形で出力されてくるので管理者のみ内容を付与する。
      errCdList = _options.api_err_cd.toString().split(",")
      errCdMessahe = ""
      firstflag = true
      for val in errCdList
        labelName = "lb_error_code_label_" + val 
        
        if firstflag
          firstflag = false
        else
          errCdMessahe += ","
        errCdMessahe += val + "【" + Backbone.AUCNET.label.get(labelName) + "】"
    else
      errCdMessahe = _options.api_err_cd
    msg = msg.replace(/\{\$0\}/,errCdMessahe)
    msg = msg.replace(/\{\$1\}/,DEFINE.get("ADMINISTRATOR_TELL"))
  else
    if Backbone.AUCNET.roleID is '1'
      if (_options.api_err_cd + _options.admin_api_err_cd).length > 0
        _options.api_err_cd = _.compact((_options.api_err_cd + _options.admin_api_err_cd).split(/(.....)/g))
        if _options.api_err_cd.length > 0
          label = Backbone.AUCNET.label
          format = label.get "lb_error_code_label"
          msg = msg + format + _options.api_err_cd

  return msg

exports.editNegoMessageShortMessage = (_messageMaster, _messageCd, _options) ->
  if !_messageMaster?
    return ""

  _messageMasterShort = _messageMaster.filter((item) ->
    item.get("short_message_kbn") is "short"
  )

  # メッセージ取得
  msglabel = (item for item in _messageMasterShort when item.get("message_cd") is _messageCd)[0]?.get("messagelabel")
  msg = Backbone.AUCNET.message.get(msglabel)
  unless msg?
    #console.error 'メッセージが定義されていません[%s]', msglabel
    return ""

  # オプション編集
  if _options.price?
    price = this.editMoneyFormat(_options.price)
    msg = msg.replace(/%s/, price)    # %sを置換

  if _options.limithour?
    limitHour = _options.limithour.replace(/[ ]/, Backbone.AUCNET.DEFINE.get 'DAY')
    msg = msg.replace(/%h/, limitHour)  # %hを時間

  DEFINE = Backbone.AUCNET.DEFINE
  message_cd = item.get("message_cd")
  if message_cd.indexOf('ER0') > -1
    msg = msg.replace(/\{\$0\}/,message_cd)
    msg = msg.replace(/\{\$1\}/,DEFINE.get("ADMINISTRATOR_TELL"))

  return msg

# -------------------------------------
# 金額を表示形式に編集する
#   _money    : String 整数値が入った文字列(単位:円)
#   _language : String 言語コード
#       "ja"の場合、「○○万○千」形式へ編集する。千円未満を切り捨て。「円」はメッセージに書かれることが前提
# -------------------------------------
exports.editMoneyFormat = (_money) ->
  switch Backbone.AUCNET.LANGUAGE_ID
    when "ja"
      iMoney = parseInt(_money)
      mannen = parseInt(iMoney / 10000)
      sennen = parseInt((iMoney % 10000) / 1000)

      if mannen is 0
        return sennen + "千"
      else
        return mannen + "万" + sennen + "千"
  return ""


exports.chkRegEmail = (mailAddress) =>
  #簡易入力チェック
  message = ""
  unless mailAddress is null or mailAddress is ""
    # @が含まれていれば正しいとする
    NGMail = /[\w.\-]+@[\w\-]+\.[\w.\-]+/

    #カンマは使用不可
    NGMail2 = /^.*@[,].*$/i

    #入力文字数
    if mailAddress.length > 256
      message = 'ms_mail_ng_address_3'

    else if mailAddress.match(NGMail2)
      message = 'ms_mail_ng_address_2'

    #不正なアドレス
    else unless mailAddress.match(NGMail)
      message = 'ms_mail_ng_address_1'

  else
    message = 'ms_mail_no_address'

  return message

# -------------------------------------
# urlクエリをjsonにして返す
# -------------------------------------
# ?q=hoge&b=fuga&c=bar
#  -> {q:"hoge", b: "fuga", c:"bar"}としてかえす
exports.getUrlParamsJson = (query = "") ->
  retObj = {}
  query = query.replace /^\?/, ""
  queryAry = query.split "&"
  for item in queryAry
    itemAr = item.split "="
    retObj[itemAr[0]] = itemAr[1]
  return retObj


###
 メッセージ取得
 @param messageId メッセージID
 @param param リスト型のパラメータ群
###
exports.getMessage = (messageId , paramArray = []) ->
  retMessage = Backbone.AUCNET.message.get messageId
  unless retMessage?
    #console.error 'メッセージが定義されていません[%s]', messageId
    return ''
  for param in paramArray
    retMessage = retMessage.replace(/\{\$\}/,param)
  return retMessage


###
 ピッチ制御
 @param applyPrice 申し込み金額
 @param salesPrice 販売金額
###
exports.checkPitch = (applyPrice,salesPrice) ->
  if applyPrice is salesPrice
    return { ret : true  , pitch : '' , message : 'OK' }

  pitchArray = Backbone.AUCNET.DEFINE.get 'PITCH_ARRAY'
  val = 0
  #レンジ取得
  for json in pitchArray
    if salesPrice >= json.range
      val = json.value

  ret = applyPrice % val
  if ret is 0
    return { ret : true  , pitch : json.value , message : 'OK' }
  else
    return { ret : false , pitch : json.value , message : 'ms_apply_msg13_message' }



###
 売り買い区分を取得
 @item item 申し込み金額

 @returan salesPrice 販売金額
###
exports.editBuySellKbn = (item) ->
  DEFINE = Backbone.AUCNET.DEFINE
  ret = DEFINE.get "USER_ROLE_KBN_OTHER"                                                    # 他社商談
  if item.hash_owner_member_cd is Backbone.AUCNET.hashUserID then ret = DEFINE.get "USER_ROLE_KBN_SELLER" # 自社の売り商談
  if item.hash_buyer_member_cd is Backbone.AUCNET.hashUserID then ret = DEFINE.get "USER_ROLE_KBN_BUYER"  # 自社の買い商談
  return ret


###
 APIエラーコードを取得する
 @item item エラーコードの羅列メッセージ

 @returan 一般の場合、一番若いコードを、管理者の場合全てを配列で
###
exports.getApiErrorCd = (item) ->
  ret = []
  if !item
    return ret
  if item.length is 5
    ret.push item
    return ret

  if Backbone.AUCNET.roleID is '0' #　一般
    ret.push  item.substring(0,5)
  else if Backbone.AUCNET.roleID is '1' #　管理
    i = 0
    while i <= (item.length / 5)
      ret.push item.substring(i,5)
      i++
    return ret
  else if Backbone.AUCNET.roleID is '2' #　派遣
    ret.push  item.substring(0,5)
  else
    ret.push  item.substring(0,5)

  return ret

exports.getApiMessageParam = (item,messageParam) ->
  ret_array = []
  ret = []
  if !item
    return ret
  if item.length is 5
    ret.push item
    ret = ret.concat(messageParam)
    ret_array.push ret
    return ret_array

  if Backbone.AUCNET.roleID is '0' #　一般
    ret.push  item.substring(0,5)
    ret = ret.concat(messageParam)
    ret_array.push ret

  else if Backbone.AUCNET.roleID is '1' #　管理
    i = 0
    while i <= (item.length / 5)
      ret.push item.substring(i,5)
      ret = ret.concat(messageParam)
      i++
      ret_array.push ret
  else if Backbone.AUCNET.roleID is '2' #　派遣
    ret.push  item.substring(0,5)
    ret = ret.concat(messageParam)
    ret_array.push ret
  else
    ret.push  item.substring(0,5)
    ret = ret.concat(messageParam)
    ret_array.push ret

  return ret_array

exports.getMessages = (messageIds , paramArray = []) ->
  retMessages = []
  for messageId, _i in messageIds
    if _i isnt 0
      retMessages = retMessages + "\n"

    retMessage = Backbone.AUCNET.message.get messageId
    unless retMessage?
      #console.error 'メッセージが定義されていません[%s]', messageId
    else
      if paramArray[_i]
        for params in paramArray[_i]
          for param in params
             retMessage = retMessage.replace(new RegExp('\\{\\$'+_k+'\\}'),param)
    retMessages = retMessages + retMessage
  return retMessages

###
  APIエラーメッセージ取得.
  @param apiKbn API区分
  @param errCd  エラーコード
###
exports.getApiErrMsg = (apiKbn, errCd) ->
  switch apiKbn
    # 申込APIの場合
    when '0'
      switch errCd
        when 'ER001'
          return Backbone.AUCNET.message.get 'ms_webapi_err_1'
        when 'ER002'
          return Backbone.AUCNET.message.get 'ms_webapi_err_1'
        when 'ER003'
          return Backbone.AUCNET.message.get 'ms_webapi_err_1'
        when 'ER004'
          return Backbone.AUCNET.message.get 'ms_webapi_err_1'
        when 'ER005'
          return Backbone.AUCNET.message.get 'ms_webapi_err_2'
        when 'ER006'
          return Backbone.AUCNET.message.get 'ms_webapi_err_1'
        when 'ER007'
          return Backbone.AUCNET.message.get 'ms_webapi_err_1'
        when 'ER008'
          return Backbone.AUCNET.message.get 'ms_webapi_err_2'
        when 'ER009'
          return Backbone.AUCNET.message.get 'ms_webapi_err_2'
        when 'ER010'
          return Backbone.AUCNET.message.get 'ms_webapi_err_2'
        when 'ER011'
          return Backbone.AUCNET.message.get 'ms_webapi_err_2'
        when 'ER012'
          return Backbone.AUCNET.message.get 'ms_webapi_err_2'
        when 'ER013'
          return Backbone.AUCNET.message.get 'ms_webapi_err_2'
        when 'ER014'
          return Backbone.AUCNET.message.get 'ms_webapi_err_2'
        when 'ER015'
          return Backbone.AUCNET.message.get 'ms_webapi_err_2'
        when 'ER016'
          return Backbone.AUCNET.message.get 'ms_webapi_err_2'
        when 'ER017'
          return Backbone.AUCNET.message.get 'ms_webapi_err_2'
        when 'EA001'
          return Backbone.AUCNET.message.get 'ms_webapi_err_2'
        when 'EA002'
          return Backbone.AUCNET.message.get 'ms_webapi_err_2'
        when 'EA003'
          return Backbone.AUCNET.message.get 'ms_webapi_err_5'
        when 'LOCAL_1'
          return Backbone.AUCNET.message.get 'ms_webapi_err_local'

    # 商談APIの場合
    when '1'
      return Backbone.AUCNET.message.get 'ms_webapi_err_4'

    # 成約APIの場合
    when '2'
      return Backbone.AUCNET.message.get 'ms_webapi_err_3'

    # 流札APIの場合
    when '3'
      return Backbone.AUCNET.message.get 'ms_webapi_err_4'
