var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<span class=\"paging_data_count_class\">\r\n  <h3>\r\n    <span id=\"paging_data_count\">"
    + alias4(((helper = (helper = helpers.paging_data_count || (depth0 != null ? depth0.paging_data_count : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"paging_data_count","hash":{},"data":data}) : helper)))
    + "</span>\r\n    <span data-label='lb_util_paging_count' ></span>\r\n  </h3>\r\n</span>\r\n\r\n<div class=\"numberDisplay\">\r\n  <label class='noPointer' data-label='lb_util_paging_limit' style=\"float: left;\"></label>  \r\n  <ul>\r\n    <li class=\"paging_disp_num\" data-value=\"10\">10</li>\r\n    <li class=\"active paging_disp_num\" data-value=\"50\">50</li>\r\n    <li class=\"paging_disp_num\" data-value=\"100\">100</li>\r\n  </ul>\r\n</div>\r\n\r\n<div class=\"pagination\">\r\n  <ul>\r\n    <li><a class=\"paging_prev prev\" href=\"#\" data-page_id=\""
    + alias4(((helper = (helper = helpers.page_id || (depth0 != null ? depth0.page_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"page_id","hash":{},"data":data}) : helper)))
    + "\" data-label='lb_prev_page'></a></li>\r\n    <li class=\"paging_number_links\"></li>\r\n    <li><a class=\"paging_next next\" href=\"#\" data-page_id=\""
    + alias4(((helper = (helper = helpers.page_id || (depth0 != null ? depth0.page_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"page_id","hash":{},"data":data}) : helper)))
    + "\" data-label='lb_next_page'></a></li>\r\n  </ul>\r\n</div>\r\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}