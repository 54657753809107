###
 ラベル情報
###

labels =
[
  {
    "id": "ja"
    "lb_language": "日本語"
    "lb_userid": "ANS会員ID"
    "lb_password": "ANSパスワード"
    "lb_adminid": "管理者ID"
    "lb_admin_password": "管理者パスワード"
    "lb_login": "ログイン"
    "lb_logout": "ログアウト"
    "lb_title": "ネット商談"
    "lb_aucnet": "株式会社オークネット"
    "lb_aucneo_station": "オークネオステーションハイパー"
    "lb_subtitle": "Japanese Used Vehicles for Export"
    "lb_copyright": "© 2013- AUCNET INC."
    "lb_permission":
      "東京都公安委員会 " +
      "古物市場主許可第301001102112号 " +
      "古物商許可 " +
      "第301001105434号 " +
      "株式会社オークネット"
    "lb_environment": "ネット商談 推奨動作環境はこちら"
    "lb_forget_password": "パスワードを忘れた方はこちら"
    "lb_news": "新着"
    "lb_request": "ANSリクエスト"
    "lb_request_select": "ANSリクエスト選択"
    "lb_mylist": "ANSマイリスト"
    "lb_search": "車両検索"
    "lb_candidate": "お気に入り"
    "lb_nego": "ネット商談"
    "lb_negolist": "自社 ネット商談"
    "lb_negolistall": "他社 ネット商談中"
    "lb_negolistadmin": "管理用ﾈｯﾄ商談一覧"
    "lb_mailconfig": "各種設定"
    "lb_privacy_policy": "プライバシーポリシー"
    "lb_terms_and_conditions": "利用規約"
    "lb_operations_guide": "利用簡易マニュアル"
    "lb_confirm_ok": "OK"
    "lb_confirm_cancel": "キャンセル"
    "lb_subheadertitle": "サブヘッダタイトル"
    "lb_tab1": "タブ１"
    "lb_searchtool": "絞り込み・並べ替え"
    "lb_changeblockview": "ブロック表示"
    "lb_changelistview": "リスト表示"
    "lb_btn_renew": "更新"
    "lb_btn_send": "送信"
    "lb_btn_preview": "送信内容を確認する"
    "lb_input_price": "金額入力"
    "lb_input_under": ",000円"
    "lb_unit_currency": "円"
    "lb_unit_thousand": "千"
    "lb_unit_ten_thousand": "万"
    "lb_unit_car": "台"
    "lb_unit_image": "枚"
    "lb_unit_month_period": "ヶ月分"
    "lb_unit_day_period": "日分"
    "lb_prev_page": "前へ"
    "lb_next_page": "次へ"
    "lb_confirm_exit": "閉じる"
    "lb_config": "設定"
    "lb_user_search": "ユーザ検索"
    "lb_update_credit": "与信更新"
    "lb_mail_config": "メール設定"
    "lb_user_id_config": "設定先ID"
    "lb_mail_add": "送信"
    "lb_to_nego_apply": "商談申込み画面へ"
    "lb_mail_del": "削除"
    "lb_mail_re_send": "再送信"
    "lb_mail_no_data": "未設定"
    "lb_mail_no_activate": "【未認証】"
    "lb_input_clear": "×"
    "lb_preceding_page": "戻る"

    "lb_date_format": "yyyy年MM月dd日HH時mm分"
    "lb_date_format_no_taime": "yyyy/MM/dd"

    "lb_search_method_title": "検索方法選択"
    "lb_search_maker_title": "メーカー選択"
    "lb_jp_maker": "国産車"
    "lb_import_maker": "輸入車"
    "lb_search_car_name_title": "車名選択"
    "lb_search_conditions_title": "条件選択"
    "lb_search_total_car_num": "台"
    "lb_search_default_car_num": "- - - - - -"
    "lb_search_inv_no_title": "問合番号入力"
    "lb_search_result": "検索結果"
    "lb_search_inventory_year": "年式選択"

    "lb_share_stock": "共有在庫・一撃市場"
    "lb_search_maker_btn": "メーカー・条件から検索"
    "lb_search_inv_no_btn": "問合番号から検索"
    "lb_all_select": "全て選択"
    "lb_all_clear": "全て解除"
    "lb_jp_other": "その他国産車"
    "lb_import_other": "その他輸入車"
    "lb_search_exec": "車両一覧へ　該当"
    "lb_search_exec2": "検索"
    "lb_search_exec3": "検索"
    "lb_search_clear": "クリア"
    "lb_conditions_btn": "条件選択"

    "lb_search_cond_style": "型式"
    "lb_search_cond_grade": "グレード"
    "lb_search_cond_color": "色"
    "lb_search_cond_modelyear": "年式"
    "lb_search_cond_mileage": "走行距離"
    "lb_search_cond_estimation": "評価点"
    "lb_search_cond_inspect": "車検"
    "lb_search_cond_shift": "シフト"
    "lb_search_cond_none": "指定なし"
    "lb_search_cond_mileage_1": "0km"
    "lb_search_cond_mileage_2": "1万km"
    "lb_search_cond_mileage_3": "2万km"
    "lb_search_cond_mileage_4": "3万km"
    "lb_search_cond_mileage_5": "4万km"
    "lb_search_cond_mileage_6": "5万km"
    "lb_search_cond_mileage_7": "6万km"
    "lb_search_cond_mileage_8": "7万km"
    "lb_search_cond_mileage_9": "8万km"
    "lb_search_cond_mileage_10": "9万km"
    "lb_search_cond_mileage_11": "10万km"
    "lb_search_cond_mileage_12": "11万km"
    "lb_search_cond_mileage_13": "12万km"
    "lb_search_cond_mileage_14": "13万km"
    "lb_search_cond_mileage_15": "14万km"
    "lb_search_cond_mileage_16": "15万km"
    "lb_search_cond_estimation_1": "S"
    "lb_search_cond_estimation_2": "6"
    "lb_search_cond_estimation_3": "5.5"
    "lb_search_cond_estimation_4": "5"
    "lb_search_cond_estimation_5": "4.5"
    "lb_search_cond_estimation_6": "4"
    "lb_search_cond_estimation_7": "3.5"
    "lb_search_cond_estimation_8": "3"
    "lb_search_cond_estimation_9": "2.5"
    "lb_search_cond_estimation_10": "2"
    "lb_search_cond_estimation_11": "1"
    "lb_search_cond_estimation_12": "0"
    "lb_search_cond_inspect_1": "あり"
    "lb_search_cond_inspect_2": "なし"
    "lb_search_cond_shift_1": "MT"
    "lb_search_cond_shift_2": "AT"

    "lb_search_inv_no_range": "問合番号の入力範囲"
    "lb_search_inv_no_range_sep": "〜"
    "lb_search_inv_no_spec": "問合番号の指定入力"
    "lb_aleart_msg_input_inv_no": "数字で入力してください"

    "lb_nego_apply_msg_input_price": "商談申込価格（申込価格は登録店の業販価格となり、変更はできません）"
    "lb_nego_apply_limit": "回答期限を指定してください"
    "lb_nego_question_agree": "に同意しますか？"
    "lb_nego_term_agree": "同意する"

    "lb_data_line_list_header_1": "画像"
    "lb_data_line_list_header_2": "アイコン"
    "lb_data_line_list_header_3": "問合番号"
    "lb_data_line_list_header_4": "地区"
    "lb_data_line_list_header_5": "年式"
    "lb_data_line_list_header_6": "モデル"
    "lb_data_line_list_header_7": "車名"
    "lb_data_line_list_header_8": "グレード"
    "lb_data_line_list_header_9": "型式"
    "lb_data_line_list_header_10": "排気量"
    "lb_data_line_list_header_11": "燃料"
    "lb_data_line_list_header_12": "シフト"
    "lb_data_line_list_header_13": "走行距離"
    "lb_data_line_list_header_14": "車検"
    "lb_data_line_list_header_15": "色"
    "lb_data_line_list_header_16": "色No."
    "lb_data_line_list_header_17": "書類"
    "lb_data_line_list_header_18": "装備"
    "lb_data_line_list_header_19": "評価"
    "lb_data_line_list_header_20": "外装"
    "lb_data_line_list_header_21": "内装"
    "lb_data_line_list_header_22": "状態"
    "lb_data_line_list_header_23": "価格"
    "lb_data_line_list_header_24": "駆動方式"

    "lb_filter_exec": "反映"
    "lb_filter_clear": "解除"
    "lb_search_milage": "走行距離"
    "lb_search_tool_color": "色"
    "lb_search_tool_doc_equipment": "書類・装備"
    "lb_search_tool_estimation": "評価"
    "lb_search_tool_status": "状態"
    "lb_search_tool_price": "価格"
    "lb_search_tool_sort": "並べ替え"
    "lb_search_tool_order_asc": "昇順"
    "lb_search_tool_order_desc": "降順"

    "lb_entry": "申し込み内容を確認する"
    "lb_cancel": "キャンセル"
    "lb_search_mileage_0": "1万km未満"
    "lb_search_mileage_1": "1万km台"
    "lb_search_mileage_2": "2万km台"
    "lb_search_mileage_3": "3万km台"
    "lb_search_mileage_4": "4万km台"
    "lb_search_mileage_5": "5万km台"
    "lb_search_mileage_6": "6万km台"
    "lb_search_mileage_7": "7万km台"
    "lb_search_mileage_8": "8万km台"
    "lb_search_mileage_9": "9万km台"
    "lb_search_mileage_10": "10万km台"
    "lb_search_mileage_11": "11万km台"
    "lb_search_mileage_12": "12万km台"
    "lb_search_mileage_13": "13万km台"
    "lb_search_mileage_14": "14万km台"
    "lb_search_mileage_15": "15万km以上"
    "lb_search_inspect_0": "車検なし"
    "lb_search_inspect_1": "車検あり"
    "lb_search_currency_from": "万円〜"
    "lb_search_currency_to": "万円"
    "car_status_0": "ﾈｯﾄ商談中"
    "car_status_1": "ﾈｯﾄ商談受付中"
    "car_status_2": "ﾈｯﾄ商談不可"
    "lb_equip_cond_title": "装備条件"
    "lb_equip_cond_equip": "装備種類"
    "lb_doc_equip_doc": "書類種類"
    "lb_equip_cond_cool": "冷房種類"
    "lb_equip_fuel": "燃料種類"
    "lb_equip_carHistory": "仕様種類"
    "lb_equip_importCar": "輸入車種類"
    "lb_equip_equip_399": "乗車定員数"
    "lb_doc_equip_00": "ナビ"
    "lb_doc_equip_00-1": "ナビ種類"
    "lb_doc_equip_01": "ナビ"
    "lb_doc_equip_02": "HDD"
    "lb_doc_equip_03": "DVD"
    "lb_doc_equip_04": "メモリ"
    "lb_doc_equip_05": "その他"
    "lb_doc_equip_10": "TV"
    "lb_doc_equip_10-1": "TV種類"
    "lb_doc_equip_11": "TV"
    "lb_doc_equip_12": "フルセグ"
    "lb_doc_equip_13": "ワンセグ"
    "lb_doc_equip_14": "地デジ"
    "lb_doc_equip_15": "アナログ"
    "lb_doc_equip_20": "その他装備"
    "lb_doc_equip_21": "サンルーフ"
    "lb_doc_equip_22": "革シート"
    "lb_doc_equip_23": "キーレス"
    "lb_doc_equip_24": "アルミホイール"
    "lb_doc_equip_25": "CD"
    "lb_doc_equip_26": "バックカメラ"
    "lb_doc_equip_27": "フロアマット"
    "lb_doc_equip_28": "ETC"
    "lb_doc_equip_29": "PS"
    "lb_doc_equip_30": "PW"
    "lb_doc_equip_31": "エアバッグ"
    "lb_doc_equip_32": "ABS"
    "lb_doc_equip_40": "冷房"
    "lb_doc_equip_41": "AC"
    "lb_doc_equip_42": "クーラー(C)"
    "lb_doc_equip_43": "GAW"
    "lb_doc_cond_title": "書類条件"
    "lb_doc_equip_50": "書類"
    "lb_doc_equip_51": "1オーナー"
    "lb_doc_equip_52": "保証"
    "lb_doc_equip_53": "取説"
    "lb_doc_equip_54": "記録"
    "lb_doc_equip_55": "手帳"
    "lb_car_cond_title": "車両条件"
    "lb_doc_equip_60": "車両の仕様"
    "lb_doc_equip_61": "福祉車両"
    "lb_doc_equip_62": "レンタカー"
    "lb_doc_equip_63": "8ナンバー"
    "lb_doc_equip_70": "輸入車区分"
    "lb_doc_equip_71": "D"
    "lb_doc_equip_72": "H"
    "lb_doc_equip_73": "M"
    "lb_doc_equip_80": "駆動方式"
    "lb_doc_equip_90": "ハンドル"
    "lb_doc_equip_100": "ドア数"
    "lb_doc_equip_110": "燃料"
    "lb_doc_equip_111": "ガソリン"
    "lb_doc_equip_112": "軽油"
    "lb_doc_equip_113": "ハイブリッド"
    "lb_doc_equip_114": "電気"
    "lb_doc_equip_115": "LPG"
    "lb_doc_equip_116": "CNG"
    "lb_doc_equip_117": "その他"
    "lb_doc_equip_120": "乗車定員"
    "lb_doc_equip_121": "〜2人"
    "lb_doc_equip_122": "3人"
    "lb_doc_equip_123": "4人"
    "lb_doc_equip_124": "5人"
    "lb_doc_equip_125": "6人"
    "lb_doc_equip_126": "7人"
    "lb_doc_equip_127": "8人"
    "lb_doc_equip_128": "9人〜"
    "lb_doc_equip_130": "ボディタイプ"
    "lb_search_tool_none": "指定なし"
    "lb_search_tool_dirve_1": "4WD"
    "lb_search_tool_dirve_2": "2WD"
    "lb_search_tool_handle_1": "右ハンドル"
    "lb_search_tool_handle_2": "左ハンドル"
    "lb_search_tool_door_1": "1D"
    "lb_search_tool_door_2": "2D"
    "lb_search_tool_door_3": "3D"
    "lb_search_tool_door_4": "4D"
    "lb_search_tool_door_5": "5D"
    "lb_search_tool_door_6": "6D"
    "lb_search_tool_sort_1": "問合番号"
    "lb_search_tool_sort_2": "年式"
    "lb_search_tool_sort_3": "車名"
    "lb_search_tool_sort_4": "グレード"
    "lb_search_tool_sort_5": "型式"
    "lb_search_tool_sort_6": "排気量"
    "lb_search_tool_sort_7": "燃料"
    "lb_search_tool_sort_8": "シフト"
    "lb_search_tool_sort_9": "走行距離"
    "lb_search_tool_sort_10": "車検"
    "lb_search_tool_sort_11": "色"
    "lb_search_tool_sort_12": "評価"
    "lb_search_tool_sort_13": "外装"
    "lb_search_tool_sort_14": "内装"
    "lb_search_tool_sort_15": "ステータス"
    "lb_search_tool_sort_16": "価格"

    "lb_aleart_msg_input_price": "数字で入力してください"

    "lb_request_header_request_no": "リクエストNO"
    "lb_request_header_request_name": ""
    "lb_request_header_maker_group_name": ""
    "lb_request_header_vsyasyuclass_nm": ""
    "lb_request_header_1": "年式"
    "lb_request_header_2": "型式"
    "lb_request_header_3": "走行距離"
    "lb_request_header_4": "シフト"
    "lb_request_header_5": "車検"
    "lb_request_header_6": "評価"
    "lb_request_header_7": "ANS連携日"
    "lb_request_header_8": "有効回数"
    "lb_request_header_9": "残り回数"
    "lb_request_header_10": "ヒット台数"
    "lb_request_header_11": "メール通知"
    "lb_request_header_12": "グレード／色"

    "lb_candidate_nego_apply_chk": "ネット商談を申し込む"
    "lb_candidate_nego_apply_btn": "チェックした車輌にネット商談を申し込む"
    "lb_candidate_remove_all": "全ての車輌をお気に入りから外す"

    "lb_negolist_my_past": "過去履歴"
    "lb_negolist_my_ongoing": "商談中"
    "lb_negolist_all_ongoing": "他社 ネット商談"
    "lb_negolist_new": "新着を表示"
    "lb_negolist_more_item": "複数表示"
    "lb_negolist_less_item": "複数表示たたむ"
    "lb_negolist_remain_time_little": "わずか！"

    "lb_nego_user_kbn_other": "他社商談"
    "lb_nego_user_kbn_buyer": "買い"
    "lb_nego_user_kbn_seller": "売り"
    "lb_nego_nego_name": "商談名"
    "lb_nego_buy_sell": "売買表示"
    "lb_nego_buy_sell_kbn": "売買区分"
    "lb_nego_owner_price": "販売金額"
    "lb_nego_owner_hope_price": "販売希望金額"
    "lb_nego_owner_last_hope_price": "最終販売希望金額"
    "lb_nego_buyer_price": "購入金額"
    "lb_nego_buyer_hope_price": "購入希望金額"
    "lb_nego_result_price": "成約金額"
    "lb_nego_status": "商談ステータス"
    "lb_nego_entry_date": "商談申込日時"
    "lb_nego_end_date": "商談終了日時"
    "lb_nego_remain_time": "残り時間"
    "lb_nego_last_message": "最新メッセージ"
    "lb_nego_owner_member_cd": "出品店コード"
    "lb_nego_buyer_member_cd": "落札希望店コード"
    "lb_nego_status_request": "申込中"
    "lb_nego_status_ongoing": "商談中"
    "lb_nego_status_contract": "成約"
    "lb_nego_status_flow": "流札"
    "lb_nego_status_cancel": "流札"
    "lb_nego_admin_cancel": "流札"

    "lb_nego_inventory_no": "問合番号"
    "lb_nego_inventory_year": "年式"
    "lb_nego_model_year": "年式"
    "lb_nego_style": "型式"
    "lb_nego_estimation": "評価点"
    "lb_nego_color": "色"
    "lb_nego_mileage": "走行距離"
    "lb_nego_inspect": "車検"
    "lb_nego_owner_price1": "販売金額"
    "lb_nego_buyer_price1": "希望金額"
    "lb_nego_result_price1": "成約金額"
    "lb_nego_timelimit": "タイムリミット"
    "lb_nego_exhaust_unit": "cc"

    "nego_status_1": "申込中"
    "nego_status_2": "商談中"
    "nego_status_3": "成約"
    "nego_status_4": "流札"
    "nego_status_5": "流札"

    "lb_sort_remain_time": "残り時間昇順"
    "lb_sort_remain_time_desc": "残り時間降順"
    "lb_sort_status": "ステータス順"
    "lb_sort_status_desc": "ステータス降順"
    "lb_sort_end_time_desc": "終了時間降順"
    "lb_sort_end_time": "終了時間昇順"
    "lb_sort_new": "新着順"

    "lb_notice": "お知らせ"
    "lb_notice_detail": "お知らせ詳細"
    "lb_negolist_my_new_sell": "ﾈｯﾄ商談(売り)"
    "lb_negolist_my_new_buy": "ﾈｯﾄ商談(買い)"
    "lb_refresh": "最新化"
    "lb_new_display": "最新情報があります！"
    "lb_show_car_detail": "車両詳細表示"

    "detail_fuel_type_1": "ガソリン"
    "detail_fuel_type_2": "軽油"
    "detail_fuel_type_3": "LPG"
    "detail_fuel_type_4": "電気"
    "detail_fuel_type_5": "ハイブリッド"
    "detail_fuel_type_6": "CNG"
    "detail_fuel_type_7": "天然ガス(TAA追加)"
    "detail_fuel_type_8": "その他"

    "lb_watchlist_add": "お気に入りに加える"
    "lb_watchlist_del": "お気に入りから外す"
    "lb_watchlist_improper": "ネット商談不可"

    "lb_nego_apply": "ネット商談申込"
    "lb_nego_see": "ネット商談確認"
    "lb_btn_nego01": "ネット商談申込"
    "lb_btn_nego02": "即決"
    "lb_btn_nego03": "価格提示"
    "lb_btn_nego04": "最終価格提示"
    "lb_btn_nego05": "流札"
    "lb_btn_nego06": "ネット商談不可（在庫なし）"
    "lb_btn_nego07": "ネット商談不可（小売商談中）"
    "lb_btn_nego08": "ネット商談可（即決成約）"
    "lb_btn_nego09": "ネット商談可（価格提示）"
    "lb_btn_nego10": "ネット商談可（最終価格提示）"
    "lb_btn_nego11": "即決"
    "lb_btn_nego12": "価格提示"
    "lb_btn_nego13": "最終価格提示"
    "lb_btn_nego14": "流札"

    "lb_send_role_kbn_0": ""
    "lb_send_role_kbn_1": "落札希望店"
    "lb_send_role_kbn_2": "出品店"
    "lb_send_role_kbn_3": "システム管理者"
    "lb_send_role_kbn_4": "自社"

    "car_histry_1": "自家用車"
    "car_histry_2": "営業者"
    "car_histry_3": "レンタカー"
    "car_histry_4": "他"

    "car_category_1": "一般車"
    "car_category_2": "RV＆SUV"
    "car_category_3": "軽自動車"
    "car_category_4": "バス・トラック"

    "air_condition_0": "無"
    "air_condition_1": "AC"
    "air_condition_2": "C"
    "air_condition_3": "WAC"
    "air_condition_4": "AAC"
    "air_condition_5": "WAAC"

    "color_1": "ｼﾛ"
    "color_2": "Pﾎﾜｲﾄ"
    "color_3": "ﾍﾞｰｼﾞｭ"
    "color_4": "ｼﾙﾊﾞｰ"
    "color_5": "ｸﾞﾚｰ"
    "color_6": "ｶﾞﾝM"
    "color_7": "ｸﾛ"
    "color_8": "ﾋﾟﾝｸ"
    "color_9": "ﾑﾗｻｷ"
    "color_10": "Wﾚｯﾄﾞ"
    "color_11": "ｱｶ"
    "color_12": "ｷｲﾛ"
    "color_13": "ｵﾚﾝｼﾞ"
    "color_14": "ｺﾞｰﾙﾄﾞ"
    "color_15": "ﾁｬｲﾛ"
    "color_16": "ﾐｽﾞｲﾛ"
    "color_17": "ｱｵ"
    "color_18": "ｺﾝ"
    "color_19": "ｸﾞﾘｰﾝ"
    "color_20": "ﾏｼﾞｮｰﾗ"
    "color_21": "ｿﾉﾀ"

    "noxpm_0": "対象外"
    "noxpm_1": "適合"
    "noxpm_2": "不適合"

    "NAVI_KIND_0": "未選択"
    "NAVI_KIND_1": "DVD"
    "NAVI_KIND_2": "HDD"
    "NAVI_KIND_3": "メモリ"
    "NAVI_KIND_4": "その他"

    "TV_KIND_0": "未選択"
    "TV_KIND_1": "フルセグ"
    "TV_KIND_2": "ワンセグ"
    "TV_KIND_3": "アナログ"
    "TV_KIND_4": "地デジ"

    "equipment_flag_0": "無"
    "equipment_flag_1": "純正"
    "equipment_flag_2": "社外"
    "equipment_flag_3": "不明"

    "detail_image": "画像"
    "detail_inventoryno": "問合番号"
    "detail_inventoryyear": "登録年"
    "detail_wholesaleprice": "業販価格"
    "detail_modelyear": "年式"
    "detail_model": "モデル"
    "detail_maker": "メーカー"
    "detail_carname": "車名"
    "detail_grade": "グレード"
    "detail_drive": "駆動"
    "detail_shift": "シフト"
    "detail_exhaust": "排気量"
    "detail_fuel": "燃料"
    "detail_milage": "走行"
    "detail_capacity": "定員"
    "detail_inspect": "車検"
    "detail_certify": "型式"
    "detail_door": "ドア"
    "detail_carbody": "車台No."
    "detail_histry": "車歴"
    "detail_category": "タイプ"
    "detail_repaint": "全塗装"
    "detail_air": "冷房"
    "detail_outcolor": "外装系統色"
    "detail_colorno": "色No."
    "detail_incolor": "内装系統色"
    "detail_trimno": "トリムNo."
    "detail_stylespecify_gradetype": "型式・類別"
    "detail_stylespecify": "型式"
    "detail_gradetype": "種別"
    "detail_noxpm": "NOx ・ PM"
    "detail_maxloading": "積載"
    "detail_region": "地区"
    "detail_recycle": "リサイクル"
    "detail_oneowner": "書類"
    "detail_document_date": "書類期限"
    "detail_8number": "8 No."

    "detail_equepmentcomment": "装備その他"
    "detail_fee": "参考輸送代"
    "detail_estimation": "総合評価"
    "detail_outward": "外装"
    "detail_upholstery": "内装"
    "detail_validity": "検査有効期限"
    "detail_damage": "傷画像"
    "detail_exhibitorcomment": "セールスポイント"
    "detail_inspectcommnet": "検査コメント"
    "detail_notices": "特記事項"
    "detail_mylist_count": "マイリスト件数"
    "detail_fain_select": "ファインセレクト"

    "detail_kokuchi_commnet": "告知コメント"
    "detail_nego_flag": "価格交渉"
    "detail_restore_kind": "修復歴申告"
    "detail_selfhyoka_kesaiflag": "自己申告画像"
    "detail_selfhyoka_title": "申告"
    "detail_selfhyoka_total": "総合評価"
    "detail_selfhyoka_gaiso": "外装"
    "detail_selfhyoka_naiso": "内装"
    "detail_vehicle_development_view": "車両展開図"
    "detail_image_count": "画像数"
    "detail_count_browse": "閲覧数"

    "point_01": "特記事項01"
    "point_02": "特記事項02"
    "point_03": "特記事項03"
    "point_04": "特記事項04"
    "point_05": "特記事項05"
    "point_06": "特記事項06"
    "point_07": "特記事項07"
    "point_08": "特記事項08"
    "point_09": "特記事項09"
    "point_10": "特記事項10"
    "point_11": "特記事項11"
    "point_12": "特記事項12"
    "point_13": "特記事項13"
    "point_14": "特記事項14"
    "point_15": "特記事項15"
    "point_16": "特記事項16"
    "point_17": "特記事項17"
    "point_18": "特記事項18"
    "point_19": "特記事項19"
    "point_20": "特記事項20"

    "restore_kind_0": "不明"
    "restore_kind_1": "有り(リペ)"
    "restore_kind_2": "無し"

    "estimation_1": "ＡＩＳ検査：無し"
    "estimation_2": "検査切れ"

    "cvt_flag_0": "対象外"
    "cvt_flag_1": "対象"

    "recycle_0": "未委託"
    "recycle_1": "委託済み"

    "lb_util_paging_limit": "表示件数："
    "lb_util_paging_count": "件 検索されました"
    "lb_util_change_disp": "表示モード"
    "lb_error_code_label": "エラーコード："

    'lb_error_code_label_EL001': "IDかパスワードが不正"
    'lb_error_code_label_ER001': "削除済み(落札希望店削除等)"
    'lb_error_code_label_ER002': "削除済み(成約)"
    'lb_error_code_label_ER003': "共有区分オープンではない"
    'lb_error_code_label_ER004': "公開区分可ではない"
    'lb_error_code_label_ER005': "TVAA振替出品中"
    'lb_error_code_label_ER006': "在庫表示可ではない【会員】"
    'lb_error_code_label_ER007': "在庫公開可ではない【会員】"
    'lb_error_code_label_ER008': "検査付ではない"
    'lb_error_code_label_ER009': "一撃設定されている"
    'lb_error_code_label_ER010': "リボン商談中"
    'lb_error_code_label_ER011': "TVAA出品受付中"
    'lb_error_code_label_ER012': "自動商談中"
    'lb_error_code_label_ER013': "出品店IDが不正"
    'lb_error_code_label_ER014': "TVAA出品受付中車両成約"
    'lb_error_code_label_ER015': "車両が存在しない"
    'lb_error_code_label_ER016': "既に登録されている商談IDが来た(申込)"
    'lb_error_code_label_ER017': "登録されていない商談IDが来た(商談、成約、流札)"
    'lb_error_code_label_EA001': "タイムアウト"
    'lb_error_code_label_EA002': "DB接続エラー"
    'lb_error_code_label_EA003': "システムメンテナンス"

    "lb_detail_next_car": "次の車両　>>"
    "lb_detail_prev_car": "<<　前の車両"
    "lb_search_result_back": "条件選択へ戻る"
    "lb_request_back": "リクエスト選択へ戻る"
    "lb_mail_setting_1": "※認証済みメールアドレスの最後の一件は削除できません。"

    "lb_breadcrumb_search_maker": "メーカー選択"
    "lb_breadcrumb_search_car_name": "車名選択"
    "lb_breadcrumb_search_conditions": "条件選択"
    "lb_breadcrumb_search_inv_no": "問い合わせ番号選択"

    "one_owner_flag_1": "(ワンオーナー)"
    "guarantee_flag_1": "(保証書)"
    "manual_flag_1": "(取扱説明書)"
    "record_flag_1": "(記録簿)"
    "note_flag_1": "(整備手帳)"

    "0": "0"
    "": ""
    "": ""
  }
]
module.exports = labels
