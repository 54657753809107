Model = require 'models/model'
infoData = require 'data/infos'

###
 ラベル
###
module.exports = class Info extends Model

  initialize: ->
    _.bindAll @

  getInfo: (languageid = "") ->
    # 言語IDに合致するインフォ情報を取得
    data = (item for item in infoData when item.id is languageid)
    @set data[0]
