Model   = require 'models/model'

###
 商談情報
###
module.exports = class NegoInfo extends Model

  socket: null
  RC_OK : "0"

  # 商談ID
  deaults:
    nego_id : ""

  # 定数定義
  DEFINE :
    # ロールＩＤ
    ROLE_ID_USER     : '0' # 一般
    ROLE_ID_ADMIN    : '1' # 管理者
    ROLE_ID_DISPATCH : '2' # 派遣

    # 商談ステータス
    NEGO_STATUS_REQUEST  : '1' # 申込中
    NEGO_STATUS_ONGOING  : '2' # 商談中
    NEGO_STATUS_CONTRACT : '3' # 成約
    NEGO_STATUS_FLOW     : '4' # 流れ
    NEGO_STATUS_CANCEL   : '5' # キャンセル

    # ユーザロール区分
    USER_ROLE_KBN_OTHER  : '0' # 他社
    USER_ROLE_KBN_BUYER  : '1' # 購入希望者
    USER_ROLE_KBN_SELLER : '2' # 出品店
    USER_ROLE_KBN_ADMIN  : '3' # 管理者

  initialize: ->
    _.bindAll @
    @socket = Backbone.AUCNET.socket

    # 商談部屋に参加成否受け取り
    @socket.removeAllListeners 'JoinNegoRoom'
    @socket.on 'JoinNegoRoom', (d)=>
      data = JSON.parse d
      return if data.nego_id        isnt @get('currentNegoID')
      return if data.inventory_year isnt @get('currentInventoryYear')
      return if data.inventory_no   isnt @get('currentInventoryNo')
      #console.debug "商談名=" + data.nego_name
      #console.debug data.nego_status
      #console.debug JSON.stringify(data)
      # ロール設定
      data.role = @_getRoleForNego(Backbone.AUCNET.hashUserID, Backbone.AUCNET.roleID, data.hash_owner_member_cd, data.hash_buyer_member_cd, data.nego_status)

      @set data
      if data.rc is @RC_OK
        @trigger "SuccessJoinNegoRoom"
      else
        @trigger "FailedJoinNegoRoom"

  # 入室願い
  joinNegoRoom: (negoID = "", inventoryYear = "", inventoryNo = "")->
    @socket.emit "JoinNegoRoom",
      nego_id        : negoID
      inventory_year : inventoryYear
      inventory_no   : inventoryNo


  # 入室願い
  joinNegoRoomReset: (negoID = "", inventoryYear = "", inventoryNo = "")->
    @socket.emit "JoinNegoRoom",
      nego_id        : negoID
      inventory_year : inventoryYear
      inventory_no   : inventoryNo
      noJoinFlag     : true #入出処理を起こさないフラグ

  # ----------------------------------
  # ログイン者にとってその商談が何か
  #  _userID        : ログインユーザーID
  #  _roleID        : ログインユーザー権限コード
  #  _ownerMemberCD : 対象商談の出品店会員コード
  #  _buyerMemberCD : 対象商談の落札希望店会員コード
  #
  #  return : ユーザーロール区分(DEFINE.USER_ROLE_KBN_???)
  # ----------------------------------
  _getRoleForNego: (_userID, _roleID, _ownerMemberCD, _buyerMemberCD, _nego_status)->

    ## 商談ステータスが成約,流れ,キャンセルの場合、どの_userID,_roleIDであっても閲覧しかさせない。
    #if _nego_status in [@DEFINE.NEGO_STATUS_CONTRACT, @DEFINE.NEGO_STATUS_FLOW, @DEFINE.NEGO_STATUS_CANCEL]
    #  return @DEFINE.USER_ROLE_KBN_OTHER

    # 管理者(派遣含む)の場合、管理者モード
    if _roleID in [@DEFINE.ROLE_ID_ADMIN , @DEFINE.ROLE_ID_DISPATCH]
      return @DEFINE.USER_ROLE_KBN_ADMIN

　　　 # _userIDから見て、対象商談が出品店、落札希望店、他社のいづれかが決まる
    if _userID is _ownerMemberCD
      return @DEFINE.USER_ROLE_KBN_SELLER
    if _userID is _buyerMemberCD
      return @DEFINE.USER_ROLE_KBN_BUYER

    return @DEFINE.USER_ROLE_KBN_OTHER
