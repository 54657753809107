Model        = require 'models/model'
util         = require 'lib/util'

###
　商談申し込みモデル
###
module.exports = class NegoApplyModel extends Model

  socket: undefined

  #持つべきデータをここに書く。
  defaults:
    emailArray : []
    message           : undefined
    messageParam      : []
    expirationDateList: undefined

  initialize: ->
    _.bindAll @
    @socket = Backbone.AUCNET.socket

  getApplyData : (applyInvKeyList)->
    #サーバ時間取得
    @socket.on 'getNegoApplyData', (d) =>
      @socket.removeAllListeners 'getNegoApplyData'
      ret = JSON.parse(d)
      unless ret.error
        @set {'expirationDateList' : ret.expirationDateList}, {silent : true}
        @set {'carDataArray'       : ret.appyCarList}, {silent : true}
        #Email取得
        @socket.on 'SettingMailResult', (d) =>
          @socket.removeAllListeners 'SettingMailResult'
          retJson = JSON.parse(d)
          unless retJson.error
            emailArray = []
            for json in retJson.address
              #取得したメールアドレスから認証済みのみ取得
              if json.flg is '1' #valid_flg 認証済み
                emailArray.push json.address
            unless _.isEmpty(emailArray)
              @set 'emailArray' : emailArray
            else
              #有効なメールがない
              @set {'message' : 'ms_apply_msg01_message'}, {silent:true}
              @trigger '_error'
          else
            #有効なメールがない
            @set {'message' : 'ms_apply_msg01_message'}, {silent:true}
            @trigger '_error'
        @socket.emit 'getEmail'
      else
        #車両取得失敗
        @set {'message' : ret.message}, {silent:true}
        @trigger '_error'

    @socket.emit 'getNegoApplyData', {applyInvKeyList : applyInvKeyList}

  negoApply : (carList, mailList, price , expirationDate,callback) ->

    @socket.on 'NegoApply', (d) =>
      @socket.removeAllListeners 'NegoApply'
      ret = JSON.parse(d)
      if ret.error
        @set {'message' : ret.message}, {silent:true}
        @set {'api_message' : util.getApiErrorCd(ret.api_message)}, {silent:true}
        if ret.message in ['ms_apply_msg14_message', 'ms_apply_msg17_message']
          @set {'messageParam' : ret.messageParam}, {silent:true}
        if ret.api_message
          paramArray = []
          paramArray.message_cd = "A05"
          options =
            api_err_cd       : (ret.api_message).substring(0,5)
            api_err_kbn      : '0'
            admin_api_err_cd : (ret.api_message).substring(5)

          paramArray.api_err_obj = options

          @set {'messageParam' : paramArray}, {silent:true}
      callback ret.error ,ret.negoId

    @socket.emit 'NegoApply', {
      applyList: carList
      mailList: mailList
      applyPrice: price
      expirationDate: expirationDate
    }
