module.exports = [
  {
    # 車両１
    id           : '00001'
    meker       : 'newlist'
    name        : 'lb_newlist'
    class        : 'newListBtn'
    newitemcount : 0
  }
  {
    # 車両２
    id           : '00002'
    meker       : 'search'
    name        : 'lb_search'
    class        : 'searchBtn'
    newitemcount : undefined
  }
  {
    # 車両３
    id           : '00003'
    meker       : 'searchresult'
    name        : 'lb_searchresult'
    class        : 'searchResultBtn'
    newitemcount : undefined
  }
]