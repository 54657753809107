setting             = require 'setting'
notSupportedBrowser = require "../lib/check_browser"
Info                = require '../models/info_model'
Label               = require '../models/label_model'
Message             = require '../models/message_model'
listener            = require '../lib/init_login_listener'

# 一般ユーザ
exports.init = ->
  LoginUserModel      = require '../models/login_user_model'
  LoginPageView       = require '../views/init_login_view'
  _initLoginpage {
    hiddenDomSelector: "#login_page"
    LoginUserModel : LoginUserModel
    LoginPageView : LoginPageView
    management_or_normal : '0'
  }

# 管理者ユーザ
exports.init_admin = ->
  LoginUserModel      = require '../models/login_admin_model'
  LoginPageView       = require '../views/init_login_admin_view'
  _initLoginpage {
    hiddenDomSelector: "#admin_login_page"
    LoginUserModel : LoginUserModel
    LoginPageView : LoginPageView
    management_or_normal : '1'
  }

# router.coffee、initialize.coffeeから持ってきて、ログインに必要な部分以外を削除した
_initLoginpage = ({hiddenDomSelector, LoginUserModel, LoginPageView, management_or_normal})->
  # ブラウザチェック
  notSupport = notSupportedBrowser.fire()
  if notSupport
    return false
  Backbone.AUCNET ?= {}
  setting.initialize (result) ->
    # ログイン後使用のセッションストレージ
    #window.sessionStorage.setItem('management_or_normal', management_or_normal)
    # #11446 対応
    localStorage.setItem('management_or_normal', management_or_normal)
    # インスタンスをグローバル変数に保存
    Backbone.AUCNET.info         = new Info()
    Backbone.AUCNET.label        = new Label()
    Backbone.AUCNET.message      = new Message()

    Backbone.history.start
      pushState: false

    # WebSocketをサーバに/buyerとして接続
    Backbone.AUCNET.socket = io.connect(Backbone.AUCNET.CONST.WS_URI, Backbone.AUCNET.SOCKETIO_OPTION)
    #errorを監視
    Backbone.AUCNET.socket.on 'error',      listener.socket_error
    #切断を監視を監視
    Backbone.AUCNET.socket.on 'disconnect', =>
      #console.log '- disconnect -'
    #システムエラーを監視
    Backbone.AUCNET.socket.on 'SendSystemError',  listener.socket_systemError
    #コネクション確立成功を待つ
    Backbone.AUCNET.socket.on 'connect', =>
      #以下、コネクションが確立されてから実行される
      if Backbone.AUCNET.socket.emitOriginal is null
        # emitを置き換えて、パラメータを操作
        emitNew = (name, json) ->
          paramJson = _.clone(json)
          if Backbone.AUCNET.sessionid?
            if json?
              paramJson = _.extend(json, {sessionID : Backbone.AUCNET.sessionid})
            else
              paramJson = {sessionID : Backbone.AUCNET.sessionid}
          Backbone.AUCNET.socket.emitOriginal name, paramJson
        # オリジナルのemitを退避
        Backbone.AUCNET.socket.emitOriginal = Backbone.AUCNET.socket.emit
        # 置き換え
        Backbone.AUCNET.socket.emit = emitNew
      # インスタンスをグローバル変数に保存
      Backbone.AUCNET.loginUser = new LoginUserModel()
      Backbone.AUCNET.loginUser.setSocket()
      # ビューのインスタンス化
      @loginPageView = new LoginPageView {
        el: $(hiddenDomSelector)
      }
    #言語設定
    Backbone.AUCNET.message.getMessage Backbone.AUCNET.LANGUAGE_ID
    Backbone.AUCNET.label.getLabel     Backbone.AUCNET.LANGUAGE_ID
    Backbone.AUCNET.info.getInfo       Backbone.AUCNET.LANGUAGE_ID
