Collection = require 'models/collection'

###
 リクエスト条件のコレクション
###
module.exports = class RequestConditionsCollection extends Collection

  socket: undefined

  defaults:
    _limit     : undefined
    _page      : undefined

  initialize: ->
    #console.debug 'RequestConditionsCollection#initialize'
    _.bindAll @

  ###
   ソケットをセットする
  ###
  setSocket: (socket) ->
    @socket = Backbone.AUCNET.socket
    @socket.on 'GetRequestConditions', (d) =>
      data = JSON.parse(d)
      @reset data.request_Conditions
      @add data.request_Conditions_all
      @trigger 'get_request_conditions'
      return

  # リクエスト検索
  get_request_conditions: (model) ->
    @socket.emit 'GetRequestConditions',
      socketid : Backbone.AUCNET.loginUser.get('_socketid')
      limit  : model._limit
      page  : model._page

