Application = require 'application'
View        = require 'views/view'
template    = require 'views/templates/view_base'
SettingUserView = require 'views/watchlist_btn_view'
SettingAdminView = require 'views/setting_admin_view'
SettingUserView = require 'views/setting_user_view'
util = require 'lib/util'

module.exports = class ConfigPageView extends View
  id         : 'config_page_view'
  template  : template
  title     : 'lb_config'
  className    : ''

  events :
    "click button.toNegoApply" : "moveToNegoApply"

  LOGIN_ROLE_USER  : '0'

  initialize: ->
    #console.debug 'ConfigPageView#initialize'
    _.bindAll @

  afterRender: ->
    #console.debug 'ConfigPageView#afterRender'
    @delegateEvents()
    #タイトル
    @$el.append('<h1 class="ptitle ptitle_50 ptitle_title" data-label="' + @title + '" ></h1>')


    if Backbone.AUCNET.roleID is @LOGIN_ROLE_USER
      settingUserView = new SettingUserView
      @$el.append(settingUserView.render().el)
    else
      settingAdminView = new SettingAdminView
      @$el.append(settingAdminView.render().el)

    # 商談申込みページに戻るボタン
    # クエリ取得

    #param = @getQueryFromUri()
    #console.debug "param= " + JSON.stringify(param)
    #nab = localStorage.nab
    #if nab is "1"
    #  @$(".settingMail").append('<button type="button" class="toNegoApply flash animated" data-label="lb_to_nego_apply"></button>')
      #$("#btnSearchExec").append('<span id="searchCount" class="searchCountClass">' + param + '</span>')
  # 商談申込み画面に遷移
  # locationを元に商談申込み画面に遷移する
  # http://localhost/#config?nab=1&iy=2013&in=00101010
  # のときinventory_year 2013, inventory_no 00101010の申込画面に飛ぶ
  moveToNegoApply: ->
    # クエリ取得
    invYear = localStorage.iy
    invNo = localStorage.in
    #console.debug "inventoryYear: " + invYear
    #console.debug "inventoryNo : " + invNo
    Backbone.AUCNET.RenderController.renderNegoApply [{inventoryYear: invYear, inventoryNo: invNo}]
    localStorage.removeItem 'iy'
    localStorage.removeItem 'in'
    localStorage.removeItem 'nab'
    @$(".settingMail").remove('<button type="button" class="toNegoApply flash animated" data-label="lb_to_nego_apply"></button>')

  # locationからにせクエリを取得
  # http://localhost/#fragment?q=hoge&r=fuga
  # の場合 {q:hoge,r:fuga}をかえす
  getQueryFromUri: ->
    fragment = location.hash
    query = fragment.split("?")?[1] || ""
    param = util.getUrlParamsJson query
    return param
