var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "\n<div class=\"condition\">\n  <div class=\"outerCont\">\n    <h1 data-label=\"lb_search_method_title\"></h1>\n      <div class=\"innerCond\">\n        <div class=\"searchMethod\">\n          "
    + container.escapeExpression((helpers.console || (depth0 && depth0.console) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.count : depth0),{"name":"console","hash":{},"data":data}))
    + "\n          <div id =\"shareStock\" data-label=\"lb_share_stock\">\n          </div>\n          <div id=\"shareStock2\">\n            <span id=\"searchCarCount\"></span>\n            <span id=\"searchCarCountUnit\" data-label=\"lb_search_total_car_num\"></span>\n          </div>\n          <button type='button' id=\"btnSearchMaker\" data-label=\"lb_search_maker_btn\" />\n          <button type='button' id=\"btnSearchInvNo\" data-label=\"lb_search_inv_no_btn\" />\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}