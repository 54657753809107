View               = require 'views/view'
template           = require 'views/templates/data_line_list'
DataLineView       = require 'views/data_line_view'

###
 [検索結果] 一覧表示の親
###
module.exports = class DataLineListView extends View
  # TODO 1画面に本ビューを複数描画する場合はIDの重複に注意
  id       : 'data_line_list'
  template : template
  _mode    : 'b'

  initialize: ->
    _.bindAll @
    if @options.mode
      @_mode = @options.mode

  # このビューがrender()された後に実行される
  # View#renderを参照のこと
  afterRender: ->
    @$el.find(".data_line-1").empty()
    _this = @
    # データリスト
    @collection.each (model) =>
      return if model.get('search_car_data_count')?
      dataLineView = new DataLineView(model: model,list_type : _this._mode)
      @$el.find(".data_line-1").append(dataLineView.render().el)

  # コネクション設定
  setCollection: (param)->
    @collection = param.carDataCollection

  # 表示モード切替
  changeDisplayMode: (mode) ->
    @changeDisplayModeOnly(mode)
    @afterRender()

  # 表示モード切替のみ
  changeDisplayModeOnly: (mode) ->
    @_mode = mode
    switch mode
      when "b" then @$el.find(".data_line-1").addClass("listType2").removeClass("dmList" )
      when "l" then @$el.find(".data_line-1").addClass("dmList" ).removeClass("listType2")
      else          @$el.find(".data_line-1").addClass("dmList" ).removeClass("listType2")
