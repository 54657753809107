Application = require 'application'
Model = require 'models/model'

###
 ログインadmin
###
module.exports = class LoginAdminModel extends Model

  socket: undefined

  defaults:
    _adminid       : undefined
    _adminpassword : undefined
    _userid        : undefined
    _sessionID     : undefined
    userrole       : undefined



  LOGIN_KBN_ADMIN : 0
  LOGIN_KBN_HAKEN : 1
  LOGIN_KBN_USER  : 2

  LOGIN_ROLE_USER  : '0'
  LOGIN_ROLE_ADMIN : '1'
  LOGIN_ROLE_HAKEN : '2'

  initialize: ->
    # ** 注意 **
    # ソケットに対するイベントハンドラはsetSocketで設定する
    _.bindAll @
    @socket = Backbone.AUCNET.socket

  ###
   ソケットをセットする
  ###

  setSocket: =>
    @socket.on 'adminLogin', (d) =>

      data = JSON.parse(d)
      loginKbn = data.loginKbn
      #admin login success
      if loginKbn is @LOGIN_KBN_ADMIN
        # ログインが完了したらパスワードは削除
        @unset '_adminpassword', silent: true
        @set data
        #ソケット破棄
        @socket.removeAllListeners 'adminLogin'
        localStorage.sessionID = data.sessionID
        localStorage.roleID = @LOGIN_ROLE_ADMIN
        Backbone.AUCNET.sessionid = data.sessionID
        Backbone.AUCNET.roleID = @LOGIN_ROLE_ADMIN
        @trigger 'adminLogin_success0' , data

      #haken  login success
      if loginKbn is @LOGIN_KBN_HAKEN
        # ログインが完了したらパスワードは削除
        @unset '_adminpassword', silent: true
        @set data
        #ソケット破棄
        @socket.removeAllListeners 'adminLogin'
        localStorage.sessionID = data.sessionID
        localStorage.roleID = @LOGIN_ROLE_HAKEN
        Backbone.AUCNET.sessionid = data.sessionID
        Backbone.AUCNET.roleID = @LOGIN_ROLE_HAKEN
        @trigger 'adminLogin_success1' , data

      #narisumasurogin success
      if loginKbn is @LOGIN_KBN_USER
        @unset '_adminpassword', silent: true
        @set data
        #ソケット破棄
        @socket.removeAllListeners 'adminLogin'
        localStorage.sessionID = data.sessionID
        localStorage.roleID = @LOGIN_ROLE_ADMIN
        localStorage.hashUserID = data.hashUserID
        Backbone.AUCNET.sessionid = data.sessionID
        Backbone.AUCNET.roleID = @LOGIN_ROLE_USER
        Backbone.AUCNET.hashUserID = data.hashUserID
        @trigger 'adminLogin_success2' , data

      #loginKbn failed
      if loginKbn is 3 #wrong ID or Password
        return @trigger('adminLogin_failed1')
      if loginKbn is 4 #no user in DB
        return @trigger('adminLogin_failed2')
      if loginKbn is 5
        return @trigger('adminLogin_failed3')
      if loginKbn is 6
        return @trigger('adminLogin_failed4')
      if loginKbn is 7
        return @trigger('adminLogin_failed5')
      if loginKbn is 8
        return @trigger('adminLogin_failed6')

    @socket.on 'logincheck', (d) =>
      #ソケット破棄
      @socket.removeAllListeners 'logincheck'
      data = JSON.parse(d)
      if data is true
        Backbone.AUCNET.sessionid = localStorage.sessionID
        Backbone.AUCNET.roleID = localStorage.roleID
        @trigger 'login_success'

  logincheckSoketOn : (callback) =>
    #from gateway  adminLogincheck
    @socket.on 'adminLoginCheck', (d) =>
      #ソケット破棄
      @socket.removeAllListeners 'adminLoginCheck'
      data = JSON.parse(d)

      if data.userID?
        Backbone.AUCNET.sessionid = localStorage.sessionID
        Backbone.AUCNET.roleID = @LOGIN_ROLE_USER
        Backbone.AUCNET.hashUserID = localStorage.hashUserID
        Backbone.AUCNET.loginUser.set
          _userid   : data.userID
      else
        Backbone.AUCNET.sessionid = localStorage.sessionID
        Backbone.AUCNET.roleID = @LOGIN_ROLE_ADMIN
        Backbone.AUCNET.loginUser.set
          _adminid   : data.adminID

      callback data

  #logincheck
  logincheck: ->
    @socket.emit 'adminLoginCheck',
     sessionID : localStorage.sessionID



  # ログイン認証
  authenticate: ->
    @socket.emit 'adminLogin',
      adminid   : @get('_adminid')
      adminpassword : @get('_adminpassword')
      userid   : @get('_userid')

  # ログオフ
  logoff: ->
    if Backbone.AUCNET.timerArray.length > 0
      for timer_item in Backbone.AUCNET.timerArray
        clearInterval timer_item
      Backbone.AUCNET.timerArray = new Array()

    if Backbone.AUCNET.timerArray_list.length > 0
      for timer_item in Backbone.AUCNET.timerArray_list
        clearInterval timer_item
      Backbone.AUCNET.timerArray_list = new Array()

    @socket.on 'adminlogoff', (d) =>
      #ソケット破棄
      @socket.removeAllListeners 'adminlogoff'
      data = JSON.parse(d)

      #localStorage.clear()
      # ↑ページングや、表示切替の情報も消えてしまう為↓に修正
      localStorage.removeItem 'sessionID'
      localStorage.removeItem 'roleID'
      localStorage.removeItem 'hashUserID'
      Backbone.AUCNET.sessionid = ''
      Backbone.AUCNET.roleID = ''
      Backbone.AUCNET.hashUserID = ''
      Backbone.AUCNET.RenderController._killSubView()
      Application.router.navigate 'adminlogin', true

    @socket.emit 'adminlogoff',
      sessionID : localStorage.sessionID



  reconnect : (callback) =>
    #from gateway  adminLogincheck
    @socket.on 'adminReconnect', (d) =>
      #ソケット破棄
      @socket.removeAllListeners 'adminReconnect'
      data = JSON.parse(d)
      callback callback

    @socket.emit 'adminReconnect',
      sessionID : localStorage.sessionID
