application  = require 'application'
MailActivationView    = require 'views/mail_activation_view'

# ログイン中に使いまわすモデルのクラス
DefineModel    = require 'models/define_model'
LoginAdminModel= require 'models/login_admin_model'
LoginUserModel = require 'models/login_user_model'


SideMenu       = require 'models/sidemenu_collection'
SearchModel    = require 'models/search_model'
ReceiveModel   = require 'models/control_receiver'

RenderController = require 'models/render_controller'


listener       = require 'lib/listener'
# ビュークラス
#AdminLoginPageView  = require 'views/admin_login_page_view'
#LoginPageView       = require 'views/login_page_view'
#NewsPageView        = require 'views/news_page_view'
#MylistPageView      = require 'views/mylist_page_view'
#RequestPageView     = require 'views/request_page_view'
#SearchPageView      = require 'views/search_page_view'
#CandidatePageView   = require 'views/candidate_page_view'
NegolistPageView    = require 'views/negolist_page_view'
NegolistallPageView = require 'views/negolistall_page_view'
NegolistadminPageView = require 'views/negolistadmin_page_view'
#NegoPageView        = require 'views/nego_page_view'
SidePageView        = require 'views/util_side_page_view'
PageErrorView       = require 'views/page_error_view'
ConfigPageView      = require 'views/config_page_view'
#NegoApplyView       = require 'views/nego_apply_view'
IndicatorView       = require 'views/indicator_view'
UtilConfirmView = require 'views/util_confirm_view'

# WebSocket通信環境チェック機能
UtilConnectView  = require 'views/util_connect_view'
Connect    = require 'models/connect'

# 著作権表示フッター
UtilFooterView = require "views/util_footer_view"

module.exports = class Router extends Backbone.Router
  # urlの変更を見張り、hostname.com/#hoge のhogeとコールバックをバインドする
  routes:
    ''              : 'index'
    'adminlogin'    : 'adminlogin'    # adminlogin
    'error/:id'     : 'error'         # エラー
    'login(?:param)': 'login'         # ログイン
    #'news'          : 'news'          # 新着
    #'request'       : 'request'       # リクエスト
    #'mylist'        : 'mylist'        # マイリスト
    #'search'        : 'search'        # 車両検索
    #'candidate'     : 'candidate'     # 商談候補一覧
    'negolist'      : 'negolist'      # マイ商談一覧
    'negolistall'   : 'negolistall'   # 他社商談一覧
    'negolistadmin' : 'negolistadmin' # 管理者用商談一覧
    'negocontent'   : 'negocontent'   # 商談内容入力
    'config(?:param)' : 'config'      # 設定

    'activation/:activation_code': 'activation'         # メール認証用
    'nego/:id/:inventory_year/:inventory_no'  : 'nego'  # 商談
    'ans?:param' : 'launchFromANS'                      # ANS連携

    'negoapply/:inventory_year/:inventory_no' : 'negoapply'


  LOGIN_ROLE_USER : '0'
  LOGIN_ROLE_ADMIN : '1'
  LOGIN_ROLE_HAKEN : '2'
  initialize: ->
    @firstConnect = true

  #----------------------
  # 各インスタンス生成
  #　ログイン後に実行される
  #----------------------
  _createInstance : =>
    # ログイン背景を消す
    $('body').removeClass 'login_page_base'

    #　スマホ、アンドロイドはリスト表示しないので、ローカルストレージを上書き
    ua = window.navigator.userAgent;
    LS_KEY_LDM = ".list_disp_mode"
    if (ua.indexOf("ipod") isnt -1 or
        ua.indexOf("iPhone") isnt -1 or
        ua.indexOf("Android") isnt -1)
      disp_mode = 'b'
      #localStorage.setItem('candidate_page'+LS_KEY_LDM, disp_mode)
      #localStorage.setItem('mylist_page'+LS_KEY_LDM, disp_mode)
      localStorage.setItem('negolist_list_my_ongoing'+LS_KEY_LDM, disp_mode)
      localStorage.setItem('negolist_list_my_past'+LS_KEY_LDM, disp_mode)
      localStorage.setItem('negolist_list_admin_ongoing'+LS_KEY_LDM, disp_mode)
      localStorage.setItem('negolist_list_admin_past'+LS_KEY_LDM, disp_mode)
      localStorage.setItem('negolist_list_all_ongoing'+LS_KEY_LDM, disp_mode)
      #localStorage.setItem('request_data_list_base'+LS_KEY_LDM, disp_mode)
      #localStorage.setItem('search_result'+LS_KEY_LDM, disp_mode)
      Backbone.AUCNET.UserAgent = "MT"
    else
      #if !localStorage.getItem('candidate_page' + LS_KEY_LDM)
      #  localStorage.setItem('candidate_page'+LS_KEY_LDM, 'l')
      #if !localStorage.getItem('mylist_page' + LS_KEY_LDM)
      #  localStorage.setItem('mylist_page'+LS_KEY_LDM, 'l')
      if !localStorage.getItem('negolist_list_my_ongoing' + LS_KEY_LDM)
        localStorage.setItem('negolist_list_my_ongoing'+LS_KEY_LDM, 'b')
      if !localStorage.getItem('negolist_list_my_past' + LS_KEY_LDM)
        localStorage.setItem('negolist_list_my_past'+LS_KEY_LDM, 'b')
      if !localStorage.getItem('negolist_list_admin_ongoing' + LS_KEY_LDM)
        localStorage.setItem('negolist_list_admin_ongoing'+LS_KEY_LDM, 'b')
      if !localStorage.getItem('negolist_list_admin_past' + LS_KEY_LDM)
        localStorage.setItem('negolist_list_admin_past'+LS_KEY_LDM, 'b')
      if !localStorage.getItem('negolist_list_all_ongoing' + LS_KEY_LDM)
        localStorage.setItem('negolist_list_all_ongoing'+LS_KEY_LDM, 'b')
      #if !localStorage.getItem('request_data_list_base' + LS_KEY_LDM)
      #  localStorage.setItem('request_data_list_base'+LS_KEY_LDM, 'l')
      #if !localStorage.getItem('search_result' + LS_KEY_LDM)
      #  localStorage.setItem('search_result'+LS_KEY_LDM, 'l')
      Backbone.AUCNET.UserAgent = "PC"

    #@newsPageView        = new NewsPageView()
    #@mylistPageView      = new MylistPageView()
    #@requestPageView     = new RequestPageView()
    #@searchPageView      = new SearchPageView()
    #@candidatePageView   = new CandidatePageView()
    @negolistPageView    = new NegolistPageView()
    @negolistallPageView = new NegolistallPageView()
    @configPageView      = new ConfigPageView()

    #renderController
    Backbone.AUCNET.RenderController = new RenderController
    # タイマー監視関数格納用配列
    Backbone.AUCNET.timerArray = new Array()
    Backbone.AUCNET.timerArray_list = new Array()
    # 商談一覧リストアイテム描画監視関数格納用配列
    Backbone.AUCNET.negoArray_list = new Array()

  # ルート
  index: ->
    sid = localStorage.getItem 'sessionID'
    # セッションIDが残っている場合、自動ログインを実行
    if !_.isEmpty(sid)
      Backbone.AUCNET.sessionid = sid
      # 接続チェック(error発生しない)
      @checkConnect (ret) =>
        # ログインチェック
        @checkLogin (ret,data) =>
          if !ret
            # ログインチェックで正常の場合
            if Backbone.AUCNET.roleID is @LOGIN_ROLE_USER
              @_drawMenu()
              if (data.myNegoCnt?) and (data.myNegoCnt > 0)
                Backbone.AUCNET.RenderController.setCurrentView @negolistPageView
              else
                Backbone.AUCNET.RenderController.setCurrentView @negolistPageView

            else if Backbone.AUCNET.roleID is @LOGIN_ROLE_HAKEN
              @_drawMenu()
              Backbone.AUCNET.RenderController.setCurrentView @negolistPageView

            else if Backbone.AUCNET.roleID is @LOGIN_ROLE_ADMIN
              @navigate 'negolistadmin', true

            else
              @navigate 'login', true

          else
            # セッションIDを削除
            localStorage.removeItem 'sessionID'
            # ログインに飛ばす
            @navigate 'login', true
    else
      # ルートにアクセスしたらログインに飛ばす
      @navigate 'login', true

  # ログイン
  login:(query) ->
    # ログイン背景を表示
    $('body').addClass 'login_page_base'
    if query? then query = "?" + query
    window.location.href = "login.html#{query ? ''}"

  adminlogin: ->
    # ログイン背景を表示
    $('body').addClass 'login_page_base'
    window.location.href = "login-admin.html"

  # 新着
  #news: ->
  #  @checkLogin (ret) =>
  #    if ret
  #      @error 'accsess_error'
  #      return ret
  #    # メニュー表示
  #    @_drawMenu()
  #    Backbone.AUCNET.RenderController.setCurrentView @newsPageView

  # リクエスト
  #request: ->
  #  @checkLogin (ret) =>
  #    if ret
  #      @error 'accsess_error'
  #      return ret
  #    @_drawMenu()
  #    @requestPageView?.suicide()
  #    @requestPageView = new RequestPageView()
  #    Backbone.AUCNET.RenderController.setCurrentView @requestPageView
  #    # 選択中のメニューを光らせる
  #    $('#util_side_navigation').find('.menus_link').removeClass('active')
  #    $('#util_side_navigation').find('[data-menucd="request"]').addClass('active')

  # マイリスト
  #mylist: ->
  #  @checkLogin (ret) =>
  #    if ret
  #      @error 'accsess_error'
  #      return ret
  #    @_drawMenu()
  #    @mylistPageView?.suicide()
  #    @mylistPageView = new MylistPageView()
  #    Backbone.AUCNET.RenderController.setCurrentView @mylistPageView
  #    # 選択中のメニューを光らせる
  #    $('#util_side_navigation').find('.menus_link').removeClass('active')
  #    $('#util_side_navigation').find('[data-menucd="mylist"]').addClass('active')


  # 車両検索
  #search: ->
  #  @checkLogin (ret) =>
  #    if ret
  #      @error 'accsess_error'
  #      return ret
  #    @_drawMenu()
  #    @searchPageView?.suicide()
  #    @searchPageView = new SearchPageView()
  #    Backbone.AUCNET.RenderController.setCurrentView @searchPageView
  #    # 選択中のメニューを光らせる
  #    $('#util_side_navigation').find('.menus_link').removeClass('active')
  #    $('#util_side_navigation').find('[data-menucd="search"]').addClass('active')

  # 商談候補一覧
  #candidate: ->
  #  @checkLogin (ret) =>
  #    if ret
  #      @error 'accsess_error'
  #      return ret
  #    @_drawMenu()
  #    @candidatePageView?.suicide()
  #    @candidatePageView = new CandidatePageView()
  #    Backbone.AUCNET.RenderController.setCurrentView @candidatePageView
  #    # 選択中のメニューを光らせる
  #    $('#util_side_navigation').find('.menus_link').removeClass('active')
  #    $('#util_side_navigation').find('[data-menucd="candidate"]').addClass('active')

  # マイ商談一覧
  negolist: ->
    @checkLogin (ret) =>
      if ret
        @error 'accsess_error'
        return ret
      @_drawMenu()
      # 後始末
      @negolistPageView?.suicide()
      @negolistPageView = new NegolistPageView display_screen : @initial_display_screen_negolist
      @initial_display_screen_negolist = ''
      Backbone.AUCNET.RenderController.setCurrentView @negolistPageView

      # 選択中のメニューを光らせる
      $('#util_side_navigation').find('.menus_link').removeClass('active')
      $('#util_side_navigation').find('[data-menucd="negolist"]').addClass('active')

  # 他社商談一覧
  negolistall: ->
    @checkLogin (ret) =>
      if ret
        @error 'accsess_error'
        return ret
      @_drawMenu()
      @negolistallPageView?.suicide()
      @negolistallPageView = new NegolistallPageView
      Backbone.AUCNET.RenderController.setCurrentView @negolistallPageView

      # 選択中のメニューを光らせる
      $('#util_side_navigation').find('.menus_link').removeClass('active')
      $('#util_side_navigation').find('[data-menucd="negolistall"]').addClass('active')

  # 管理者用商談一覧
  negolistadmin: ->
    @checkLogin (ret) =>
      if ret
        @error 'accsess_error'
        return ret
      @_drawMenu()
      @negolistadminPageView?.suicide()
      @negolistadminPageView = new NegolistadminPageView display_screen : @initial_display_screen_negolist
      @initial_display_screen_negolist = ''
      Backbone.AUCNET.RenderController.setCurrentView @negolistadminPageView

      # 選択中のメニューを光らせる
      $('#util_side_navigation').find('.menus_link').removeClass('active')
      $('#util_side_navigation').find('[data-menucd="negolistadmin"]').addClass('active')


  # 商談
  nego: (id, inventory_year, inventory_no) ->
    @checkLogin (ret) =>
      if ret
        @navigate "login?tc=1&ni=#{id}&iy=#{inventory_year}&in=#{inventory_no}", true
        # @error 'accsess_error'
        return ret
      @_drawMenu()
      # 商談部屋IDをセット
      Backbone.AUCNET.RenderController.renderNegoPage id, inventory_year, inventory_no , false

  #メール認証
  activation : (activation_code) ->
    @checkConnect (ret) =>
      # インスタンスをグローバル変数に保存
      Backbone.AUCNET.loginUser = new LoginUserModel()
      Backbone.AUCNET.loginUser.mailActivate activation_code, (err,message,negoApplyParam) =>
        mailActivationView = new MailActivationView
          message : message
          negoApplyParam : negoApplyParam
        if err
          $('#side_navigation_container').empty()
          $('#main_container').html mailActivationView.render().el
        else
          @_drawMenu()
          @_createInstance()
          $('#main_container').html mailActivationView.render().el

  #商談申込
  negoapply : ( inventory_year, inventory_no) ->
    @checkLogin (ret) =>
      if ret
        @error 'accsess_error'
        return ret
      @_drawMenu()
      json = { inventoryYear : inventory_year , inventoryNo   : inventory_no }
      Backbone.AUCNET.RenderController.renderNegoApply [json] , true

  # 設定
  config :(param = "") =>
    @checkLogin (ret) =>
      if ret
        @error 'accsess_error'
        return ret
      @_drawMenu()
      Backbone.AUCNET.RenderController.setCurrentView @configPageView
      # 選択中のメニューを光らせる
      $('#util_side_navigation').find('.menus_link').removeClass('active')
      $('#util_side_navigation').find('[data-menucd="config"]').addClass('active')

  # ANS連携
  launchFromANS: (param) ->
    params =
      # *必須* 遷移区分 : 0：商談申込、1：商談、2：新着（TOP画面)、3：マイ商談
      param_tc   : @getQueryVariable(param, 'tc')
      # *必須* 会員コード : 平文（暗号化しない）で送信(会員番号の数字部分を「×2」)
      param_mc   : @getQueryVariable(param, 'mc')
      # *必須* トークンID : SSO認証時に使用した値
      param_ti   : @getQueryVariable(param, 'ti')
      # 車両問合番号（登録年） : 商談申込、商談時の対象となる車両問合番号。
      #   遷移区分「商談申込」「商談」の場合は必須。
      param_iy   : @getQueryVariable(param, 'iy')
      # 車両問合番号(問合番号)
      param_in   : @getQueryVariable(param, 'in')

    @checkConnect (ret) =>
      # インスタンスをグローバル変数に保存
      Backbone.AUCNET.loginUser = new LoginUserModel()
      Backbone.AUCNET.loginUser.ansLogin params, () =>
        @_createInstance()

  # メニューが表示されているかチェック
  _drawMenu: ->
    if $("#util_side_navigation").length is 0
      if Backbone.AUCNET.sideMenu is undefined
        Backbone.AUCNET.sideMenu = new SideMenu()
        Backbone.AUCNET.sideMenu.setSocket()
      if @sidePageView is undefined then @sidePageView = new SidePageView()

      $('#side_navigation_container').html @sidePageView.render().el

  # フッタを画面に出力
  _drawFooter: ->
    # フッタ
    if $("#util_footer").length is 0
      utilFooterView = new UtilFooterView
      $("#footer_container").append utilFooterView.render().el


  error: (id) ->
    @pageErrorView = new PageErrorView() if @pageErrorView is undefined
    @pageErrorView.errorID = id
    $('body').html @pageErrorView.render().el

  # GETクエリパラメータ解析関数
  getQueryVariable: (param, variable) ->
    query = param
    vars = query.split("&")
    vars_length = vars.length

    for i in [0...vars_length]
      pair = vars[i].split("=")
      return pair[1]              if pair[0] is variable
    null

  #----------------------
  # ・WebSoketのコネクションチェック
  #   WebSoketのコネクションチェックを行い、接続がなかった場合接続を行う
  # param callback
  #----------------------
  checkConnect : (callback) =>
    if Backbone.AUCNET.socket is undefined
      Backbone.AUCNET.waitFlag = false
      waitOn = =>
        # インディケーター(くるくる)表示
        if Backbone.AUCNET.indicator is undefined
          Backbone.AUCNET.indicator = new IndicatorView
            title   : ''
            message : Backbone.AUCNET.message.get "ms_reconnect"

        unless Backbone.AUCNET.waitFlag
          Backbone.AUCNET.waitFlag = true
          $('body').append(Backbone.AUCNET.indicator.render().el)

      waitOff = =>
        Backbone.AUCNET.waitFlag = false
        if Backbone.AUCNET.indicator
          Backbone.AUCNET.indicator.suicide()

      Backbone.AUCNET.waitOn  = waitOn
      Backbone.AUCNET.waitOff = waitOff

      # WebSocketをサーバに/buyerとして接続
      Backbone.AUCNET.socket = io(Backbone.AUCNET.CONST.WS_URI, Backbone.AUCNET.SOCKETIO_OPTION)
      #errorを監視
      Backbone.AUCNET.socket.on 'error', =>
        # console.log 'WEB_SOKET_ERROR'
      #切断を監視を監視
      Backbone.AUCNET.socket.on 'disconnect', =>
        Backbone.AUCNET.waitOn()
        # console.log 'WEB_SOKET_DISCONNECT'

      Backbone.AUCNET.socket.on 'reconnect_failed', =>
        # console.log '再接続失敗'
        Backbone.AUCNET.waitOff()
        listener.socket_disconnect
      # 再接続成功
      Backbone.AUCNET.socket.on 'reconnect', =>
        # console.log '再接続完了'
        Backbone.AUCNET.loginUser.reconnect (data) ->
          if data.ret
            # console.log '接続成功'
            Backbone.AUCNET.waitOff()
            Backbone.AUCNET.utilConnectView.setStopingFlg true if Backbone.AUCNET.utilConnectView
            confirmView = new UtilConfirmView
              title    : Backbone.AUCNET.message.get 'ms_reconnect_title'
              message  : Backbone.AUCNET.message.get 'ms_reconnect_end'
              cancelDelFlg : true
              # =>(ファットアロー)で渡すとcontextがここになる
              callback : (ok)=>
                if ok
                  Backbone.AUCNET.RenderController.get('current_view').render()
            $("body").append(confirmView.render().el)
          else
            # console.log '再接続失敗'
            Backbone.AUCNET.waitOff()
            listener.socket_disconnect

      # 再接続実行
      Backbone.AUCNET.socket.on 'reconnecting', =>
        # console.log '再接続中'

      #システムエラーを監視
      Backbone.AUCNET.socket.on 'SendSystemError',  listener.socket_systemError

      #コネクション確立成功を待つ
      Backbone.AUCNET.socket.on 'connect', =>
        if @firstConnect
          # console.log "- connect - : firstConnect"
          @firstConnect = false
          #以下、コネクションが確立されてから実行される
          Backbone.AUCNET.DEFINE       = new DefineModel()
          Backbone.AUCNET.searchModel  = new SearchModel()
          Backbone.AUCNET.receiveModel = new ReceiveModel()
          # Websocket　の通信速度監視
          if Backbone.AUCNET.utilConnectView is undefined
            Backbone.AUCNET.utilConnectView = new UtilConnectView
               model: new Connect()
          Backbone.AUCNET.utilConnectView.setStopingFlg true

          $("body").find('#footer_container').append Backbone.AUCNET.utilConnectView.render().el

          # emitを置き換えて、パラメータを操作
          emitNew = (name, json) ->
            paramJson = _.clone(json)
            if Backbone.AUCNET.sessionid?
              if json?
                paramJson = _.extend(json, {sessionID : Backbone.AUCNET.sessionid})
              else
                paramJson = {sessionID : Backbone.AUCNET.sessionid}
            Backbone.AUCNET.socket.emitOriginal name, paramJson
          # オリジナルのemitを退避
          Backbone.AUCNET.socket.emitOriginal = Backbone.AUCNET.socket.emit
          # 置き換え
          Backbone.AUCNET.socket.emit = emitNew
          #言語設定
          @getLanguage Backbone.AUCNET.LANGUAGE_ID
          # フッタ
          @_drawFooter()
          return callback true
        else
          # console.log "- connect - : reConnect"
    else
      # フッタ
      @_drawFooter()
      return callback true

  #----------------------
  #ログインのチェックを行う
  #----------------------
  checkLogin : (callback) =>
    err = false
    #コネクションチェック
    @checkConnect (ret) =>
      if Backbone.AUCNET.loginUser isnt undefined
        return callback err
      #ローカルストレージに保持しているroleIDによって使用するモデルを分ける
      roleID = localStorage.roleID
      if roleID isnt undefined
        if roleID is @LOGIN_ROLE_USER
            Backbone.AUCNET.loginUser = new LoginUserModel()
        else
            Backbone.AUCNET.loginUser = new LoginAdminModel()
      else
        #ローカルストレージに存在しない場合ログイン画面へ
        @navigate 'login',true
        return callback true


      #logincheck用ソケット準備
      Backbone.AUCNET.loginUser.logincheckSoketOn (ret) =>
        if ret
          #インスタンス生成
          @_createInstance()
          return callback err , ret
        else
          #認証エラー
          err = true
          return callback err , ret

      Backbone.AUCNET.loginUser.logincheck()

  # -----------------------------------
  # 言語設定
  # -----------------------------------
  # @param 言語ID
  # setting.coffeeで指定した言語でラベルモデルとメッセージモデルを定義する
  getLanguage: (languageID) ->
    Backbone.AUCNET.message.getMessage languageID
    Backbone.AUCNET.label.getLabel     languageID
    Backbone.AUCNET.info.getInfo       languageID
