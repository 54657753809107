Model = require 'models/model'

###
  サーバから配信される定数受け取り
  受け取りだけして値はBackbone.AUCNET.DEFINEに保存
###
module.exports = class DefineModel extends Model

  socket: undefined

  initialize: ->
    _.bindAll @
    @socket =  Backbone.AUCNET.socket
    @socket.on 'GetDefine', (d) =>
      data = JSON.parse(d)
      @set data.DEFINE
    @socket.emit "GetDefine"

