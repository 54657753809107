###
  インディケータービュー
  （くるくるページ）
###
Application = require 'application'
View        = require 'views/view'
Template    = require 'views/templates/indicator'

module.exports = class IndicatorView extends View
  id       : 'indicator'
  template : Template

  # 定数宣言
  CLASS_NAME = 'IndicatorView'  # クラス名

  initialize: ->
    funcName = 'initialize'

    # 既にconfirmが存在していたら中止する
    if $("#" + @id).length isnt 0
      # 規定クラスで定義されている@renderを
      # 自殺するだけの関数に書き換える
      @render = ->
        _.defer =>
          @suicide()
      return
    _.bindAll @

    ## 背景画面のTab移動とEnter押下とスクロールを無効にする
    $("body").addClass("scrollBlock");
    $(window).on "touchmove.noScroll", (e) ->
      e.preventDefault()
    $(document).bind('keydown', @disableFocusOut)
    $(document).bind('keydown', @disableEnterPress)

    return

  afterRender: ->

  getRenderData: ->
    return {
      title: @options.title
      message: @options.message
    }
  #自分自身を削除する。
  suicide: ->
    $("body").removeClass("scrollBlock");
    $(window).off('.noScroll');
    $(document).unbind('keydown', @disableFocusOut)
    $(document).unbind('keydown', @disableEnterPress)
    @.remove()

  disableFocusOut: (e) ->
    if e.keyCode is 9
      e.stopPropagation()
      e.preventDefault()
      $(e.currentTarget).focus()
      return

  disableEnterPress: (e) ->
    #if e.keyCode is 13
    #  console.log 'PRESS ENTER'
    return
