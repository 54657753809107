View       = require 'views/view'
template   = require 'views/templates/negolist_tab'

###
  商談一覧ヘッダ
  過去/進行中切り替えボタン
###
module.exports = class NegolistHeaderView extends View

  className : 'negolist_header'
  template  : template
  events :
    "click #negolist_btn_my_past"     : "fireMyPast"
    "click #negolist_btn_my_ongoing"  : "fireMyOngoing"
    "click #negolist_btn_all_ongoing" : "fireAllOngoing"

  # 過去商談コレクション
  collection : null

  #検索ツール初期値は現在商談
  currentPage : "ShowMyOngoing"

  initialize: ->
    _.bindAll @
    # 商談中「」台を更新
    # console.log @collection
    @listenTo  @collection, "reset", @updateCarCount

  getRenderData: ->
    months = Backbone.AUCNET.label.get "lb_unit_month_period"
    days   = Backbone.AUCNET.label.get "lb_unit_day_period"

    past = ''
    if Backbone.AUCNET.roleID is '0'
      past = Backbone.AUCNET.DEFINE.get "NEGO_PAST_SEARCH_LIMIT"
      past = past?.replace /months/, months
      past = past?.replace /days/, days

    return {
      carNum: ' '
      past: past
    }

  # 過去商談
  fireMyPast: ->
    @currentPage = "ShowMyPast"
    @trigger "ShowMyPast"

  # 進行中商談
  fireMyOngoing: ->
    @currentPage = "ShowMyOngoing"
    @trigger "ShowMyOngoing"

  updateCarCount: ->
    @$el.find("#negolist_list_tab_past_car_num").text(@collection.getDataCountResult())
