# The application bootstrapper.
Application =
  initialize: ->
    
    # ログイン中に使いまわすモデルのクラス(WEBSoket不要)
    Info           = require 'models/info_model'
    Label          = require 'models/label_model'
    Message        = require 'models/message_model'


    # インスタンスをグローバル変数に保存
    Backbone.AUCNET.info         = new Info()
    Backbone.AUCNET.label        = new Label()
    Backbone.AUCNET.message      = new Message()


    # Router
    Router = require 'lib/router'

    # Ideally, initialized classes should be kept in controllers & mediator.
    # If you're making big webapp, here's more sophisticated skeleton
    # https://github.com/paulmillr/brunch-with-chaplin

    # Instantiate the router
    @router = new Router()

    # Freeze the object
    Object.freeze? this

module.exports = Application
