var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing;

  return "    <div class=\"negolist_item_"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" data-negoid=\""
    + alias1(((helper = (helper = helpers.nego_id || (depth0 != null ? depth0.nego_id : depth0)) != null ? helper : alias3),(typeof helper === "function" ? helper.call(alias2,{"name":"nego_id","hash":{},"data":data}) : helper)))
    + "\">\n"
    + ((stack1 = (helpers.firstItem || (depth0 && depth0.firstItem) || alias3).call(alias2,(depth0 != null ? depth0.list : depth0),{"name":"firstItem","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function", alias5=container.lambda;

  return "      <div class=\"negotiationContainer\">\n        <div class=\"negotiationCarBox\">\n          <div>\n            <div class=\"paddedBox\">\n              <div class=\"notice "
    + alias3((helpers.getRibbonClass || (depth0 && depth0.getRibbonClass) || alias2).call(alias1,(depth0 != null ? depth0.nego_status : depth0),(depth0 != null ? depth0.hash_owner_member_cd : depth0),(depth0 != null ? depth0.hash_buyer_member_cd : depth0),{"name":"getRibbonClass","hash":{},"data":data}))
    + "\"><label class='noPointer' data-label=\""
    + alias3(((helper = (helper = helpers.nego_status_lb || (depth0 != null ? depth0.nego_status_lb : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"nego_status_lb","hash":{},"data":data}) : helper)))
    + "\"></label></div>\n              <div class=\"showdetail\" data-inventory_year=\""
    + alias3(alias5((depth0 != null ? depth0.inventory_year : depth0), depth0))
    + "\" data-inventory_no=\""
    + alias3(alias5((depth0 != null ? depth0.inventory_no : depth0), depth0))
    + "\">\n                "
    + alias3((helpers.console || (depth0 && depth0.console) || alias2).call(alias1,(depth0 != null ? depth0.image : depth0),{"name":"console","hash":{},"data":data}))
    + "\n                "
    + ((stack1 = (helpers.outputImage || (depth0 && depth0.outputImage) || alias2).call(alias1,(depth0 != null ? depth0.image : depth0),{"name":"outputImage","hash":{},"data":data})) != null ? stack1 : "")
    + "\n              </div>\n              <div class=\"carDetails\">\n                <div class=\"inventoryNoArea\">\n                  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isFine : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isSFine : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                  <span data-label=\"lb_nego_inventory_no\" class=\"notBlock\"></span>\n                  <span class=\"inf notBlockValue\">"
    + alias3(((helper = (helper = helpers.inventory_no || (depth0 != null ? depth0.inventory_no : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"inventory_no","hash":{},"data":data}) : helper)))
    + "</span>\n                </div>\n                <span class=\"manufact\">"
    + alias3(((helper = (helper = helpers.carname_full || (depth0 != null ? depth0.carname_full : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"carname_full","hash":{},"data":data}) : helper)))
    + "</span>\n                <span class=\"carName\">"
    + alias3(((helper = (helper = helpers.grade_name_full || (depth0 != null ? depth0.grade_name_full : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"grade_name_full","hash":{},"data":data}) : helper)))
    + "</span>\n              </div>\n              <div class=\"otherDetails negotiation\">\n                <div>\n                  <span data-label=\"lb_nego_model_year\"> </span>\n                  <span>"
    + alias3(alias5((depth0 != null ? depth0.model_year : depth0), depth0))
    + "</span>\n                </div>\n                <div>\n                  <span data-label=\"lb_nego_style\"> </span>\n                  <span>"
    + alias3(alias5((depth0 != null ? depth0.style : depth0), depth0))
    + "</span>\n                </div>\n                <div>\n                  <span data-label=\"lb_nego_estimation\"></span>\n                  <span>"
    + alias3(((helper = (helper = helpers.estimation || (depth0 != null ? depth0.estimation : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"estimation","hash":{},"data":data}) : helper)))
    + "</span>\n                </div>\n                <div>\n                  <span data-label=\"lb_nego_mileage\"></span>\n                  <span>"
    + alias3(alias5((depth0 != null ? depth0.mileage : depth0), depth0))
    + "</span>\n                </div>\n                <div>\n                  <span data-label=\"lb_nego_inspect\"></span>\n                  <span>"
    + alias3(alias5((depth0 != null ? depth0.inspect_expire_date : depth0), depth0))
    + "</span>\n                </div>\n                <div>\n                  <span data-label=\"lb_nego_color\"></span>\n                  <span>"
    + ((stack1 = (helpers.getColorLabel || (depth0 && depth0.getColorLabel) || alias2).call(alias1,(depth0 != null ? depth0.outcolor : depth0),{"name":"getColorLabel","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n                </div>\n              </div>\n              <div class=\"timer_price_wrap\">\n\n\n                <div class=\"salesAmmount priceDetails\" style='float:left;'>\n                  <span data-label=\"lb_nego_owner_price1\"></span>\n"
    + ((stack1 = (helpers.isBigger || (depth0 && depth0.isBigger) || alias2).call(alias1,(depth0 != null ? depth0.owner_price : depth0),(depth0 != null ? depth0.owner_hope_price : depth0),{"name":"isBigger","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "                  <div class=\"askingPrice\">\n"
    + ((stack1 = (helpers.isContract || (depth0 && depth0.isContract) || alias2).call(alias1,(depth0 != null ? depth0.nego_status : depth0),{"name":"isContract","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data})) != null ? stack1 : "")
    + "                  </div>\n                </div>\n                <div class=\"timerWrap cd2 count_down_timer_info_"
    + alias3(alias5((depth0 != null ? depth0.id : depth0), depth0))
    + " ongoing_only\">\n                  <div class=\"mask\">\n                    <div class=\"timerNotice\">\n                      <span data-label=\"lb_nego_remain_time\">残り時間</span><br />\n                      <span class=\"remain_time_little\">&nbsp;&nbsp;&nbsp;</span>    <!-- 30分以内の場合\"<br>わずか！\"を付与する (段落を確保する為「&nbsp;」を付与)-->\n                    </div>\n                    <div class=\"timeLabel\">\n                      <span data-label=\"lb_nego_timelimit\"></span><br>\n                      <span class=\"limitTime\">00:00</span>\n                    </div>\n                  </div>\n                  <div class=\"timer\">\n                    <div class=\"timerInner1\"></div>\n                    <div class=\"timerInner2\"></div>\n                  </div>\n                  <div class=\"timerSecWrap\">\n                    <div class=\"timerSecInner\">\n                      <div class=\"timerSec1\"></div>\n                      <div class=\"timerSec2\"></div>\n                    </div>\n                  </div>\n                </div>\n                "
    + ((stack1 = (helpers.getAgreementWrap || (depth0 && depth0.getAgreementWrap) || alias2).call(alias1,(depth0 != null ? depth0.nego_status : depth0),{"name":"getAgreementWrap","hash":{},"data":data})) != null ? stack1 : "")
    + "\n              </div>\n              <div class=\"clear\"></div>\n            </div>\n            <div class=\"clear\"></div>\n          </div>\n          <div class=\"clear\"></div>\n        </div>\n      </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " <span class=\"fine  notBlock\"></span>";
},"5":function(container,depth0,helpers,partials,data) {
    return "<span class=\"sfine notBlock\"></span>";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                    <span class=\"oldAmmount lineThrough2 onwer\">"
    + container.escapeExpression((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || alias2).call(alias1,(depth0 != null ? depth0.owner_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>\n"
    + ((stack1 = (helpers.isContract || (depth0 && depth0.isContract) || alias2).call(alias1,(depth0 != null ? depth0.nego_status : depth0),{"name":"isContract","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.isEquals || (depth0 && depth0.isEquals) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.owner_hope_price : depth0),(depth0 != null ? depth0.result_price : depth0),{"name":"isEquals","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    return "";
},"11":function(container,depth0,helpers,partials,data) {
    return "                        <!--<span class=\"ammount lineThrough2 owner\">"
    + container.escapeExpression((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.owner_hope_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>-->\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"ammount lineThrough2 owner\">"
    + container.escapeExpression((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.owner_hope_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"ammount lineThrough2 owner\">"
    + container.escapeExpression((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.owner_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "                      <span data-label=\"lb_nego_result_price1\"></span>\n                      <span class=\"ammount lineThrough2\">"
    + container.escapeExpression((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.result_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "                      <span data-label=\"lb_nego_buyer_price1\"></span>\n                      <span class=\"ammount lineThrough2 buyer\">"
    + container.escapeExpression((helpers.numberWithYenComma || (depth0 && depth0.numberWithYenComma) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.buyer_hope_price : depth0),{"name":"numberWithYenComma","hash":{},"data":data}))
    + "</span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.list : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}