Model = require 'models/model'

###
 [車両検索] 検索ツールモデル
###
module.exports = class SearchToolModel extends Model
  socket : undefined

  EVENT_NAME_SEARCH    = 'GetSearchSearchToolCond'
  EVENT_NAME_MYLIST    = 'GetMylistSearchToolCond'
  EVENT_NAME_REQUEST   = 'GetRequestSearchToolCond'
  EVENT_NAME_CANDIDATE = 'GetCandidateSearchToolCond'

  # 初期化
  initialize: ->
    _.bindAll @
    # ソケット設定
    @socket = Backbone.AUCNET.socket

  # 検索条件取得
  getSearchSearchToolCond: (json) ->
    @getSearchToolCond EVENT_NAME_SEARCH, json

  # 検索条件取得(マイリスト)
  getMylistSearchToolCond: (json) ->
    @getSearchToolCond EVENT_NAME_MYLIST, json

  # 検索条件取得(リクエスト)
  getRequestSearchToolCond: (json) ->
    @getSearchToolCond EVENT_NAME_REQUEST, json

  # 検索条件取得(商談候補)
  getCandidateSearchToolCond: (json) ->
    @getSearchToolCond EVENT_NAME_CANDIDATE, json

  # 検索条件取得(共通)
  getSearchToolCond: (evnet, json) =>
    # コールバッククリア
    @socket.removeAllListeners evnet
    # コールバック設定
    @socket.on evnet, (d) =>
      conditions = JSON.parse(d)
      #console.log "search condition = " + JSON.stringify(conditions)
      @conditions = conditions
      @trigger evnet
    # WebSocketに書込
    @socket.emit evnet, json
